// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/dashboard/widgets/WidgetTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.dashboard.widgets.WidgetTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import {SanitizedHtml as goog_soy_data_SanitizedHtml} from 'ts-closure-library/lib/soy/data';
import * as google from 'ts-closure-library/lib/google';
import * as goog_html from 'ts-closure-library/lib/html/safehtml';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$base$scaffolding$PerspectiveBaseTemplate from './../../../base/scaffolding/PerspectiveBaseTemplate.soy.generated.js';
import * as $soy$ts$commons$LinkTemplate from './../../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../../commons/UIUtilsTemplate.soy.generated.js';
import * as $soy$ts$perspectives$metrics$code$TeamscaleCodePerspectiveTemplate from './../../metrics/code/TeamscaleCodePerspectiveTemplate.soy.generated.js';


/**
 * @param {!$widgetFrame.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $widgetFrame = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $widgetFrame$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.widget, opt_data.editable, opt_data.index, opt_data.commit, opt_data.resourceType);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} widget
 * @param {?} editable
 * @param {?} index
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {null|string|undefined=} resourceType
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $widgetFrame$ = function($$areYouAnInternalCaller, $ijData, widget, editable, index, commit, resourceType) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetFrame']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetFrame']({widget: widget, editable: editable, index: index, commit: commit, resourceType: resourceType}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(resourceType == null || typeof resourceType === 'string', 'resourceType', resourceType, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="widget-frame ' + (editable ? 'edit' : '') + '" id="widget' + soy.$$escapeHtmlAttribute(index) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.widgetFrame ts/perspectives/dashboard/widgets/WidgetTemplate.soy:37"' : '') + '><div class="widget-title ' + (editable ? 'editing' : '') + '">' + $titleMenu(null, $ijData) + $widgetTitle$(soy.$$internalCallMarkerDoNotUse, $ijData, widget, commit, resourceType) + '</div>' + (editable ? '<div class="widget-resize-handle"></div><div class="widget-drag-handle"></div>' : '') + '<div class="widget-content ' + (!widget[/** @type {?} */ ('Path')] && !widget[/** @type {?} */ ('Project')] ? 'widget-content-notitle' : '') + '"></div></div>');
};
export { $widgetFrame as widgetFrame };
export { $widgetFrame$ as widgetFrame$ };
/**
 * @typedef {{
 *  widget: ?,
 *  editable: ?,
 *  index: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  resourceType?: (null|string|undefined),
 * }}
 */
$widgetFrame.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $widgetFrame.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.widgetFrame';
}


/**
 * @param {!$usageTreeMapLegend.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $usageTreeMapLegend = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $usageTreeMapLegend$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.unusedPercentage, opt_data.baselineEndDateInformation);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} entries
 * @param {?} unusedPercentage
 * @param {?} baselineEndDateInformation
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $usageTreeMapLegend$ = function($$areYouAnInternalCaller, $ijData, entries, unusedPercentage, baselineEndDateInformation) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.usageTreeMapLegend']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.usageTreeMapLegend']({entries: entries, unusedPercentage: unusedPercentage, baselineEndDateInformation: baselineEndDateInformation}, $ijData);
  }
  soy.assertParamType(Array.isArray(entries), 'entries', entries, '@param', '!Array<?>');
  let $output = '<div class="treemap-ratio-headline"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.usageTreeMapLegend ts/perspectives/dashboard/widgets/WidgetTemplate.soy:71"' : '') + '>' + $soy$ts$commons$UIUtilsTemplate.baselineEndDateInformation$(soy.$$internalCallMarkerDoNotUse, $ijData, baselineEndDateInformation) + ' | <span>Execution Count:</span>';
  const entry16689List = entries;
  const entry16689ListLen = entry16689List.length;
  for (let entry16689Index = 0; entry16689Index < entry16689ListLen; entry16689Index++) {
    const entry16689Data = entry16689List[entry16689Index];
    let $tmp;
    if (entry16689Data[/** @type {?} */ (1)] > 0) {
      $tmp = '10<sup>' + soy.$$escapeHtml(entry16689Data[/** @type {?} */ (1)]) + '</sup>';
    } else if (soy.$$equals(entry16689Data[/** @type {?} */ (1)], 0)) {
      $tmp = '1';
    } else {
      $tmp = '0';
    }
    $output += (entry16689Data[/** @type {?} */ (1)] < 0 ? '<abbr' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + soy.$$round(unusedPercentage * 100, 2) + '% of all methods have not been executed')) + '>' : '') + '<i class="stop icon" style="color:' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(entry16689Data[/** @type {?} */ (0)])) + '; margin-left:10px;"></i><span style="margin-left:5px;">' + $tmp + '</span>' + (entry16689Data[/** @type {?} */ (1)] < 0 ? '</abbr>' : '');
  }
  $output += ' | ';
  const formattedUnusedPercentage__soy16741 = soy.$$round(unusedPercentage * 100, 2);
  $output += '<abbr' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + formattedUnusedPercentage__soy16741 + '% of the code in the given timeframe was not executed.')) + '>Unexecuted: ' + soy.$$escapeHtml(formattedUnusedPercentage__soy16741) + '%</abbr></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $usageTreeMapLegend as usageTreeMapLegend };
export { $usageTreeMapLegend$ as usageTreeMapLegend$ };
/**
 * @typedef {{
 *  entries: !Array<?>,
 *  unusedPercentage: ?,
 *  baselineEndDateInformation: ?,
 * }}
 */
$usageTreeMapLegend.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $usageTreeMapLegend.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.usageTreeMapLegend';
}


/**
 * @param {!$widgetTitle.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $widgetTitle = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $widgetTitle$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.widget, opt_data.commit, opt_data.resourceType);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} widget
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {null|string|undefined=} resourceType
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $widgetTitle$ = function($$areYouAnInternalCaller, $ijData, widget, commit, resourceType) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetTitle']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetTitle']({widget: widget, commit: commit, resourceType: resourceType}, $ijData);
  }
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(resourceType == null || typeof resourceType === 'string', 'resourceType', resourceType, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="title-marker"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.widgetTitle ts/perspectives/dashboard/widgets/WidgetTemplate.soy:119"' : '') + '>' + (widget[/** @type {?} */ ('Action menu')] ? '<div class="pull-left action-menu" style="clear: right;">' + $soy$ts$commons$UIUtilsTemplate.actionDropdown$(soy.$$internalCallMarkerDoNotUse, $ijData, 'action-button', 'pull-left widget-action-button', soy.VERY_UNSAFE.$$ordainSanitizedAttributesForInternalBlocks('style="display: none"')) + '</div>' : '') + '<div class="title-without-action-menu"><div class="analysis-state-marker"></div><strong>' + soy.$$escapeHtml(widget[/** @type {?} */ ('Title')]) + '</strong>' + (soy.$$coerceToBoolean(widget[/** @type {?} */ ('Path')]) && !widget[/** @type {?} */ ('Path')][/** @type {?} */ ('hiddenInWidgetTitle')] ? ' for ' + $soy$ts$perspectives$metrics$code$TeamscaleCodePerspectiveTemplate.metricsLink$(soy.$$internalCallMarkerDoNotUse, $ijData, widget[/** @type {?} */ ('Path')][/** @type {?} */ ('project')], widget[/** @type {?} */ ('Path')][/** @type {?} */ ('path')], commit, undefined, widget[/** @type {?} */ ('Path')][/** @type {?} */ ('project')] + '/' + widget[/** @type {?} */ ('Path')][/** @type {?} */ ('path')], resourceType) : '') + '</div></div>');
};
export { $widgetTitle as widgetTitle };
export { $widgetTitle$ as widgetTitle$ };
/**
 * @typedef {{
 *  widget: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  resourceType?: (null|string|undefined),
 * }}
 */
$widgetTitle.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $widgetTitle.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.widgetTitle';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $titleMenu = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.titleMenu']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.titleMenu'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="mini ui icon buttons edit-buttons"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.titleMenu ts/perspectives/dashboard/widgets/WidgetTemplate.soy:155"' : '') + '><button class="ui compact button dashboard-button widget-button button-edit-marker"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Edit properties')) + '><i class="cog icon"></i></button><button class="ui compact button widget-button button-duplicate-marker"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Duplicate')) + '><i class="clone icon"></i></button><button class="ui compact button widget-button button-delete-marker"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Delete')) + '><i class="red trash icon"></i></button></div> ');
};
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $titleMenu.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.titleMenu';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $loading = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.loading']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.loading'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui segment vertical center aligned widget-loading"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.loading ts/perspectives/dashboard/widgets/WidgetTemplate.soy:176"' : '') + '><i class="large grey spinner loading icon"></i></div>');
};
export { $loading as loading };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $loading.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.loading';
}


/**
 * @param {!$error.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $error = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $error$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} message
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $error$ = function($$areYouAnInternalCaller, $ijData, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.error']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.error']({message: message}, $ijData);
  }
  soy.assertParamType(typeof message === 'string', 'message', message, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.error ts/perspectives/dashboard/widgets/WidgetTemplate.soy:188"' : '') + '><div class="header">Error:</div> ' + soy.$$escapeHtml(message) + '</div>');
};
export { $error as error };
export { $error$ as error$ };
/**
 * @typedef {{
 *  message: string,
 * }}
 */
$error.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $error.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.error';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $warning = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.warning']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.warning'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<i class="widget-warning warning sign icon"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.warning ts/perspectives/dashboard/widgets/WidgetTemplate.soy:197"' : '') + '></i>');
};
export { $warning as warning };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $warning.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.warning';
}


/**
 * @param {!$findingBadge.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $findingBadge = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.color, opt_data.title, opt_data.bold, opt_data.churn, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} color
 * @param {string} title
 * @param {?} bold
 * @param {?} churn
 * @param {?=} link
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $findingBadge$ = function($$areYouAnInternalCaller, $ijData, color, title, bold, churn, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadge']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadge']({color: color, title: title, bold: bold, churn: churn, link: link}, $ijData);
  }
  soy.assertParamType(typeof color === 'string', 'color', color, '@param', 'string');
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span title="' + soy.$$escapeHtmlAttribute(title) + '" class="badge ' + soy.$$escapeHtmlAttribute(color) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadge ts/perspectives/dashboard/widgets/WidgetTemplate.soy:215"' : '') + '>' + (link ? '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(link)) + '" class="badge ' + soy.$$escapeHtmlAttribute(color) + '"> ' + (bold ? '<b>' + soy.$$escapeHtml(churn) + '</b>' : soy.$$escapeHtml(churn)) + '</a>' : ' ' + (bold ? '<b>' + soy.$$escapeHtml(churn) + '</b>' : soy.$$escapeHtml(churn))) + '</span>');
};
/**
 * @typedef {{
 *  color: string,
 *  title: string,
 *  bold: ?,
 *  churn: ?,
 *  link?: (?|undefined),
 * }}
 */
$findingBadge.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadge.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadge';
}


/**
 * @param {!$findingBadgeDetail.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $findingBadgeDetail = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadgeDetail$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.bold, opt_data.project, opt_data.path, opt_data.showFindingsWithColor, opt_data.findingCollection, opt_data.commit, opt_data.baseline);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {boolean} bold
 * @param {string} project
 * @param {string} path
 * @param {string} showFindingsWithColor
 * @param {?} findingCollection
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @param {?=} baseline
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $findingBadgeDetail$ = function($$areYouAnInternalCaller, $ijData, bold, project, path, showFindingsWithColor, findingCollection, commit, baseline) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadgeDetail']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadgeDetail']({bold: bold, project: project, path: path, showFindingsWithColor: showFindingsWithColor, findingCollection: findingCollection, commit: commit, baseline: baseline}, $ijData);
  }
  soy.assertParamType(typeof bold === 'boolean', 'bold', bold, '@param', 'boolean');
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(typeof path === 'string', 'path', path, '@param', 'string');
  soy.assertParamType(typeof showFindingsWithColor === 'string', 'showFindingsWithColor', showFindingsWithColor, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span class="badge-group"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadgeDetail ts/perspectives/dashboard/widgets/WidgetTemplate.soy:245"' : '') + '>' + ((showFindingsWithColor == 'none' || showFindingsWithColor == 'yellow') && !soy.$$equals(findingCollection[/** @type {?} */ ('countYellow')], 0) ? $findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'yellow', 'Yellow findings', bold, findingCollection[/** @type {?} */ ('count')] - findingCollection[/** @type {?} */ ('countRed')], '' + $soy$ts$commons$LinkTemplate.findingsList(/** @type {?} */ ({project: project, uniformPath: path, commit: commit, parameters: {filter: findingCollection[/** @type {?} */ ('filterString')], assessmentFilters: 'red', regex: findingCollection[/** @type {?} */ ('regex')], invert: true, baseline: baseline}}), $ijData)) : '') + ((showFindingsWithColor == 'none' || showFindingsWithColor == 'red') && !soy.$$equals(findingCollection[/** @type {?} */ ('countRed')], 0) ? $findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'red', 'Red findings', bold, findingCollection[/** @type {?} */ ('countRed')], '' + $soy$ts$commons$LinkTemplate.findingsList(/** @type {?} */ ({project: project, uniformPath: path, commit: commit, parameters: {filter: findingCollection[/** @type {?} */ ('filterString')], assessmentFilters: 'yellow', regex: findingCollection[/** @type {?} */ ('regex')], invert: true, baseline: baseline}}), $ijData)) : '') + '</span>');
};
/**
 * @typedef {{
 *  bold: boolean,
 *  project: string,
 *  path: string,
 *  showFindingsWithColor: string,
 *  findingCollection: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  baseline?: (?|undefined),
 * }}
 */
$findingBadgeDetail.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadgeDetail.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.findingBadgeDetail';
}


/**
 * @param {!$findingsSummaryTable.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingsSummaryTable = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryTable']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryTable'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {string} */
  const path = soy.assertParamType(typeof opt_data.path === 'string', 'path', opt_data.path, '@param', 'string');
  /** @type {!Array<?>} */
  const findingSummaries = soy.assertParamType(Array.isArray(opt_data.findingSummaries), 'findingSummaries', opt_data.findingSummaries, '@param', '!Array<?>');
  /** @type {?} */
  const detailLevel = opt_data.detailLevel;
  /** @type {string} */
  const showFindingsWithColor = soy.assertParamType(typeof opt_data.showFindingsWithColor === 'string', 'showFindingsWithColor', opt_data.showFindingsWithColor, '@param', 'string');
  /** @type {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}} */
  const commit = soy.assertParamType(opt_data.commit == null || google.isObject(opt_data.commit), 'commit', opt_data.commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  /** @type {?} */
  const trendInfo = opt_data.trendInfo;
  /** @type {?} */
  const urlEncodedBaseline = opt_data.urlEncodedBaseline;
  let $output = '<div style="width: 100%; overflow: auto; height: 100%; display: flex; flex-direction: column"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryTable ts/perspectives/dashboard/widgets/WidgetTemplate.soy:304"' : '') + '><div style="flex: 1"><table class="ts-metrics-table">';
  const findingSummary17036List = findingSummaries;
  const findingSummary17036ListLen = findingSummary17036List.length;
  for (let findingSummary17036Index = 0; findingSummary17036Index < findingSummary17036ListLen; findingSummary17036Index++) {
    const findingSummary17036Data = findingSummary17036List[findingSummary17036Index];
    $output += '<tr><td><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.findingsList(/** @type {?} */ ({project: project, uniformPath: path, parameters: {filter: findingSummary17036Data[/** @type {?} */ ('filterString')], invert: true, baseline: urlEncodedBaseline}, commit: commit}), $ijData))) + '">' + soy.$$escapeHtml(findingSummary17036Data[/** @type {?} */ ('categoryName')]) + '</a></td><td style="text-align: right;">' + $findingBadgeDetail$(soy.$$internalCallMarkerDoNotUse, $ijData, true, project, path, showFindingsWithColor, findingSummary17036Data, commit, urlEncodedBaseline) + '</td><td style="text-align: right;"></td></tr>';
    if (soy.$$equals(detailLevel, 1) || soy.$$equals(detailLevel, 2)) {
      const findingGroupInfo17085List = findingSummary17036Data[/** @type {?} */ ('groupInfos')];
      const findingGroupInfo17085ListLen = findingGroupInfo17085List.length;
      for (let findingGroupInfo17085Index = 0; findingGroupInfo17085Index < findingGroupInfo17085ListLen; findingGroupInfo17085Index++) {
        const findingGroupInfo17085Data = findingGroupInfo17085List[findingGroupInfo17085Index];
        if (showFindingsWithColor == 'none' && findingGroupInfo17085Data[/** @type {?} */ ('count')] > 0 || showFindingsWithColor == 'yellow' && findingGroupInfo17085Data[/** @type {?} */ ('count')] - findingGroupInfo17085Data[/** @type {?} */ ('countRed')] > 0 || showFindingsWithColor == 'red' && findingGroupInfo17085Data[/** @type {?} */ ('countRed')] > 0) {
          $output += '<tr><td><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.findingsList(/** @type {?} */ ({project: project, uniformPath: path, commit: commit, parameters: {filter: findingGroupInfo17085Data[/** @type {?} */ ('filterString')], invert: true, baseline: urlEncodedBaseline}}), $ijData))) + '">' + soy.$$escapeHtml(findingGroupInfo17085Data[/** @type {?} */ ('groupName')]) + '</a></td><td style="text-align: right;">' + $findingBadgeDetail$(soy.$$internalCallMarkerDoNotUse, $ijData, false, project, path, showFindingsWithColor, findingGroupInfo17085Data, commit, urlEncodedBaseline) + '</td></tr>';
          if (soy.$$equals(detailLevel, 2)) {
            const findingTypeInfo17128List = findingGroupInfo17085Data[/** @type {?} */ ('typeIdInfos')];
            const findingTypeInfo17128ListLen = findingTypeInfo17128List.length;
            for (let findingTypeInfo17128Index = 0; findingTypeInfo17128Index < findingTypeInfo17128ListLen; findingTypeInfo17128Index++) {
              const findingTypeInfo17128Data = findingTypeInfo17128List[findingTypeInfo17128Index];
              $output += showFindingsWithColor == 'none' && findingTypeInfo17128Data[/** @type {?} */ ('count')] > 0 || showFindingsWithColor == 'yellow' && findingTypeInfo17128Data[/** @type {?} */ ('count')] - findingTypeInfo17128Data[/** @type {?} */ ('countRed')] > 0 || showFindingsWithColor == 'red' && findingTypeInfo17128Data[/** @type {?} */ ('countRed')] > 0 ? '<tr><td><i><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.findingsList(/** @type {?} */ ({project: project, uniformPath: path, commit: commit, parameters: {filter: findingTypeInfo17128Data[/** @type {?} */ ('filterString')], invert: true, baseline: urlEncodedBaseline}}), $ijData))) + '">' + soy.$$escapeHtml(findingTypeInfo17128Data[/** @type {?} */ ('name')]) + '</a></i></td><td style="text-align: right;">' + $findingBadgeDetail$(soy.$$internalCallMarkerDoNotUse, $ijData, false, project, path, showFindingsWithColor, findingTypeInfo17128Data, commit, urlEncodedBaseline) + '</td></tr>' : '';
            }
          }
        }
      }
    }
  }
  $output += '</table></div>' + (trendInfo ? '<div class="widget-trend-text" style="background: white">Findings since: ' + $trendInfoLink(/** @type {?} */ (opt_data), $ijData) + '</div>' : '') + '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $findingsSummaryTable as findingsSummaryTable };
/**
 * @typedef {{
 *  project: string,
 *  path: string,
 *  findingSummaries: !Array<?>,
 *  detailLevel: ?,
 *  showFindingsWithColor: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  trendInfo?: (?|undefined),
 *  urlEncodedBaseline?: (?|undefined),
 *  startCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$findingsSummaryTable.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingsSummaryTable.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryTable';
}


/**
 * @param {!$findingsSummaryBarChartContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingsSummaryBarChartContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryBarChartContainer']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryBarChartContainer'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {?} */
  const trendInfo = opt_data.trendInfo;
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="width: 100%; height: 100%"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryBarChartContainer ts/perspectives/dashboard/widgets/WidgetTemplate.soy:406"' : '') + '><div class="bar-chart-container" style="height: 100%; width: 100%; position: relative"></div>' + (trendInfo ? '<div class="widget-trend-text" style="right: 0; vertical-align: bottom; padding-right: 10px">Findings since: ' + $trendInfoLink(/** @type {?} */ (opt_data), $ijData) + '</div>' : '') + '</div>');
};
export { $findingsSummaryBarChartContainer as findingsSummaryBarChartContainer };
/**
 * @typedef {{
 *  project: string,
 *  trendInfo?: (?|undefined),
 *  startCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$findingsSummaryBarChartContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingsSummaryBarChartContainer.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.findingsSummaryBarChartContainer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $metricsBarChart = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.metricsBarChart']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.metricsBarChart'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div style="width: 100%; height: 100%"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.metricsBarChart ts/perspectives/dashboard/widgets/WidgetTemplate.soy:421"' : '') + '><div class="metrics-bar-chart-container" style="height: 100%; width: 100%; position: relative"></div></div>');
};
export { $metricsBarChart as metricsBarChart };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricsBarChart.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.metricsBarChart';
}


/**
 * @param {!$trendInfoLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $trendInfoLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $trendInfoLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.trendInfo, opt_data.startCommit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} trendInfo
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} startCommit
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $trendInfoLink$ = function($$areYouAnInternalCaller, $ijData, project, trendInfo, startCommit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.trendInfoLink']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.trendInfoLink']({project: project, startCommit: startCommit, trendInfo: trendInfo}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(startCommit == null || google.isObject(startCommit), 'startCommit', startCommit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '';
  if (trendInfo) {
    const $tmp = trendInfo[/** @type {?} */ ('type')];
    switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
      case 'BASELINE':
        $output += '<a class="bold grey-500"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + trendInfo[/** @type {?} */ ('longText')])) + ' href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.baselines(/** @type {?} */ ({project: project}), $ijData))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.trendInfoLink ts/perspectives/dashboard/widgets/WidgetTemplate.soy:441"' : '') + '>' + soy.$$escapeHtml(trendInfo[/** @type {?} */ ('shortText')]) + '</a>';
        break;
      case 'REVISION':
        $output += '<a class="bold grey-500"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + trendInfo[/** @type {?} */ ('longText')])) + ' href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.commitDetails(/** @type {?} */ ({project: project, commit: startCommit}), $ijData))) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.trendInfoLink ts/perspectives/dashboard/widgets/WidgetTemplate.soy:450"' : '') + '>' + soy.$$escapeHtml(trendInfo[/** @type {?} */ ('shortText')]) + '</a>';
        break;
      default:
        $output += soy.$$escapeHtml(trendInfo[/** @type {?} */ ('shortText')]);
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: string,
 *  startCommit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  trendInfo: ?,
 * }}
 */
$trendInfoLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $trendInfoLink.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.trendInfoLink';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noWebGlWarning = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.noWebGlWarning']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.noWebGlWarning'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.noWebGlWarning ts/perspectives/dashboard/widgets/WidgetTemplate.soy:470"' : '') + '><strong>Warning:</strong> Your browser does not support WebGL, so rendering is disabled. Search the Internet for your browser\'s name and <strong>WebGL</strong> to learn how to enable WebGL for your browser.</div>');
};
export { $noWebGlWarning as noWebGlWarning };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noWebGlWarning.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.noWebGlWarning';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $testGapTreeMapRatioHeadline = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.testGapTreeMapRatioHeadline']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.testGapTreeMapRatioHeadline'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="treemap-ratio-headline"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.testGapTreeMapRatioHeadline ts/perspectives/dashboard/widgets/WidgetTemplate.soy:482"' : '') + '></div>');
};
export { $testGapTreeMapRatioHeadline as testGapTreeMapRatioHeadline };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testGapTreeMapRatioHeadline.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.testGapTreeMapRatioHeadline';
}


/**
 * @param {!$noDataAvailable.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $noDataAvailable = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $noDataAvailable$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.analysisInProgress);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} analysisInProgress
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noDataAvailable$ = function($$areYouAnInternalCaller, $ijData, analysisInProgress) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.noDataAvailable']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.noDataAvailable']({analysisInProgress: analysisInProgress}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="no-data-available"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.noDataAvailable ts/perspectives/dashboard/widgets/WidgetTemplate.soy:495"' : '') + '><div><strong style="color: #c33434;">Data is unavailable. ' + (analysisInProgress ? 'Analysis in progress... ' : '') + '</strong></div></div>');
};
export { $noDataAvailable as noDataAvailable };
export { $noDataAvailable$ as noDataAvailable$ };
/**
 * @typedef {{
 *  analysisInProgress: ?,
 * }}
 */
$noDataAvailable.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noDataAvailable.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.noDataAvailable';
}


/**
 * @param {!$widgetInfoText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $widgetInfoText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $widgetInfoText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} message
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $widgetInfoText$ = function($$areYouAnInternalCaller, $ijData, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoText']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoText']({message: message}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($soy$ts$base$scaffolding$PerspectiveBaseTemplate.infoText$(soy.$$internalCallMarkerDoNotUse, $ijData, message));
};
export { $widgetInfoText as widgetInfoText };
export { $widgetInfoText$ as widgetInfoText$ };
/**
 * @typedef {{
 *  message: ?,
 * }}
 */
$widgetInfoText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $widgetInfoText.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoText';
}


/**
 * @param {!$warningsTooltipText.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $warningsTooltipText = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $warningsTooltipText$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.warnings);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} warnings
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $warningsTooltipText$ = function($$areYouAnInternalCaller, $ijData, warnings) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.warningsTooltipText']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.warningsTooltipText']({warnings: warnings}, $ijData);
  }
  let $output = '<b' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.warningsTooltipText ts/perspectives/dashboard/widgets/WidgetTemplate.soy:524"' : '') + '>Warnings</b>';
  const warning17362List = warnings;
  const warning17362ListLen = warning17362List.length;
  for (let warning17362Index = 0; warning17362Index < warning17362ListLen; warning17362Index++) {
    const warning17362Data = warning17362List[warning17362Index];
    $output += '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.warningsTooltipText ts/perspectives/dashboard/widgets/WidgetTemplate.soy:526"' : '') + '>' + soy.$$escapeHtml(warning17362Data);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $warningsTooltipText as warningsTooltipText };
export { $warningsTooltipText$ as warningsTooltipText$ };
/**
 * @typedef {{
 *  warnings: ?,
 * }}
 */
$warningsTooltipText.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $warningsTooltipText.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.warningsTooltipText';
}


/**
 * @param {!$widgetInfoTextWithProjectLink.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $widgetInfoTextWithProjectLink = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $widgetInfoTextWithProjectLink$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.message);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} message
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $widgetInfoTextWithProjectLink$ = function($$areYouAnInternalCaller, $ijData, project, message) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoTextWithProjectLink']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoTextWithProjectLink']({project: project, message: message}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="no-data-available" class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoTextWithProjectLink ts/perspectives/dashboard/widgets/WidgetTemplate.soy:540"' : '') + '><span>' + soy.$$escapeHtml(message) + ' Check <a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.uniformLink$(soy.$$internalCallMarkerDoNotUse, $ijData, 'project', 'projects', undefined, undefined, undefined, {name: project, action: 'edit'}))) + '" style="font-weight: bold">project configuration.</a></span></div>');
};
export { $widgetInfoTextWithProjectLink as widgetInfoTextWithProjectLink };
export { $widgetInfoTextWithProjectLink$ as widgetInfoTextWithProjectLink$ };
/**
 * @typedef {{
 *  project: ?,
 *  message: ?,
 * }}
 */
$widgetInfoTextWithProjectLink.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $widgetInfoTextWithProjectLink.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.widgetInfoTextWithProjectLink';
}


/**
 * @param {!$numericMetricValueTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $numericMetricValueTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $numericMetricValueTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.description, opt_data.metricText, opt_data.trendBaselineText, opt_data.deltaText);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} description
 * @param {string} metricText
 * @param {string} trendBaselineText
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|null|string|undefined=} deltaText
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $numericMetricValueTooltip$ = function($$areYouAnInternalCaller, $ijData, description, metricText, trendBaselineText, deltaText) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.numericMetricValueTooltip']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.numericMetricValueTooltip']({description: description, metricText: metricText, deltaText: deltaText, trendBaselineText: trendBaselineText}, $ijData);
  }
  soy.assertParamType(typeof description === 'string', 'description', description, '@param', 'string');
  soy.assertParamType(typeof metricText === 'string', 'metricText', metricText, '@param', 'string');
  soy.assertParamType(deltaText == null || goog_soy_data_SanitizedHtml.isCompatibleWith(deltaText) || typeof deltaText === 'string', 'deltaText', deltaText, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(typeof trendBaselineText === 'string', 'trendBaselineText', trendBaselineText, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$escapeHtml(description) + ' = ' + soy.$$escapeHtml(metricText) + (deltaText ? '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.numericMetricValueTooltip ts/perspectives/dashboard/widgets/WidgetTemplate.soy:566"' : '') + '>Trend = ' + soy.$$escapeHtml(deltaText) + ' ' + soy.$$escapeHtml(trendBaselineText) : ''));
};
export { $numericMetricValueTooltip as numericMetricValueTooltip };
export { $numericMetricValueTooltip$ as numericMetricValueTooltip$ };
/**
 * @typedef {{
 *  description: string,
 *  metricText: string,
 *  deltaText?: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  trendBaselineText: string,
 * }}
 */
$numericMetricValueTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $numericMetricValueTooltip.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.numericMetricValueTooltip';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $defaultImplementation = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.defaultImplementation']) {
    return soy.$$stubsMap['ts.perspectives.dashboard.widgets.WidgetTemplate.defaultImplementation'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<p' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.dashboard.widgets.WidgetTemplate.defaultImplementation ts/perspectives/dashboard/widgets/WidgetTemplate.soy:573"' : '') + '>Default implementation</p>');
};
export { $defaultImplementation as defaultImplementation };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $defaultImplementation.soyTemplateName = 'ts.perspectives.dashboard.widgets.WidgetTemplate.defaultImplementation';
}
