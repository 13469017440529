import { NumericValueFormatter } from 'ts/commons/formatter/NumericValueFormatter';

/** Utility methods for dealing numbers */
export class NumberUtils {
	/**
	 * Rounds the given number with the given number of decimal places (digits after the dot).
	 * Examples:<ul><li>round(100.00, 2) = 100</li> <li>round(100.000001, 2) = 100</li> <li>round(100.1, 2) =
	 * 100.1</li><li>round(100.114392, 2) = 100.11</li> <li>round(100.115392, 2) = 100.12</li> </ul>
	 *
	 * This is useful for displaying numbers as strings in the UI. It has advantages over alternatives such
	 * as<ul><li>100.toFixed(2) = 100.00 (does not remove trailing 0)</li> <li>Math.round(100.1234) = 100 (removes all
	 * decimal places)</li> </ul>
	 *
	 * @param num The number to be rounded
	 * @param maxFractionDigits Maximum number of digits after the decimal point
	 */
	public static round(num: number, maxFractionDigits: number): number {
		const factor = Math.pow(10, maxFractionDigits);
		return Math.round(num * factor) / factor;
	}

	/**
	 * Formats the given percentage as percentage string. The percentage string consists out of the sign, the percentage
	 * value with at most one number after the decimal point and the percentage symbols. Some
	 * Examples:<ul><li>formatAsPercentage(0.95312) = "+95.3%"</li> <li>formatAsPercentage(0) =
	 * "±0%"</li><li>formatAsPercentage(-12) = "-1200%"</li> <li>formatAsPercentage(Number.POSITIVE_INFINITY) =
	 * "+∞%"</li><li>formatAsPercentage(Number.NEGATIVE_INFINITY) = "-∞%"</li><li>formatAsPercentage(Number.NaN) =
	 * "N/A"</li> </ul>
	 *
	 * @param value The provided percentage value. 1.0 translates to 100%.
	 */
	public static formatAsPercentage(value: number): string {
		return NumericValueFormatter.formatAsPercentage(value, { minDecimals: 0, maxDecimals: 1 }, true);
	}
}
