/**
 * Teamscale REST API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit the class manually.
 */

import type { QueryOperation } from './Query';
import type { MutationOperation } from './Mutation';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { OperationInfo } from './ServiceCallOperationHandlerBase';
import type { ABAPLintConfigurationFileImportSummary } from 'typedefs/ABAPLintConfigurationFileImportSummary';
import type { AbapFileMetadata } from 'typedefs/AbapFileMetadata';
import type { AccessKeyDTO } from 'typedefs/AccessKeyDTO';
import type { AffectedFiles } from 'typedefs/AffectedFiles';
import type { AnalysisGroup } from 'typedefs/AnalysisGroup';
import type { AnalysisProfile } from 'typedefs/AnalysisProfile';
import type { AnalysisProfileEditVersion } from 'typedefs/AnalysisProfileEditVersion';
import type { AnalysisProfileHistoryVersionComparison } from 'typedefs/AnalysisProfileHistoryVersionComparison';
import type { AnalysisProfileMetadata } from 'typedefs/AnalysisProfileMetadata';
import type { AnalysisProfileUsageInfoWithProjects } from 'typedefs/AnalysisProfileUsageInfoWithProjects';
import type { AnalysisProfileValidationResult } from 'typedefs/AnalysisProfileValidationResult';
import type { AnalysisProfileWithProjects } from 'typedefs/AnalysisProfileWithProjects';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { AnalysisState } from 'typedefs/AnalysisState';
import type { ArchitectureAssessmentInfo } from 'typedefs/ArchitectureAssessmentInfo';
import type { ArchitectureCommitUploadInfo } from 'typedefs/ArchitectureCommitUploadInfo';
import type { ArchitectureComponentAssignment } from 'typedefs/ArchitectureComponentAssignment';
import type { ArchitectureInfo } from 'typedefs/ArchitectureInfo';
import type { ArchitectureInfoWithUniformPath } from 'typedefs/ArchitectureInfoWithUniformPath';
import type { ArchitectureOverviewInfo } from 'typedefs/ArchitectureOverviewInfo';
import type { ArchitectureWithCommitCount } from 'typedefs/ArchitectureWithCommitCount';
import type { AssessedReviewStatus } from 'typedefs/AssessedReviewStatus';
import type { AuthenticatorMappingReply } from 'typedefs/AuthenticatorMappingReply';
import type { Backup } from 'typedefs/Backup';
import type { BackupExportStatus } from 'typedefs/BackupExportStatus';
import type { BackupImportStatus } from 'typedefs/BackupImportStatus';
import type { BackupJobSummary } from 'typedefs/BackupJobSummary';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { BenchmarkResult } from 'typedefs/BenchmarkResult';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { CancelTriggerRequestBody } from 'typedefs/CancelTriggerRequestBody';
import type { ClangTidyOutsourcedAnalysisRequestParameters } from 'typedefs/ClangTidyOutsourcedAnalysisRequestParameters';
import type { ClangTidyResultsTransport } from 'typedefs/ClangTidyResultsTransport';
import type { CodeCityNode } from 'typedefs/CodeCityNode';
import type { CodeFileInfo } from 'typedefs/CodeFileInfo';
import type { CodeSearchResultsWrapper } from 'typedefs/CodeSearchResultsWrapper';
import type { CommitAlertWithCommit } from 'typedefs/CommitAlertWithCommit';
import type { CommitData } from 'typedefs/CommitData';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { CommitTreeNodeData } from 'typedefs/CommitTreeNodeData';
import type { CommitWithUserName } from 'typedefs/CommitWithUserName';
import type { CommitterDisplayName } from 'typedefs/CommitterDisplayName';
import type { ConfigurationTemplate } from 'typedefs/ConfigurationTemplate';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { ConnectorDescriptorTransport } from 'typedefs/ConnectorDescriptorTransport';
import type { ContainerInfo } from 'typedefs/ContainerInfo';
import type { CopyrightLicenseInfo } from 'typedefs/CopyrightLicenseInfo';
import type { CoverageSourceInfo } from 'typedefs/CoverageSourceInfo';
import type { Credentials } from 'typedefs/Credentials';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardEntryWithPermissions } from 'typedefs/DashboardEntryWithPermissions';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { DeclarationOfAccessibilityContext } from 'typedefs/DeclarationOfAccessibilityContext';
import type { DependencyEntry } from 'typedefs/DependencyEntry';
import type { DependencyWithOccurrenceLocation } from 'typedefs/DependencyWithOccurrenceLocation';
import type { DerivedTestCoverageInfo } from 'typedefs/DerivedTestCoverageInfo';
import type { DetailedInstanceComparisonValue } from 'typedefs/DetailedInstanceComparisonValue';
import type { DetailedJavaScriptErrorLog } from 'typedefs/DetailedJavaScriptErrorLog';
import type { DetailedServiceLog } from 'typedefs/DetailedServiceLog';
import type { DetailedWorkerLog } from 'typedefs/DetailedWorkerLog';
import type { DiffDescription } from 'typedefs/DiffDescription';
import type { DotNetVersionInfo } from 'typedefs/DotNetVersionInfo';
import type { EAnalysisStateEntry } from 'typedefs/EAnalysisState';
import type { EAnalysisToolEntry } from 'typedefs/EAnalysisTool';
import type { EArchitectureFormatEntry } from 'typedefs/EArchitectureFormat';
import type { EArchitectureUploadTypeEntry } from 'typedefs/EArchitectureUploadType';
import type { EAuditExportLanguageEntry } from 'typedefs/EAuditExportLanguage';
import type { EAuditExportTableEntry } from 'typedefs/EAuditExportTable';
import type { EAuthenticationToolEntry } from 'typedefs/EAuthenticationTool';
import type { EBasicPermissionEntry } from 'typedefs/EBasicPermission';
import type { EBasicPermissionScopeEntry } from 'typedefs/EBasicPermissionScope';
import type { EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import type { EClangTidyOutsourcedAnalysisStatusEntry } from 'typedefs/EClangTidyOutsourcedAnalysisStatus';
import type { ECodeRepresentationEntry } from 'typedefs/ECodeRepresentation';
import type { ECommitAttributeEntry } from 'typedefs/ECommitAttribute';
import type { ECommitAuthorSortingOrderEntry } from 'typedefs/ECommitAuthorSortingOrder';
import type { ECommitTypeEntry } from 'typedefs/ECommitType';
import type { EDeleteModeEntry } from 'typedefs/EDeleteMode';
import type { EExtendedResourceTypeEntry } from 'typedefs/EExtendedResourceType';
import type { EExternalDataExportGranularityEntry } from 'typedefs/EExternalDataExportGranularity';
import type { EExternalDataExportTargetEntry } from 'typedefs/EExternalDataExportTarget';
import type { EFileBasedSummaryTypeEntry } from 'typedefs/EFileBasedSummaryType';
import type { EFindingBlacklistOperationEntry } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistTypeEntry } from 'typedefs/EFindingBlacklistType';
import type { EFindingEnablementEntry } from 'typedefs/EFindingEnablement';
import type { EFindingsExportFormatEntry } from 'typedefs/EFindingsExportFormat';
import type { EInstanceComparisonStatusEntry } from 'typedefs/EInstanceComparisonStatus';
import type { EInstanceComparisonTypeEntry } from 'typedefs/EInstanceComparisonType';
import type { EIssueTgaFilterOptionEntry } from 'typedefs/EIssueTgaFilterOption';
import type { EIssuesExportFormatEntry } from 'typedefs/EIssuesExportFormat';
import type { EKeyedObjectTypeEntry } from 'typedefs/EKeyedObjectType';
import type { ELanguageEntry } from 'typedefs/ELanguage';
import type { ELogLevelEntry } from 'typedefs/ELogLevel';
import type { EMergeRequestStatusEntry } from 'typedefs/EMergeRequestStatus';
import type { EProjectScheduleCommandEntry } from 'typedefs/EProjectScheduleCommand';
import type { EQueryTypeEntry } from 'typedefs/EQueryType';
import type { EReportFormatEntry } from 'typedefs/EReportFormat';
import type { ERepositoryConnectorEntry } from 'typedefs/ERepositoryConnector';
import type { ERepresentationEntry } from 'typedefs/ERepresentation';
import type { EResourceTypeEntry } from 'typedefs/EResourceType';
import type { ESchedulerCommandEntry } from 'typedefs/ESchedulerCommand';
import type { ESchedulingModeEntry } from 'typedefs/ESchedulingMode';
import type { ESonarLintIndependentAnalysisStatusEntry } from 'typedefs/ESonarLintIndependentAnalysisStatus';
import type { ESortOrderEntry } from 'typedefs/ESortOrder';
import type { EStatisticsCalculationEntry } from 'typedefs/EStatisticsCalculation';
import type { ETaskResolutionEntry } from 'typedefs/ETaskResolution';
import type { ETaskSortPropertyEntry } from 'typedefs/ETaskSortProperty';
import type { ETaskStatusEntry } from 'typedefs/ETaskStatus';
import type { ETestPrioritizationStrategyEntry } from 'typedefs/ETestPrioritizationStrategy';
import type { ETokenClassEntry } from 'typedefs/ETokenClass';
import type { ETrafficLightColorEntry } from 'typedefs/ETrafficLightColor';
import type { ETypeEntry } from 'typedefs/EType';
import type { EVotingConnectorOptionEntry } from 'typedefs/EVotingConnectorOption';
import type { EvaluatedMetricThresholdPath } from 'typedefs/EvaluatedMetricThresholdPath';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';
import type { ExceptionsTree } from 'typedefs/ExceptionsTree';
import type { ExecutionUnit } from 'typedefs/ExecutionUnit';
import type { ExtendedFindingDelta } from 'typedefs/ExtendedFindingDelta';
import type { ExtendedMergeRequest } from 'typedefs/ExtendedMergeRequest';
import type { ExtendedMergeRequestsInfo } from 'typedefs/ExtendedMergeRequestsInfo';
import type { ExtendedRepositoryLogEntry } from 'typedefs/ExtendedRepositoryLogEntry';
import type { ExtendedTrackedFinding } from 'typedefs/ExtendedTrackedFinding';
import type { ExternalAnalysisCommitStatus } from 'typedefs/ExternalAnalysisCommitStatus';
import type { ExternalAnalysisGroup } from 'typedefs/ExternalAnalysisGroup';
import type { ExternalAnalysisPartitionInfo } from 'typedefs/ExternalAnalysisPartitionInfo';
import type { ExternalAnalysisStatusInfo } from 'typedefs/ExternalAnalysisStatusInfo';
import type { ExternalCredentialsData } from 'typedefs/ExternalCredentialsData';
import type { ExternalCredentialsUsageInfo } from 'typedefs/ExternalCredentialsUsageInfo';
import type { ExternalFindingsDescription } from 'typedefs/ExternalFindingsDescription';
import type { ExternalInstanceComparisonSnapshotDto } from 'typedefs/ExternalInstanceComparisonSnapshotDto';
import type { ExternalStorageBackendData } from 'typedefs/ExternalStorageBackendData';
import type { ExternalToolIssueCustomFieldResult } from 'typedefs/ExternalToolIssueCustomFieldResult';
import type { ExternalXCloneClass } from 'typedefs/ExternalXCloneClass';
import type { ExternalXCloneStatus } from 'typedefs/ExternalXCloneStatus';
import type { ExternalXClonesParameter } from 'typedefs/ExternalXClonesParameter';
import type { FileGroup } from 'typedefs/FileGroup';
import type { FilteredTreeMapWrapper } from 'typedefs/FilteredTreeMapWrapper';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnCount } from 'typedefs/FindingChurnCount';
import type { FindingChurnList } from 'typedefs/FindingChurnList';
import type { FindingDeltaCount } from 'typedefs/FindingDeltaCount';
import type { FindingResolutionResult } from 'typedefs/FindingResolutionResult';
import type { FindingTypeDescription } from 'typedefs/FindingTypeDescription';
import type { FindingsNotificationRules } from 'typedefs/FindingsNotificationRules';
import type { FindingsSummaryInfo } from 'typedefs/FindingsSummaryInfo';
import type { FindingsTreemapWrapper } from 'typedefs/FindingsTreemapWrapper';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { GetTaskToFindingMappingDefaultResponseInner } from 'typedefs/GetTaskToFindingMappingDefaultResponseInner';
import type { GitLabProject } from 'typedefs/GitLabProject';
import type { GitTag } from 'typedefs/GitTag';
import type { GlobalRole } from 'typedefs/GlobalRole';
import type { GroupAssessment } from 'typedefs/GroupAssessment';
import type { Guideline } from 'typedefs/Guideline';
import type { IColorOption } from 'typedefs/IColorOption';
import type { IMetricOption } from 'typedefs/IMetricOption';
import type { ImportedLinksAndTypeResolvedSpecItem } from 'typedefs/ImportedLinksAndTypeResolvedSpecItem';
import type { IncludeExcludePatterns } from 'typedefs/IncludeExcludePatterns';
import type { IndicatorsAndGroups } from 'typedefs/IndicatorsAndGroups';
import type { InstanceComparisonComputationMetadata } from 'typedefs/InstanceComparisonComputationMetadata';
import type { InstanceComparisonResultBase } from 'typedefs/InstanceComparisonResultBase';
import type { InstanceComparisonResultMetadata } from 'typedefs/InstanceComparisonResultMetadata';
import type { InstanceComparisonSnapshotDto } from 'typedefs/InstanceComparisonSnapshotDto';
import type { IssueHierarchy } from 'typedefs/IssueHierarchy';
import type { IssueQueryResultReferenceResolvedSpecItem } from 'typedefs/IssueQueryResultReferenceResolvedSpecItem';
import type { IssueQueryResultUserResolvedTeamscaleIssue } from 'typedefs/IssueQueryResultUserResolvedTeamscaleIssue';
import type { JavaScriptError } from 'typedefs/JavaScriptError';
import type { JobDescriptor } from 'typedefs/JobDescriptor';
import type { JobQueueCountWithDelayedJobs } from 'typedefs/JobQueueCountWithDelayedJobs';
import type { Language } from 'typedefs/Language';
import type { LanguageProcessingInfo } from 'typedefs/LanguageProcessingInfo';
import type { LateExternalUploadProcessingInfo } from 'typedefs/LateExternalUploadProcessingInfo';
import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { LineBasedChangeRegion } from 'typedefs/LineBasedChangeRegion';
import type { LineBasedMethodInfo } from 'typedefs/LineBasedMethodInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { LoadProfile } from 'typedefs/LoadProfile';
import type { LoginContext } from 'typedefs/LoginContext';
import type { MergeRequestDelta } from 'typedefs/MergeRequestDelta';
import type { MergeRequestParentInfoTransport } from 'typedefs/MergeRequestParentInfoTransport';
import type { MethodBasedTreemapWrapper } from 'typedefs/MethodBasedTreemapWrapper';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MethodLocation } from 'typedefs/MethodLocation';
import type { MethodTreeMapNode } from 'typedefs/MethodTreeMapNode';
import type { MetricBadgeEvaluationParameters } from 'typedefs/MetricBadgeEvaluationParameters';
import type { MetricBadgesConfiguration } from 'typedefs/MetricBadgesConfiguration';
import type { MetricDeltaValue } from 'typedefs/MetricDeltaValue';
import type { MetricDirectoryEntry } from 'typedefs/MetricDirectoryEntry';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricDistributionEntry } from 'typedefs/MetricDistributionEntry';
import type { MetricDistributionWithDelta } from 'typedefs/MetricDistributionWithDelta';
import type { MetricNotificationRules } from 'typedefs/MetricNotificationRules';
import type { MetricSchemaChangeEntry } from 'typedefs/MetricSchemaChangeEntry';
import type { MetricTablePage } from 'typedefs/MetricTablePage';
import type { MetricThresholdConfiguration } from 'typedefs/MetricThresholdConfiguration';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import type { MetricsForThresholdProfile } from 'typedefs/MetricsForThresholdProfile';
import type { OpenIdEndpointInfo } from 'typedefs/OpenIdEndpointInfo';
import type { OptionDescriptor } from 'typedefs/OptionDescriptor';
import type { OutlineElement } from 'typedefs/OutlineElement';
import type { PaginatedFindingsWithCount } from 'typedefs/PaginatedFindingsWithCount';
import type { PairListStringFileSummaryInfoRecord } from 'typedefs/PairListStringFileSummaryInfoRecord';
import type { ParseLogEntry } from 'typedefs/ParseLogEntry';
import type { ParseLogOverviewEntry } from 'typedefs/ParseLogOverviewEntry';
import type { PartitionInfo } from 'typedefs/PartitionInfo';
import type { PartitionedTestSet } from 'typedefs/PartitionedTestSet';
import type { PasswordChangeRequest } from 'typedefs/PasswordChangeRequest';
import type { PerformanceMetricsEntry } from 'typedefs/PerformanceMetricsEntry';
import type { PermissionLookupEBasicPermission } from 'typedefs/PermissionLookupEBasicPermission';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { PolarionWorkItemLinkRolesResult } from 'typedefs/PolarionWorkItemLinkRolesResult';
import type { PolarionWorkItemTypeResult } from 'typedefs/PolarionWorkItemTypeResult';
import type { PostponedRollback } from 'typedefs/PostponedRollback';
import type { PostponedRollbackCounts } from 'typedefs/PostponedRollbackCounts';
import type { PreCommit3Result } from 'typedefs/PreCommit3Result';
import type { PreprocessorExpansionsTransport } from 'typedefs/PreprocessorExpansionsTransport';
import type { PreviousNextSiblings } from 'typedefs/PreviousNextSiblings';
import type { PrioritizableTest } from 'typedefs/PrioritizableTest';
import type { PrioritizableTestCluster } from 'typedefs/PrioritizableTestCluster';
import type { ProbeCoverageInfo } from 'typedefs/ProbeCoverageInfo';
import type { ProcessInformation } from 'typedefs/ProcessInformation';
import type { ProfilerConfiguration } from 'typedefs/ProfilerConfiguration';
import type { ProfilerInfo } from 'typedefs/ProfilerInfo';
import type { ProfilerRegistration } from 'typedefs/ProfilerRegistration';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { ProjectConfiguration } from 'typedefs/ProjectConfiguration';
import type { ProjectConnectorStatus } from 'typedefs/ProjectConnectorStatus';
import type { ProjectDescription } from 'typedefs/ProjectDescription';
import type { ProjectIdEntry } from 'typedefs/ProjectIdEntry';
import type { ProjectInfo } from 'typedefs/ProjectInfo';
import type { ProjectLanguage } from 'typedefs/ProjectLanguage';
import type { ProjectLogLevelFrequencies } from 'typedefs/ProjectLogLevelFrequencies';
import type { ProjectNotificationRules } from 'typedefs/ProjectNotificationRules';
import type { ProjectPartitionsInfo } from 'typedefs/ProjectPartitionsInfo';
import type { ProjectPortfolioResult } from 'typedefs/ProjectPortfolioResult';
import type { ProjectRole } from 'typedefs/ProjectRole';
import type { ProjectSchedulingFilter } from 'typedefs/ProjectSchedulingFilter';
import type { ProjectUpdateResult } from 'typedefs/ProjectUpdateResult';
import type { ProjectsConnectorState } from 'typedefs/ProjectsConnectorState';
import type { ProjectsState } from 'typedefs/ProjectsState';
import type { QualityArtifactParameters } from 'typedefs/QualityArtifactParameters';
import type { QualityGoalManualAssessment } from 'typedefs/QualityGoalManualAssessment';
import type { QualityReport } from 'typedefs/QualityReport';
import type { QueryParserFailure } from 'typedefs/QueryParserFailure';
import type { QueryTrendResult } from 'typedefs/QueryTrendResult';
import type { RedundantLiteralInfo } from 'typedefs/RedundantLiteralInfo';
import type { RefactoringSuggestions } from 'typedefs/RefactoringSuggestions';
import type { RelatedIssuesMetricTreemapWrapper } from 'typedefs/RelatedIssuesMetricTreemapWrapper';
import type { ReportSlideBase } from 'typedefs/ReportSlideBase';
import type { ReportSlideWithRenderData } from 'typedefs/ReportSlideWithRenderData';
import type { RepositoryActivitySummary } from 'typedefs/RepositoryActivitySummary';
import type { RepositoryLogEntry } from 'typedefs/RepositoryLogEntry';
import type { RepositoryLogEntryAggregate } from 'typedefs/RepositoryLogEntryAggregate';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { RepositorySummary } from 'typedefs/RepositorySummary';
import type { ResolvedTask } from 'typedefs/ResolvedTask';
import type { ResourceLink } from 'typedefs/ResourceLink';
import type { ReviewComment } from 'typedefs/ReviewComment';
import type { ReviewUploadInfo } from 'typedefs/ReviewUploadInfo';
import type { RiskMethods } from 'typedefs/RiskMethods';
import type { RoleChange } from 'typedefs/RoleChange';
import type { RoleSchemaData } from 'typedefs/RoleSchemaData';
import type { RolesWithAssignmentsEBasicRole } from 'typedefs/RolesWithAssignmentsEBasicRole';
import type { RolesWithAssignmentsProjectRole } from 'typedefs/RolesWithAssignmentsProjectRole';
import type { RulesContainer } from 'typedefs/RulesContainer';
import type { RunningProfilerInfo } from 'typedefs/RunningProfilerInfo';
import type { RunningProfilersResult } from 'typedefs/RunningProfilersResult';
import type { SamlAuthenticationOption } from 'typedefs/SamlAuthenticationOption';
import type { SamlServiceProviderConfiguration } from 'typedefs/SamlServiceProviderConfiguration';
import type { ScoredMetricDirectoryEntry } from 'typedefs/ScoredMetricDirectoryEntry';
import type { SearchResultContainer } from 'typedefs/SearchResultContainer';
import type { SearchSuggestion } from 'typedefs/SearchSuggestion';
import type { SelectedTest } from 'typedefs/SelectedTest';
import type { ShortLogResponseShortInteractionLog } from 'typedefs/ShortLogResponseShortInteractionLog';
import type { ShortLogResponseShortJavaScriptErrorLog } from 'typedefs/ShortLogResponseShortJavaScriptErrorLog';
import type { ShortLogResponseShortLogEntryBase } from 'typedefs/ShortLogResponseShortLogEntryBase';
import type { ShortLogResponseShortServiceLog } from 'typedefs/ShortLogResponseShortServiceLog';
import type { ShortLogResponseShortWorkerLog } from 'typedefs/ShortLogResponseShortWorkerLog';
import type { SimulinkBlockData } from 'typedefs/SimulinkBlockData';
import type { SimulinkFileReferenceDependency } from 'typedefs/SimulinkFileReferenceDependency';
import type { SimulinkModelComparisonResult } from 'typedefs/SimulinkModelComparisonResult';
import type { SingleMetricDeltaTableData } from 'typedefs/SingleMetricDeltaTableData';
import type { SingleMetricDeltaTreemapData } from 'typedefs/SingleMetricDeltaTreemapData';
import type { SinglePreprocessorExpansionTransport } from 'typedefs/SinglePreprocessorExpansionTransport';
import type { SonarLintFindingTransport } from 'typedefs/SonarLintFindingTransport';
import type { SonarLintIndependentAnalysisRequestParameters } from 'typedefs/SonarLintIndependentAnalysisRequestParameters';
import type { SonarQualityProfileImportSummary } from 'typedefs/SonarQualityProfileImportSummary';
import type { SpecItem } from 'typedefs/SpecItem';
import type { SpecItemCodeReference } from 'typedefs/SpecItemCodeReference';
import type { SpecItemGraph } from 'typedefs/SpecItemGraph';
import type { SpecItemReferenceElement } from 'typedefs/SpecItemReferenceElement';
import type { SpecItemReferenceMapping } from 'typedefs/SpecItemReferenceMapping';
import type { SpecItemRelationReference } from 'typedefs/SpecItemRelationReference';
import type { Stacktrace } from 'typedefs/Stacktrace';
import type { StatisticsCalculationContext } from 'typedefs/StatisticsCalculationContext';
import type { StoredQueryDescriptor } from 'typedefs/StoredQueryDescriptor';
import type { SubjectRoleAssignments } from 'typedefs/SubjectRoleAssignments';
import type { SufficientRamResponse } from 'typedefs/SufficientRamResponse';
import type { SupportRequestData } from 'typedefs/SupportRequestData';
import type { SwiftLintConfigurationImportSummary } from 'typedefs/SwiftLintConfigurationImportSummary';
import type { SystemProcessInfo } from 'typedefs/SystemProcessInfo';
import type { Task } from 'typedefs/Task';
import type { TaskWithDetailedFindings } from 'typedefs/TaskWithDetailedFindings';
import type { TasksWithCount } from 'typedefs/TasksWithCount';
import type { TeamscaleIssueStatus } from 'typedefs/TeamscaleIssueStatus';
import type { TeamscaleUploadWizardInput } from 'typedefs/TeamscaleUploadWizardInput';
import type { TeamscaleVersionContainer } from 'typedefs/TeamscaleVersionContainer';
import type { TestCoverageDeltaInfo } from 'typedefs/TestCoverageDeltaInfo';
import type { TestCoverageOverlayData } from 'typedefs/TestCoverageOverlayData';
import type { TestCoveragePartitionInfo } from 'typedefs/TestCoveragePartitionInfo';
import type { TestExecutionWithPartition } from 'typedefs/TestExecutionWithPartition';
import type { TestGapTreeMapWrapper } from 'typedefs/TestGapTreeMapWrapper';
import type { TestHistoryWrapper } from 'typedefs/TestHistoryWrapper';
import type { TestImplementation } from 'typedefs/TestImplementation';
import type { TestListDescriptor } from 'typedefs/TestListDescriptor';
import type { TestMinimizationJobRun } from 'typedefs/TestMinimizationJobRun';
import type { TestMinimizationRequestOptions } from 'typedefs/TestMinimizationRequestOptions';
import type { TestMinimizationResult } from 'typedefs/TestMinimizationResult';
import type { TestPathExecutionWrapper } from 'typedefs/TestPathExecutionWrapper';
import type { TestQueryResult } from 'typedefs/TestQueryResult';
import type { TestWithClusterId } from 'typedefs/TestWithClusterId';
import type { TgaSummary } from 'typedefs/TgaSummary';
import type { TgaTableEntry } from 'typedefs/TgaTableEntry';
import type { TokenElementChurnWithOriginInfo } from 'typedefs/TokenElementChurnWithOriginInfo';
import type { TokenElementDetailBase } from 'typedefs/TokenElementDetailBase';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { TrackedFindingWithDiffInfo } from 'typedefs/TrackedFindingWithDiffInfo';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { TriggerGraphNode } from 'typedefs/TriggerGraphNode';
import type { TriggerMultiReanalysisResult } from 'typedefs/TriggerMultiReanalysisResult';
import type { UnlinkedChangesWrapper } from 'typedefs/UnlinkedChangesWrapper';
import type { UsageDataPreview } from 'typedefs/UsageDataPreview';
import type { UsageDataReportingOption } from 'typedefs/UsageDataReportingOption';
import type { UsageTreeMapWrapper } from 'typedefs/UsageTreeMapWrapper';
import type { UserActivity } from 'typedefs/UserActivity';
import type { UserBatchOperation } from 'typedefs/UserBatchOperation';
import type { UserData } from 'typedefs/UserData';
import type { UserGroup } from 'typedefs/UserGroup';
import type { UserResolvedDashboardTemplateDescriptor } from 'typedefs/UserResolvedDashboardTemplateDescriptor';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedQualityReport } from 'typedefs/UserResolvedQualityReport';
import type { UserResolvedRetrospective } from 'typedefs/UserResolvedRetrospective';
import type { UserResolvedSpecItem } from 'typedefs/UserResolvedSpecItem';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';
import type { UserWithActivity } from 'typedefs/UserWithActivity';
import type { ValidationErrorResponseEntity } from 'typedefs/ValidationErrorResponseEntity';
import type { WidgetContext } from 'typedefs/WidgetContext';
import type { WorkerGroupStatus } from 'typedefs/WorkerGroupStatus';

export type AddExternalMetricsQueryParams = {
	/**
	 * The type of external metric (CODE or NON_CODE)
	 */
	'type'?: ETypeEntry
};

export type AutocompleteIssueIdQueryParams = {
	'search'?: string
};

export type AutocompleteUserGroupQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type AutocompleteUserNameQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type ChangeReviewFindingMessageQueryParams = {
	/**
	 * The new finding message
	 */
	'message'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type ClearPreCommitBranchQueryParams = {
	'commit'?: string, 
	'branch'?: string
};

export type ComputeMetricTableQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to compute the metric table for
	 */
	'uniform-path'?: string, 
	/**
	 * The name of the metric threshold configuration to use
	 */
	'configuration-name': string, 
	/**
	 * Allows to return only the metrics that are in the threshold profile.
	 */
	'limit-to-profile'?: boolean, 
	/**
	 * Allows to limit the considered metrics to the given list.
	 */
	'metrics'?: Array<string>, 
	/**
	 * Allows to specify which partitions should be considered. This only has an effect for test and test execution metrics.
	 */
	'partition'?: Array<string>, 
	/**
	 * Includes data from all partitions.
	 */
	'all-partitions'?: boolean
};

export type CreateAnalysisProfileForLanguagesQueryParams = {
	'name'?: string, 
	'languages'?: Array<ELanguageEntry>
};

export type CreateBackupBody = {
	/**
	 * Include global data in the backup.
	 */
	'backup-global'?: boolean, 
	/**
	 * The backup path. If this is not set, a new internal path will be generated.
	 */
	'backup-path'?: string, 
	/**
	 * The branch (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-branch'?: Array<string>, 
	/**
	 * The start date for export of external data. May be present multiple times.
	 */
	'export-option-date'?: Array<string>, 
	/**
	 * The granularity to apply for export of external data (DISCARD, DAILY, WEEKLY, PRESERVE). May be present multiple times.
	 */
	'export-option-granularity'?: Array<EExternalDataExportGranularityEntry>, 
	/**
	 * The partition (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-partition'?: Array<string>, 
	/**
	 * The partition (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-project'?: Array<string>, 
	/**
	 * The target to apply for export of external data. May be present multiple times.
	 */
	'export-option-target'?: Array<EExternalDataExportTargetEntry>, 
	/**
	 * Includes all user visible projects in the backup.
	 */
	'include-all-visible-projects'?: boolean, 
	/**
	 * Include information about branch labels associated with Git commits. Default: true.
	 */
	'include-git-labeling-info'?: boolean, 
	/**
	 * Include information about pending sessions for external uploads. Default: true.
	 */
	'include-pending-external-upload-sessions'?: boolean, 
	/**
	 * Include project data in the backup. May be present multiple times.
	 */
	'include-project'?: Array<string>
};

export type CreateComparisonBody = {
	/**
	 * The name of the externally uploaded snapshot. If provided, the remote-snapshot-id must not be provided.
	 */
	'external-snapshot-name'?: string, 
	/**
	 * Access token for the remote instance.
	 */
	'remote-access-token'?: string, 
	/**
	 * The ID of the remote snapshot. May be empty or an incomplete prefix, as long as exactly one remote snapshot is uniquely identified.
	 */
	'remote-snapshot-id'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string
};

export type CreateDashboardFromTemplateQueryParams = {
	/**
	 * ID of the template to use as base for the new dashboard.
	 */
	'template': string, 
	/**
	 * ID of the project to use as datasource for the new dashboard.
	 */
	'project': string, 
	/**
	 * Name of the new dashboard.
	 */
	'dashboard-name': string, 
	/**
	 * Path within the project to use as datasource for the new dashboard.
	 */
	'path'?: string
};

export type CreateOrUpdateBaselineQueryParams = {
	/**
	 * Old baseline name, needed if case of baseline renaming
	 */
	'old-name'?: string
};

export type CreateProjectQueryParams = {
	/**
	 * Indicates whether to copy all data (ext. uploads, finding exclusions, etc.) from an existing project. The value is the project id.
	 */
	'copy-data-from-project'?: string, 
	/**
	 * Indicates whether to skip validation of the project. Can be used to force project creation despite validation errors.
	 */
	'skip-project-validation'?: boolean
};

export type CreateQualityReportQueryParams = {
	/**
	 * The id of the report to be copied
	 */
	'from-template'?: string
};

export type CreateRetrospectiveQueryParams = {
	/**
	 * The id of the retrospective to be copied/overwritten
	 */
	'from-template'?: string
};

export type CreateReviewFindingQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type CreateSessionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean
};

export type CreateSnapshotBody = {
	/**
	 * The branch name for which to create the instance comparison snapshot.
	 */
	'branch-name'?: string, 
	/**
	 * Whether a more detailed snapshot should be created (i.e. includes which findings are present). May slow down the snapshot creation and comparison computation depending on instance size.
	 */
	'detailed-snapshot'?: boolean, 
	/**
	 * The timestamp used to determine when to cut off comparison. Useful when one instance has an ongoing analysis, and the other does not.
	 */
	'end-timestamp'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be excluded from the snapshot.
	 */
	'project-exclusion-pattern'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be included in the snapshot.
	 */
	'project-inclusion-pattern'?: string, 
	/**
	 * Access token for the remote instance. May be empty, in this case access token of locally logged in user is assumed.
	 */
	'remote-access-token'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string, 
	/**
	 * Whether to schedule a comparison with a remote instance that uses the same parameters.
	 */
	'schedule-remote-comparison'?: string
};

export type DeleteAllArchitectureCommitsQueryParams = {
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string
};

export type DeleteAllUnprocessedArchitectureCommitsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type DeleteArchitectureQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string
};

export type DeleteExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type DeleteExternalMetricQueryParams = {
	/**
	 * The type of external metric (CODE or NON_CODE)
	 */
	'type'?: ETypeEntry
};

export type DeleteExternalStorageBackendQueryParams = {
	/**
	 * Force deletion of the external storage backend.
	 */
	'force-deletion'?: boolean
};

export type DeleteGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteJobTestsQueryParams = {
	'deleteMode'?: EDeleteModeEntry
};

export type DeleteProjectQueryParams = {
	/**
	 * Whether to force project deletion regardless of current deletion or re-analysis state.
	 */
	'force-delete'?: boolean, 
	/**
	 * Whether all current role assignments should be deleted. Otherwise these can be reimported if a project with the same id or alias is created.
	 */
	'delete-all-assignments'?: boolean, 
	/**
	 * Whether dashboards that only refer to the project to delete or/and non-existing projects should be deleted.
	 */
	'delete-all-dashboards'?: boolean
};

export type DeleteProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DownloadArchitectureAsXmlBody = {
	/**
	 * The architecture data.
	 */
	'architectureData'?: string
};

export type DownloadBenchmarkQueryParams = {
	/**
	 * Uniform path to download a benchmark for
	 */
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type DownloadGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadHeapDumpBody = {
	/**
	 * Whether dead (i.e., unreachable) objects are included in the heap dump.
	 */
	'include-dead-objects'?: boolean
};

export type DownloadMetricHistoryQueryParams = {
	/**
	 * Uniform path to retrieve the metric history for.
	 */
	'uniform-path': string, 
	/**
	 * The begin of the history range (in milliseconds since 1970). If not provided, begins with first available data.
	 */
	'start'?: UnresolvedCommitDescriptor, 
	/**
	 * The end of the history range (in milliseconds since 1970). If not provided, end with current date.
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * Branch to retrieve metric history for
	 */
	'branch'?: string, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * The maximal number of milliseconds of history to return (backwards from the end). If the end timestamp is not defined, the end is fixed at the current date. Default is unlimited.
	 */
	'max-milliseconds'?: number, 
	/**
	 * The index(es) of the metric in the schema for which to retrieve the history
	 */
	'metric-indexes': Array<number>, 
	/**
	 * Additional uniform paths for which to retrieve the metric history. This parameter is only supported for single metric mode, i.e. if this parameter is used, the number of metric indexes must be 1.
	 */
	'additional-path'?: Array<string>
};

export type DownloadProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadSearchResultCsvQueryParams = {
	/**
	 * The query string.
	 */
	'query': string, 
	/**
	 * In which source to search.
	 */
	'source': EQueryTypeEntry, 
	/**
	 * The project the search should be restricted to.
	 */
	'project'?: string, 
	/**
	 * The sub-path to use as search scope (optional)
	 */
	'path'?: string
};

export type DownloadTestSelectionCsvQueryParams = {
	'timeBudgetMillis'?: number
};

export type EditProjectWithConfigurationQueryParams = {
	/**
	 * Parameter for controlling whether to perform a re-analyze of the project if required when changing the project configuration. Certain connector parameter  changes require the project to be re-analyzed for consistency reasons. This flag indicates that the user agreed for a re-analysis.
	 */
	'reanalyze-if-required'?: boolean, 
	/**
	 * Indicates whether to skip validation of the project. Can be used to force project creation despite validation errors.
	 */
	'skip-project-validation'?: boolean
};

export type EditServerOptionQueryParams = {
	/**
	 * If the option should be saved despite failing validation
	 */
	'save-if-validation-fails'?: boolean
};

export type EnableVotingOptionQueryParams = {
	/**
	 * Whether to enable the given voting type for this project
	 */
	'enable'?: boolean
};

export type ExecuteSchedulerCommandBody = {
	'command'?: ESchedulerCommandEntry
};

export type ExportCodeMatchResultQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	'search-term': string, 
	'token-classes'?: Array<ETokenClassEntry>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type ExportExternalFindingsQueryParams = {
	/**
	 * The group to export, if specified
	 */
	'group-name'?: string
};

export type ExportImpactedTestsAsCsvQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type ExportMetricAssessmentAsCSVQueryParams = {
	/**
	 * Id of the metric threshold configuration.
	 */
	'configuration-name': string, 
	/**
	 * The timestamp used as baseline for trends. (No trend will be computed if this parameter is not specified.
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Metric assessment name.
	 */
	'uniform-path': string
};

export type ExportTeamscaleTomlQueryParams = {
	/**
	 * Identifier of the repository for which a Teamscale TOML will be generated.
	 */
	'repositoryId'?: string
};

export type FindInitialCommitQueryParams = {
	/**
	 * The path of the file
	 */
	'uniformPath'?: string
};

export type FindLogEntriesInRangeQueryParams = {
	/**
	 * Start timestamp to return repository log entries after.
	 */
	'start'?: number, 
	/**
	 * End timestamp to return repository log entries before.
	 */
	'end'?: number, 
	/**
	 * Whether or not commits for the timestamps from the start and/or end commit are included.
	 */
	'include-bounds'?: boolean, 
	/**
	 * The amount of repository logs returned when at least one of the commit parameters is omitted.
	 */
	'entry-count'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon. Only log entries from commits merged into the given branch up to the given point will be considered when the start and end timestamps are evaluated.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Controls whether only repository log entries are returned where the current user was the committer.
	 */
	'privacy-aware'?: boolean, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type FlagFindingsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Request operation to perform (e.g. add or remove flagging information).
	 */
	'operation': EFindingBlacklistOperationEntry, 
	/**
	 * The type of flagging (optional for an unflag operation, findings of both types will be unflagged)
	 */
	'type'?: EFindingBlacklistTypeEntry
};

export type GetAbsoluteDateQueryParams = {
	'relativeDate'?: string
};

export type GetActiveUsersForProjectsQueryParams = {
	/**
	 * Number of days
	 */
	'number-of-days'?: number
};

export type GetAffectedFilesCsvQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	/**
	 * The commit type or subtype to include in the response. One of ECommitType or EExternalAnalysisResultType. For regular code commits, the type is 'CODE_COMMIT', which is also the default value.
	 */
	'type'?: string
};

export type GetAffectedFilesForCommitsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	/**
	 * The commit type or subtype to include in the response. One of ECommitType or EExternalAnalysisResultType. For regular code commits, the type is 'CODE_COMMIT', which is also the default value.
	 */
	'type'?: string
};

export type GetAiFindingResolutionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAiSummaryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string
};

export type GetAllArchitectureAssessmentsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllDashboardsQueryParams = {
	/**
	 * If the dashboards should be retrieved for specific project only
	 */
	'project'?: string
};

export type GetAllExternalSnapshotsQueryParams = {
	/**
	 * Whether only the IDs of the snapshots created by the currently logged in user should be returned
	 */
	'only-current-user'?: boolean
};

export type GetAllGitTagsQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetAllMetricThresholdConfigurationNamesQueryParams = {
	/**
	 * Parameter whether to include default configurations.
	 */
	'include-default-configurations'?: boolean
};

export type GetAllMetricsForThresholdProfilesQueryParams = {
	/**
	 * Project for which to return the metrics for threshold profiles.
	 */
	'project'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllProjectFilePathsQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether the files of the source library connector should be included.
	 */
	'include-source-library-connectors'?: boolean
};

export type GetAllProjectFilesQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether the files of the source library connector should be included.
	 */
	'include-source-library-connectors'?: boolean
};

export type GetAllProjectIdsQueryParams = {
	/**
	 * Whether to include projects marked as deleted or not.
	 */
	'include-deleting'?: boolean, 
	/**
	 * Whether to include reanalyzing projects or not.
	 */
	'include-reanalyzing'?: boolean
};

export type GetAllProjectsQueryParams = {
	/**
	 * Whether to include projects marked as deleted or not.
	 */
	'include-deleting'?: boolean, 
	/**
	 * Whether to include reanalyzing projects or not.
	 */
	'include-reanalyzing'?: boolean
};

export type GetAllSnapshotIdsQueryParams = {
	/**
	 * Whether only the IDs of the snapshots created by the currently logged in user should be returned
	 */
	'only-current-user'?: boolean
};

export type GetAllTestExecutionPartitionDetailsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllTestExecutionPartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAnalysisEditHistoryVersionDiffQueryParams = {
	/**
	 * The version number of the newer version
	 */
	'newVersion': number, 
	/**
	 * The version number of the older version
	 */
	'oldVersion': number
};

export type GetAnalysisResultsByPathQueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisResultsByPath1QueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisStatusQueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisStatus1QueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisToolsQueryParams = {
	/**
	 * Languages to retrieve possible tools for
	 */
	'language': Array<ELanguageEntry>
};

export type GetAnalysisToolsForProjectQueryParams = {
	/**
	 * Languages to retrieve the project's tools for
	 */
	'language': Array<ELanguageEntry>
};

export type GetArchitectureAssessmentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetArchitectureComponentAssignmentsQueryParams = {
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetArchitecturePerformanceStatisticsQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetBaselinesQueryParams = {
	'project'?: Array<string>
};

export type GetBasicPreprocessorExpansionsInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBasicRolesQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetBenchmarkResultsAsCsvQueryParams = {
	/**
	 * The non-anonymized projects considered for the benchmark.
	 */
	'projects'?: Array<string>, 
	/**
	 * The anonymized projects considered for the benchmark.
	 */
	'anonymized-projects'?: Array<string>, 
	/**
	 * The metric used for the benchmark.
	 */
	'metric-name'?: string
};

export type GetBranchForSpecItemQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBranchesGetRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetBranchesPostRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetChangeTreemapQueryParams = {
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetChildrenResourceTypesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to retrieve the children resource types for
	 */
	'uniform-path': string
};

export type GetCodeQueryParams = {
	/**
	 * The commit for which the source code should be downloaded.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether to allow missing files by not logging the error. The eclipse plugin does not know whether files are excluded from Teamscale or not, so we disable the logging of these errors in that case.
	 */
	'expectFileUnknown'?: boolean, 
	/**
	 * The requested representation for the file to be downloaded.
	 */
	'representation'?: ECodeRepresentationEntry
};

export type GetCodeChangesQueryParams = {
	/**
	 * The baseline name or baseline timestamp. Only changes that happened after this baseline are returned. If no baseline is given, all changes are reported.
	 */
	'baseline'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon. This can be used to get a historic view on the changes.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeCitiesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to build a code city for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The index for the metric used to determine the height of the displayed nodes.
	 */
	'height-metric'?: number
};

export type GetCodeComponentsTreemapWrapperQueryParams = {
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * Color (as 6-digit hex value without preceding #) for highlighted components.
	 */
	'highlight-color'?: string, 
	/**
	 * Pattern to color only matching components.
	 */
	'highlight-term'?: string, 
	/**
	 * Specifies whether top-level components should be colored or whether languages should be colored
	 */
	'is-show-components'?: boolean
};

export type GetCodeOutlineQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeOwnershipTreemapQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Baseline commit to build code ownership treemap for
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to build a code city for
	 */
	'uniform-path': string, 
	/**
	 * Determines if import commits should be excluded
	 */
	'exclude-merge-commits'?: boolean, 
	/**
	 * Determines if merge commits should be excluded
	 */
	'exclude-import-commits'?: boolean, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean
};

export type GetCodeReferencesAndTestExecutionMappingQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeSearchMatchResultAndTreemapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	'search-term': string, 
	'token-classes'?: Array<ETokenClassEntry>, 
	'is-color-gradation-active'?: boolean, 
	/**
	 * Result list size limit (or 0 for no limit)
	 */
	'preview-size'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeUsageTreemapQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	'uniform-path': string
};

export type GetCommitAlertsQueryParams = {
	/**
	 * Uniform path to retrieve data for. Can be an architecture path.
	 */
	'uniform-path'?: string, 
	/**
	 * If this parameter is given, only alerts whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only alerts whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetCommitsLeftToAnalyzeForMergeRequestQueryParams = {
	/**
	 * The name of the repository the merge request belongs to.
	 */
	'repositoryName'?: string, 
	/**
	 * The source branch of the merge request.
	 */
	'sourceBranch'?: string
};

export type GetCompleteExampleListQueryParams = {
	/**
	 * Whether the \"missingLocal\" or \"missingRemote\" value is requested.
	 */
	'missingLocal'?: boolean
};

export type GetConcurrencyPerformanceDetailsQueryParams = {
	/**
	 * The Analysis States to include, must value from EAnalysisState enum.
	 */
	'state'?: Array<EAnalysisStateEntry>
};

export type GetConfigurationTemplateQueryParams = {
	/**
	 * Languages to retrieve the configuration template for
	 */
	'language'?: Array<ELanguageEntry>, 
	/**
	 * Tools to retrieve the configuration template for
	 */
	'tool'?: Array<EAnalysisToolEntry>, 
	/**
	 * Analysis profile to obtain the default values for the configuration template from
	 */
	'profile'?: string
};

export type GetCopyrightLicensesTreemapQueryParams = {
	/**
	 * Uniform path to retrieve licenses for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The list of customer licenses that should be compared against. Comparison is case sensitive. Licenses are separated by commas.
	 */
	'customer-license'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCoverageSourcesQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor
};

export type GetDebugContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'representation'?: ERepresentationEntry, 
	'pre-commit-id'?: string
};

export type GetDeclarationOfAccessibilityQueryParams = {
	/**
	 * Whether a reload should be forced.
	 */
	'force-reload'?: boolean
};

export type GetDefaultHiddenMetricsQueryParams = {
	/**
	 * the option name for which the default hidden metrics should be returned
	 */
	'option-name': string
};

export type GetDeltaAffectedFilesQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetDependenciesWithOccurrenceLocationQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is set to true, the inverse dependencies on the file identified by the uniform path are returned. This does not work in combination with the types option and will result in an exception.
	 */
	'inverse'?: boolean
};

export type GetDependencyGraphQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetDiffsQueryParams = {
	/**
	 * The full path of the left element of the comparison. Notation: {project}/{uniformPath}#@#{commit}
	 */
	'left': string, 
	/**
	 * The full path of the right element of the comparison. Notation: {project}/{uniformPath}#@#{commit}
	 */
	'right': string, 
	/**
	 * An optional explicit region to focus on. This can be used, e.g., to highlight differences in a clone. The format of this region is 'leftStart-leftEnd:rightStart-rightEnd', e.g. '45-67:22-43'.
	 */
	'region'?: string, 
	/**
	 * A boolean parameter which decides whether or not the comparison should be made by using the normalized codes.
	 */
	'normalized'?: boolean
};

export type GetDirectMetricDirectoryEntryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to retrieve the metric values for
	 */
	'uniform-path': string
};

export type GetDotNetVersionsQueryParams = {
	'project'?: Array<string>
};

export type GetDumpCommitDescriptorQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns commit descriptors as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean
};

export type GetDumpCommitTreeQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns the commit trees as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean, 
	/**
	 * When provided will only dump the commit trees for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetDumpCommitTreeNodesQueryParams = {
	/**
	 * When provided will only dump the commit tree for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetEditedServiceProviderConfigurationQueryParams = {
	'name'?: string
};

export type GetExceptionsHierarchyTreeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetExceptionsHierarchyTreeSVGQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether the DOT format is expected.
	 */
	'isdot'?: boolean
};

export type GetExecutionUnitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetExtendedResourceTypesQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetExternalAnalysisCommitInfosQueryParams = {
	/**
	 * Baseline commit. We only use this for the timestamp. Only upload commits after this timestamp will be considered.
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * End commit. Only upload commits which are reachable via predecessors of this commit will be considered
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean
};

export type GetExternalLinkQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFileSummaryQueryParams = {
	/**
	 * The path to a folder in the local file system for which the summary is created.
	 */
	'path'?: string, 
	/**
	 * Included files of summary, given as ant pattern. Matching is case insensitive.
	 */
	'includes'?: Array<string>, 
	/**
	 * Excluded files of summary, given as ant pattern. Matching is case insensitive.
	 */
	'excludes'?: Array<string>
};

export type GetFilesWithIdenticalContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string, 
	'ignoreWhitespaces'?: boolean
};

export type GetFilesWithIdenticalNamesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string
};

export type GetFindingQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFindingChurnCountQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetFindingChurnListQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't': UnresolvedCommitDescriptor, 
	/**
	 * Maximum number of findings to return per category. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetFindingDeltaQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * Filter findings changed by commits of this user group. The group needs at least 3 members.
	 */
	'group'?: string
};

export type GetFindingDeltaCountQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * Filter findings changed by commits of this user group. The group needs at least 3 members.
	 */
	'group'?: string
};

export type GetFindingSiblingsQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetFindingWithDiffInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFindingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsExportQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container. In the latter case the recursive parameter can be used to specify whether sub-trees should be considered.
	 */
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type GetFindingsSiblingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsSummaryQueryParams = {
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string, 
	/**
	 * Whether finding categories without findings will be included.
	 */
	'report-categories-without-findings'?: boolean, 
	/**
	 * Whether guidelines rules without findings will be included.
	 */
	'report-guideline-rules-without-findings'?: boolean
};

export type GetFindingsTreemapQueryParams = {
	'uniform-path'?: string, 
	/**
	 * Optional: The main color for the treemap.
	 */
	'color'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string
};

export type GetFindingsWithCountQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * A custom issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'custom-issue-query'?: string, 
	/**
	 * The name of a stored issue query to be executed. If provided, only findings of files which were changed in at least one of the resulting issues are returned.
	 */
	'stored-issue-query'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsWithIdsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetFlaggedFindingIdsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsQueryParams = {
	/**
	 * Key of a precomputed (using the \"merge-requests/parent-info\" endpoint) merge-base info. Should be provided in case the finding delta is requested for a (possible) merge request. 
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Commit denoting the start of the flagged finding interval, or the source commit in case of a (possible) merge. Must be present when \"merge-base-cache-key\" is provided.
	 */
	'from'?: UnresolvedCommitDescriptor, 
	/**
	 * Commit denoting the end of the flagged finding interval, or the target commit in case of a (possible) merge.
	 */
	'to': UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetFlaggedFindingsDeltaQueryParams = {
	/**
	 * Key of a precomputed (using the \"merge-requests/parent-info\" endpoint) merge-base info. Should be provided in case the finding delta is requested for a (possible) merge request. 
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Commit denoting the start of the flagged finding interval, or the source commit in case of a (possible) merge. Must be present when \"merge-base-cache-key\" is provided.
	 */
	'from'?: UnresolvedCommitDescriptor, 
	/**
	 * Commit denoting the end of the flagged finding interval, or the target commit in case of a (possible) merge.
	 */
	'to': UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetFlaggedFindingsInfosQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetFormattedTokenElementInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string
};

export type GetGitHubAppInstallationRepositoriesQueryParams = {
	'appId'?: string
};

export type GetGitTagByNameQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetGlobalBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>, 
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetGlobalContainerInfoQueryParams = {
	/**
	 * The uniform path of the parent directory.
	 */
	'uniform-path'?: string
};

export type GetGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalFirstUIBranchGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalMetricDirectorySchemasQueryParams = {
	/**
	 * List of uniform paths to retrieve metric directory schemas for
	 */
	'uniform-path'?: Array<string>
};

export type GetGlobalRecentBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGroupAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetHasUnlinkedChangesExistQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetHealthStatusQueryParams = {
	/**
	 * List of system health checks to perform
	 */
	'check'?: Array<string>, 
	/**
	 * Determines if only critical health check entries should be returned
	 */
	'critical-only'?: boolean
};

export type GetHistoryForChangedMethodsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>
};

export type GetImpactedTestsQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetImpactedTestsFromAvailableTestsQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetInstancePermissionsQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetIssueFindingChurnQueryParams = {
	/**
	 * If set, findings that have already been resolved will not be included in the result
	 */
	'exclude-resolved-findings'?: boolean, 
	/**
	 * If set, findings that have been flagged as tolerated or false-positives will not be included in the result
	 */
	'exclude-flagged-findings'?: boolean, 
	/**
	 * Maximum number of findings to return per category. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetIssueImpactedTestsQueryParams = {
	/**
	 * Whether the impacted tests of the child issues should be included
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The branch from which the coverage is retrieved
	 */
	'branch'?: string, 
	/**
	 * If given only returns tests from the given partitions
	 */
	'partition'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean
};

export type GetIssueQueryTgaSummaryQueryParams = {
	/**
	 * The issue query
	 */
	'query': string, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetIssueQueryTrendQueryParams = {
	/**
	 * Issue query
	 */
	'query'?: string
};

export type GetIssueTreemapQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to build an issue treemap for
	 */
	'uniform-path': string, 
	/**
	 * Issue query to build a treemap for
	 */
	'query'?: string, 
	/**
	 * Exclude files that are not referenced by issues
	 */
	'exclude-non-referenced'?: boolean, 
	/**
	 * Specifies how the statistics information displayed in the subtitle should be calculated.
	 */
	'summary-type'?: EFileBasedSummaryTypeEntry, 
	/**
	 * Specifies a threshold for numeric metric summaries. The percent of files/area that violate the given threshold will be displayed in the summary.
	 */
	'violation-threshold'?: number, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean
};

export type GetIssueTrendAsCsvQueryParams = {
	/**
	 * Issue query
	 */
	'query': string
};

export type GetIssuesByRepositoryCommitsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetIssuesDetailsQueryParams = {
	/**
	 * IDs of the issues to retrieve. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator '|', followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \"TS-123\" is imported using an issue tracker connector with the connector ID \"issues1\". The expected ID is then \"issues1|TS-123\".
	 */
	'issue-ids'?: Array<string>
};

export type GetIssuesExportQueryParams = {
	/**
	 * The current query.
	 */
	'query'?: string, 
	'tga-filter'?: EIssueTgaFilterOptionEntry, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetKnownSpecItemColumnsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetKubernetesManifestQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetLanguageRulesQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>
};

export type GetLanguageRulesForAnalysisProfileQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>, 
	/**
	 * Whether to include rules that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledRules'?: boolean, 
	/**
	 * Whether to include analysis groups that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledGroups'?: boolean, 
	/**
	 * Whether the current analysis profile is on create mode. 
	 */
	'createMode'?: boolean, 
	/**
	 * The code scope the rules should be calculated for. If no code scope is specified, the default code scope is selected.
	 */
	'codeScope'?: string
};

export type GetLanguageRulesForProjectQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>, 
	/**
	 * Whether to include rules that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledRules'?: boolean, 
	/**
	 * Whether to include analysis groups that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledGroups'?: boolean, 
	/**
	 * Whether the current analysis profile is on create mode. 
	 */
	'createMode'?: boolean, 
	/**
	 * The code scope the rules should be calculated for. If no code scope is specified, the default code scope is selected.
	 */
	'codeScope'?: string
};

export type GetLanguagesForGitlabProjectQueryParams = {
	/**
	 * ID of the GitLab repository
	 */
	'repoId'?: string
};

export type GetLastChangeLogEntryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether non-code commits such as external uploads and code reviews should be ignored for the last change date.
	 */
	'exclude-non-code-commits'?: boolean
};

export type GetLastCommitOfTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'commit-type'?: ECommitTypeEntry
};

export type GetLicenseInfoQueryParams = {
	/**
	 * Reload license information
	 */
	'reload'?: boolean
};

export type GetLicensesQueryParams = {
	/**
	 * The list of customer licenses that should be compared against. Comparison is case sensitive. Licenses are separated by commas.
	 */
	'customer-license'?: Array<string>, 
	/**
	 * If given filters the results to this sub-path (including nested resources)
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetListOfActiveCommittersQueryParams = {
	/**
	 * Number of days
	 */
	'number-of-days'?: number
};

export type GetListOfActiveCommittersForProjectsQueryParams = {
	/**
	 * Number of days
	 */
	'number-of-days'?: number
};

export type GetLoginContextQueryParams = {
	/**
	 * A URL encoded redirection target that is used to build the redirection link for SSO providers.
	 */
	'redirection-target'?: string
};

export type GetMergeRequestAffectedFilesQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string
};

export type GetMergeRequestChangedLinesQueryParams = {
	'origin-path': string, 
	'path': string
};

export type GetMergeRequestDeltaQueryParams = {
	/**
	 * Maximum number of findings to return per category. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetMergeRequestFindingChurnQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetMergeRequestParentInfoQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Whether only the merge-base field in the result object should be filled.
	 */
	'merge-base-only'?: boolean
};

export type GetMergeRequestRepositoryChurnQueryParams = {
	/**
	 * Source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * Target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMetadataForFileQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetMethodBasedTreemapQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to build a treemap for
	 */
	'uniform-path': string, 
	/**
	 * Regular expressions the individual file paths must match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the individual file paths must not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * Whether issues should be loaded and referenced to methods.
	 */
	'should-load-issues'?: boolean, 
	/**
	 * The query to use for loading issues. May be empty, to denote that all known issues should be loaded.
	 */
	'query'?: string, 
	/**
	 * Specifies the minimum number of issue references. Methods with fewer issue references will be treated as if they would not have any reference at all. 
	 */
	'min-reference-count'?: number, 
	/**
	 * Whether methods, which aren't referenced by enough issues loaded by `query`, should be excluded from the result treemap altogether.
	 */
	'exclude-non-referenced'?: boolean, 
	/**
	 * The treemap node color to use for methods, which are not referenced by enough issues loaded by the provided `query` (in case they are not excluded).
	 */
	'non-referenced-methods-color'?: string, 
	/**
	 * The metric option to use.
	 */
	'metric': IMetricOption, 
	/**
	 * The coloring strategy to use.
	 */
	'coloring': IColorOption, 
	/**
	 * Specifies how the statistics information displayed in the subtitle should be calculated.
	 */
	'statistics-calculation': EStatisticsCalculationEntry, 
	/**
	 * The context for the statistics calculation
	 */
	'statistics-calculation-context': StatisticsCalculationContext
};

export type GetMethodHistoryQueryParams = {
	/**
	 * The character-based offset in file on which the method starts (at the specified time).
	 */
	'start-offset': number, 
	/**
	 * The character-based offset in file on which the method ends (at the specified time).
	 */
	'end-offset': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The partitions from which coverage uploads should be retrieved. If not set, all partitions in the project are used.
	 */
	'partitions'?: Array<string>, 
	/**
	 * The cross-annotation projects to retrieve execution information from.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The baseline until to compute detailed history.
	 */
	'baseline-timestamp'?: number, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMethodsExecutedByTestQueryParams = {
	'query': string, 
	/**
	 * The partitions to consider for the test executions
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetMethodsForFileQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>
};

export type GetMetricAssessmentWithUniformPathQueryParams = {
	/**
	 * Uniform path of requested file or directory.
	 */
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * ID of the metric threshold configuration.
	 */
	'configuration-name'?: string, 
	/**
	 * The timestamp used as baseline for trends. If this parameter is missing, no trend is computed.
	 */
	'baseline'?: UnresolvedCommitDescriptor
};

export type GetMetricBenchmarkQueryParams = {
	/**
	 * The metric used for the benchmark.
	 */
	'metric-name': string, 
	/**
	 * The anonymized project ids considered for the benchmark.
	 */
	'anonymized-projects'?: Array<string>, 
	/**
	 * The non-anonymized project ids considered for the benchmark.
	 */
	'projects'?: Array<string>
};

export type GetMetricDeltaQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetMetricDistributionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the metric distribution for
	 */
	'uniform-path': string, 
	/**
	 * The index (in the metrics schema) of the principal metric delta.
	 */
	'principal-metric-index': number, 
	/**
	 * A list of indexes (in the metrics schema) denoting the metric deltas for which to compute the metric delta distribution.
	 */
	'metric-indexes'?: Array<number>, 
	/**
	 * A list of boundaries for the desired distribution intervals.
	 */
	'boundaries'?: Array<number>
};

export type GetMetricDistributionDeltaQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The index (in the metrics schema) of the principal metric delta.
	 */
	'principal-metric-index': number, 
	/**
	 * A list of indexes (in the metrics schema) denoting the metric deltas for which to compute the metric delta distribution.
	 */
	'metric-indexes'?: Array<number>, 
	/**
	 * A list of boundaries for the desired distribution intervals.
	 */
	'boundaries'?: Array<number>
};

export type GetMetricFileDistributionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to compute metric file distribution for
	 */
	'uniform-path': string, 
	/**
	 * Metric name to compute file distribution for
	 */
	'metric-name': string, 
	/**
	 * Regular expressions identifying the file to compute the metric distribution for
	 */
	'file-regexps'?: Array<string>
};

export type GetMetricHistoryQueryParams = {
	/**
	 * Uniform path to retrieve the metric history for.
	 */
	'uniform-path': string, 
	/**
	 * The begin of the history range (in milliseconds since 1970). If not provided, begins with first available data.
	 */
	'start'?: UnresolvedCommitDescriptor, 
	/**
	 * The end of the history range (in milliseconds since 1970). If not provided, end with current date.
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * Branch to retrieve metric history for
	 */
	'branch'?: string, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * The maximal number of milliseconds of history to return (backwards from the end). If the end timestamp is not defined, the end is fixed at the current date. Default is unlimited.
	 */
	'max-milliseconds'?: number
};

export type GetMetricHistoryCommitsQueryParams = {
	/**
	 * The begin of the history range (in milliseconds since 1970). If not provided, begins with first available data.
	 */
	'start'?: UnresolvedCommitDescriptor, 
	/**
	 * The end of the history range (in milliseconds since 1970). If not provided, end with current date.
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * Branch to retrieve metric history for
	 */
	'branch'?: string, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * The maximal number of milliseconds of history to return (backwards from the end). If the end timestamp is not defined, the end is fixed at the current date. Default is unlimited.
	 */
	'max-milliseconds'?: number, 
	/**
	 * The timestamps to look up.
	 */
	'timestamp'?: Array<number>
};

export type GetMetricHotspotsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to compute metric hotspots for
	 */
	'uniform-path': string, 
	/**
	 * Metric indexes to compute metric hotspots for
	 */
	'metric-indexes': Array<number>, 
	/**
	 * Maximum result count to return
	 */
	'num-results': number, 
	/**
	 * The score at which to perform a cutoff (i.e., files with this score or higher will no longer be displayed)
	 */
	'score-cutoff': number
};

export type GetMetricSchemaEntriesQueryParams = {
	/**
	 * The type of external metric (CODE or NON_CODE)
	 */
	'type'?: ETypeEntry
};

export type GetMetricThresholdConfigurationQueryParams = {
	/**
	 * Project name to load built-in configurations for
	 */
	'project-name'?: string, 
	/**
	 * Parameter indicating if configuration should be loaded together with default configurations.
	 */
	'load-with-base-configurations'?: boolean
};

export type GetMetricThresholdPathForMetricQueryParams = {
	/**
	 * Threshold configuration name.
	 */
	'configuration': string, 
	/**
	 * Metric name.
	 */
	'metricName': string, 
	/**
	 * Path parameter.
	 */
	'path': string, 
	/**
	 * Start of threshold.
	 */
	'start'?: number, 
	/**
	 * End of threshold.
	 */
	'end'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetMetricTreemapQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to build a treemap for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean
};

export type GetMinimizedTestsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetMultiProjectLatexTableQueryParams = {
	/**
	 * The language in which a table is created.
	 */
	'language': EAuditExportLanguageEntry, 
	/**
	 * The table types containing specific metrics.
	 */
	'table-type': Array<EAuditExportTableEntry>, 
	/**
	 * The project with corresponding application code.
	 */
	'application': string, 
	/**
	 * The project with corresponding test code.
	 */
	'test': string, 
	/**
	 * The project with test and application code combined.
	 */
	'all': string
};

export type GetNonIssueTgaCommitsAndTreemapQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetOpenApiQueryParams = {
	/**
	 * Whether to include non-public API services.
	 */
	'include-internal'?: boolean
};

export type GetOpenIdEndpointsQueryParams = {
	'issuer'?: string
};

export type GetParseLogElementQueryParams = {
	/**
	 * The uniform path for which parse log entries should be retrieved.
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetPendingArchitectureUploadsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetPendingFindingExclusionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetPerformanceMetricsTrendQueryParams = {
	/**
	 * The count of seconds of trend data to include.
	 */
	'max-seconds': number
};

export type GetPermissionLookupQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetPortfolioOverviewQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * Project portfolio labels to return the overview for.
	 */
	'labels'?: Array<string>
};

export type GetPreCommitContentQueryParams = {
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string, 
	/**
	 * The commit id for which the pre-commit analysis has been triggered
	 */
	'preCommitId': string
};

export type GetProbeCoverageQueryParams = {
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>
};

export type GetProjectCredentialsQueryParams = {
	'project'?: Array<string>
};

export type GetProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectUserActivityTrendQueryParams = {
	/**
	 * List of all ids of the projects.
	 */
	'project-ids'?: Array<string>, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline': number, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\" If this is not given, only one trend entry will be returned, which is for the day on which the given baseline timestamp lies.
	 */
	'end'?: number, 
	/**
	 * Users in these groups are ignored in the user count. Group names can contain spaces.
	 */
	'exclude-group'?: Array<string>
};

export type GetProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetRandomFilesInRangeQueryParams = {
	/**
	 * The minimum file size in SLoC to be a valid selection.
	 */
	'min': number, 
	/**
	 * The maximum file size in SLoC to be a valid selection.
	 */
	'max': number, 
	/**
	 * Filters the randomly picked files by Java Regex(e.g. '*.java' only shows files ending with '.java')
	 */
	'regex': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to retrieve the random files for.
	 */
	'uniform-path': string
};

export type GetRedundantLiteralTreemapQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRedundantLiteralsQueryQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRefactoringSuggestionsQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetRelatedChangesTreemapQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetRepositoryActivitySummaryQueryParams = {
	/**
	 * Optional parameter, may be used to specify the last point in time to consider in the given query.
	 */
	'end-timestamp'?: number, 
	/**
	 * Optional parameter, if set only RepositoryLogEntries that include at least one code commit are considered
	 */
	'code-commits-only'?: boolean
};

export type GetRepositoryChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetRepositoryLogQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	'merge-request-id'?: string
};

export type GetRepositoryLogCountQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	'merge-request-id'?: string
};

export type GetRepositoryLogWithSplitAggregatesQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	'merge-request-id'?: string
};

export type GetRepositorySummaryQueryParams = {
	/**
	 * Optional parameter, may be used to specify the last point in time to consider in the given query.
	 */
	'end-timestamp'?: number
};

export type GetResolvedTasksQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetResourceHistoryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>, 
	/**
	 * This parameter limits the number of returned entries to the newest ones. If all parameters should be returned, either omit the parameter or use 0 instead.
	 */
	'number-of-entries'?: number
};

export type GetResourceTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to retrieve the resource type for
	 */
	'uniform-path': string, 
	/**
	 * Determines if the resource type should be searched in the default branch head in case not found for the given commit.
	 */
	'check-default-branch'?: boolean
};

export type GetRetrospectivesForProjectQueryParams = {
	/**
	 * The project id
	 */
	'project-id'?: string
};

export type GetReviewStatusQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRisksQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	'test-gaps-only'?: boolean
};

export type GetRunningProfilersQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type GetSamlIdentityProviderMetadataQueryParams = {
	'idp-url'?: string
};

export type GetScatterPlotMetricsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The indices of the metrics used for the scatter plot.
	 */
	'metric-indices'?: Array<number>, 
	/**
	 * For all files in the uniform path the metric are retrieved
	 */
	'uniform-path'?: string
};

export type GetSearchAutocompletionResultsQueryParams = {
	/**
	 * The string/query that should be auto-completed.
	 */
	'token'?: string, 
	/**
	 * The project the search should be restricted to.
	 */
	'project'?: string, 
	/**
	 * The maximal number of matches to return. Defaults to 10.
	 */
	'max_matches'?: number
};

export type GetSearchResultsQueryParams = {
	/**
	 * The query string.
	 */
	'query'?: string, 
	/**
	 * The project the search should be restricted to.
	 */
	'project'?: string, 
	/**
	 * Which page of the search results to return
	 */
	'page'?: number, 
	/**
	 * Which page of the search results to return
	 */
	'limit-per-page'?: number, 
	/**
	 * In which sources to search.
	 */
	'source'?: Array<EQueryTypeEntry>, 
	/**
	 * The sub-path to use as search scope (optional)
	 */
	'path'?: string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean
};

export type GetSelectedTestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetSimulinkFileReferenceDependenciesQueryParams = {
	/**
	 * The qualified name of the relevant Simulink block of the reference. For subsystem or model references, this is the source block. For library block references, this is the target block.
	 */
	'qualifiedName'?: string, 
	/**
	 * If this is set to true, the inverse references for the file identified by the uniform path are returned.
	 */
	'inverse'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSimulinkModelQueryParams = {
	/**
	 * Specifies the sub system (or sub chart) to be returned. If not set, the UI data for the model itself will be returned.
	 */
	'sub-system'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSimulinkModelComparisonResultQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path of the left model in the comparison
	 */
	'left-path': string, 
	/**
	 * Uniform path of the right model in the comparison
	 */
	'right-path': string, 
	/**
	 * Location (ID) of the left subsystem element in the comparison
	 */
	'left-location': string, 
	/**
	 * Location (ID) of the right subsystem element in the comparison
	 */
	'right-location': string
};

export type GetSimulinkSignalsForDependencyQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Dependency source type
	 */
	'source': string, 
	/**
	 * Dependency target type
	 */
	'target': string
};

export type GetSimulinkTestCoverageQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>
};

export type GetSingleMetricDeltaTableQueryParams = {
	/**
	 * Threshold configuration of the metric.
	 */
	'threshold-configuration'?: string, 
	/**
	 * Metric group of the metric.
	 */
	'metric-group'?: string, 
	/**
	 * Display name of the metric.
	 */
	'metric-name'?: string, 
	/**
	 * Whether color-blind mode is enabled.
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * Whether moved or renamed entries should be resolved (heuristically).
	 */
	'hide-refactored-entries'?: boolean, 
	/**
	 * Whether only changed entries should be displayed.
	 */
	'filter-unchanged-entries'?: boolean, 
	/**
	 * Whether deleted entries should be displayed. Compares the absolute value at the start to the metric's default value.
	 */
	'show-deleted-entries'?: boolean, 
	/**
	 * Whether the delta percentage calculation should be based on the maximum encountered delta (false) or on the old metric value of the entry (true) 
	 */
	'calculate-entry-based-delta-percentage'?: boolean, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetSingleMetricDeltaTreemapQueryParams = {
	/**
	 * Threshold configuration of the metric.
	 */
	'threshold-configuration'?: string, 
	/**
	 * Metric group of the metric.
	 */
	'metric-group'?: string, 
	/**
	 * Display name of the metric.
	 */
	'metric-name'?: string, 
	/**
	 * Whether color-blind mode is enabled.
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * Whether moved or renamed entries should be resolved (heuristically).
	 */
	'hide-refactored-entries'?: boolean, 
	/**
	 * Whether only changed entries should be displayed.
	 */
	'filter-unchanged-entries'?: boolean, 
	/**
	 * Whether deleted entries should be displayed. Compares the absolute value at the start to the metric's default value.
	 */
	'show-deleted-entries'?: boolean, 
	/**
	 * Whether the delta percentage calculation should be based on the maximum encountered delta (false) or on the old metric value of the entry (true) 
	 */
	'calculate-entry-based-delta-percentage'?: boolean, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetSinglePreprocessorExpansionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Identifier (number) of the requested expansion group. The numbers are given by the \"basicInfo\" service endpoint.
	 */
	'expansionGroupIds'?: Array<number>
};

export type GetSingleProjectLatexTableQueryParams = {
	/**
	 * The language in which a table is created.
	 */
	'language': EAuditExportLanguageEntry, 
	/**
	 * The table types containing specific metrics.
	 */
	'table-type': Array<EAuditExportTableEntry>, 
	/**
	 * The uniform path for which metrics should be returned.
	 */
	'uniform-path': string
};

export type GetSnapshotQueryParams = {
	/**
	 * A flag whether or not the detailed comparison inputs should be included in the reply
	 */
	'reduced'?: boolean, 
	/**
	 * Whether the response should be a file download
	 */
	'asFileDownload'?: boolean
};

export type GetSpecItemQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetSpecItemCodeReferencesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemCountQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemDetailsQueryParams = {
	'spec-item-ids'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemGraphQueryParams = {
	/**
	 * Issue query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemTestExecutionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemVerifiesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemsByIdAndSortedQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemsVerifiedByQueriedQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemsVerifyingQueriedQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetStacktraceQueryParams = {
	/**
	 * This parameter must be set to the partition in which the test was uploaded.
	 */
	'partition': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetStaticMetricBadgeQueryParams = {
	'metric'?: string, 
	'value'?: string, 
	'color'?: ETrafficLightColorEntry, 
	'incompleteBuildText'?: string
};

export type GetStoredServiceProviderConfigurationQueryParams = {
	'name'?: string
};

export type GetTaskQueryParams = {
	/**
	 * The branch for which to show the findings status
	 */
	'branch'?: string
};

export type GetTaskPreviousNextQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTaskSummaryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline from which tasks that don't have a task status of open are counted.
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTaskToFindingMappingBody = {
	/**
	 * Finding IDs to determine tasks for
	 */
	'finding-id'?: Array<string>, 
	/**
	 * Tasks statuses to be filtered for
	 */
	'task-status'?: Array<ETaskStatusEntry>
};

export type GetTasksWithCountQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTeamscaleUploadInvocationQueryParams = {
	/**
	 * The Teamscale user which should be used to perform the upload
	 */
	'user': string, 
	/**
	 * The partition in which to store the uploaded coverage in Teamscale
	 */
	'partition': string, 
	/**
	 * The format of the external upload data
	 */
	'format': EReportFormatEntry
};

export type GetTechnologyDependenciesQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * Package depth for the given project.
	 */
	'package-depth': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTechnologyTreeMapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: string, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	'dependency'?: string, 
	'package-depth'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestCoverageQueryParams = {
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean
};

export type GetTestCoverageOverlayQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTestCoveragePartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestExecutionExecutionUnitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestExecutionImplementationQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestExecutionPartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestGapDataAsCsvQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTestGapTreeMapQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTestHistoryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Partition to retrieve test history for
	 */
	'partition'?: string
};

export type GetTestImplementationQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestImplementationTestExecutionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestPathsAndExecResultsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestsExecutingMethodQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestwiseCoveragePartitionsQueryParams = {
	/**
	 * ID of the issue to determine the time for which the data should be provided. If this is set, and the timestamp parameter is also defined, the HEAD of the given branch will be used. If the timestamp parameter is not defined, the HEAD of the issue's last commit will be used. This complies with the behavior of auto-selecting the branch of an issue.
	 */
	'issue-id'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTgaPercentageQueryParams = {
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTgaTableQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTgaTestCoveragePartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTgaTrendQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetThresholdPathQueryParams = {
	/**
	 * Threshold group name.
	 */
	'thresholdGroupName': string, 
	/**
	 * Threshold display name.
	 */
	'thresholdDisplayName': string, 
	/**
	 * Threshold configuration name.
	 */
	'configuration': string, 
	/**
	 * Start of threshold.
	 */
	'start'?: number, 
	/**
	 * End of threshold.
	 */
	'end'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTokenElementInfoDetailsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string
};

export type GetTriggerGraphDotQueryParams = {
	/**
	 * Whether read/written indexes are included as nodes in the graph.
	 */
	'include-indexes'?: boolean, 
	/**
	 * Names of nodes in the graph that should be used to start slicing. This can be trigger names or index names. To slice by index, use the index name, not the index-class name (e.g., check-phase-dependencies, not CheckPhaseResultDependencyIndex). All nodes that are not forward/backward reachable from these nodes are not displayed.Nodes are matched exact, so provide the exact names of triggers/indexes that you want to be included.If the list is empty, the complete graph is returned.
	 */
	'slice-nodes'?: Array<string>, 
	/**
	 * If true (forward slicing), only nodes influenced by the slice nodes are returned (i.e., nodes that are executed \"after\" the slice nodes). If false (backward slicing), we return only nodes that influence one of the slice nodes.
	 */
	'forward-slice'?: boolean
};

export type GetTypeToFileLookupQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetUsageDataAsCsvQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	'uniform-path': string
};

export type GetUserAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetUserAvatarQueryParams = {
	/**
	 * Controls the least size of the image.
	 */
	'size': string, 
	/**
	 * Hash of the avatar to improve caching. Can be set to an arbitrary string but should change when the image has been updated.
	 */
	'avatar-hash': string
};

export type GetUserToCommitMapQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The minimum commit count, an author needs to be included.
	 */
	'commits'?: number, 
	/**
	 * The sort order of the authors. Defaults to sorting by time.
	 */
	'order'?: ECommitAuthorSortingOrderEntry
};

export type GetVerifiedBySpecItemsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type ImportAbapLintConfigurationFileBody = {
	'configuration-files': Array<File>, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportAnalysisProfileBody = {
	'analysis-profile': Array<File>
};

export type ImportAndReplaceDashboardsBody = {
	'dashboard': Array<File>
};

export type ImportBackupBody = {
	/**
	 * The backups to import.
	 */
	'backup'?: Array<File>, 
	/**
	 * Path to the backup
	 */
	'backup-path'?: string, 
	/**
	 * Remote user access token
	 */
	'remote-access-token'?: string, 
	/**
	 * Path to the instance
	 */
	'remote-url'?: string, 
	/**
	 * Remote user
	 */
	'remote-user'?: string, 
	/**
	 * Whether to enable shadow mode right after import.
	 */
	'shadow-mode'?: boolean, 
	/**
	 * Whether to skip importing global data
	 */
	'skip-global-data-import'?: boolean, 
	/**
	 * Whether to skip new projects and only import backup data into existing projects.
	 */
	'skip-new-projects'?: boolean, 
	/**
	 * Whether to skip the project validation on import
	 */
	'skip-project-validation'?: boolean
};

export type ImportDashboardBody = {
	'dashboard': Array<File>
};

export type ImportDashboardTemplateBody = {
	'dashboard-template': Array<File>
};

export type ImportExternalFindingsBody = {
	'external-findings'?: File
};

export type ImportExternalMetricsBody = {
	'metrics-descriptions-import-file'?: File
};

export type ImportGroupQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type ImportMetricThresholdsConfigurationBody = {
	'metric-threshold-configuration-data'?: Array<File>
};

export type ImportProjectConfigurationBody = {
	'project-configuration': Array<File>
};

export type ImportSonarProfileBody = {
	'blocker-enablement'?: EFindingEnablementEntry, 
	'critical-enablement'?: EFindingEnablementEntry, 
	'enable-teamscale-defaults'?: boolean, 
	'info-enablement'?: EFindingEnablementEntry, 
	'major-enablement'?: EFindingEnablementEntry, 
	'minor-enablement'?: EFindingEnablementEntry, 
	'profile': File
};

export type ImportSwiftLintConfigurationBody = {
	'configuration': File, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportUserQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type IsIssueQueryValidQueryParams = {
	/**
	 * Issue query to validate
	 */
	'query': string
};

export type IsSpecItemQueryValidQueryParams = {
	/**
	 * Spec item query to validate
	 */
	'query': string
};

export type IsTestQueryValidQueryParams = {
	/**
	 * Test query to validate
	 */
	'query': string
};

export type ListAllParseLogEntriesQueryParams = {
	/**
	 * The logs will start at this given index.
	 */
	'start'?: number, 
	/**
	 * Limits the number of returned logs to the given number.
	 */
	'max'?: number
};

export type ListMergeRequestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * The value to filter by in the merge request list. If the value is empty do not filter.
	 */
	'filter'?: string, 
	/**
	 * The status of the merge request to filter by.
	 */
	'status'?: EMergeRequestStatusEntry
};

export type ListQualityReportsQueryParams = {
	/**
	 * The project id
	 */
	'projectId'?: string
};

export type LoadFromNamedTestListQueryParams = {
	'testListName'?: string, 
	'replaceTests'?: boolean
};

export type MarkDashboardAsFavoriteQueryParams = {
	/**
	 * Whether to mark or unmark a dashboard as favorite
	 */
	'setFavorite': boolean
};

export type PerformIssueQueryQueryParams = {
	/**
	 * Issue query
	 */
	'query': string, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type PerformIssueTgaQueryQueryParams = {
	/**
	 * Issue query
	 */
	'query': string, 
	/**
	 * TGA filter
	 */
	'tga-filter'?: EIssueTgaFilterOptionEntry, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type PerformSpecItemQueryQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type PerformTestQueryQueryParams = {
	/**
	 * Test query
	 */
	'query': string, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true, one result entry is returned per uniform path and partition
	 */
	'on-partition-level'?: boolean, 
	/**
	 * If true only tests with testwise coverage will be returned
	 */
	'only-with-coverage'?: boolean
};

export type PostArchitectureAssessmentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * A type search query pattern
	 */
	'type-search'?: string
};

export type PostBasicRoleForInstanceQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type PostBasicRoleGloballyQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type PostResolveUserAliasesBody = {
	/**
	 * User aliases to resolve
	 */
	'alias'?: Array<string>
};

export type PreviewRepositoryBranchesQueryParams = {
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type PreviewRepositoryCommitMessagesQueryParams = {
	/**
	 * The name of the branch to fetch the commits from
	 */
	'branchName'?: string, 
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type PreviewRepositoryFilesQueryParams = {
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type ProcessBuildWebhookEventQueryParams = {
	/**
	 * Git revision for which the build job is running.
	 */
	'revision': string, 
	/**
	 * Git repository URL for which the build job is running.
	 */
	'repository-url': string, 
	/**
	 * Git repository's platform. It must be one of [AZURE_DEVOPS_GIT, BITBUCKET, BITBUCKET_SERVER, GITEA, GITHUB, GITLAB, SCM_MANAGER]
	 */
	'repository-platform': ERepositoryConnectorEntry, 
	/**
	 * The URL the GitHub instance is reachable at. This parameter is required and used only if repository-platform is set to GITHUB.
	 */
	'github-server-url'?: string
};

export type PutJobTestsFromImpactedQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string, 
	'cross-annotation-project-patterns'?: Array<string>, 
	'test-gaps-only'?: boolean
};

export type PutJobTestsFromQueryQueryParams = {
	'query'?: string, 
	/**
	 * If true only tests with testwise coverage will be considered
	 */
	'only-with-coverage'?: boolean
};

export type ReceiveAnalysisCompletedConfirmationQueryParams = {
	'sessionKey'?: string, 
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor
};

export type ReceiveAnalysisCompletedConfirmation1QueryParams = {
	'sessionKey'?: string, 
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor
};

export type RedirectToCorrectHostQueryParams = {
	/**
	 * Id of the snapshot the comparison belongs to
	 */
	'snapshot-id'?: string, 
	/**
	 * Id of the comparison the example belongs to
	 */
	'comparison-id'?: string, 
	/**
	 * Project ID of the example
	 */
	'project'?: string, 
	/**
	 * ID of the example
	 */
	'example-id'?: string, 
	/**
	 * Type of the example
	 */
	'example-type'?: EInstanceComparisonTypeEntry, 
	/**
	 * Whether the example is on the remote instance
	 */
	'is-remote-example'?: boolean
};

export type RegenerateStoredServiceProviderCertificateQueryParams = {
	'name'?: string
};

export type RegisterProfilerQueryParams = {
	/**
	 * The ID of the profiler configuration to retrieve.
	 */
	'configuration-id': string
};

export type RemoveAccessKeysQueryParams = {
	'keys'?: Array<string>
};

export type RequestAnalysisAndOpenSessionQueryParams = {
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor, 
	'returnAddress'?: string
};

export type RequestAnalysisAndOpenSession1QueryParams = {
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor, 
	'returnAddress'?: string
};

export type RequestPreCommitAnalysisQueryParams = {
	'commit'?: string, 
	/**
	 * The repository branch that this precommit-request is based on (the branch that the user works on when starting this request)
	 */
	'branch'?: string, 
	'handleFilePathsCaseInsensitive'?: boolean
};

export type ResolveReviewFindingQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type SetReviewStatusQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type SetupJobQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type ShutdownBody = {
	/**
	 * The exit code of the process. By convention, a nonzero status code indicates abnormal termination.
	 */
	'exitCode'?: number
};

export type SynchronizeAllGroupsQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type SynchronizeAllUsersQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type SynchronizeSingleGroupQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type TriggerMultiReanalysisQueryParams = {
	/**
	 * Whether the projects should be validated when triggering the reanalysis
	 */
	'validate-projects'?: boolean
};

export type TriggerReanalysisQueryParams = {
	/**
	 * Indicates whether to only update the findings schema without re-analysis.
	 */
	'only-findings-schema-update'?: boolean, 
	/**
	 * The name of the analysis profile for which the schema should be updated.
	 */
	'analysis-profile-name'?: string, 
	/**
	 * Whether the project should be validated when triggering the reanalysis
	 */
	'validate-project'?: boolean
};

export type TriggerSnapshotBackupBody = {
	'target'?: string
};

export type TryResolveNonCodeLinkQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the link info for
	 */
	'uniform-path': string
};

export type UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether false positive findings should be unmarked as well. If not specified, only tolerated findings will be unmarked.
	 */
	'unmark-false-positives'?: boolean
};

export type UpdateAnalysisProfileQueryParams = {
	/**
	 * A comment similar to git commit messages associated with the changes made in the analysis profile
	 */
	'analysisProfileEditComment'?: string
};

export type UpdateExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type UpdateExternalMetricSchemaQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The name of the analysis profile used to extract the enabled external metrics.
	 */
	'analysis-profile-name': string
};

export type UpdateExternalMetricsQueryParams = {
	/**
	 * The type of external metric (CODE or NON_CODE)
	 */
	'type'?: ETypeEntry
};

export type UpdateJobOptionsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type UpdateTaskQueryParams = {
	'keep-findings'?: boolean
};

export type UpdateUserAvatarBody = {
	/**
	 * User avatar image
	 */
	'avatar'?: object
};

export type UploadArchitectureQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string, 
	/**
	 * The parameter that determines the upload format.
	 */
	'format'?: EArchitectureFormatEntry, 
	/**
	 * The parameter used for passing in a template architecture.
	 */
	'template'?: string, 
	/**
	 * If true, the architecture will be saved to all important branches and default branch in addition to the current branch
	 */
	'save-to-all-important-branches'?: boolean
};

export type UploadArchitectureAsJsonQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string, 
	/**
	 * If true, the architecture will be saved to all important branches and default branch in addition to the current branch
	 */
	'save-to-all-important-branches'?: boolean
};

export type UploadExternalSnapshotBody = {
	/**
	 * The snapshot file
	 */
	'snapshot'?: File, 
	/**
	 * Name of the external snapshot
	 */
	'snapshot-name'?: string
};

export type UploadReportQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean, 
	/**
	 * The format of the uploaded report.
	 */
	'format': EReportFormatEntry, 
	/**
	 * The path prefix for the uploaded test artifacts. For coverage reports the covered paths are only matched against those files that have the specified prefix within the Teamscale project. This can be used if the same package structures and classes appear in multiple subfolders of a project. For test execution reports the prefix is prepended to the test execution names, which allows to make otherwise ambiguous test paths unique.
	 */
	'path-prefix'?: string
};

export type UploadReportBody = {
	/**
	 * The external analysis report(s) to upload to Teamscale. The reports are expected to be UTF-8 encoded, other encodings need to be specified.  Note: Files that are *not* encoded in UTF-8, or without a BOM, and are not specified as such, cannot be read reliably, which might lead to parsing errors. For more information on uploading external analysis reports, see https://docs.teamscale.com/howto/uploading-external-results.
	 */
	'report': Array<File>
};

export type UploadSlideImageBody = {
	'slide-image'?: object
};

export type ValidateConnectorQueryParams = {
	/**
	 * The project id.
	 */
	'project-id'?: string
};

export type ValidateRegexQueryParams = {
	/**
	 * The regex that should be checked
	 */
	'regex'?: string
};


/** Type definition of the QUERIES interface. */
export type Queries = {

	/**
	 * Add comment to task
	 *
	 * Adds a comment to the task specified by ID. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param task ID of the task to add comment to
	 */
	addCommentToTask(this: void, project: string, task: number, body: string): QueryOperation<void>

	/**
	 * Add metrics
	 *
	 * Adds a set of external metrics to the schema. This service is public API since Teamscale 5.8. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	addExternalMetrics(this: void, queryParams: AddExternalMetricsQueryParams, body: Array<MetricSchemaChangeEntry>): QueryOperation<void>

	/**
	 * Adds new manual assessment
	 *
	 * Adds a new manual assessment to the list of manual assessments for a given project in the portfolio overview. The API requires the user to have View Project permissions on the project.
     * @param project The id of the project
	 */
	addPortfolioManualAssessment(this: void, project: string, body: QualityGoalManualAssessment): QueryOperation<void>

	/**
	 * Add report slide
	 *
	 * Adds slide(s) to an existing quality report. The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The ID of the report that is added.
	 */
	addQualityReportSlides(this: void, reportId: string, body: Record<string, ReportSlideBase>): QueryOperation<void>

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	autocompleteIssueId(this: void, project: string, queryParams: AutocompleteIssueIdQueryParams): QueryOperation<Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup(this: void, queryParams: AutocompleteUserGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName(this: void, queryParams: AutocompleteUserNameQueryParams): QueryOperation<Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics(this: void): QueryOperation<Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics(this: void): QueryOperation<Array<UserActivity>>

	/**
	 * Cancel the provided trigger
	 *
	 * Cancels the provided trigger. There are two steps of canceling a trigger. The first is via a flag, the trigger has to look up and may cancel gracefully. The second is via interrupt, where the trigger may be canceled at any operation, which may lead to inconsistent data. The API requires the user to have Access Administrative Services permissions.
     * @param workerId 
	 */
	cancelTrigger(this: void, workerId: string, body: CancelTriggerRequestBody): QueryOperation<void>

	/**
	 * Changes review finding message
	 *
	 * Change the message of a review finding. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param findingId ID of the resolved finding
	 */
	changeReviewFindingMessage(this: void, project: string, findingId: string, queryParams: ChangeReviewFindingMessageQueryParams): QueryOperation<void>

	/**
	 * Clear history ID
	 *
	 * Clears the history ID lock flag for the given SAP configuration. The lock flag is set in table /CQSE/ABAPEXP in the SAP system when a Teamscale export is active as only one instance of Teamscale should access the same history. In rare cases, e.g. the export job did not finish properly, the lock flag remains set. This prevents further fetching of ABAP changes. In this case, this service can be called to reset the flag. The API requires the user to have Access Administrative Services permissions.
     * @param sapConfigurationId Identifier of the SAP connection configuration in the Admin perspective.
	 */
	clearHistoryIdLock(this: void, sapConfigurationId: string): QueryOperation<void>

	/**
	 * Removes pre-commit 3.0 branches
	 *
	 * Removes data associated with the pre-commit branches created for requests with the given parameters. If the query parameters are omitted, all pre-commit branches for the current user are deleted. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	clearPreCommitBranch(this: void, project: string, queryParams: ClearPreCommitBranchQueryParams): QueryOperation<void>

	/**
	 * Clear analysis results cached on this Execution Server for a given project
	 *
	 * The execution server caches analysis results and serves them if the same analysis request comes again. This DELETE request clears that cache of analysis results. The API requires the user to have Access Administrative Services permissions.
	 */
	clearResultsCache(this: void): QueryOperation<void>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults(this: void, project: string, sessionId: string): QueryOperation<void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	computeMetricTable(this: void, project: string, queryParams: ComputeMetricTableQueryParams): QueryOperation<MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile(this: void, body: AnalysisProfile): QueryOperation<void>

	/**
	 * Create analysis profile for languages
	 *
	 * Creates a new analysis profile only based on the given languages, checks will fallback to default for each language. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfileForLanguages(this: void, queryParams: CreateAnalysisProfileForLanguagesQueryParams): QueryOperation<void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup(this: void, body: CreateBackupBody): QueryOperation<string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot.
	 */
	createComparison(this: void, snapshotId: string, body: CreateComparisonBody): QueryOperation<string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboard(this: void, body: DashboardDescriptor): QueryOperation<string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboardFromTemplate(this: void, queryParams: CreateDashboardFromTemplateQueryParams): QueryOperation<string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate(this: void, body: DashboardTemplateDescriptor): QueryOperation<string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts(this: void, body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup(this: void, body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription(this: void, body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend(this: void, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Creates a markdown link which renders the metric badge.
	 *
	 * Creates a markdown link which renders the metric badge. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param idWithRepository 
	 */
	createMetricBadgeMarkdown(this: void, project: string, idWithRepository: string, body: MetricBadgeEvaluationParameters): QueryOperation<string>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration(this: void, body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	createNewRandomAccessKey(this: void, user: string): QueryOperation<string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	createOrUpdateBaseline(this: void, project: string, baseline: string, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo): QueryOperation<void>

	/**
	 * Create profiler configuration
	 *
	 * Creates a new profiler configuration. The API requires the user to have Create Profiler Configurations permissions.
	 */
	createProfilerConfiguration(this: void, body: ProfilerConfiguration): QueryOperation<void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 9.9. The API requires the user to have Create Projects permissions.
	 */
	createProject(this: void, queryParams: CreateProjectQueryParams, body: ProjectConfiguration): QueryOperation<void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport(this: void, queryParams: CreateQualityReportQueryParams, body: QualityArtifactParameters): QueryOperation<string>

	/**
	 * Create quality retrospective
	 *
	 * Creates a new quality retrospective either from scratch or by copying an existing retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the template retrospective if given and on the projects of the to be created retrospective.
	 */
	createRetrospective(this: void, queryParams: CreateRetrospectiveQueryParams, body: QualityArtifactParameters): QueryOperation<string>

	/**
	 * Create review finding
	 *
	 * Creates a code review finding. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	createReviewFinding(this: void, project: string, queryParams: CreateReviewFindingQueryParams, body: Array<ReviewComment>): QueryOperation<void>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	createSession(this: void, project: string, queryParams: CreateSessionQueryParams): QueryOperation<string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot(this: void, body: CreateSnapshotBody): QueryOperation<string>

	/**
	 * Create support request
	 *
	 * Creates the support request. The API requires the user to have Create Support Request permissions.
	 */
	createSupportRequest(this: void, body: SupportRequestData): QueryOperation<void>

	/**
	 * Create task
	 *
	 * Creates a new task. The API requires the user to have Edit Tasks permissions on the project.
     * @param project The project ID.
	 */
	createTask(this: void, project: string, body: Task): QueryOperation<Task>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup(this: void, body: UserGroup): QueryOperation<void>

	/**
	 * Delete all architecture commits
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
     * @param uniformPath The uniform path of the architecture to be deleted
	 */
	deleteAllArchitectureCommits(this: void, project: string, uniformPath: string, queryParams: DeleteAllArchitectureCommitsQueryParams): QueryOperation<void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
     * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits(this: void, project: string, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams): QueryOperation<void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults(this: void, project: string, sessionId: string): QueryOperation<void>

	/**
	 * Delete architecture
	 *
	 * Deletes the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
     * @param uniformPath The uniform path of the deleted architecture
	 */
	deleteArchitecture(this: void, project: string, uniformPath: string, queryParams: DeleteArchitectureQueryParams): QueryOperation<void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	deleteBaseline(this: void, project: string, baseline: string): QueryOperation<void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison(this: void, snapshotId: string, comparisonId: string): QueryOperation<void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard(this: void, dashboardId: string): QueryOperation<void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Template templateId.
     * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate(this: void, templateId: string): QueryOperation<void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	deleteEventAnnouncement(this: void, eventId: string): QueryOperation<void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Account externalCredentialsName.
     * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts(this: void, externalCredentialsName: string, queryParams: DeleteExternalAccountsQueryParams): QueryOperation<void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup(this: void, groupName: string): QueryOperation<void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription(this: void, externalFindingDescriptionName: string): QueryOperation<void>

	/**
	 * Delete metric
	 *
	 * Deletes an external metric. The API requires the user to have Edit External Metrics Schema permissions.
     * @param metricName Metric to delete
	 */
	deleteExternalMetric(this: void, metricName: string, queryParams: DeleteExternalMetricQueryParams): QueryOperation<void>

	/**
	 * Delete external snapshots
	 *
	 * Deletes the external snapshot with the provided name The API requires the user to have Access Administrative Services permissions.
     * @param name The name of the external snapshot to be deleted
	 */
	deleteExternalSnapshot(this: void, name: string): QueryOperation<void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backend externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend(this: void, externalStorageBackendName: string, queryParams: DeleteExternalStorageBackendQueryParams): QueryOperation<void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalCriticalEventLogs(this: void, queryParams: DeleteGlobalCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalInteractionLogs(this: void, queryParams: DeleteGlobalInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalJavascriptErrorLogs(this: void, queryParams: DeleteGlobalJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Delete global role
	 *
	 * Removes an existing global role. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to delete.
	 */
	deleteGlobalRole(this: void, name: string): QueryOperation<void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalServiceLogs(this: void, queryParams: DeleteGlobalServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalWorkerLogs(this: void, queryParams: DeleteGlobalWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Delete issue query
	 *
	 * Deletes an issue query descriptor from the system. The API requires the user to have Edit Issue Queries permissions on the project.
     * @param project The project ID.
     * @param issueQueryName Name of the issue metric to delete
	 */
	deleteIssueQueryDescriptor(this: void, project: string, issueQueryName: string): QueryOperation<void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	deleteJobTests(this: void, project: string, jobId: string, queryParams: DeleteJobTestsQueryParams, body: Array<number>): QueryOperation<number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
     * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration(this: void, metricThresholdConfigurationName: string): QueryOperation<void>

	/**
	 * Remove profiler configuration
	 *
	 * Removes the profiler configuration identified by given ID. The API requires the user to have Delete permissions on the Profiler Configuration configurationId.
     * @param configurationId ID of the dashboard to retrieve.
	 */
	deleteProfilerConfiguration(this: void, configurationId: string): QueryOperation<void>

	/**
	 * Delete project
	 *
	 * Deletes a project. This service is public API since Teamscale 5.2. The API requires the user to have Delete Project permissions on the project.
     * @param project 
	 */
	deleteProject(this: void, project: string, queryParams: DeleteProjectQueryParams): QueryOperation<void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectCriticalEventLogs(this: void, project: string, queryParams: DeleteProjectCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectInteractionLogs(this: void, project: string, queryParams: DeleteProjectInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectJavascriptErrorLogs(this: void, project: string, queryParams: DeleteProjectJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to delete
	 */
	deleteProjectOption(this: void, project: string, optionId: string): QueryOperation<void>

	/**
	 * Delete project role
	 *
	 * Removes an existing project role. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to delete.
	 */
	deleteProjectRole(this: void, name: string): QueryOperation<void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectServiceLogs(this: void, project: string, queryParams: DeleteProjectServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectWorkerLogs(this: void, project: string, queryParams: DeleteProjectWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
     * @param id The report id
	 */
	deleteQualityReport(this: void, id: string): QueryOperation<void>

	/**
	 * Delete quality retrospective
	 *
	 * Deletes an existing quality retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
     * @param id The ID of the retrospective that should be deleted.
	 */
	deleteRetrospective(this: void, id: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to delete
	 */
	deleteServerOption(this: void, optionId: string): QueryOperation<void>

	/**
	 * Delete single upload
	 *
	 * Allows to delete a single external analysis upload. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param commit The commit of the external upload
	 */
	deleteSingleUpload(this: void, project: string, commit: UnresolvedCommitDescriptor): QueryOperation<void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot(this: void, id: string): QueryOperation<void>

	/**
	 * Delete spec item query
	 *
	 * Deletes a spec item query descriptor from the system. The API requires the user to have Edit Specification Item Queries permissions on the project.
     * @param project The project ID.
     * @param specItemQueryName Name of the spec item query to delete
	 */
	deleteSpecItemQueryDescriptor(this: void, project: string, specItemQueryName: string): QueryOperation<void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
     * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor(this: void, project: string, testListName: string): QueryOperation<void>

	/**
	 * Delete test query
	 *
	 * Deletes a test query descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
     * @param testQueryName Name of the test query to delete
	 */
	deleteTestQueryDescriptor(this: void, project: string, testQueryName: string): QueryOperation<void>

	/**
	 * Delete upload partition
	 *
	 * Allows to delete all external analysis uploads of a partition. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param partition The partition that should be deleted
	 */
	deleteUploadPartition(this: void, project: string, partition: string): QueryOperation<void>

	/**
	 * Delete users
	 *
	 * Deletes the given users. This service is public API since Teamscale 5.2. The API requires the user to have Delete permissions on the users.
     * @param users The user names to delete. May be either a single user name or a comma separated list of names.
	 */
	deleteUser(this: void, users: string): QueryOperation<string>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
     * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup(this: void, userGroupName: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user 
     * @param optionId 
	 */
	deleteUserOption(this: void, user: string, optionId: string): QueryOperation<void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	deleteUsers(this: void, body: UserBatchOperation): QueryOperation<string>

	/**
	 * Download all archived external reports as ZIP archive
	 *
	 * Returns a ZIP download of all archived external reports. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param commit 
	 */
	downloadAllExternalReportsAsZip(this: void, project: string, commit: UnresolvedCommitDescriptor): QueryOperation<void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * Download architecture
	 *
	 * Download the architecture definition as XML. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	downloadArchitectureAsXml(this: void, project: string, body: DownloadArchitectureAsXmlBody): QueryOperation<File>

	/**
	 * Get audit data
	 *
	 * Download audit result data for export. Result data is represented with a CSV file. User must have audit support included in the license.
     * @param project The project ID.
     * @param uniformPath The uniform path
	 */
	downloadAuditData(this: void, project: string, uniformPath: string): QueryOperation<void>

	/**
	 * Download backup
	 *
	 * Allows downloading a Teamscale backup from the temporary file store. This service is public API since Teamscale 6.1. The user needs to have the permission to backup global data provided it is contained in the backup and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	downloadBackup(this: void, backupId: string): QueryOperation<File>

	/**
	 * Download the benchmark results
	 *
	 * Downloads the benchmark results for a project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	downloadBenchmark(this: void, project: string, queryParams: DownloadBenchmarkQueryParams): QueryOperation<void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalCriticalEventLogs(this: void, queryParams: DownloadGlobalCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalInteractionLogs(this: void, queryParams: DownloadGlobalInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalJavascriptErrorLogs(this: void, queryParams: DownloadGlobalJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalServiceLogs(this: void, queryParams: DownloadGlobalServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalWorkerLogs(this: void, queryParams: DownloadGlobalWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump(this: void, body: DownloadHeapDumpBody): QueryOperation<void>

	/**
	 * Download metric history
	 *
	 * Downloads metric history as CSV The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	downloadMetricHistory(this: void, project: string, queryParams: DownloadMetricHistoryQueryParams): QueryOperation<void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectCriticalEventLogs(this: void, project: string, queryParams: DownloadProjectCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectInteractionLogs(this: void, project: string, queryParams: DownloadProjectInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectJavascriptErrorLogs(this: void, project: string, queryParams: DownloadProjectJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectServiceLogs(this: void, project: string, queryParams: DownloadProjectServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectWorkerLogs(this: void, project: string, queryParams: DownloadProjectWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Download report
	 *
	 * Returns a file download of the requested archived report. The report index information is part of the external analysis status details. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param commit 
     * @param reportIndex 
	 */
	downloadReport(this: void, project: string, commit: UnresolvedCommitDescriptor, reportIndex: number): QueryOperation<void>

	/**
	 * Search code, issues, commits
	 *
	 * Provides the possibility to search the indexed code base. The service will only search through projects visible to current user.
	 */
	downloadSearchResultCsv(this: void, queryParams: DownloadSearchResultCsvQueryParams): QueryOperation<File>

	/**
	 * Download support request
	 *
	 * Downloads the support request previously created with POST. The API requires the user to have Create Support Request permissions.
	 */
	downloadSupportRequest(this: void): QueryOperation<File>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	downloadTestSelectionCsv(this: void, project: string, jobId: string, queryParams: DownloadTestSelectionCsvQueryParams): QueryOperation<File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard(this: void, dashboardId: string, body: DashboardDescriptor): QueryOperation<void>

	/**
	 * Update project
	 *
	 * Updates an existing project. This service is public API since Teamscale 7.1. The API requires the user to have Edit Project permissions on the project.
     * @param project 
	 */
	editProject(this: void, project: string, body: ProjectInfo): QueryOperation<ProjectUpdateResult>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to edit
	 */
	editProjectOption(this: void, project: string, optionId: string, body: object): QueryOperation<void>

	/**
	 * Edit project
	 *
	 * Edits project based on provided project configuration. This service is public API since Teamscale 9.9. The API requires the user to have Edit Project permissions on the project.
     * @param project The project ID.
	 */
	editProjectWithConfiguration(this: void, project: string, queryParams: EditProjectWithConfigurationQueryParams, body: ProjectConfiguration): QueryOperation<boolean>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to edit
	 */
	editServerOption(this: void, optionId: string, queryParams: EditServerOptionQueryParams, body: object): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user Name of the user to edit the option for
     * @param optionId Name of the option to edit
	 */
	editUserOption(this: void, user: string, optionId: string, body: object): QueryOperation<void>

	/**
	 * Enable or disable voting option
	 *
	 * Enable or disable the given kind of voting for all connectors of the project which support it. The API requires the user to have Edit Project permissions on the project.
     * @param project 
     * @param votingOption 
	 */
	enableVotingOption(this: void, project: string, votingOption: EVotingConnectorOptionEntry, queryParams: EnableVotingOptionQueryParams): QueryOperation<void>

	/**
	 * Execute postponed rollback
	 *
	 * Executes the postponed rollback of given id right now. The API requires the user to have Edit Project permissions on the project.
     * @param project The project ID.
     * @param id 
	 */
	executePostponedRollback(this: void, project: string, id: string): QueryOperation<void>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand(this: void, body: ExecuteSchedulerCommandBody): QueryOperation<void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	exportAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * export code search result
	 *
	 * Downloads code search matches result list as CSV The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportCodeMatchResult(this: void, project: string, queryParams: ExportCodeMatchResultQueryParams): QueryOperation<void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard
	 */
	exportDashboard(this: void, dashboardId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
     * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate(this: void, templateId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Export external findings
	 *
	 * Downloads external findings groups and descriptions as a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	exportExternalFindings(this: void, queryParams: ExportExternalFindingsQueryParams): QueryOperation<File>

	/**
	 * Downloads external metrics
	 *
	 * Downloads external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	exportExternalMetrics(this: void): QueryOperation<void>

	/**
	 * Export impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of tests is a subset of all tests known to Teamscale in CSV format. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportImpactedTestsAsCsv(this: void, project: string, queryParams: ExportImpactedTestsAsCsvQueryParams): QueryOperation<void>

	/**
	 * Export metric assessment
	 *
	 * Exports the metric assessment based on a threshold configuration as CSV The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportMetricAssessmentAsCSV(this: void, project: string, queryParams: ExportMetricAssessmentAsCSVQueryParams): QueryOperation<void>

	/**
	 * Export metric threshold configuration
	 *
	 * Export metric threshold configuration. The API requires the user to have View permissions on the Metric Threshold Configuration target.
     * @param target Metric threshold configuration name.
	 */
	exportMetricThresholdsConfiguration(this: void, target: string): QueryOperation<void>

	/**
	 * Export project configuration
	 *
	 * Downloads the project configuration which is only the configuration and does not include the project data, analysis profiles or external accounts. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportProjectConfiguration(this: void, project: string): QueryOperation<void>

	/**
	 * Export a Teamscale IDE Configuration File
	 *
	 * Exports a Teamscale IDE Configuration File for the given project and repository, to be used by the IDE plugins. This service is public API since Teamscale 9.9. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportTeamscaleToml(this: void, project: string, queryParams: ExportTeamscaleTomlQueryParams): QueryOperation<string>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
     * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist(this: void, externalCredentialsName: string): QueryOperation<ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
     * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists(this: void, externalStorageBackendName: string): QueryOperation<ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue(this: void): QueryOperation<Array<JobDescriptor>>

	/**
	 * Gets GitLab repositories for an account
	 *
	 * Get all GitLab repositories that the current user has maintainer access to. The API requires the user to have View permissions on the External Account credentialsName.
     * @param credentialsName Name of the external accounts
	 */
	fetchGitlabProjects(this: void, credentialsName: string): QueryOperation<Array<GitLabProject>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit(this: void, project: string, unresolvedCommit: UnresolvedCommitDescriptor, queryParams: FindInitialCommitQueryParams): QueryOperation<CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	findLogEntriesInRange(this: void, project: string, queryParams: FindLogEntriesInRangeQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	flagFindings(this: void, project: string, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody): QueryOperation<void>

	/**
	 * Get Absolute Date
	 *
	 * Transforms a relative date string into a fixed date string The API requires no permissions
	 */
	getAbsoluteDate(this: void, queryParams: GetAbsoluteDateQueryParams): QueryOperation<string>

	/**
	 * List Access Keys Metadata
	 *
	 * List the metadata of all access keys of the user The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	getAccessKeysMetadata(this: void, user: string): QueryOperation<Array<AccessKeyDTO>>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the given last number of days that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects(this: void, queryParams: GetActiveUsersForProjectsQueryParams, body: Array<string>): QueryOperation<Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getAdditionallyCoveredMethods(this: void, project: string, jobId: string): QueryOperation<Array<Array<MethodLocation>>>

	/**
	 * Get affected files for the given commits as CSV
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAffectedFilesCsv(this: void, project: string, queryParams: GetAffectedFilesCsvQueryParams): QueryOperation<void>

	/**
	 * Get affected files by commits
	 *
	 * Returns a wrapper object with repository log file entries for commits, i.e. the association between files and repository log entries. The returned typeCounts mapping will always count the entries across all types (not just the passed one) The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAffectedFilesForCommits(this: void, project: string, queryParams: GetAffectedFilesForCommitsQueryParams): QueryOperation<AffectedFiles>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics(this: void): QueryOperation<void>

	/**
	 * Get an AI generated finding resolution suggestion
	 *
	 * Retrieves the AI generated resolution for a finding. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param engine The name of the configured AI engine to use.
     * @param id The id of the finding to find a resolution for.
	 */
	getAiFindingResolution(this: void, project: string, engine: string, id: string, queryParams: GetAiFindingResolutionQueryParams): QueryOperation<FindingResolutionResult>

	/**
	 * Get an AI generated summary for an element
	 *
	 * Retrieves the AI summary for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param engine The interal name of the configured AI engine to use.
	 */
	getAiSummary(this: void, project: string, engine: string, queryParams: GetAiSummaryQueryParams): QueryOperation<string>

	/**
	 * Get all existing A3 Options.
	 *
	 * Returns all A3 option identifiers. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getAllA3OptionIdentifiers(this: void): QueryOperation<Array<string>>

	/**
	 * Get all architecture assessments
	 *
	 * Returns available architecture assessments. All architectures in analyzed and in pending commits are returned including tags on the state of their analysis (added, modified, deleted). Returns an empty list if no assessments available. This service is public API since Teamscale 5.7. The API requires the user to have View Project permissions on the project.
     * @param project ID of the project where architectures are defined.
	 */
	getAllArchitectureAssessments(this: void, project: string, queryParams: GetAllArchitectureAssessmentsQueryParams): QueryOperation<Array<ArchitectureOverviewInfo>>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the project. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllBaselines(this: void, project: string): QueryOperation<Array<BaselineInfo>>

	/**
	 * Get all test coverage partitions
	 *
	 * Returns the all available test coverage partitions of the project across all branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllCrossBranchPartitions(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates(this: void): QueryOperation<Array<UserResolvedDashboardTemplateDescriptor>>

	/**
	 * Get all dashboards
	 *
	 * Retrieves all the dashboards available in the system No permissions needed, as the service will only return the dashboards visible to current user.
	 */
	getAllDashboards(this: void, queryParams: GetAllDashboardsQueryParams): QueryOperation<Array<DashboardEntryWithPermissions>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts(this: void): QueryOperation<Array<ExternalCredentialsData>>

	/**
	 * Get external clone classes
	 *
	 * Retrieves the found external clone classes The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
     * @param project The project ID.
	 */
	getAllExternalCrossClones(this: void, project: string): QueryOperation<Array<ExternalXCloneClass>>

	/**
	 * Get external snapshots
	 *
	 * Returns the list of all available externally uploaded snapshots The API requires the user to have Access Administrative Services permissions.
	 */
	getAllExternalSnapshots(this: void, queryParams: GetAllExternalSnapshotsQueryParams): QueryOperation<Array<ExternalInstanceComparisonSnapshotDto>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends(this: void): QueryOperation<Array<ExternalStorageBackendData>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getAllGitTags(this: void, project: string, queryParams: GetAllGitTagsQueryParams): QueryOperation<Array<GitTag>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns a the available test coverage partitions. Each object contains a project and it's test partitions. Only partitions are returned for which the user has view permissions.
	 */
	getAllGlobalLineBasedPartitions(this: void): QueryOperation<Array<ProjectPartitionsInfo>>

	/**
	 * Get guidelines
	 *
	 * Gets all guidelines available in the current Teamscale version. No permission needed since every user can explore guidelines with the check explorer.
	 */
	getAllGuidelines(this: void): QueryOperation<Array<Guideline>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames(this: void, queryParams: GetAllMetricThresholdConfigurationNamesQueryParams): QueryOperation<Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations(this: void): QueryOperation<Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles(this: void, queryParams: GetAllMetricsForThresholdProfilesQueryParams): QueryOperation<Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries(this: void): QueryOperation<Array<ParseLogOverviewEntry>>

	/**
	 * Get postponed rollbacks
	 *
	 * Returns all postponed rollbacks that are currently pending for the project. The API requires the user to have Edit Project permissions on the project.
     * @param project The project ID.
	 */
	getAllPostponedRollbacks(this: void, project: string): QueryOperation<Array<PostponedRollback>>

	/**
	 * Get all profiler configurations
	 *
	 * Retrieves all the defined profiler configurations No permissions needed, as the service will only return the profiler configurations visible to current user.
	 */
	getAllProfilerConfigurations(this: void): QueryOperation<Array<ProfilerConfiguration>>

	/**
	 * Get all project file paths
	 *
	 * Retrieves all files paths of a project as a CSV file. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getAllProjectFilePaths(this: void, project: string, queryParams: GetAllProjectFilePathsQueryParams): QueryOperation<void>

	/**
	 * Get all project files
	 *
	 * Retrieves all files of a project as a ZIP file. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getAllProjectFiles(this: void, project: string, queryParams: GetAllProjectFilesQueryParams): QueryOperation<void>

	/**
	 * Get project IDs
	 *
	 * Returns all primary project IDs. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjectIds(this: void, queryParams: GetAllProjectIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get portfolio labels for all projects
	 *
	 * Returns portfolio labels for all projects. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjectPortfolioLabels(this: void): QueryOperation<Array<string>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects(this: void, queryParams: GetAllProjectsQueryParams): QueryOperation<Array<ProjectInfo>>

	/**
	 * Get all existing SAP connection identifiers.
	 *
	 * Returns all SAP connection identifiers configured in the instance. This service is public API since Teamscale 6.4. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getAllSapSystemConnectionIdentifiers(this: void): QueryOperation<Array<string>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds(this: void, queryParams: GetAllSnapshotIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get all role assignments for all groups and users (and assigned groups).
	 *
	 * Returns a list of all subject role assignments. The API requires the user to have Assign Global Roles permissions.
	 */
	getAllSubjectRoleAssignments(this: void): QueryOperation<Array<Array<SubjectRoleAssignments>>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllTestExecutionPartitionDetails(this: void, project: string, queryParams: GetAllTestExecutionPartitionDetailsQueryParams): QueryOperation<Array<PartitionInfo>>

	/**
	 * Get all test execution partitions
	 *
	 * Returns all available test execution partitions of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllTestExecutionPartitions(this: void, project: string, queryParams: GetAllTestExecutionPartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml(this: void): QueryOperation<string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames(this: void): QueryOperation<Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups(this: void): QueryOperation<Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers(this: void): QueryOperation<Array<UserWithActivity>>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions of a project known by uploaded PDBs. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllVersions(this: void, project: string): QueryOperation<Array<DotNetVersionInfo>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff(this: void, analysisProfileName: string, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams): QueryOperation<AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis group
	 *
	 * Returns a mapping from code scopes from the provided project to the analysis group from the analysis profile of the code scope identified by the group name. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param groupName Name of the requested analysis group.
	 */
	getAnalysisGroupPerCodeScope(this: void, project: string, groupName: string): QueryOperation<Record<string, AnalysisGroup>>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory(this: void, analysisProfileName: string): QueryOperation<Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps(this: void, analysisProfileName: string): QueryOperation<Array<number>>

	/**
	 * Get analysis profile metadata
	 *
	 * Retrieves the analysis profile identified by given name and returns its description, languages and tools. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileMetadata(this: void, analysisProfileName: string): QueryOperation<AnalysisProfileMetadata>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames(this: void): QueryOperation<Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo(this: void): QueryOperation<Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects(this: void, analysisProfileName: string): QueryOperation<AnalysisProfileWithProjects>

	/**
	 * Get ClangTidy analysis results
	 *
	 * Gets the ClangTidy analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath(this: void, queryParams: GetAnalysisResultsByPathQueryParams): QueryOperation<Array<ClangTidyResultsTransport>>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath1(this: void, queryParams: GetAnalysisResultsByPath1QueryParams): QueryOperation<Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Returns branch or project analysis state
	 *
	 * Provides the most up-to-date information about completed and currently-analyzed projects and branches. The analysis state is obtained from the branch, if it is given as target. Otherwise the analysis state is based on the project's default branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param branchName 
	 */
	getAnalysisState(this: void, project: string, branchName: string): QueryOperation<AnalysisState>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming(this: void): QueryOperation<void>

	/**
	 * Get the status of a ClangTidy Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus(this: void, queryParams: GetAnalysisStatusQueryParams): QueryOperation<EClangTidyOutsourcedAnalysisStatusEntry>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus1(this: void, queryParams: GetAnalysisStatus1QueryParams): QueryOperation<ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get analysis tools
	 *
	 * Retrieves the available tools based on the languages provided. Teamscale itself is not returned as a tool. No specific permissions required to retrieve the available tools
	 */
	getAnalysisTools(this: void, queryParams: GetAnalysisToolsQueryParams): QueryOperation<Array<EAnalysisToolEntry>>

	/**
	 * Get analysis tools
	 *
	 * Returns the available analysis tools for a specific project. Teamscale itself is not returned as a tool. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getAnalysisToolsForProject(this: void, project: string, queryParams: GetAnalysisToolsForProjectQueryParams): QueryOperation<Array<EAnalysisToolEntry>>

	/**
	 * Get architecture assessment
	 *
	 * Returns the architecture assessment with the identifier in the path parameter. Returns an empty assessment if an unknown identifier is given. The API requires the user to have View Project permissions on the project.
     * @param project ID of the project where architecture is defined.
     * @param uniformPath Uniform path of requested architecture
	 */
	getArchitectureAssessment(this: void, project: string, uniformPath: string, queryParams: GetArchitectureAssessmentQueryParams): QueryOperation<ArchitectureAssessmentInfo>

	/**
	 * Get architecture upload info
	 *
	 * Returns architecture upload info for the specified commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param commit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	getArchitectureCommitUploadInfo(this: void, project: string, commit: UnresolvedCommitDescriptor): QueryOperation<ArchitectureCommitUploadInfo | null>

	/**
	 * Get all architecture upload infos
	 *
	 * Returns architecture upload infos for the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param architecture 
	 */
	getArchitectureCommitUploadInfos(this: void, project: string, architecture: string): QueryOperation<Array<CommitWithUserName>>

	/**
	 * Get architecture components
	 *
	 * Returns the list of architecture component assignments for an element in all the available project architectures. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getArchitectureComponentAssignments(this: void, project: string, queryParams: GetArchitectureComponentAssignmentsQueryParams): QueryOperation<Array<ArchitectureComponentAssignment>>

	/**
	 * Profile architecture pattern performance
	 *
	 * Measures the performance of every include/exclude pattern of the provided architecture, by matching it against all available types. Reports the 10 slowest patterns and their runtime. Can be used to detect slow patterns in the architecture analysis.  The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
     * @param architecture 
	 */
	getArchitecturePerformanceStatistics(this: void, project: string, architecture: string, queryParams: GetArchitecturePerformanceStatisticsQueryParams): QueryOperation<string>

	/**
	 * Get all architectures with commit count
	 *
	 * Returns architecture uniform paths and the number of commits per architecture for the project. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getArchitectures(this: void, project: string): QueryOperation<Array<ArchitectureWithCommitCount>>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds(this: void, id: string): QueryOperation<Array<string>>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Create Users permissions.
	 */
	getAuthenticatorInformation(this: void): QueryOperation<AuthenticatorMappingReply>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Edit permissions on the User username.
     * @param username User to retrieve authentication info for
	 */
	getAuthenticatorInformationForUser(this: void, username: string): QueryOperation<AuthenticatorMappingReply>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers(this: void): QueryOperation<Record<string, Array<string>>>

	/**
	 * Get the backup export status
	 *
	 * Get the current backup export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupExportStatus(this: void, backupId: string): QueryOperation<BackupExportStatus>

	/**
	 * Get the complete backup export summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupExportSummary(this: void): QueryOperation<Array<BackupJobSummary>>

	/**
	 * Get the backup import status
	 *
	 * Get the current backup import status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupImportStatus(this: void, backupId: string): QueryOperation<BackupImportStatus>

	/**
	 * Get the complete backup import summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupImportSummary(this: void): QueryOperation<Array<BackupJobSummary>>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard(this: void): QueryOperation<Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	getBaseline(this: void, project: string, baseline: string): QueryOperation<BaselineInfo>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getBaselines(this: void, queryParams: GetBaselinesQueryParams): QueryOperation<Record<string, Array<BaselineInfo>>>

	/**
	 * Returns the basic preprocessor information for a file
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getBasicPreprocessorExpansionsInfo(this: void, project: string, uniformPath: string, queryParams: GetBasicPreprocessorExpansionsInfoQueryParams): QueryOperation<PreprocessorExpansionsTransport>

	/**
	 * Get basic roles assigned to an instance
	 *
	 * Returns all basic roles with assignments. Requires the basic permission to \"Assign Roles\"
     * @param instance The instance&#39;s id
	 */
	getBasicRoles(this: void, instance: string, queryParams: GetBasicRolesQueryParams): QueryOperation<RolesWithAssignmentsEBasicRole>

	/**
	 * Get benchmark projects
	 *
	 * Returns the projects with benchmark analysis profiles User must have audit support included in the license.
	 */
	getBenchmarkProjects(this: void): QueryOperation<Array<ProjectDescription>>

	/**
	 * Get a (partly anonymized) benchmark results for a specific metric
	 *
	 * Returns a CSV file with all benchmark results. Only projects visible to the user are returned.
	 */
	getBenchmarkResultsAsCsv(this: void, queryParams: GetBenchmarkResultsAsCsvQueryParams): QueryOperation<void>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem(this: void, project: string, id: string, queryParams: GetBranchForSpecItemQueryParams): QueryOperation<string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesGetRequest(this: void, project: string, queryParams: GetBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesPostRequest(this: void, project: string, queryParams: GetBranchesPostRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getChangeTreemap(this: void, project: string, queryParams: GetChangeTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * Get children resource types
	 *
	 * Retrieves the types of children of the resource represented by a uniform path. For children that are containers, this will be the type of their deepest relative path with more than one child The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getChildrenResourceTypes(this: void, project: string, queryParams: GetChildrenResourceTypesQueryParams): QueryOperation<Record<string, EResourceTypeEntry>>

	/**
	 * Download source code
	 *
	 * Starts a download of the source code file at the given uniform path. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath The uniform path of the source code file to be downloaded.
	 */
	getCode(this: void, project: string, uniformPath: string, queryParams: GetCodeQueryParams): QueryOperation<string>

	/**
	 * Get code changes
	 *
	 * Retrieves information on code changes. Returns for all changes after the given baseline the timestamp of the last change in the corresponding region. Regions are line-based starting at 1 with both bounds being inclusive. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getCodeChanges(this: void, project: string, uniformPath: string, queryParams: GetCodeChangesQueryParams): QueryOperation<Array<LineBasedChangeRegion>>

	/**
	 * Get code city
	 *
	 * Builds a code city for the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeCities(this: void, project: string, queryParams: GetCodeCitiesQueryParams): QueryOperation<CodeCityNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper(this: void, project: string, queryParams: GetCodeComponentsTreemapWrapperQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get code outline
	 *
	 * Returns a code outline for a file, which summarizes the abstract syntax tree of the code The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getCodeOutline(this: void, project: string, uniformPath: string, queryParams: GetCodeOutlineQueryParams): QueryOperation<Array<OutlineElement>>

	/**
	 * Get ownership treemap
	 *
	 * Builds a code ownership treemap using the given parameters The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeOwnershipTreemap(this: void, project: string, queryParams: GetCodeOwnershipTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping(this: void, project: string, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams): QueryOperation<SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap(this: void, project: string, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams): QueryOperation<CodeSearchResultsWrapper>

	/**
	 * Get code usage treemap
	 *
	 * Returns a usage treemap. For each method under the selected uniform path, the treemap shows how often the method was executed in the specified time frame. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeUsageTreemap(this: void, project: string, queryParams: GetCodeUsageTreemapQueryParams): QueryOperation<UsageTreeMapWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCommitAlerts(this: void, project: string, queryParams: GetCommitAlertsQueryParams): QueryOperation<Array<CommitAlertWithCommit>>

	/**
	 * Get teamscale commits
	 *
	 * Returns the teamscale commit representations for a given revision. As a revision can occur in multiple repositories, more than one commit may be returned. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param revision 
	 */
	getCommitsForRevision(this: void, project: string, revision: string): QueryOperation<Array<CommitDescriptor>>

	/**
	 * Get task commits
	 *
	 * Returns the timestamps of commits associated with a given task. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the requested task
	 */
	getCommitsForTask(this: void, project: string, id: number): QueryOperation<Array<CommitDescriptor>>

	/**
	 * Retrieves the analysis progress of the given merge request in terms of commits left to be analyzed
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The name of the project.
	 */
	getCommitsLeftToAnalyzeForMergeRequest(this: void, project: string, queryParams: GetCommitsLeftToAnalyzeForMergeRequestQueryParams): QueryOperation<number>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison(this: void, snapshotId: string, comparisonId: string): QueryOperation<InstanceComparisonComputationMetadata>

	/**
	 * Get specific project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param projectId 
     * @param contributor 
	 */
	getComparisonResult(this: void, snapshotId: string, comparisonId: string, projectId: string, contributor: string): QueryOperation<InstanceComparisonResultBase>

	/**
	 * Get comparison result metadata
	 *
	 * Returns the comparison metadata for the specified comparison. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
	 */
	getComparisonResultMetadata(this: void, snapshotId: string, comparisonId: string): QueryOperation<Record<string, Record<string, InstanceComparisonResultMetadata>>>

	/**
	 * Debug dump compilation commands
	 *
	 * Create a debug dump for compilation commands for a given file from a given partition. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param commit The commit of the external upload
     * @param partition The partition to read from
     * @param path The path to read for
	 */
	getCompilationCommandsDebugDump(this: void, project: string, commit: UnresolvedCommitDescriptor, partition: string, path: string): QueryOperation<string>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param project The name of the project.
     * @param contributor The contributor of the comparison.
     * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList(this: void, snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string, queryParams: GetCompleteExampleListQueryParams): QueryOperation<Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails(this: void, queryParams: GetConcurrencyPerformanceDetailsQueryParams): QueryOperation<string>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis, sorted by their readable name. This is available to every authenticated user.
	 */
	getConfigLanguages(this: void): QueryOperation<Array<Language>>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis for a specific project, sorted by their readable name. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getConfigLanguagesForProject(this: void, project: string): QueryOperation<Array<Language>>

	/**
	 * Get configuration template
	 *
	 * Retrieves the configuration template for given languages. No specific permissions required to retrieve a configuration template.
	 */
	getConfigurationTemplate(this: void, queryParams: GetConfigurationTemplateQueryParams): QueryOperation<ConfigurationTemplate>

	/**
	 * Get connector descriptors
	 *
	 * Retrieves connector descriptors available in the system. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getConnectorDescriptors(this: void): QueryOperation<Array<ConnectorDescriptorTransport>>

	/**
	 * Returns all connectors for a project
	 *
	 * Returns information about the connector configurations associated with the project. User must either have the global permission to create projects, or the permission to view any existing project.
     * @param projectId The project id.
	 */
	getConnectors(this: void, projectId: string): QueryOperation<Array<ConnectorConfiguration>>

	/**
	 * Get copyright license treemap
	 *
	 * Builds a treemap of the copyright licenses in a system. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCopyrightLicensesTreemap(this: void, project: string, queryParams: GetCopyrightLicensesTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCoverageSources(this: void, project: string, queryParams: GetCoverageSourcesQueryParams): QueryOperation<Array<CoverageSourceInfo>>

	/**
	 * Get scheduler filtering status
	 *
	 * Returns the current filtering status of the scheduler. The API requires no permissions
	 */
	getCurrentSchedulingFilter(this: void): QueryOperation<ProjectSchedulingFilter>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard(this: void, dashboardId: string): QueryOperation<DashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
     * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor(this: void, templateId: string): QueryOperation<UserResolvedDashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDebugContent(this: void, project: string, uniformPath: string, queryParams: GetDebugContentQueryParams): QueryOperation<string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility(this: void, queryParams: GetDeclarationOfAccessibilityQueryParams): QueryOperation<string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext(this: void): QueryOperation<DeclarationOfAccessibilityContext>

	/**
	 * Returns the default value for 'Disable automatic webhook creation'
	 *
	 * Returns the default value for 'Disable automatic webhook creation' specified by JVM flags. The API requires no permissions
	 */
	getDefaultForDisableWebhookCreation(this: void): QueryOperation<boolean>

	/**
	 * Get hidden metrics
	 *
	 * Returns the names of the metrics that are hidden by default in the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPathType 
	 */
	getDefaultHiddenMetrics(this: void, project: string, uniformPathType: ETypeEntry, queryParams: GetDefaultHiddenMetricsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get default patterns
	 *
	 * Returns the default include/exclude patterns for connectors as a map from analysis profile name to the corresponding default patterns based on the languages configured in the respective analysis profile The API requires no permissions
	 */
	getDefaultPatterns(this: void): QueryOperation<Record<string, IncludeExcludePatterns>>

	/**
	 * Get token element churn
	 *
	 * Provides a token element churn (i.e., the list of changed elements) for a given uniform path and time range. This service is public API since Teamscale 5.9.10. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDeltaAffectedFiles(this: void, project: string, queryParams: GetDeltaAffectedFilesQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get dependencies
	 *
	 * Returns a list of dependencies for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDependenciesWithOccurrenceLocation(this: void, project: string, uniformPath: string, queryParams: GetDependenciesWithOccurrenceLocationQueryParams): QueryOperation<Array<DependencyWithOccurrenceLocation>>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDependencyGraph(this: void, project: string, uniformPath: string, queryParams: GetDependencyGraphQueryParams): QueryOperation<string>

	/**
	 * Get names of deriving metric threshold configurations
	 *
	 * Retrieves names of deriving metric threshold configurations. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
     * @param metricThresholdConfigurationName 
	 */
	getDerivingMetricThresholdConfigurationNames(this: void, metricThresholdConfigurationName: string): QueryOperation<Array<string>>

	/**
	 * Get diffs for two files
	 *
	 * Compares two elements with each other. The elements are given via a full path including project and uniform path and (optionally) the commit separated by '#@#' Requires read permissions on the projects containing the files to be compared
	 */
	getDiffs(this: void, queryParams: GetDiffsQueryParams): QueryOperation<Array<DiffDescription>>

	/**
	 * Get metric values
	 *
	 * Retrieves the metric values for a resource The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDirectMetricDirectoryEntry(this: void, project: string, queryParams: GetDirectMetricDirectoryEntryQueryParams): QueryOperation<MetricDirectoryEntry>

	/**
	 * Get .NET version info
	 *
	 * Returns a the version info for a specific PDB. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param versionName The name of the version to get infos for
	 */
	getDotNetVersionInfo(this: void, project: string, versionName: string): QueryOperation<DotNetVersionInfo>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions known by uploaded PDBs for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getDotNetVersions(this: void, queryParams: GetDotNetVersionsQueryParams): QueryOperation<Record<string, Array<DotNetVersionInfo>>>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDumpCommitDescriptor(this: void, project: string, queryParams: GetDumpCommitDescriptorQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDumpCommitTree(this: void, project: string, queryParams: GetDumpCommitTreeQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitTreeNodes(this: void, project: string, queryParams: GetDumpCommitTreeNodesQueryParams): QueryOperation<Array<CommitTreeNodeData>>

	/**
	 * Returns the configuration of the service provider that is passed to this service.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires the user to have Access Administrative Services permissions.
	 */
	getEditedServiceProviderConfiguration(this: void, queryParams: GetEditedServiceProviderConfigurationQueryParams, body: SamlAuthenticationOption): QueryOperation<SamlServiceProviderConfiguration>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements(this: void): QueryOperation<Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTree(this: void, project: string, queryParams: GetExceptionsHierarchyTreeQueryParams): QueryOperation<Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG(this: void, project: string, queryParams: GetExceptionsHierarchyTreeSVGQueryParams): QueryOperation<Array<string>>

	/**
	 * Get execution unit
	 *
	 * Returns a execution unit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param executionUnit The execution unit to return.
     * @param partition The partition of the execution unit
	 */
	getExecutionUnit(this: void, project: string, executionUnit: string, partition: string, queryParams: GetExecutionUnitQueryParams): QueryOperation<ExecutionUnit>

	/**
	 * Get extended resource types
	 *
	 * Returns the extended resource types for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getExtendedResourceTypes(this: void, project: string, uniformPath: string, queryParams: GetExtendedResourceTypesQueryParams): QueryOperation<Array<EExtendedResourceTypeEntry>>

	/**
	 * Get all external analysis upload commits for the specified timeframe and partitions
	 *
	 * Returns status infos for all external analysis upload commits in the given partitions which occurred in the given timeframe. The relevant commits are collected by going back from the given end commit through all of its predecessors until the given baseline timestamp is reached. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExternalAnalysisCommitInfos(this: void, project: string, queryParams: GetExternalAnalysisCommitInfosQueryParams): QueryOperation<Record<string, Array<ExternalAnalysisCommitStatus>>>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExternalAnalysisStatusPartitions(this: void, project: string): QueryOperation<Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get uploads
	 *
	 * Returns the uploads for a given partition. The API requires the user to have Perform External Uploads, View Project permissions on the project.
     * @param project The project ID.
     * @param partition 
	 */
	getExternalAnalysisStatusUploads(this: void, project: string, partition: string): QueryOperation<Array<ExternalAnalysisCommitStatus>>

	/**
	 * Get external file content
	 *
	 * Retrieves the file content of a file from disk with local pre-processing. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
     * @param path The path to retrieve files for
	 */
	getExternalFileWithPreprocessing(this: void, path: string): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getExternalFindingsDescriptions(this: void): QueryOperation<Array<ExternalFindingsDescription>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getExternalFindingsGroups(this: void): QueryOperation<Array<ExternalAnalysisGroup>>

	/**
	 * Get ABAP external link
	 *
	 * Allows obtaining an external link for a file, e.g. for opening it in an IDE. This only works for ABAP files imported from an SAP system. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param uniformPath 
	 */
	getExternalLink(this: void, project: string, uniformPath: string, queryParams: GetExternalLinkQueryParams): QueryOperation<string | null>

	/**
	 * Get file summary
	 *
	 * Retrieves the file summary showing the different file types in a given folder on the local file system along with the corresponding number of lines of code. User must have audit support included in the license.
	 */
	getFileSummary(this: void, queryParams: GetFileSummaryQueryParams): QueryOperation<PairListStringFileSummaryInfoRecord>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalContent(this: void, project: string, uniformPath: string, queryParams: GetFilesWithIdenticalContentQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalNames(this: void, project: string, uniformPath: string, queryParams: GetFilesWithIdenticalNamesQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * Get finding
	 *
	 * Retrieves a finding by its ID. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id 
	 */
	getFinding(this: void, project: string, id: string, queryParams: GetFindingQueryParams): QueryOperation<TrackedFinding>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingChurnCount(this: void, project: string, queryParams: GetFindingChurnCountQueryParams): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Get finding churn list
	 *
	 * Provides the findings churn (i.e. the list of added and deleted findings) for a given commit. This service is public API since Teamscale 5.6.4. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingChurnList(this: void, project: string, queryParams: GetFindingChurnListQueryParams): QueryOperation<FindingChurnList>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingDelta(this: void, project: string, queryParams: GetFindingDeltaQueryParams): QueryOperation<ExtendedFindingDelta>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingDeltaCount(this: void, project: string, queryParams: GetFindingDeltaCountQueryParams): QueryOperation<FindingDeltaCount>

	/**
	 * Get sibling findings
	 *
	 * Returns all sibling findings of the given finding. Only searches for siblings in the same findings partition. The finding with the given finding id is not included in the list. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id 
	 */
	getFindingSiblings(this: void, project: string, id: string, queryParams: GetFindingSiblingsQueryParams): QueryOperation<Array<TrackedFinding>>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingTypeDescriptions(this: void, project: string, body: Array<string>): QueryOperation<Array<FindingTypeDescription>>

	/**
	 * Get finding with diff information
	 *
	 * Retrieves a finding by its ID with additional information on when and where the finding was introduced or removed. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id 
	 */
	getFindingWithDiffInfo(this: void, project: string, id: string, queryParams: GetFindingWithDiffInfoQueryParams): QueryOperation<TrackedFindingWithDiffInfo>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindings(this: void, project: string, queryParams: GetFindingsQueryParams): QueryOperation<Array<ExtendedTrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param format The export format used.
	 */
	getFindingsExport(this: void, project: string, format: EFindingsExportFormatEntry, queryParams: GetFindingsExportQueryParams): QueryOperation<void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding 
	 */
	getFindingsSiblings(this: void, project: string, finding: string, queryParams: GetFindingsSiblingsQueryParams): QueryOperation<PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsSummary(this: void, project: string, queryParams: GetFindingsSummaryQueryParams): QueryOperation<FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsTreemap(this: void, project: string, queryParams: GetFindingsTreemapQueryParams): QueryOperation<FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsWithCount(this: void, project: string, queryParams: GetFindingsWithCountQueryParams): QueryOperation<PaginatedFindingsWithCount>

	/**
	 * Get findings with provided ids
	 *
	 * Gets a list of all findings with provided ids. This service is public API since Teamscale 6.0. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsWithIds(this: void, project: string, queryParams: GetFindingsWithIdsQueryParams, body: Array<string>): QueryOperation<Array<TrackedFinding | null>>

	/**
	 * Get the first branch name to be displayed in the UI.
	 *
	 * Get the first branch name to be displayed in the UI. This is either the preselected UI branch or the default branch. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getFirstUIBranchNameForProject(this: void, project: string): QueryOperation<string>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingIds(this: void, project: string, queryParams: GetFlaggedFindingIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo(this: void, project: string, finding: string, queryParams: GetFlaggedFindingInfoQueryParams): QueryOperation<UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindings(this: void, project: string, queryParams: GetFlaggedFindingsQueryParams): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingsDelta(this: void, project: string, queryParams: GetFlaggedFindingsDeltaQueryParams): QueryOperation<Array<ExtendedTrackedFinding>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingsInfos(this: void, project: string, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string>): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get formatted content for an element
	 *
	 * Retrieves the formatted content for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFormattedTokenElementInfo(this: void, project: string, queryParams: GetFormattedTokenElementInfoQueryParams): QueryOperation<FormattedTokenElementInfo | null>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getGitConnectorIds(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories(this: void, queryParams: GetGitHubAppInstallationRepositoriesQueryParams): QueryOperation<Array<string>>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls(this: void): QueryOperation<Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param tagName 
	 */
	getGitTagByName(this: void, project: string, tagName: string, queryParams: GetGitTagByNameQueryParams): QueryOperation<GitTag | null>

	/**
	 * Get global analysis progress
	 *
	 * Retrieves the global analysis progress (for all projects). User must either have the permission to view system status or the permission to access administrative services
	 */
	getGlobalAnalysisProgress(this: void): QueryOperation<Record<string, Array<AnalysisProgressDescriptor>>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest(this: void, queryParams: GetGlobalBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get all project's directories
	 *
	 * Returns global container info by merging container info from all projects. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	getGlobalContainerInfo(this: void, queryParams: GetGlobalContainerInfoQueryParams): QueryOperation<ContainerInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalCriticalEventLog(this: void, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogs(this: void, queryParams: GetGlobalCriticalEventLogsQueryParams): QueryOperation<ShortLogResponseShortLogEntryBase>

	/**
	 * Get multi project first UI branch.
	 *
	 * Gets the branch to be displayed first in the UI for one of the specified projects. The API requires no permissions
	 */
	getGlobalFirstUIBranchGetRequest(this: void, queryParams: GetGlobalFirstUIBranchGetRequestQueryParams): QueryOperation<string | null>

	/**
	 * Get quality indicator and analysis group names
	 *
	 * Retrieves the available quality indicator and analysis group names from all configured projects. Every authenticated user should see the available quality indicator and analysis group names.
	 */
	getGlobalIndicatorsAndGroups(this: void): QueryOperation<IndicatorsAndGroups>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalInteractionLog(this: void, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogs(this: void, queryParams: GetGlobalInteractionLogsQueryParams): QueryOperation<ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalJavascriptErrorLog(this: void, timestamp: number): QueryOperation<DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogs(this: void, queryParams: GetGlobalJavascriptErrorLogsQueryParams): QueryOperation<ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getGlobalMetricDirectorySchemas(this: void, queryParams: GetGlobalMetricDirectorySchemasQueryParams): QueryOperation<Array<MetricDirectorySchema>>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest(this: void, queryParams: GetGlobalRecentBranchesGetRequestQueryParams): QueryOperation<Array<string>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalServiceLog(this: void, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogs(this: void, queryParams: GetGlobalServiceLogsQueryParams): QueryOperation<ShortLogResponseShortServiceLog>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalWorkerLog(this: void, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogs(this: void, queryParams: GetGlobalWorkerLogsQueryParams): QueryOperation<ShortLogResponseShortWorkerLog>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions(this: void, input: string, queryParams: GetGroupAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Checks if unlinked changes exist
	 *
	 * Checks if methods have unlinked changes since a given baseline. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getHasUnlinkedChangesExist(this: void, project: string, queryParams: GetHasUnlinkedChangesExistQueryParams): QueryOperation<boolean>

	/**
	 * Get system health status
	 *
	 * Retrieves a log of system health status information. This service is public API since Teamscale 5.5. No login required to perform monitoring.
	 */
	getHealthStatus(this: void, queryParams: GetHealthStatusQueryParams): QueryOperation<string>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage(this: void): QueryOperation<string>

	/**
	 * Get related changes to a test
	 *
	 * Returns the commit history of commits that affected code tested by the test. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testUniformPath 
	 */
	getHistoryForChangedMethods(this: void, project: string, testUniformPath: string, queryParams: GetHistoryForChangedMethodsQueryParams): QueryOperation<MethodHistoryEntriesWrapper>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned flat list of tests is a subset of all tests known to Teamscale. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. Please use the PUT endpoint instead to provide a list of available tests. In particular, this endpoint may return tests that no longer exist. Callers must handle this case. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImpactedTests(this: void, project: string, queryParams: GetImpactedTestsQueryParams): QueryOperation<Array<PrioritizableTest>>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of test clusters is a subset of the tests given in the request body. Clusters of selected and prioritized tests are formed based on the clustering information given in the request body. The tests from the request body are furthermore used to determine if any tests have changed or new tests were added. These are always included in the returned list of impacted tests. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImpactedTestsFromAvailableTests(this: void, project: string, queryParams: GetImpactedTestsFromAvailableTestsQueryParams, body: Array<TestWithClusterId>): QueryOperation<Array<PrioritizableTestCluster>>

	/**
	 * Get the names of the important branches
	 *
	 * Get the names of the important branches The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImportantBranches(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get included source file paths
	 *
	 * Gets paths to all source files that are included in a project file which is included in a solution file. Requires the Project Connector to be configured so that only solution (.sln), project (.*proj) and source files (e.g. .cs, .cpp, .hpp, ...) are included. Also, solution (.sln) and project (.*proj) files need to be mapped to the language LINE. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIncludedDotNetSourcePaths(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get permissions for an instance
	 *
	 * Returns a set of basic permissions for a single instance identified by the instance ID. The API requires no permissions
     * @param instanceId ID of the instance to get the permissions for
	 */
	getInstancePermissions(this: void, instanceId: string, queryParams: GetInstancePermissionsQueryParams): QueryOperation<Array<EBasicPermissionEntry>>

	/**
	 * Get issue
	 *
	 * Retrieves an issue by ID. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssue(this: void, project: string, issueId: string): QueryOperation<UserResolvedTeamscaleIssue>

	/**
	 * Get files affected by issue
	 *
	 * Retrieves the files affected by the commits to a given issue. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve affected files for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueAffectedFiles(this: void, project: string, issueId: string): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get issue commits
	 *
	 * Returns the list of commits associated with the issue specified after the slash. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve the commits for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssueCommits(this: void, project: string, issueId: string): QueryOperation<Array<CommitDescriptor>>

	/**
	 * Get issue count
	 *
	 * Retrieves the overall issue count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueCount(this: void, project: string): QueryOperation<number>

	/**
	 * Get issue finding churn
	 *
	 * Determines an aggregated finding churn across all commits of the issue. This service is public API since Teamscale 5.9. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to determine the finding churn for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssueFindingChurn(this: void, project: string, issueId: string, queryParams: GetIssueFindingChurnQueryParams): QueryOperation<FindingChurnList>

	/**
	 * Get issue hierarchy
	 *
	 * Retrieves the respective issue hierarchy for the given issue. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve the hierarchy for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueHierarchy(this: void, project: string, issueId: string): QueryOperation<IssueHierarchy>

	/**
	 * Get impacted tests by issue
	 *
	 * Retrieves the impacted tests for a given issue. This are all tests that are expected to execute the methods that have been changed in the context of the given ticket. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve impacted tests for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueImpactedTests(this: void, project: string, issueId: string, queryParams: GetIssueImpactedTestsQueryParams): QueryOperation<Array<PrioritizableTest>>

	/**
	 * Get issue queries
	 *
	 * Retrieves the list of available issue query descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueQueries(this: void, project: string): QueryOperation<Array<StoredQueryDescriptor>>

	/**
	 * Get Issue Query TGA Summary
	 *
	 * Retrieves the TGA summary for a set of issues matched by the given query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueQueryTgaSummary(this: void, project: string, queryParams: GetIssueQueryTgaSummaryQueryParams): QueryOperation<TgaSummary>

	/**
	 * Perform issue trend query
	 *
	 * Retrieves the issue trend for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueQueryTrend(this: void, project: string, queryParams: GetIssueQueryTrendQueryParams): QueryOperation<QueryTrendResult>

	/**
	 * Get issue treemap
	 *
	 * Builds an issue treemap for the given parameters. If the color metric is set to -1, it calculates how many issues reference the given nodes, while any other valid color metric value calculates the corresponding metric for the nodes. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueTreemap(this: void, project: string, queryParams: GetIssueTreemapQueryParams): QueryOperation<RelatedIssuesMetricTreemapWrapper>

	/**
	 * Get issue trend
	 *
	 * Downloads issue trend data. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssueTrendAsCsv(this: void, project: string, queryParams: GetIssueTrendAsCsvQueryParams): QueryOperation<File>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesByRepositoryCommits(this: void, project: string, queryParams: GetIssuesByRepositoryCommitsQueryParams): QueryOperation<Array<Array<TeamscaleIssueStatus> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesDetails(this: void, project: string, queryParams: GetIssuesDetailsQueryParams): QueryOperation<Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Export issues
	 *
	 * Export issues for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param format The export format used.
	 */
	getIssuesExport(this: void, project: string, format: EIssuesExportFormatEntry, queryParams: GetIssuesExportQueryParams): QueryOperation<void>

	/**
	 * Get contained package names
	 *
	 * All packages that should be profiled (subpackages will be profiled as well). The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getJavaProfilerJvmPackageNames(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getJobOptions(this: void, project: string, jobId: string): QueryOperation<TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize(this: void): QueryOperation<JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getJobs(this: void, project: string): QueryOperation<Array<TestMinimizationJobRun>>

	/**
	 * Get available column names
	 *
	 * Returns the names of all columns that are available for issue entities The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getKnownIssueQueryColumns(this: void, project: string): QueryOperation<Record<string, EKeyedObjectTypeEntry>>

	/**
	 * Get available column names
	 *
	 * Returns the names of all columns that are available for specification item entities The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getKnownSpecItemColumns(this: void, project: string, queryParams: GetKnownSpecItemColumnsQueryParams): QueryOperation<Record<string, EKeyedObjectTypeEntry>>

	/**
	 * Get full Kubernetes manifest
	 *
	 * Returns the content of the full Kubernetes manifest which is built from the given Helm chart or Kustomization. Returns null in case no full manifest is available for the given uniform path, e.g. a non-root Kustomization. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getKubernetesManifest(this: void, project: string, uniformPath: string, queryParams: GetKubernetesManifestQueryParams): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Retrieve the language processing information
	 *
	 * Returns whether the given language has a shallow parser and a preprocessor. The API requires no permissions
     * @param language The name of the language that we want to check for parser support and preprocessor
	 */
	getLanguageProcessingInfo(this: void, language: ELanguageEntry): QueryOperation<LanguageProcessingInfo>

	/**
	 * Get rules
	 *
	 * Gets rules for a given language available in the current Teamscale version. No permission needed since every user can explore rules with the rule browser.
	 */
	getLanguageRules(this: void, queryParams: GetLanguageRulesQueryParams): QueryOperation<RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages and tools for a specific analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName The analysis profile for which to get rules.
	 */
	getLanguageRulesForAnalysisProfile(this: void, analysisProfileName: string, queryParams: GetLanguageRulesForAnalysisProfileQueryParams): QueryOperation<RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages for a specific project. The API requires the user to have View Project permissions on the project.
     * @param project The project for which to get rules.
	 */
	getLanguageRulesForProject(this: void, project: string, queryParams: GetLanguageRulesForProjectQueryParams): QueryOperation<RulesContainer>

	/**
	 * Get project languages
	 *
	 * Returns a list of languages a project has been configured for This service is public API since Teamscale 5.5. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getLanguages(this: void, project: string): QueryOperation<Array<ELanguageEntry>>

	/**
	 * Get GitLab repository languages
	 *
	 * Gets all languages associated with a GitLab repository, including their usage percentages in the project codebase. The API requires the user to have View permissions on the External Account credentialsName.
     * @param credentialsName Name of the external account
	 */
	getLanguagesForGitlabProject(this: void, credentialsName: string, queryParams: GetLanguagesForGitlabProjectQueryParams): QueryOperation<Array<ProjectLanguage>>

	/**
	 * Return last change log entry
	 *
	 * Returns the log entry for the last change containing the given file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getLastChangeLogEntry(this: void, project: string, uniformPath: string, queryParams: GetLastChangeLogEntryQueryParams): QueryOperation<RepositoryLogEntry | null>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getLastCommitOfType(this: void, project: string, queryParams: GetLastCommitOfTypeQueryParams): QueryOperation<RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo(this: void, project: string): QueryOperation<LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo(this: void, queryParams: GetLicenseInfoQueryParams): QueryOperation<LicenseInfo | null>

	/**
	 * Get licenses
	 *
	 * Service to retrieve all licenses sorted by criticality The API requires no permissions
     * @param project The project ID.
	 */
	getLicenses(this: void, project: string, queryParams: GetLicensesQueryParams): QueryOperation<Array<CopyrightLicenseInfo>>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Account accountName.
     * @param accountName The ID of the external account the user needs access to.
	 */
	getLinkRoles(this: void, accountName: string, body: ConnectorConfiguration): QueryOperation<GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last n days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters(this: void, queryParams: GetListOfActiveCommittersQueryParams): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last n days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects(this: void, queryParams: GetListOfActiveCommittersForProjectsQueryParams, body: Array<string>): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext(this: void, queryParams: GetLoginContextQueryParams): QueryOperation<LoginContext>

	/**
	 * Get a single merge request
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequest(this: void, project: string, idWithRepository: string): QueryOperation<ExtendedMergeRequest | null>

	/**
	 * Get affected files for merge request
	 *
	 * Provides the list of changed files for a given merge request. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestAffectedFiles(this: void, project: string, queryParams: GetMergeRequestAffectedFilesQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get merge request changed lines
	 *
	 * Calculates the lines that have been changed in the merge request for the given file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequestChangedLines(this: void, project: string, idWithRepository: string, queryParams: GetMergeRequestChangedLinesQueryParams): QueryOperation<Array<number>>

	/**
	 * Get a merge request analysis delta
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequestDelta(this: void, project: string, idWithRepository: string, queryParams: GetMergeRequestDeltaQueryParams): QueryOperation<MergeRequestDelta | null>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestFindingChurn(this: void, project: string, queryParams: GetMergeRequestFindingChurnQueryParams): QueryOperation<ExtendedFindingDelta>

	/**
	 * Get merge request parent info
	 *
	 * Retrieves information on the parent commits for a merge request (merge-base, source-only ancestors). The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestParentInfo(this: void, project: string, queryParams: GetMergeRequestParentInfoQueryParams): QueryOperation<MergeRequestParentInfoTransport>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
     * @param project ID of the project which analyzes the merge request.
	 */
	getMergeRequestRepositoryChurn(this: void, project: string, queryParams: GetMergeRequestRepositoryChurnQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get merge request test coverage
	 *
	 * Calculates how much lines of the new and changed lines are covered by tests. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequestTestCoverage(this: void, project: string, idWithRepository: string): QueryOperation<TestCoverageDeltaInfo>

	/**
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP system.
	 *
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP System The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param filePath Path to the file in Teamscale.
	 */
	getMetadataForFile(this: void, project: string, filePath: string, queryParams: GetMetadataForFileQueryParams): QueryOperation<AbapFileMetadata>

	/**
	 * Get method-based treemap
	 *
	 * Builds a treemap based on methods. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMethodBasedTreemap(this: void, project: string, queryParams: GetMethodBasedTreemapQueryParams): QueryOperation<MethodBasedTreemapWrapper>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory(this: void, project: string, uniformPath: string, queryParams: GetMethodHistoryQueryParams): QueryOperation<MethodHistoryEntriesWrapper>

	/**
	 * Get methods executed by the queried tests
	 *
	 * Returns all methods executed by the given test execution(s) matched by the given query, as a *.csv file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMethodsExecutedByTest(this: void, project: string, queryParams: GetMethodsExecutedByTestQueryParams): QueryOperation<File>

	/**
	 * Get methods
	 *
	 * Returns the list of methods for a given file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getMethodsForFile(this: void, project: string, uniformPath: string, queryParams: GetMethodsForFileQueryParams): QueryOperation<Array<LineBasedMethodInfo>>

	/**
	 * Get metric assessment
	 *
	 * Provides a list of metrics and their assessment for a given path and threshold configuration The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricAssessmentWithUniformPath(this: void, project: string, queryParams: GetMetricAssessmentWithUniformPathQueryParams): QueryOperation<Array<GroupAssessment>>

	/**
	 * Get metric badges configuration for the given merge request.
	 *
	 * Get metric badges configuration for the given merge request. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param idWithRepository 
	 */
	getMetricBadgesConfigurationForMergeRequest(this: void, project: string, idWithRepository: string): QueryOperation<MetricBadgesConfiguration>

	/**
	 * Get benchmark results
	 *
	 * Returns (partly anonymized) benchmark results for a specific metric. User must have audit support included in the license.
	 */
	getMetricBenchmark(this: void, queryParams: GetMetricBenchmarkQueryParams): QueryOperation<Array<BenchmarkResult>>

	/**
	 * Get metric delta
	 *
	 * Provides the metric delta (i.e., the changes in metric values) for a given uniform path and time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricDelta(this: void, project: string, queryParams: GetMetricDeltaQueryParams): QueryOperation<Array<MetricDeltaValue>>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath(this: void, project: string, uniformPathType: ETypeEntry): QueryOperation<MetricDirectorySchema>

	/**
	 * Get metric schemas
	 *
	 * Returns all metric schemas of a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricDirectorySchemas(this: void, project: string): QueryOperation<Record<string, MetricDirectorySchema>>

	/**
	 * Get metric distribution
	 *
	 * Retrieves metric distributions for a resource sub-tree. A metric distribution can basically be thought of as a table which describes the distribution of a set of specified metrics with respect to a selected metric (called \"principal metric\") according to a set of given ranges (which are consecutive intervals based on a set of given boundaries). The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricDistribution(this: void, project: string, queryParams: GetMetricDistributionQueryParams): QueryOperation<Array<MetricDistributionEntry>>

	/**
	 * Get metric distribution delta
	 *
	 * Retrieves metrics distribution with deltas for a resource sub-tree. A metric distribution delta can basically be thought of as a table which describes the distribution of the delta for a set of specified metrics with respect to a selected metric delta (called \"principal metric delta\") according to a set of given ranges (which are consecutive intervals based on a set of given boundaries). The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricDistributionDelta(this: void, project: string, queryParams: GetMetricDistributionDeltaQueryParams): QueryOperation<MetricDistributionWithDelta>

	/**
	 * Get metric file distribution
	 *
	 * Retrieves the metric file distribution for the provided metric and regexps. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricFileDistribution(this: void, project: string, queryParams: GetMetricFileDistributionQueryParams): QueryOperation<Array<number>>

	/**
	 * Get metric history
	 *
	 * Retrieves the metric history for a resource. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricHistory(this: void, project: string, queryParams: GetMetricHistoryQueryParams): QueryOperation<Array<MetricTrendEntry>>

	/**
	 * Get metric history commits
	 *
	 * Returns the full commits (with branch name) for points in time of a metric history trend The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricHistoryCommits(this: void, project: string, queryParams: GetMetricHistoryCommitsQueryParams): QueryOperation<Array<CommitDescriptor>>

	/**
	 * Get metric hotspots
	 *
	 * Retrieves the metric hotspots for the provided parameters. Hotspots are determined using the aggregation information of the metric. The result is determined by ranking all elements with regards to each individual metric and computing an overall ranking by equally weighting each metric rank. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricHotspots(this: void, project: string, queryParams: GetMetricHotspotsQueryParams): QueryOperation<Array<ScoredMetricDirectoryEntry>>

	/**
	 * Get metric notification rules
	 *
	 * Retrieves the currently set metric notification rules for the current user The API requires no permissions
	 */
	getMetricNotificationRules(this: void): QueryOperation<MetricNotificationRules>

	/**
	 * Get metrics
	 *
	 * Retrieves the available external metrics. The API requires no permissions
	 */
	getMetricSchemaEntries(this: void, queryParams: GetMetricSchemaEntriesQueryParams): QueryOperation<Array<MetricSchemaChangeEntry>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
     * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration(this: void, metricThresholdConfigurationName: string, queryParams: GetMetricThresholdConfigurationQueryParams): QueryOperation<MetricThresholdConfiguration>

	/**
	 * Provides threshold path for a metric.
	 *
	 * Provides the time course for a given metric threshold configuration and metric. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricThresholdPathForMetric(this: void, project: string, queryParams: GetMetricThresholdPathForMetricQueryParams): QueryOperation<EvaluatedMetricThresholdPath>

	/**
	 * Get treemap
	 *
	 * Builds a treemap for the given metric. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMetricTreemap(this: void, project: string, queryParams: GetMetricTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMinimizedTests(this: void, project: string, queryParams: GetMinimizedTestsQueryParams): QueryOperation<TestMinimizationResult>

	/**
	 * Returns a preview of the data we would send
	 *
	 * Returns a the preview of the data we would send to our monitoring back-end. The API requires the user to have Access Administrative Services permissions.
	 */
	getMonitoringPreview(this: void): QueryOperation<string>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for a given project as well as the corresponding test and application subprojects. User must have audit support included in the license.
	 */
	getMultiProjectLatexTable(this: void, queryParams: GetMultiProjectLatexTableQueryParams): QueryOperation<string>

	/**
	 * Get unlinked commits
	 *
	 * Retrieves the commits for changes not linked to any issue. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getNonIssueTgaCommitsAndTreemap(this: void, project: string, queryParams: GetNonIssueTgaCommitsAndTreemapQueryParams): QueryOperation<UnlinkedChangesWrapper>

	/**
	 * Get notification rules
	 *
	 * Retrieves the notification rules for the current user The API requires no permissions
	 */
	getNotificationRules(this: void): QueryOperation<FindingsNotificationRules>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers(this: void): QueryOperation<number>

	/**
	 * Get OpenAPI definition
	 *
	 * Returns an OpenAPI (formerly known as Swagger) definition for all available services. The API requires no permissions
     * @param type 
	 */
	getOpenApi(this: void, type: string, queryParams: GetOpenApiQueryParams): QueryOperation<object>

	/**
	 * Retrieves OpenID endpoints
	 *
	 * Tries to retrieve all Teamscale-relevant endpoints from the issuer URL The API requires the user to have Access Administrative Services permissions.
	 */
	getOpenIdEndpoints(this: void, queryParams: GetOpenIdEndpointsQueryParams): QueryOperation<OpenIdEndpointInfo>

	/**
	 * List parse log of single element
	 *
	 * Lists all parse log entries for the element with given uniform path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getParseLogElement(this: void, project: string, queryParams: GetParseLogElementQueryParams): QueryOperation<Array<ParseLogEntry>>

	/**
	 * Get architecture analysis progress.
	 *
	 * Returns pending architecture uploads. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getPendingArchitectureUploads(this: void, project: string, queryParams: GetPendingArchitectureUploadsQueryParams): QueryOperation<Record<string, EArchitectureUploadTypeEntry>>

	/**
	 * Get pending finding exclusions
	 *
	 * Returns any pending finding exclusions for the given commits, or null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding Id of the finding the information is requested for.
	 */
	getPendingFindingExclusion(this: void, project: string, finding: string, queryParams: GetPendingFindingExclusionQueryParams): QueryOperation<UserResolvedFindingBlacklistInfo>

	/**
	 * Get system performance metrics
	 *
	 * Provides a trend of performance metrics of previous days. The API requires the user to have View System Status permissions.
	 */
	getPerformanceMetricsTrend(this: void, queryParams: GetPerformanceMetricsTrendQueryParams): QueryOperation<Array<PerformanceMetricsEntry>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup(this: void, queryParams: GetPermissionLookupQueryParams): QueryOperation<PermissionLookupEBasicPermission>

	/**
	 * Perspective context
	 *
	 * Provides basic information about the logged in user, permissions, available projects and the Teamscale instance. The API requires no permissions
	 */
	getPerspectiveContext(this: void): QueryOperation<PerspectiveContext>

	/**
	 * Get Polarion link roles
	 *
	 * Returns the link roles for a Polarion project. The API requires the user to have View permissions on the External Account accountName.
     * @param accountName The ID of the external account the user needs access to.
	 */
	getPolarionWorkItemLinkRoles(this: void, accountName: string, body: ConnectorConfiguration): QueryOperation<PolarionWorkItemLinkRolesResult>

	/**
	 * Get Polarion work item types
	 *
	 * Returns the work item types for a Polarion project. The API requires the user to have View permissions on the External Account accountName.
     * @param accountName The ID of the external account the user needs access to.
	 */
	getPolarionWorkItemTypes(this: void, accountName: string, body: ConnectorConfiguration): QueryOperation<PolarionWorkItemTypeResult>

	/**
	 * Returns portfolio manual assessments
	 *
	 * Returns portfolio quality goal manual assessments for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The id of the project to which manual assessments belongs
	 */
	getPortfolioManualAssessments(this: void, project: string): QueryOperation<Array<QualityGoalManualAssessment>>

	/**
	 * Get portfolio overview
	 *
	 * Retrieves the portfolio overview for projects filtered by labels withing a specified time range Only projects visible to the user are shown in the overview
	 */
	getPortfolioOverview(this: void, queryParams: GetPortfolioOverviewQueryParams): QueryOperation<Array<ProjectPortfolioResult>>

	/**
	 * Gets postponed rollback counts of all Teamscale projects
	 *
	 * Provides information about project postponed rollbacks counts. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getPostponedRollbackCounts(this: void): QueryOperation<PostponedRollbackCounts>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getPreCommitContent(this: void, project: string, queryParams: GetPreCommitContentQueryParams): QueryOperation<FormattedTokenElementInfo | null>

	/**
	 * Get probe coverage
	 *
	 * Returns the probe coverage data for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getProbeCoverage(this: void, project: string, uniformPath: string, queryParams: GetProbeCoverageQueryParams): QueryOperation<ProbeCoverageInfo | null>

	/**
	 * Get profiler configuration
	 *
	 * Retrieves the profiler configuration identified by given ID. The API requires the user to have View permissions on the Profiler Configuration configurationId.
     * @param configurationId ID of the profiler configuration to retrieve.
	 */
	getProfilerConfiguration(this: void, configurationId: string): QueryOperation<ProfilerConfiguration>

	/**
	 * Get project
	 *
	 * Returns details on a project. This service is public API since Teamscale 7.1. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getProject(this: void, project: string): QueryOperation<ProjectInfo>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectAnalysisProgress(this: void, project: string): QueryOperation<Array<AnalysisProgressDescriptor>>

	/**
	 * Get project configuration
	 *
	 * Returns the configuration used for creation of the project in the path parameter. This service is public API since Teamscale 9.9. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getProjectConfiguration(this: void, project: string): QueryOperation<ProjectConfiguration>

	/**
	 * Get connector status details
	 *
	 * Returns detailed status information for each connector in a project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectConnectorStatusDetails(this: void, project: string): QueryOperation<Array<ProjectConnectorStatus>>

	/**
	 * Get project credentials
	 *
	 * Returns the names of credentials that are assigned to the given Teamscale projects. User must have the permission to configure external accounts.
	 */
	getProjectCredentials(this: void, queryParams: GetProjectCredentialsQueryParams): QueryOperation<ExternalCredentialsUsageInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectCriticalEventLog(this: void, project: string, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogs(this: void, project: string, queryParams: GetProjectCriticalEventLogsQueryParams): QueryOperation<ShortLogResponseShortLogEntryBase>

	/**
	 * Get project IDs
	 *
	 * Returns available internal and public project IDs, grouped by primary public ID. Only projects visible to the user are returned.
	 */
	getProjectIdMap(this: void): QueryOperation<Record<string, ProjectIdEntry>>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectInteractionLog(this: void, project: string, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogs(this: void, project: string, queryParams: GetProjectInteractionLogsQueryParams): QueryOperation<ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectJavascriptErrorLog(this: void, project: string, timestamp: number): QueryOperation<DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogs(this: void, project: string, queryParams: GetProjectJavascriptErrorLogsQueryParams): QueryOperation<ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules(this: void): QueryOperation<ProjectNotificationRules | null>

	/**
	 * Get schema
	 *
	 * Retrieves project option schema. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectOptionSchema(this: void, project: string): QueryOperation<Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectOptions(this: void, project: string): QueryOperation<Record<string, object>>

	/**
	 * Get project roles with assignments
	 *
	 * Returns the roles assigned to users or groups on an object. Requires the permission to \"Assign Roles\" for the project
     * @param instance The project ID to load roles for.
	 */
	getProjectRolesWithAssignments(this: void, instance: string): QueryOperation<RolesWithAssignmentsProjectRole>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectServiceLog(this: void, project: string, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogs(this: void, project: string, queryParams: GetProjectServiceLogsQueryParams): QueryOperation<ShortLogResponseShortServiceLog>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend(this: void, queryParams: GetProjectUserActivityTrendQueryParams): QueryOperation<Array<Array<number>>>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectWorkerLog(this: void, project: string, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogs(this: void, project: string, queryParams: GetProjectWorkerLogsQueryParams): QueryOperation<ShortLogResponseShortWorkerLog>

	/**
	 * Gets connector states of all Teamscale projects
	 *
	 * Provides information about project connector states. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getProjectsConnectorStates(this: void): QueryOperation<ProjectsConnectorState>

	/**
	 * Returns all projects' connectors
	 *
	 * Returns information about the connector configurations associated with each project by its ID (not alias). The service returns the connector configurations only for projects visible to the user and thus no permissions are required. The API requires no permissions
	 */
	getProjectsConnectors(this: void): QueryOperation<Record<string, Array<ConnectorConfiguration>>>

	/**
	 * Gets analysis states of all Teamscale projects
	 *
	 * Provides the most up-to-date information about completed and currently-analyzed projects. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getProjectsStates(this: void): QueryOperation<ProjectsState>

	/**
	 * Get projects that use timestamp-based thresholds
	 *
	 * null Only returns projects the user is allowed to view
	 */
	getProjectsWithTimestampBasedThresholds(this: void): QueryOperation<Record<string, boolean>>

	/**
	 * Get quality indicator names
	 *
	 * Returns the union of distinct quality indicator names configured in the analysis profiles of all code scopes of a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getQualityIndicators(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Select random files.
	 *
	 * Select files randomly for manual review in an audit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRandomFilesInRange(this: void, project: string, queryParams: GetRandomFilesInRangeQueryParams): QueryOperation<Array<CodeFileInfo>>

	/**
	 * Get simple read/write performance statistics
	 *
	 * Returns a textual summary of read/write performance statistics. The API requires the user to have Access Administrative Services permissions.
	 */
	getReadWriteStatistics(this: void): QueryOperation<string>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRecentBranches(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralTreemap(this: void, project: string, queryParams: GetRedundantLiteralTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralsQuery(this: void, project: string, queryParams: GetRedundantLiteralsQueryQueryParams): QueryOperation<Array<RedundantLiteralInfo>>

	/**
	 * Get refactoring suggestions
	 *
	 * Returns extract method refactoring suggestions for a given finding as a sorted list (best-first) of refactoring suggestions for the method with the given finding. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param findingId The finding for which to suggest possible refactorings
	 */
	getRefactoringSuggestions(this: void, project: string, findingId: string, queryParams: GetRefactoringSuggestionsQueryParams): QueryOperation<RefactoringSuggestions | null>

	/**
	 * Get change data of testwise coverage methods in treemap
	 *
	 * Retrieves the changes of methods covered by a test with testwise coverage data. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testExecution 
	 */
	getRelatedChangesTreemap(this: void, project: string, testExecution: string, queryParams: GetRelatedChangesTreemapQueryParams): QueryOperation<MethodTreeMapNode>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
     * @param id The report id
	 */
	getReport(this: void, id: string): QueryOperation<QualityReport>

	/**
	 * Get report slide
	 *
	 * Returns the data necessary to render the slide in the frontend. The API requires the user to have View permissions on the Quality Report reportId.
     * @param reportId The ID of the report.
     * @param slideId The ID of the slide.
	 */
	getReportSlide(this: void, reportId: string, slideId: string): QueryOperation<ReportSlideWithRenderData>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryActivitySummary(this: void, project: string, queryParams: GetRepositoryActivitySummaryQueryParams): QueryOperation<RepositoryActivitySummary>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryChurn(this: void, project: string, queryParams: GetRepositoryChurnQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLog(this: void, project: string, queryParams: GetRepositoryLogQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the number of all repository log entries
	 *
	 * Returns the number of repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLogCount(this: void, project: string, queryParams: GetRepositoryLogCountQueryParams): QueryOperation<number>

	/**
	 * Get all repository log entries, with split aggregates
	 *
	 * Returns repository log entries for given commits. Aggregated entries are split into their constituents.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLogWithSplitAggregates(this: void, project: string, queryParams: GetRepositoryLogWithSplitAggregatesQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositorySummary(this: void, project: string, queryParams: GetRepositorySummaryQueryParams): QueryOperation<RepositorySummary>

	/**
	 * Get resolved tasks
	 *
	 * Returns all available tasks with resolved properties. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getResolvedTasks(this: void, project: string, queryParams: GetResolvedTasksQueryParams): QueryOperation<Array<ResolvedTask>>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getResourceHistory(this: void, project: string, uniformPath: string, queryParams: GetResourceHistoryQueryParams): QueryOperation<Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get resource type
	 *
	 * Retrieves the type of a resource represented by a uniform path The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getResourceType(this: void, project: string, queryParams: GetResourceTypeQueryParams): QueryOperation<EResourceTypeEntry>

	/**
	 * Get retrospective
	 *
	 * Returns the retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project of the retrospective.
     * @param id The retrospective id
	 */
	getRetrospective(this: void, id: string): QueryOperation<UserResolvedRetrospective>

	/**
	 * Get retrospective notes
	 *
	 * Returns the retrospective notes for the given project. The user needs to have \"Edit Retrospectives\" permission on the given project ID.
     * @param id The ID of the retrospective to get notes for.
     * @param projectId 
	 */
	getRetrospectiveNote(this: void, id: string, projectId: string): QueryOperation<string>

	/**
	 * Get quality retrospectives
	 *
	 * Returns all retrospectives stored for the given project. No permissions needed, as the service will only return retrospectives visible to current user i.e., the user has \"Edit Retrospectives\" permission on at least one project of the retrospective.
	 */
	getRetrospectivesForProject(this: void, queryParams: GetRetrospectivesForProjectQueryParams): QueryOperation<Array<UserResolvedRetrospective>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getReviewBaseline(this: void, project: string): QueryOperation<number>

	/**
	 * Get review status
	 *
	 * Allows to retrieve the review status for a file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath The uniform path of the file for which the review status should be retrieved
	 */
	getReviewStatus(this: void, project: string, uniformPath: string, queryParams: GetReviewStatusQueryParams): QueryOperation<AssessedReviewStatus | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRisks(this: void, project: string, queryParams: GetRisksQueryParams): QueryOperation<RiskMethods>

	/**
	 * Get role schema
	 *
	 * Returns the RoleSchema holding all existing global and project roles. The API requires no permissions
	 */
	getRoleSchema(this: void): QueryOperation<RoleSchemaData>

	/**
	 * Get running profiler
	 *
	 * Retrieves the running profiler The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId 
	 */
	getRunningProfiler(this: void, profilerId: string): QueryOperation<RunningProfilerInfo>

	/**
	 * Get running profilers
	 *
	 * Retrieves the running profilers available in the system No permissions needed, as the service will only return the profiler infos that use a profiler configuration that is visible to the current user.
	 */
	getRunningProfilers(this: void, queryParams: GetRunningProfilersQueryParams): QueryOperation<RunningProfilersResult>

	/**
	 * Returns the SAML metadata from the given URL.
	 *
	 * This is meant to simplify SAML configuration in Teamscale. The API requires the user to have Access Administrative Services permissions.
	 */
	getSamlIdentityProviderMetadata(this: void, queryParams: GetSamlIdentityProviderMetadataQueryParams): QueryOperation<string>

	/**
	 * Get loading state of SAP JCo library.
	 *
	 * Allows to determine whether the SAP JCo library has been loaded or not. No permissions needed, as this service will not provide any critical information
	 */
	getSapJcoLibraryIsLoaded(this: void): QueryOperation<boolean>

	/**
	 * Get metric scatter plot
	 *
	 * Retrieves a list of metrics for all files in the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getScatterPlotMetrics(this: void, project: string, queryParams: GetScatterPlotMetricsQueryParams): QueryOperation<Array<MetricDirectoryEntry>>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage(this: void): QueryOperation<string>

	/**
	 * Autocompletion for search field
	 *
	 * Service that provides possible completions for a query. The service will only search among projects visible to current user.
	 */
	getSearchAutocompletionResults(this: void, queryParams: GetSearchAutocompletionResultsQueryParams): QueryOperation<Record<string, Array<SearchSuggestion>>>

	/**
	 * Search code, issues, commits
	 *
	 * Provides the possibility to search the indexed code base. The service will only search through projects visible to current user.
	 */
	getSearchResults(this: void, queryParams: GetSearchResultsQueryParams): QueryOperation<SearchResultContainer>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getSelectedTests(this: void, project: string, jobId: string, queryParams: GetSelectedTestsQueryParams): QueryOperation<Array<SelectedTest>>

	/**
	 * Get schema
	 *
	 * Retrieves server option schema. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptionSchema(this: void): QueryOperation<Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available server options. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptions(this: void): QueryOperation<Record<string, object>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad(this: void): QueryOperation<Array<LoadProfile>>

	/**
	 * Get Simulink file references
	 *
	 * Returns a list of Simulink file references for a source element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getSimulinkFileReferenceDependencies(this: void, project: string, uniformPath: string, queryParams: GetSimulinkFileReferenceDependenciesQueryParams): QueryOperation<Array<SimulinkFileReferenceDependency>>

	/**
	 * Get simulink model
	 *
	 * Returns the content for the specified view in a simulink model (mdl and slx). The view can be the model itself, a subsystem, a Stateflow subchart, a Simulink Function node in Stateflow, ... . The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getSimulinkModel(this: void, project: string, uniformPath: string, queryParams: GetSimulinkModelQueryParams): QueryOperation<SimulinkBlockData>

	/**
	 * Get comparison info
	 *
	 * Returns the comparison information for two Simulink subsystems. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSimulinkModelComparisonResult(this: void, project: string, queryParams: GetSimulinkModelComparisonResultQueryParams): QueryOperation<SimulinkModelComparisonResult>

	/**
	 * Get signals
	 *
	 * Returns the list of signals for a Simulink dependency. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSimulinkSignalsForDependency(this: void, project: string, queryParams: GetSimulinkSignalsForDependencyQueryParams): QueryOperation<Array<string>>

	/**
	 * Get test coverage for Simulink models
	 *
	 * Retrieves test coverage for a simulink model. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getSimulinkTestCoverage(this: void, project: string, uniformPath: string, queryParams: GetSimulinkTestCoverageQueryParams): QueryOperation<DerivedTestCoverageInfo | null>

	/**
	 * Get single metric delta table
	 *
	 * Creates a table with the changes of a single metric in a merge request. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSingleMetricDeltaTable(this: void, project: string, queryParams: GetSingleMetricDeltaTableQueryParams): QueryOperation<SingleMetricDeltaTableData>

	/**
	 * Get single metric delta treemap
	 *
	 * Creates a treemap with the changes of a single metric in a merge request. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSingleMetricDeltaTreemap(this: void, project: string, queryParams: GetSingleMetricDeltaTreemapQueryParams): QueryOperation<SingleMetricDeltaTreemapData>

	/**
	 * Returns a specific token-expansion (i.e., the result of one macro or directive expansion/replacement). This service should be called with the commit returned by the preprocessor-expansions endpoint to achieve correct results (the file contents might have changed in the meantime).
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getSinglePreprocessorExpansion(this: void, project: string, uniformPath: string, queryParams: GetSinglePreprocessorExpansionQueryParams): QueryOperation<Array<SinglePreprocessorExpansionTransport>>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for the project. User must have audit support included in the license.
     * @param project The project ID.
	 */
	getSingleProjectLatexTable(this: void, project: string, queryParams: GetSingleProjectLatexTableQueryParams): QueryOperation<string>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption(this: void, project: string, optionId: string): QueryOperation<object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
     * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption(this: void, optionId: string): QueryOperation<object>

	/**
	 * Returns an image that has been stored previously using this service
	 *
	 * null The API requires the user to have View permissions on the Quality Report reportId.
     * @param reportId The ID of the quality report the user needs access to.
     * @param id The unique image id that was returned by the POST call to this service.
	 */
	getSlideImage(this: void, reportId: string, id: string): QueryOperation<void>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot(this: void, id: string, queryParams: GetSnapshotQueryParams): QueryOperation<InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus(this: void, id: string): QueryOperation<EInstanceComparisonStatusEntry>

	/**
	 * Get spec item
	 *
	 * Gets the spec item identified by given ID The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch
	 */
	getSpecItem(this: void, project: string, id: string, queryParams: GetSpecItemQueryParams): QueryOperation<ImportedLinksAndTypeResolvedSpecItem>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemChurn(this: void, project: string, queryParams: GetSpecItemChurnQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get spec item code references
	 *
	 * Retrieves all spec item code references for the given spec item ID. If there is no spec item code reference, it returns an empty list. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch
	 */
	getSpecItemCodeReferences(this: void, project: string, id: string, queryParams: GetSpecItemCodeReferencesQueryParams): QueryOperation<Array<SpecItemCodeReference>>

	/**
	 * Get spec item count
	 *
	 * Fetches the count of spec items for the given project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemCount(this: void, project: string, queryParams: GetSpecItemCountQueryParams): QueryOperation<number>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemDetails(this: void, project: string, queryParams: GetSpecItemDetailsQueryParams): QueryOperation<Array<UserResolvedSpecItem | null>>

	/**
	 * Get spec item graph
	 *
	 * Retrieves a graph representation of the performed spec item query. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getSpecItemGraph(this: void, project: string, queryParams: GetSpecItemGraphQueryParams): QueryOperation<SpecItemGraph>

	/**
	 * Get spec item queries
	 *
	 * Retrieves the list of available spec item query descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemQueries(this: void, project: string): QueryOperation<Array<StoredQueryDescriptor>>

	/**
	 * Get spec item test executions
	 *
	 * Retrieves all test executions for tests that have a reference to the spec item. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch
	 */
	getSpecItemTestExecutions(this: void, project: string, id: string, queryParams: GetSpecItemTestExecutionsQueryParams): QueryOperation<Array<TestExecutionWithPartition>>

	/**
	 * Get the spec items that the given one is verifying
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the verifying spec item
	 */
	getSpecItemVerifies(this: void, project: string, id: string, queryParams: GetSpecItemVerifiesQueryParams): QueryOperation<Array<SpecItemReferenceElement>>

	/**
	 * Get spec items
	 *
	 * Retrieves the available spec items filtered by given parameters. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemsByIdAndSorted(this: void, project: string, queryParams: GetSpecItemsByIdAndSortedQueryParams): QueryOperation<Array<SpecItem>>

	/**
	 * Get the spec items that are verified by the queried items 
	 *
	 * Retrieves all 'verifies' references from the queried spec items to the verified ones. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemsVerifiedByQueried(this: void, project: string, queryParams: GetSpecItemsVerifiedByQueriedQueryParams): QueryOperation<Array<SpecItemRelationReference>>

	/**
	 * Get the spec items that verify the queried items 
	 *
	 * Retrieves all 'verified-by' references from the queried spec items to the verifying ones. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemsVerifyingQueried(this: void, project: string, queryParams: GetSpecItemsVerifyingQueriedQueryParams): QueryOperation<Array<SpecItemRelationReference>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace(this: void, project: string, test: string, queryParams: GetStacktraceQueryParams): QueryOperation<Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getStateChangeTimestamps(this: void, project: string): QueryOperation<Record<string, number>>

	/**
	 * Returns a static SVG badge for metrics
	 *
	 * null The API requires no login
	 */
	getStaticMetricBadge(this: void, queryParams: GetStaticMetricBadgeQueryParams): QueryOperation<string>

	/**
	 * Get external clone detection status
	 *
	 * Retrieves the current status of running external clone detection. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
     * @param project The project ID.
	 */
	getStatusOfCloneDetection(this: void, project: string): QueryOperation<ExternalXCloneStatus>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails(this: void): QueryOperation<void>

	/**
	 * Returns the configuration of the service provider that is stored at the named option.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires no login
	 */
	getStoredServiceProviderConfiguration(this: void, queryParams: GetStoredServiceProviderConfigurationQueryParams): QueryOperation<File>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestLists(this: void, project: string): QueryOperation<Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestListsNames(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get stored test queries
	 *
	 * Retrieves the list of available test query descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestQueries(this: void, project: string): QueryOperation<Array<StoredQueryDescriptor>>

	/**
	 * Get subject role assignments for a specific subject.
	 *
	 * Returns a list of subject role assignments for a specific subject. We have to do the permission check by hand as the needed permissions to display the own subject role assignments differ from the ones needed to view someone elses subject role assignments.
     * @param subjectId 
     * @param subjectType 
	 */
	getSubjectRoleAssignments(this: void, subjectId: string, subjectType: string): QueryOperation<Array<SubjectRoleAssignments>>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation(this: void): QueryOperation<Array<SystemProcessInfo>>

	/**
	 * Get task
	 *
	 * Returns the respective task with detailed findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the requested task
	 */
	getTask(this: void, project: string, id: number, queryParams: GetTaskQueryParams): QueryOperation<TaskWithDetailedFindings>

	/**
	 * Get task siblings
	 *
	 * Retrieves the task siblings (previous and next tasks) for the task specified by ID, allowing user filters. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param task ID of the task to retrieve the sibling tasks for.
	 */
	getTaskPreviousNext(this: void, project: string, task: number, queryParams: GetTaskPreviousNextQueryParams): QueryOperation<PreviousNextSiblings>

	/**
	 * Get task summary
	 *
	 * Retrieves the counts of tasks in each possible task state. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskSummary(this: void, project: string, queryParams: GetTaskSummaryQueryParams): QueryOperation<Record<string, number>>

	/**
	 * Get task tags
	 *
	 * Allows to retrieve all used task tags. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskTags(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get finding to task mapping
	 *
	 * Returns the IDs of all tasks containing the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskToFindingMapping(this: void, project: string, body: GetTaskToFindingMappingBody): QueryOperation<Array<GetTaskToFindingMappingDefaultResponseInner>>

	/**
	 * Get tasks with count
	 *
	 * Returns all available tasks with count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTasksWithCount(this: void, project: string, queryParams: GetTasksWithCountQueryParams): QueryOperation<TasksWithCount>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage(this: void): QueryOperation<string>

	/**
	 * Get a valid teamscale-upload invocation.
	 *
	 * Allows the user to get a valid teamscale-upload invocation that the Teamscale Upload wizard UI created. The API requires the user to have View Project permissions on the project.
     * @param project The project ID to which the external coverage report should be uploaded
	 */
	getTeamscaleUploadInvocation(this: void, project: string, queryParams: GetTeamscaleUploadInvocationQueryParams): QueryOperation<string>

	/**
	 * Input for the Teamscale Upload wizard.
	 *
	 * Returns the input needed to render the Teamscale Upload wizard in the UI. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getTeamscaleUploadWizardInput(this: void, project: string): QueryOperation<TeamscaleUploadWizardInput>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyDependencies(this: void, project: string, queryParams: GetTechnologyDependenciesQueryParams): QueryOperation<Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyTreeMap(this: void, project: string, queryParams: GetTechnologyTreeMapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoverage(this: void, project: string, uniformPath: string, queryParams: GetTestCoverageQueryParams): QueryOperation<LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestCoverageOverlay(this: void, project: string, queryParams: GetTestCoverageOverlayQueryParams): QueryOperation<TestCoverageOverlayData>

	/**
	 * Test Coverage Partitions
	 *
	 * Returns the line coverage partitions for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoveragePartitions(this: void, project: string, uniformPath: string, queryParams: GetTestCoveragePartitionsQueryParams): QueryOperation<Array<TestCoveragePartitionInfo>>

	/**
	 * Get the execution unit
	 *
	 * Returns the path of the execution unit the given test belongs to. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testExecution The path of the test for which to return the execution unit.
     * @param partition 
	 */
	getTestExecutionExecutionUnit(this: void, project: string, testExecution: string, partition: string, queryParams: GetTestExecutionExecutionUnitQueryParams): QueryOperation<string | null>

	/**
	 * Get test implementation path
	 *
	 * Returns a test executions corresponding implementation path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testExecution The path of the test for which to return the test implementation path.
	 */
	getTestExecutionImplementation(this: void, project: string, testExecution: string, queryParams: GetTestExecutionImplementationQueryParams): QueryOperation<string | null>

	/**
	 * Get test execution partitions
	 *
	 * Returns all partitions in which the test execution path exists. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testExecution The path of the test for which to return the partitions.
	 */
	getTestExecutionPartitions(this: void, project: string, testExecution: string, queryParams: GetTestExecutionPartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapDataAsCsv(this: void, project: string, queryParams: GetTestGapDataAsCsvQueryParams): QueryOperation<File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapTreeMap(this: void, project: string, queryParams: GetTestGapTreeMapQueryParams): QueryOperation<TestGapTreeMapWrapper>

	/**
	 * Get test history
	 *
	 * Retrieves the history of executions of a specific test. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testExecution Uniform path to retrieve test history for
	 */
	getTestHistory(this: void, project: string, testExecution: string, queryParams: GetTestHistoryQueryParams): QueryOperation<TestHistoryWrapper>

	/**
	 * Get test implementation
	 *
	 * Returns a test implementation. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testImplementation The path of the test implementation to return.
	 */
	getTestImplementation(this: void, project: string, testImplementation: string, queryParams: GetTestImplementationQueryParams): QueryOperation<TestImplementation>

	/**
	 * Get test executions
	 *
	 * Returns the test executions for a given test implementation path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param testImplementation The path of the test implementation for which to return the test executions.
	 */
	getTestImplementationTestExecutions(this: void, project: string, testImplementation: string, queryParams: GetTestImplementationTestExecutionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getTestMinimizationJobStatus(this: void, project: string, jobId: string): QueryOperation<TestMinimizationJobRun>

	/**
	 * Get test information for a file.
	 *
	 * Returns a list of test locations and their execution results for a file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath The path to a file for which we collect test information from.
	 */
	getTestPathsAndExecResults(this: void, project: string, uniformPath: string, queryParams: GetTestPathsAndExecResultsQueryParams): QueryOperation<Array<TestPathExecutionWrapper> | null>

	/**
	 * Get tests executing a method
	 *
	 * Returns the tests executing a given method. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
     * @param startOffset The character-based offset in file on which the method starts (at the specified time).
     * @param endOffset The character-based offset in file on which the method ends (at the specified time).
	 */
	getTestsExecutingMethod(this: void, project: string, uniformPath: string, startOffset: number, endOffset: number, queryParams: GetTestsExecutingMethodQueryParams): QueryOperation<PartitionedTestSet | null>

	/**
	 * Get testwise coverage partitions
	 *
	 * Returns a list of partitions that have testwise coverage in the given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestwiseCoveragePartitions(this: void, project: string, queryParams: GetTestwiseCoveragePartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaPercentage(this: void, project: string, queryParams: GetTgaPercentageQueryParams): QueryOperation<number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTable(this: void, project: string, queryParams: GetTgaTableQueryParams): QueryOperation<Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTestCoveragePartitions(this: void, project: string, queryParams: GetTgaTestCoveragePartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTrend(this: void, project: string, queryParams: GetTgaTrendQueryParams): QueryOperation<Array<MetricTrendEntry>>

	/**
	 * Get threshold configuration documentation
	 *
	 * Retrieves a text describing the specified threshold configuration The API requires the user to have View permissions on the Metric Threshold Configuration thresholdConfigurationName.
     * @param thresholdConfigurationName 
	 */
	getThresholdConfigurationDocumentation(this: void, thresholdConfigurationName: string): QueryOperation<void>

	/**
	 * Provides threshold path for a configuration.
	 *
	 * Provides the time course for a given metric threshold configuration and metric. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getThresholdPath(this: void, project: string, queryParams: GetThresholdPathQueryParams): QueryOperation<EvaluatedMetricThresholdPath>

	/**
	 * Get details for a token element
	 *
	 * Retrieves the details for a token element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTokenElementInfoDetails(this: void, project: string, queryParams: GetTokenElementInfoDetailsQueryParams): QueryOperation<Array<TokenElementDetailBase> | null>

	/**
	 * Get custom fields definitions
	 *
	 * Returns the list of all available custom fields from the given connector User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getTrackerCustomFields(this: void, body: ConnectorConfiguration): QueryOperation<ExternalToolIssueCustomFieldResult>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTriggerGraphDot(this: void, project: string, queryParams: GetTriggerGraphDotQueryParams): QueryOperation<Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails(this: void): QueryOperation<void>

	/**
	 * Get a lookup mapping from types to files.
	 *
	 * Returns the lookup mapping from types to files. In the context of architecture assessments the lookup can be used to map from unmapped types of type based architectures to their respective code file location. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTypeToFileLookup(this: void, project: string, queryParams: GetTypeToFileLookupQueryParams): QueryOperation<Record<string, string>>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId(this: void): QueryOperation<string>

	/**
	 * Get upload commit details
	 *
	 * Returns the details for a given upload commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param commit 
	 */
	getUploadCommitDetails(this: void, project: string, commit: UnresolvedCommitDescriptor): QueryOperation<ExternalAnalysisStatusInfo>

	/**
	 * Get code usage data as csv
	 *
	 * Returns code usage information in a CSV file with the format 'Uniform Path;Method Name;Method Region;Execution Count'. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getUsageDataAsCsv(this: void, project: string, queryParams: GetUsageDataAsCsvQueryParams): QueryOperation<void>

	/**
	 * Preview of usage data
	 *
	 * Returns a preview of the data we collect if you opt-in to provide that data. The API requires the user to have Access Administrative Services permissions.
	 */
	getUsageDataPreview(this: void, body: UsageDataReportingOption): QueryOperation<UsageDataPreview>

	/**
	 * Get user
	 *
	 * Returns information about one user. This service is public API since Teamscale 5.2. The API requires the user to have View permissions on the user.
     * @param user 
	 */
	getUser(this: void, user: string): QueryOperation<UserWithActivity>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions(this: void, input: string, queryParams: GetUserAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
     * @param user 
	 */
	getUserAvatar(this: void, user: string, queryParams: GetUserAvatarQueryParams): QueryOperation<void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
     * @param userGroupName Name of the requested user group.
	 */
	getUserGroup(this: void, userGroupName: string): QueryOperation<UserGroup>

	/**
	 * Get schema
	 *
	 * Retrieves the user option schema. The API requires no permissions
	 */
	getUserOptionSchema(this: void): QueryOperation<Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available user options. The API requires the user to have Edit permissions on the user.
     * @param user Name of the user to retrieve options for
	 */
	getUserOptions(this: void, user: string): QueryOperation<Record<string, object>>

	/**
	 * Collect commits done by specific users
	 *
	 * Retrieves a map from committing users to their commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getUserToCommitMap(this: void, project: string, queryParams: GetUserToCommitMapQueryParams): QueryOperation<CommitData>

	/**
	 * Get the spec items that are verifying the given one (the given spec item is verified by...)
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch
	 */
	getVerifiedBySpecItems(this: void, project: string, id: string, queryParams: GetVerifiedBySpecItemsQueryParams): QueryOperation<Array<SpecItemReferenceElement>>

	/**
	 * Returns all accessible projects along with their metric schema
	 *
	 * null The permissions will be checked at runtime (this service works cross-project)
	 */
	getWidgetContext(this: void): QueryOperation<WidgetContext>

	/**
	 * Get worker status
	 *
	 * Retrieves the worker status info. The API requires the user to have View System Status permissions.
	 */
	getWorkerStatus(this: void): QueryOperation<Array<WorkerGroupStatus>>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam(this: void): QueryOperation<SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement(this: void, body: string): QueryOperation<void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile(this: void, body: ImportAbapLintConfigurationFileBody): QueryOperation<Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile(this: void, body: ImportAnalysisProfileBody): QueryOperation<void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. Every logged in user is allowed to create dashboards. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards(this: void, body: ImportAndReplaceDashboardsBody): QueryOperation<Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup(this: void, body: ImportBackupBody): QueryOperation<string>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. Every logged in user is allowed to create dashboards
	 */
	importDashboard(this: void, body: ImportDashboardBody): QueryOperation<Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate(this: void, body: ImportDashboardTemplateBody): QueryOperation<Array<string>>

	/**
	 * Import external findings
	 *
	 * Uploads external findings groups and/or descriptions put from a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	importExternalFindings(this: void, body: ImportExternalFindingsBody): QueryOperation<void>

	/**
	 * Imports external metrics
	 *
	 * Imports external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	importExternalMetrics(this: void, body: ImportExternalMetricsBody): QueryOperation<void>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
     * @param groupName The name of the group that should be imported.
	 */
	importGroup(this: void, groupName: string, queryParams: ImportGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Import metric threshold configuration
	 *
	 * Import metric threshold configuration. The service will only allow to import metric threshold configurations for projects that are visible to current user.
	 */
	importMetricThresholdsConfiguration(this: void, body: ImportMetricThresholdsConfigurationBody): QueryOperation<void>

	/**
	 * Import project(s)
	 *
	 * Imports a project(s) into the instance. The given file can either be a project configuration (.tsproject) or a project backup (.zip). The API requires the user to have Create Projects permissions.
	 */
	importProjectConfiguration(this: void, body: ImportProjectConfigurationBody): QueryOperation<void>

	/**
	 * Upload Sonar profile
	 *
	 * Uploads Sonar Quality Profiles which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSonarProfile(this: void, body: ImportSonarProfileBody): QueryOperation<SonarQualityProfileImportSummary>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSwiftLintConfiguration(this: void, body: ImportSwiftLintConfigurationBody): QueryOperation<SwiftLintConfigurationImportSummary>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
     * @param userName The name of the user that should be imported.
	 */
	importUser(this: void, userName: string, queryParams: ImportUserQueryParams): QueryOperation<string>

	/**
	 * Validate issue query
	 *
	 * Decided whether an issue query is valid The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isIssueQueryValid(this: void, project: string, queryParams: IsIssueQueryValidQueryParams): QueryOperation<boolean>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isMergeRequestsEnabled(this: void, project: string): QueryOperation<boolean>

	/**
	 * Availability of review findings
	 *
	 * Returns true if review findings are enabled in the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isReviewFindingsEnabled(this: void, project: string): QueryOperation<boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported(this: void): QueryOperation<boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled(this: void, project: string): QueryOperation<boolean>

	/**
	 * Validate spec item query
	 *
	 * Decided whether an spec item query is valid The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isSpecItemQueryValid(this: void, project: string, queryParams: IsSpecItemQueryValidQueryParams): QueryOperation<boolean>

	/**
	 * Validate test query
	 *
	 * Decides whether an test query is valid. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isTestQueryValid(this: void, project: string, queryParams: IsTestQueryValidQueryParams): QueryOperation<boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
     * @param project The project ID.
	 */
	listAllParseLogEntries(this: void, project: string, queryParams: ListAllParseLogEntriesQueryParams): QueryOperation<Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	listMergeRequests(this: void, project: string, queryParams: ListMergeRequestsQueryParams): QueryOperation<ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports(this: void, queryParams: ListQualityReportsQueryParams): QueryOperation<Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	loadFromNamedTestList(this: void, project: string, jobId: string, queryParams: LoadFromNamedTestListQueryParams): QueryOperation<number>

	/**
	 * Logout
	 *
	 * Performs a logout of the currently logged in user by invalidating the current session. The API requires no login
	 */
	logout(this: void): QueryOperation<void>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
     * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite(this: void, dashboardId: string, queryParams: MarkDashboardAsFavoriteQueryParams): QueryOperation<void>

	/**
	 * Perform issue query
	 *
	 * Retrieves the size and list of issues for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performIssueQuery(this: void, project: string, queryParams: PerformIssueQueryQueryParams): QueryOperation<IssueQueryResultUserResolvedTeamscaleIssue>

	/**
	 * Perform issue query with TGA-related data
	 *
	 * Retrieves the size and list of issues for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performIssueTgaQuery(this: void, project: string, queryParams: PerformIssueTgaQueryQueryParams): QueryOperation<IssueQueryResultUserResolvedTeamscaleIssue>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin(this: void, body: Credentials): QueryOperation<void>

	/**
	 * Perform spec item query
	 *
	 * Retrieves the size and list of spec items for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performSpecItemQuery(this: void, project: string, queryParams: PerformSpecItemQueryQueryParams): QueryOperation<IssueQueryResultReferenceResolvedSpecItem>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performTestQuery(this: void, project: string, queryParams: PerformTestQueryQueryParams): QueryOperation<TestQueryResult>

	/**
	 * Poll pre-commit 3.0 results
	 *
	 * Returns exactly the same response as the initial finding request. It may, however, omit the list of findings unless the analysis is ready. If the analysis is ready, the list of findings is returned (may be empty) and the token is empty. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param token 
	 */
	pollPreCommitResults(this: void, project: string, token: string): QueryOperation<PreCommit3Result>

	/**
	 * Assess architecture definition
	 *
	 * Returns the architecture assessment of the given architecture definition. The API requires the user to have View Project permissions on the project.
     * @param project ID of the project where architecture should be defined.
	 */
	postArchitectureAssessment(this: void, project: string, queryParams: PostArchitectureAssessmentQueryParams, body: ArchitectureInfo): QueryOperation<ArchitectureAssessmentInfo>

	/**
	 * Apply basic role change for instance
	 *
	 * Applies the instance's role changes sent to the sever. Requires the basic permission to \"Assign Roles\"
     * @param instance The instance&#39;s id
	 */
	postBasicRoleForInstance(this: void, instance: string, queryParams: PostBasicRoleForInstanceQueryParams, body: RoleChange): QueryOperation<void>

	/**
	 * Apply basic role change globally
	 *
	 * Applies the role changes sent to the sever. The API requires the user to have Assign Global Roles permissions.
	 */
	postBasicRoleGlobally(this: void, queryParams: PostBasicRoleGloballyQueryParams, body: RoleChange): QueryOperation<void>

	/**
	 * Create global role
	 *
	 * Creates a new global role. The API requires the user to have Edit Roles permissions.
	 */
	postGlobalRole(this: void, body: GlobalRole): QueryOperation<void>

	/**
	 * Apply role change for global role assignments
	 *
	 * Updates the role assignments with the given changes. The API requires the user to have Assign Global Roles permissions.
	 */
	postGlobalRoleWithAssignments(this: void, body: RoleChange): QueryOperation<void>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	postJobTests(this: void, project: string, jobId: string, body: Array<string>): QueryOperation<number>

	/**
	 * Create project role
	 *
	 * Creates a new project role. The API requires the user to have Edit Roles permissions.
	 */
	postProjectRole(this: void, body: ProjectRole): QueryOperation<void>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases(this: void, body: PostResolveUserAliasesBody): QueryOperation<Array<string>>

	/**
	 * Apply role change for the project's role assignments
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} for the given project. Requires the permission to \"Assign Roles\" for the project
     * @param instance The project ID to load roles for.
	 */
	postRoleChangeForInstance(this: void, instance: string, body: Record<string, RoleChange>): QueryOperation<void>

	/**
	 * Apply role change for project role assignments globally
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} globally. The API requires the user to have Assign Global Roles permissions.
	 */
	postRoleChangeGlobally(this: void, body: RoleChange): QueryOperation<void>

	/**
	 * Retrieves branches from VCSs
	 *
	 * Returns set of all branches for a given VCS connector The API requires no permissions
	 */
	previewRepositoryBranches(this: void, queryParams: PreviewRepositoryBranchesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Retrieves commit messages from VCSs
	 *
	 * Returns latest commit messages for a given branch for a given VCS connector The API requires no permissions
	 */
	previewRepositoryCommitMessages(this: void, queryParams: PreviewRepositoryCommitMessagesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Retrieves files from VCSs
	 *
	 * Returns list of all files for a given VCS connector existing on the default branch at HEAD The API requires no permissions
	 */
	previewRepositoryFiles(this: void, queryParams: PreviewRepositoryFilesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Handles the incoming requests from code collaboration platforms signaling an update in the status of build jobs.
	 *
	 * Handles the incoming requests from code collaboration platforms signaling the start or completion of a build job.The service identifies the merge request corresponding to the provided revision and triggers the merge request update process. The API requires no login
     * @param project The project ID.
	 */
	processBuildWebhookEvent(this: void, project: string, queryParams: ProcessBuildWebhookEventQueryParams): QueryOperation<void>

	/**
	 * Handles incoming requests from Gitea.
	 *
	 * Handles the incoming requests from Gitea and processes the request body depending on the event. The events which are handled by the service are 'push' and 'pull_request'. The API requires no login
	 */
	processWebhook(this: void, body: string): QueryOperation<void>

	/**
	 * Update global role
	 *
	 * Updates/renames an existing global role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to update/rename.
	 */
	putGlobalRole(this: void, name: string, body: GlobalRole): QueryOperation<void>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromImpacted(this: void, project: string, jobId: string, queryParams: PutJobTestsFromImpactedQueryParams): QueryOperation<number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromQuery(this: void, project: string, jobId: string, queryParams: PutJobTestsFromQueryQueryParams): QueryOperation<number>

	/**
	 * Update project role
	 *
	 * Updates/renames an existing project role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to update/rename.
	 */
	putProjectRole(this: void, name: string, body: ProjectRole): QueryOperation<void>

	/**
	 * Update user
	 *
	 * Creates a new user or overwrites an existing one. This service is public API since Teamscale 5.2. The user needs either CREATE_USER or EDIT user permissions to create or edit the user and EDIT permissions on the groups the user is added to or removed from.
     * @param user 
	 */
	putUser(this: void, user: string, body: UserData): QueryOperation<void>

	/**
	 * Receive an \"analysis completed\" notification from the clang-tidy execution server
	 *
	 * Receiver for the \"analysis completed\" notification from execution server to Teamscale server. After the notification, this Teamscale server will query the analysis results from the execution server.  The API requires the user to have Access Administrative Services permissions.
	 */
	receiveAnalysisCompletedConfirmation(this: void, queryParams: ReceiveAnalysisCompletedConfirmationQueryParams): QueryOperation<void>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires no login
	 */
	receiveAnalysisCompletedConfirmation1(this: void, queryParams: ReceiveAnalysisCompletedConfirmation1QueryParams): QueryOperation<void>

	/**
	 * Profiler heartbeat
	 *
	 * Updates the profiler infos and sets the profiler to still alive. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId The ID of the profiler to update.
	 */
	receiveHeartbeat(this: void, profilerId: string, body: ProfilerInfo): QueryOperation<void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost(this: void, queryParams: RedirectToCorrectHostQueryParams): QueryOperation<void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate(this: void, queryParams: RegenerateStoredServiceProviderCertificateQueryParams): QueryOperation<boolean>

	/**
	 * Registers a profiler instance
	 *
	 * Registers a profiler to Teamscale and returns the profiler configuration it should be started with. This service is public API since Teamscale 9.4. The API requires the user to have View permissions on the Profiler Configuration .
	 */
	registerProfiler(this: void, queryParams: RegisterProfilerQueryParams, body: ProcessInformation): QueryOperation<ProfilerRegistration>

	/**
	 * Register project visit
	 *
	 * Notifies the server that the user visited a project. This is used to display information about the most recently visited projects in the project selector. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	registerProjectViewedByUser(this: void, project: string): QueryOperation<void>

	/**
	 * Mark branch as visited
	 *
	 * Adds the given branch to the list of recently viewed branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param branchName 
	 */
	registerVisitedBranch(this: void, project: string, branchName: string): QueryOperation<void>

	/**
	 * Revoke Access Keys
	 *
	 * Revokes the given access key of the user. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	removeAccessKeys(this: void, user: string, queryParams: RemoveAccessKeysQueryParams): QueryOperation<void>

	/**
	 * Removes a manual assessment
	 *
	 * Removes a manual assessment from the list of assessments that belong to a project. The API requires the user to have View Project permissions on the project.
     * @param project The id of the project
	 */
	removePortfolioManualAssessment(this: void, project: string, body: QualityGoalManualAssessment): QueryOperation<void>

	/**
	 * Delete report slides
	 *
	 * Deletes the given slides from an existing report. The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The id of the report that is edited.
	 */
	removeQualityReportSlides(this: void, reportId: string, body: Array<string>): QueryOperation<void>

	/**
	 * Report JavaScript error
	 *
	 * Writes the given JavaScript error that occurred in the Web UI into the Teamscale's JavaScript error log. The API requires no permissions
	 */
	reportJavascriptError(this: void, body: JavaScriptError): QueryOperation<void>

	/**
	 * Request a ClangTidy analysis
	 *
	 * Opens and schedules a new ClangTidy analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession(this: void, queryParams: RequestAnalysisAndOpenSessionQueryParams, body: ClangTidyOutsourcedAnalysisRequestParameters): QueryOperation<string>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession1(this: void, queryParams: RequestAnalysisAndOpenSession1QueryParams, body: SonarLintIndependentAnalysisRequestParameters): QueryOperation<string>

	/**
	 * Request pre-commit 3.0 analysis
	 *
	 * Uploads new change data and analyzes them on a user-specific pre-commit branch. The new content is uploaded as a map from path to new/changed content. To mark deleted files (also after a rename), put null values into the map. The service returns immediately with a list of already calculated findings and an optional pre-commit token that can be used to poll for an updated list of findings given the new file contents. The initial finding response contains already adjusted finding locations with regards to the passed content, this means no location adjustment is needed on the client side. If all file contents match the content on the server, no pre-commit polling token is returned. If pre-commit limits are exceeded, an optional error message is returned, but the current findings are returned as well. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	requestPreCommitAnalysis(this: void, project: string, queryParams: RequestPreCommitAnalysisQueryParams, body: Record<string, string>): QueryOperation<PreCommit3Result>

	/**
	 * Returns the a resolved commit Descriptor for the given commit descriptor string. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	resolveCommit(this: void, project: string, unresolvedCommit: UnresolvedCommitDescriptor): QueryOperation<CommitDescriptor>

	/**
	 * Resolve code review finding
	 *
	 * Mark the provided code review finding as resolved. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param findingId ID of the resolved finding
	 */
	resolveReviewFinding(this: void, project: string, findingId: string, queryParams: ResolveReviewFindingQueryParams): QueryOperation<void>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	resolveTag(this: void, project: string, body: GitTag): QueryOperation<CommitDescriptor | null>

	/**
	 * Re-vote on all open merge requests
	 *
	 * Re-votes on all open merge requests in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	revoteOnAllOpenMergeRequests(this: void, project: string): QueryOperation<void>

	/**
	 * Re-vote on a specific open merge request
	 *
	 * Re-votes on a specific open merge request in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	revoteOnMergeRequest(this: void, project: string, idWithRepository: string): QueryOperation<void>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction(this: void): QueryOperation<void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	saveAsNamedTestList(this: void, project: string, jobId: string, body: string): QueryOperation<number>

	/**
	 * Save issue query
	 *
	 * Creates an issue query descriptor in the system. This service is public API since Teamscale 7.4. The API requires the user to have Edit Issue Queries permissions on the project.
     * @param project The project ID.
	 */
	saveIssueQueryDescriptor(this: void, project: string, body: StoredQueryDescriptor): QueryOperation<void>

	/**
	 * Save spec item query
	 *
	 * Creates an spec item query descriptor in the system. The API requires the user to have Edit Specification Item Queries permissions on the project.
     * @param project The project ID.
	 */
	saveSpecItemQueryDescriptor(this: void, project: string, body: StoredQueryDescriptor): QueryOperation<void>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
	 */
	saveTestListDescriptor(this: void, project: string, body: TestListDescriptor): QueryOperation<void>

	/**
	 * Save test query
	 *
	 * Creates a test query descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
	 */
	saveTestQueryDescriptor(this: void, project: string, body: StoredQueryDescriptor): QueryOperation<void>

	/**
	 * Schedule external clone detection
	 *
	 * Schedules the trigger for external clone detection The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
     * @param project The project ID.
	 */
	scheduleCloneDetectionTrigger(this: void, project: string, body: ExternalXClonesParameter): QueryOperation<void>

	/**
	 * Send test e-mail
	 *
	 * Allows to test sending of e-mails. This will attempt to send an e-mail to the e-mail address of the current user. The API requires the user to have Edit Server Options permissions.
	 */
	sendTestEmail(this: void): QueryOperation<void>

	/**
	 * Set metric notification rules
	 *
	 * Edits the currently set metric notification rules for the current user The API requires no permissions
	 */
	setMetricNotificationRules(this: void, body: MetricNotificationRules): QueryOperation<void>

	/**
	 * Set notification rules
	 *
	 * Sets the notification rules for the current user The API requires no permissions
	 */
	setNotificationRules(this: void, body: FindingsNotificationRules): QueryOperation<void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules(this: void, body: ProjectNotificationRules): QueryOperation<void>

	/**
	 * Set project scheduling
	 *
	 * Pauses or resumes the scheduling for a specific project. The API requires the user to have Edit Project permissions on the project.
     * @param project 
	 */
	setProjectSchedulePausing(this: void, project: string, body: string): QueryOperation<void>

	/**
	 * Set review status
	 *
	 * Allows to retrieve the review status for a file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath The uniform path of the file for which the review status should be changed
	 */
	setReviewStatus(this: void, project: string, uniformPath: string, queryParams: SetReviewStatusQueryParams, body: ReviewUploadInfo): QueryOperation<void>

	/**
	 * Set scheduler filtering status
	 *
	 * Sets the filtering status of the scheduler The API requires the user to have Access Administrative Services permissions.
	 */
	setSchedulingMode(this: void, body: string): QueryOperation<void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	setupJob(this: void, project: string, queryParams: SetupJobQueryParams): QueryOperation<string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning(this: void): QueryOperation<boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning(this: void): QueryOperation<boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning(this: void): QueryOperation<boolean>

	/**
	 * Returns if the legacy default for 'Disable automatic webhook creation' is enabled, if the user has admin permissions The API requires no permissions
	 */
	showLegacyWebHookWarning(this: void): QueryOperation<boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning(this: void): QueryOperation<boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown(this: void, body: ShutdownBody): QueryOperation<void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	startJob(this: void, project: string, jobId: string): QueryOperation<void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	stopAndDeleteMinimizationJob(this: void, project: string, jobId: string): QueryOperation<void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	storeEventAnnouncement(this: void, eventId: string, body: EventAnnouncement): QueryOperation<void>

	/**
	 * Synchronize groups
	 *
	 * Synchronizes all groups with a remote server. Will also automatically update all users in the groups. In case groups are deleted on the server, the process will remove all users (and additional remote server information) from them, but will not delete them, in order to preserve access rights. Returns a list containing the names of the synchronized groups. The API requires the user to have Create Users, Create Groups permissions.
	 */
	synchronizeAllGroups(this: void, queryParams: SynchronizeAllGroupsQueryParams): QueryOperation<Array<string>>

	/**
	 * Synchronize users
	 *
	 * Synchronizes all users with a remote server. Returns the names of updated users. The API requires the user to have Create Users permissions.
	 */
	synchronizeAllUsers(this: void, queryParams: SynchronizeAllUsersQueryParams): QueryOperation<Array<string>>

	/**
	 * Synchronize group
	 *
	 * Synchronizes the given group with a remote server. Will also automatically update all users in the group. In case the group is deleted on the server, the process will remove all users (and additional remote server information) from it, but will not delete it, in order to preserve access rights. The API requires the user to have Create Users, Create Groups permissions.
     * @param groupName The name of the group that should be synchronized.
	 */
	synchronizeSingleGroup(this: void, groupName: string, queryParams: SynchronizeSingleGroupQueryParams): QueryOperation<void>

	/**
	 * Trigger multi-project re-analysis.
	 *
	 * Triggers re-analysis of all projects specified in the request body (a list of project ids). The API requires the user to have Edit Project permissions on all provided projects.
	 */
	triggerMultiReanalysis(this: void, queryParams: TriggerMultiReanalysisQueryParams, body: Array<string>): QueryOperation<TriggerMultiReanalysisResult>

	/**
	 * Trigger project reanalysis
	 *
	 * Triggers reanalysis of the project specified in the path parameter. This service is public API since Teamscale 5.9. The API requires the user to have Edit Project permissions on the project.
     * @param project 
	 */
	triggerReanalysis(this: void, project: string, queryParams: TriggerReanalysisQueryParams): QueryOperation<void>

	/**
	 * Trigger S3 commit hook
	 *
	 * Allows to trigger updates of S3 repos via a commit hook. Returns a map from (potentially) affected project connectors to their associated keys. Only projects that are visible to the user and for which the user has the \"Trigger Commit Hook\" permission will be affected by the commit hook
	 */
	triggerS3CommitHook(this: void, body: Array<string>): QueryOperation<Record<string, Array<string>>>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup(this: void, body: TriggerSnapshotBackupBody): QueryOperation<void>

	/**
	 * Try get user
	 *
	 * Returns information about one user. The API requires the user to have View permissions on the user.
     * @param user 
	 */
	tryGetUser(this: void, user: string): QueryOperation<UserWithActivity | null>

	/**
	 * Resolves the element as a link
	 *
	 * Tries to resolve the content of the token element as a link to another element. This currently only works for certain non-code metrics that have a json content attached with file and line properties. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	tryResolveNonCodeLink(this: void, project: string, queryParams: TryResolveNonCodeLinkQueryParams): QueryOperation<ResourceLink | null>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Mark Red Findings, Mark Yellow Findings permissions on the project.
     * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit(this: void, project: string, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams): QueryOperation<Array<string>>

	/**
	 * Unregister profiler
	 *
	 * Removes the profiler identified by given ID. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId The ID of the profiler to unregister.
	 */
	unregisterProfiler(this: void, profilerId: string): QueryOperation<void>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile(this: void, queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile): QueryOperation<void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Template templateId.
     * @param templateId ID of the template to update
	 */
	updateDashboardTemplate(this: void, templateId: string, body: DashboardTemplateDescriptor): QueryOperation<void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Account externalCredentialsName.
     * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts(this: void, externalCredentialsName: string, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup(this: void, groupName: string, body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription(this: void, externalFindingDescriptionTypeId: string, body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Update project metric schema
	 *
	 * Updates the project metric schema with the entries in the analysis profile. User must either have the permission to create analysis profiles, or the permission to view any existing analysis profile.
     * @param project The project ID.
	 */
	updateExternalMetricSchema(this: void, project: string, queryParams: UpdateExternalMetricSchemaQueryParams): QueryOperation<void>

	/**
	 * Update metrics
	 *
	 * Updates a set of external metrics The API requires the user to have Edit External Metrics Schema permissions.
	 */
	updateExternalMetrics(this: void, queryParams: UpdateExternalMetricsQueryParams, body: Array<MetricSchemaChangeEntry>): QueryOperation<void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backend externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend(this: void, externalStorageBackendName: string, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	updateJobOptions(this: void, project: string, jobId: string, queryParams: UpdateJobOptionsQueryParams): QueryOperation<void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration(this: void, body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Update password
	 *
	 * Updates the user password The API requires the user to have Edit permissions on the User username.
     * @param username The ID of the user the user needs access to.
	 */
	updatePassword(this: void, username: string, body: PasswordChangeRequest): QueryOperation<void>

	/**
	 * Updates the selected manual assessment
	 *
	 * Updates the currently selected manual assessment for a given project in the portfolio overview. The API requires the user to have View Project permissions on the project.
     * @param project The id of the project
	 */
	updatePortfolioSelectedManualAssessment(this: void, project: string, body: QualityGoalManualAssessment): QueryOperation<void>

	/**
	 * Update profiler configuration
	 *
	 * Updates an existing profiler configuration. The API requires the user to have Edit permissions on the Profiler Configuration configurationId.
     * @param configurationId The ID of the dashboard to update.
	 */
	updateProfilerConfiguration(this: void, configurationId: string, body: ProfilerConfiguration): QueryOperation<void>

	/**
	 * Updates quality report
	 *
	 * Updates a quality report's metadata such as title and global settings like default project and threshold profile. The API requires the user to have Edit permissions on the Quality Report id.
     * @param id The ID of the report that gets an update.
	 */
	updateQualityReport(this: void, id: string, body: QualityArtifactParameters): QueryOperation<void>

	/**
	 * Update report slide
	 *
	 * Updates an existing slide in a quality report. The API requires the user to have Edit permissions on the Quality Report id.
     * @param id The ID of the report that is updated.
	 */
	updateQualityReportSlides(this: void, id: string, body: Array<ReportSlideBase>): QueryOperation<void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
     * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospective(this: void, id: string, body: QualityArtifactParameters): QueryOperation<void>

	/**
	 * Update retrospective notes
	 *
	 * Updates an existing note for a project in a retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
     * @param id The ID of the retrospective that gets an update.
     * @param projectId 
	 */
	updateRetrospectiveNote(this: void, id: string, projectId: string, body: string): QueryOperation<void>

	/**
	 * Update task
	 *
	 * Updates the task with the given ID. The API requires the user to have Edit Tasks permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTask(this: void, project: string, id: number, queryParams: UpdateTaskQueryParams, body: Task): QueryOperation<Task>

	/**
	 * Update task resolution
	 *
	 * Updates the resolution of the task with the given ID. The API requires the user to have Update Task Resolution permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTaskResolution(this: void, project: string, id: number, body: string): QueryOperation<Task>

	/**
	 * Update task status
	 *
	 * Updates the status of the task with the given ID. The API requires the user to have Update Task Status permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTaskStatus(this: void, project: string, id: number, body: string): QueryOperation<Task>

	/**
	 * Update user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
     * @param user 
	 */
	updateUserAvatar(this: void, user: string, body: UpdateUserAvatarBody): QueryOperation<void>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
     * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup(this: void, oldUserGroupName: string, body: UserGroup): QueryOperation<void>

	/**
	 * Upload architecture
	 *
	 * Imports an architecture file. This service is public API since Teamscale 5.9. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
	 */
	uploadArchitecture(this: void, project: string, queryParams: UploadArchitectureQueryParams, body: Record<string, File>): QueryOperation<void>

	/**
	 * Upload architecture
	 *
	 * Saves an architecture definition. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
	 */
	uploadArchitectureAsJson(this: void, project: string, queryParams: UploadArchitectureAsJsonQueryParams, body: ArchitectureInfoWithUniformPath): QueryOperation<void>

	/**
	 * Upload snapshot
	 *
	 * Uploads an external snapshot for comparison The API requires the user to have Access Administrative Services permissions.
	 */
	uploadExternalSnapshot(this: void, body: UploadExternalSnapshotBody): QueryOperation<void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport(this: void, project: string, sessionId: string, queryParams: UploadReportQueryParams, body: UploadReportBody): QueryOperation<void>

	/**
	 * Stores the passed image and returns a unique key to reference it in future scenarios
	 *
	 * null The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The ID of the quality report the user needs access to.
	 */
	uploadSlideImage(this: void, reportId: string, body: UploadSlideImageBody): QueryOperation<string>

	/**
	 * Validate analysis profile
	 *
	 * Validates the analysis profile provided in the request body. Requires the edit permission on the respective analysis profile.
	 */
	validateAnalysisProfile(this: void, body: AnalysisProfile): QueryOperation<AnalysisProfileValidationResult | null>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration(this: void, body: ProjectBranchingConfiguration): QueryOperation<string | null>

	/**
	 * Validates a connector configuration
	 *
	 * Validates a given connector configuration User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateConnector(this: void, queryParams: ValidateConnectorQueryParams, body: ConnectorConfiguration): QueryOperation<string | null>

	/**
	 * Validate RegEx
	 *
	 * Validates the given regex. The API requires no permissions
	 */
	validateRegex(this: void, queryParams: ValidateRegexQueryParams): QueryOperation<string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup(this: void): QueryOperation<string>

	/**
	 * Whether the legacy API was used since the last instance restart.
	 *
	 * The legacy API will be disabled in Teamscale v10.0 and completely removed in a future version. This endpoint provides information whether the legacy API was used since the last instance restart. The API requires no permissions
	 */
	wasLegacyApiUsed(this: void): QueryOperation<boolean>

}

/** Type definition of the MUTATIONS interface. */
export type Mutations = {

	/**
	 * Add comment to task
	 *
	 * Adds a comment to the task specified by ID. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param task ID of the task to add comment to
	 */
	addCommentToTask: MutationOperation<{ pathParams: { project: string, task: number }, body: string }, void>

	/**
	 * Add metrics
	 *
	 * Adds a set of external metrics to the schema. This service is public API since Teamscale 5.8. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	addExternalMetrics: MutationOperation<{ queryParams: AddExternalMetricsQueryParams, body: Array<MetricSchemaChangeEntry> }, void>

	/**
	 * Adds new manual assessment
	 *
	 * Adds a new manual assessment to the list of manual assessments for a given project in the portfolio overview. The API requires the user to have View Project permissions on the project.
	 * @param project The id of the project
	 */
	addPortfolioManualAssessment: MutationOperation<{ pathParams: { project: string }, body: QualityGoalManualAssessment }, void>

	/**
	 * Add report slide
	 *
	 * Adds slide(s) to an existing quality report. The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The ID of the report that is added.
	 */
	addQualityReportSlides: MutationOperation<{ pathParams: { reportId: string }, body: Record<string, ReportSlideBase> }, void>

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	autocompleteIssueId: MutationOperation<{ pathParams: { project: string }, queryParams: AutocompleteIssueIdQueryParams }, Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup: MutationOperation<{ queryParams: AutocompleteUserGroupQueryParams }, Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName: MutationOperation<{ queryParams: AutocompleteUserNameQueryParams }, Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Cancel the provided trigger
	 *
	 * Cancels the provided trigger. There are two steps of canceling a trigger. The first is via a flag, the trigger has to look up and may cancel gracefully. The second is via interrupt, where the trigger may be canceled at any operation, which may lead to inconsistent data. The API requires the user to have Access Administrative Services permissions.
	 * @param workerId 
	 */
	cancelTrigger: MutationOperation<{ pathParams: { workerId: string }, body: CancelTriggerRequestBody }, void>

	/**
	 * Changes review finding message
	 *
	 * Change the message of a review finding. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param findingId ID of the resolved finding
	 */
	changeReviewFindingMessage: MutationOperation<{ pathParams: { project: string, findingId: string }, queryParams: ChangeReviewFindingMessageQueryParams }, void>

	/**
	 * Clear history ID
	 *
	 * Clears the history ID lock flag for the given SAP configuration. The lock flag is set in table /CQSE/ABAPEXP in the SAP system when a Teamscale export is active as only one instance of Teamscale should access the same history. In rare cases, e.g. the export job did not finish properly, the lock flag remains set. This prevents further fetching of ABAP changes. In this case, this service can be called to reset the flag. The API requires the user to have Access Administrative Services permissions.
	 * @param sapConfigurationId Identifier of the SAP connection configuration in the Admin perspective.
	 */
	clearHistoryIdLock: MutationOperation<{ pathParams: { sapConfigurationId: string } }, void>

	/**
	 * Removes pre-commit 3.0 branches
	 *
	 * Removes data associated with the pre-commit branches created for requests with the given parameters. If the query parameters are omitted, all pre-commit branches for the current user are deleted. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	clearPreCommitBranch: MutationOperation<{ pathParams: { project: string }, queryParams: ClearPreCommitBranchQueryParams }, void>

	/**
	 * Clear analysis results cached on this Execution Server for a given project
	 *
	 * The execution server caches analysis results and serves them if the same analysis request comes again. This DELETE request clears that cache of analysis results. The API requires the user to have Access Administrative Services permissions.
	 */
	clearResultsCache: MutationOperation<void, void>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	computeMetricTable: MutationOperation<{ pathParams: { project: string }, queryParams: ComputeMetricTableQueryParams }, MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, void>

	/**
	 * Create analysis profile for languages
	 *
	 * Creates a new analysis profile only based on the given languages, checks will fallback to default for each language. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfileForLanguages: MutationOperation<{ queryParams: CreateAnalysisProfileForLanguagesQueryParams }, void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup: MutationOperation<{ body: CreateBackupBody }, string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot.
	 */
	createComparison: MutationOperation<{ pathParams: { snapshotId: string }, body: CreateComparisonBody }, string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboard: MutationOperation<{ body: DashboardDescriptor }, string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboardFromTemplate: MutationOperation<{ queryParams: CreateDashboardFromTemplateQueryParams }, string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate: MutationOperation<{ body: DashboardTemplateDescriptor }, string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts: MutationOperation<{ body: ExternalCredentialsData }, void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup: MutationOperation<{ body: ExternalAnalysisGroup }, void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription: MutationOperation<{ body: ExternalFindingsDescription }, void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend: MutationOperation<{ body: ExternalStorageBackendData }, void>

	/**
	 * Creates a markdown link which renders the metric badge.
	 *
	 * Creates a markdown link which renders the metric badge. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param idWithRepository 
	 */
	createMetricBadgeMarkdown: MutationOperation<{ pathParams: { project: string, idWithRepository: string }, body: MetricBadgeEvaluationParameters }, string>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	createNewRandomAccessKey: MutationOperation<{ pathParams: { user: string } }, string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	createOrUpdateBaseline: MutationOperation<{ pathParams: { project: string, baseline: string }, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo }, void>

	/**
	 * Create profiler configuration
	 *
	 * Creates a new profiler configuration. The API requires the user to have Create Profiler Configurations permissions.
	 */
	createProfilerConfiguration: MutationOperation<{ body: ProfilerConfiguration }, void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 9.9. The API requires the user to have Create Projects permissions.
	 */
	createProject: MutationOperation<{ queryParams: CreateProjectQueryParams, body: ProjectConfiguration }, void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport: MutationOperation<{ queryParams: CreateQualityReportQueryParams, body: QualityArtifactParameters }, string>

	/**
	 * Create quality retrospective
	 *
	 * Creates a new quality retrospective either from scratch or by copying an existing retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the template retrospective if given and on the projects of the to be created retrospective.
	 */
	createRetrospective: MutationOperation<{ queryParams: CreateRetrospectiveQueryParams, body: QualityArtifactParameters }, string>

	/**
	 * Create review finding
	 *
	 * Creates a code review finding. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	createReviewFinding: MutationOperation<{ pathParams: { project: string }, queryParams: CreateReviewFindingQueryParams, body: Array<ReviewComment> }, void>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	createSession: MutationOperation<{ pathParams: { project: string }, queryParams: CreateSessionQueryParams }, string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot: MutationOperation<{ body: CreateSnapshotBody }, string>

	/**
	 * Create support request
	 *
	 * Creates the support request. The API requires the user to have Create Support Request permissions.
	 */
	createSupportRequest: MutationOperation<{ body: SupportRequestData }, void>

	/**
	 * Create task
	 *
	 * Creates a new task. The API requires the user to have Edit Tasks permissions on the project.
	 * @param project The project ID.
	 */
	createTask: MutationOperation<{ pathParams: { project: string }, body: Task }, Task>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup: MutationOperation<{ body: UserGroup }, void>

	/**
	 * Delete all architecture commits
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The uniform path of the architecture to be deleted
	 */
	deleteAllArchitectureCommits: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: DeleteAllArchitectureCommitsQueryParams }, void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams }, void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Delete architecture
	 *
	 * Deletes the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The uniform path of the deleted architecture
	 */
	deleteArchitecture: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: DeleteArchitectureQueryParams }, void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	deleteBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Template templateId.
	 * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	deleteEventAnnouncement: MutationOperation<{ pathParams: { eventId: string } }, void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Account externalCredentialsName.
	 * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: DeleteExternalAccountsQueryParams }, void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string } }, void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionName: string } }, void>

	/**
	 * Delete metric
	 *
	 * Deletes an external metric. The API requires the user to have Edit External Metrics Schema permissions.
	 * @param metricName Metric to delete
	 */
	deleteExternalMetric: MutationOperation<{ pathParams: { metricName: string }, queryParams: DeleteExternalMetricQueryParams }, void>

	/**
	 * Delete external snapshots
	 *
	 * Deletes the external snapshot with the provided name The API requires the user to have Access Administrative Services permissions.
	 * @param name The name of the external snapshot to be deleted
	 */
	deleteExternalSnapshot: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backend externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, queryParams: DeleteExternalStorageBackendQueryParams }, void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalCriticalEventLogs: MutationOperation<{ queryParams: DeleteGlobalCriticalEventLogsQueryParams }, void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalInteractionLogs: MutationOperation<{ queryParams: DeleteGlobalInteractionLogsQueryParams }, void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: DeleteGlobalJavascriptErrorLogsQueryParams }, void>

	/**
	 * Delete global role
	 *
	 * Removes an existing global role. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to delete.
	 */
	deleteGlobalRole: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalServiceLogs: MutationOperation<{ queryParams: DeleteGlobalServiceLogsQueryParams }, void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalWorkerLogs: MutationOperation<{ queryParams: DeleteGlobalWorkerLogsQueryParams }, void>

	/**
	 * Delete issue query
	 *
	 * Deletes an issue query descriptor from the system. The API requires the user to have Edit Issue Queries permissions on the project.
	 * @param project The project ID.
	 * @param issueQueryName Name of the issue metric to delete
	 */
	deleteIssueQueryDescriptor: MutationOperation<{ pathParams: { project: string, issueQueryName: string } }, void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	deleteJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DeleteJobTestsQueryParams, body: Array<number> }, number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
	 * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string } }, void>

	/**
	 * Remove profiler configuration
	 *
	 * Removes the profiler configuration identified by given ID. The API requires the user to have Delete permissions on the Profiler Configuration configurationId.
	 * @param configurationId ID of the dashboard to retrieve.
	 */
	deleteProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string } }, void>

	/**
	 * Delete project
	 *
	 * Deletes a project. This service is public API since Teamscale 5.2. The API requires the user to have Delete Project permissions on the project.
	 * @param project 
	 */
	deleteProject: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectQueryParams }, void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectCriticalEventLogsQueryParams }, void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectInteractionLogsQueryParams }, void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectJavascriptErrorLogsQueryParams }, void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to delete
	 */
	deleteProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, void>

	/**
	 * Delete project role
	 *
	 * Removes an existing project role. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to delete.
	 */
	deleteProjectRole: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectServiceLogsQueryParams }, void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectWorkerLogsQueryParams }, void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
	 * @param id The report id
	 */
	deleteQualityReport: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete quality retrospective
	 *
	 * Deletes an existing quality retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
	 * @param id The ID of the retrospective that should be deleted.
	 */
	deleteRetrospective: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to delete
	 */
	deleteServerOption: MutationOperation<{ pathParams: { optionId: string } }, void>

	/**
	 * Delete single upload
	 *
	 * Allows to delete a single external analysis upload. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param commit The commit of the external upload
	 */
	deleteSingleUpload: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor } }, void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete spec item query
	 *
	 * Deletes a spec item query descriptor from the system. The API requires the user to have Edit Specification Item Queries permissions on the project.
	 * @param project The project ID.
	 * @param specItemQueryName Name of the spec item query to delete
	 */
	deleteSpecItemQueryDescriptor: MutationOperation<{ pathParams: { project: string, specItemQueryName: string } }, void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor: MutationOperation<{ pathParams: { project: string, testListName: string } }, void>

	/**
	 * Delete test query
	 *
	 * Deletes a test query descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 * @param testQueryName Name of the test query to delete
	 */
	deleteTestQueryDescriptor: MutationOperation<{ pathParams: { project: string, testQueryName: string } }, void>

	/**
	 * Delete upload partition
	 *
	 * Allows to delete all external analysis uploads of a partition. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param partition The partition that should be deleted
	 */
	deleteUploadPartition: MutationOperation<{ pathParams: { project: string, partition: string } }, void>

	/**
	 * Delete users
	 *
	 * Deletes the given users. This service is public API since Teamscale 5.2. The API requires the user to have Delete permissions on the users.
	 * @param users The user names to delete. May be either a single user name or a comma separated list of names.
	 */
	deleteUser: MutationOperation<{ pathParams: { users: string } }, string>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
	 * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user 
	 * @param optionId 
	 */
	deleteUserOption: MutationOperation<{ pathParams: { user: string, optionId: string } }, void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	deleteUsers: MutationOperation<{ body: UserBatchOperation }, string>

	/**
	 * Download all archived external reports as ZIP archive
	 *
	 * Returns a ZIP download of all archived external reports. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param commit 
	 */
	downloadAllExternalReportsAsZip: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor } }, void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Download architecture
	 *
	 * Download the architecture definition as XML. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	downloadArchitectureAsXml: MutationOperation<{ pathParams: { project: string }, body: DownloadArchitectureAsXmlBody }, File>

	/**
	 * Get audit data
	 *
	 * Download audit result data for export. Result data is represented with a CSV file. User must have audit support included in the license.
	 * @param project The project ID.
	 * @param uniformPath The uniform path
	 */
	downloadAuditData: MutationOperation<{ pathParams: { project: string, uniformPath: string } }, void>

	/**
	 * Download backup
	 *
	 * Allows downloading a Teamscale backup from the temporary file store. This service is public API since Teamscale 6.1. The user needs to have the permission to backup global data provided it is contained in the backup and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	downloadBackup: MutationOperation<{ pathParams: { backupId: string } }, File>

	/**
	 * Download the benchmark results
	 *
	 * Downloads the benchmark results for a project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	downloadBenchmark: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadBenchmarkQueryParams }, void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalCriticalEventLogs: MutationOperation<{ queryParams: DownloadGlobalCriticalEventLogsQueryParams }, void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalInteractionLogs: MutationOperation<{ queryParams: DownloadGlobalInteractionLogsQueryParams }, void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: DownloadGlobalJavascriptErrorLogsQueryParams }, void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalServiceLogs: MutationOperation<{ queryParams: DownloadGlobalServiceLogsQueryParams }, void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalWorkerLogs: MutationOperation<{ queryParams: DownloadGlobalWorkerLogsQueryParams }, void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump: MutationOperation<{ body: DownloadHeapDumpBody }, void>

	/**
	 * Download metric history
	 *
	 * Downloads metric history as CSV The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	downloadMetricHistory: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadMetricHistoryQueryParams }, void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectCriticalEventLogsQueryParams }, void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectInteractionLogsQueryParams }, void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectJavascriptErrorLogsQueryParams }, void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectServiceLogsQueryParams }, void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectWorkerLogsQueryParams }, void>

	/**
	 * Download report
	 *
	 * Returns a file download of the requested archived report. The report index information is part of the external analysis status details. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param commit 
	 * @param reportIndex 
	 */
	downloadReport: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor, reportIndex: number } }, void>

	/**
	 * Search code, issues, commits
	 *
	 * Provides the possibility to search the indexed code base. The service will only search through projects visible to current user.
	 */
	downloadSearchResultCsv: MutationOperation<{ queryParams: DownloadSearchResultCsvQueryParams }, File>

	/**
	 * Download support request
	 *
	 * Downloads the support request previously created with POST. The API requires the user to have Create Support Request permissions.
	 */
	downloadSupportRequest: MutationOperation<void, File>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	downloadTestSelectionCsv: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DownloadTestSelectionCsvQueryParams }, File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard: MutationOperation<{ pathParams: { dashboardId: string }, body: DashboardDescriptor }, void>

	/**
	 * Update project
	 *
	 * Updates an existing project. This service is public API since Teamscale 7.1. The API requires the user to have Edit Project permissions on the project.
	 * @param project 
	 */
	editProject: MutationOperation<{ pathParams: { project: string }, body: ProjectInfo }, ProjectUpdateResult>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to edit
	 */
	editProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string }, body: object }, void>

	/**
	 * Edit project
	 *
	 * Edits project based on provided project configuration. This service is public API since Teamscale 9.9. The API requires the user to have Edit Project permissions on the project.
	 * @param project The project ID.
	 */
	editProjectWithConfiguration: MutationOperation<{ pathParams: { project: string }, queryParams: EditProjectWithConfigurationQueryParams, body: ProjectConfiguration }, boolean>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to edit
	 */
	editServerOption: MutationOperation<{ pathParams: { optionId: string }, queryParams: EditServerOptionQueryParams, body: object }, void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user Name of the user to edit the option for
	 * @param optionId Name of the option to edit
	 */
	editUserOption: MutationOperation<{ pathParams: { user: string, optionId: string }, body: object }, void>

	/**
	 * Enable or disable voting option
	 *
	 * Enable or disable the given kind of voting for all connectors of the project which support it. The API requires the user to have Edit Project permissions on the project.
	 * @param project 
	 * @param votingOption 
	 */
	enableVotingOption: MutationOperation<{ pathParams: { project: string, votingOption: EVotingConnectorOptionEntry }, queryParams: EnableVotingOptionQueryParams }, void>

	/**
	 * Execute postponed rollback
	 *
	 * Executes the postponed rollback of given id right now. The API requires the user to have Edit Project permissions on the project.
	 * @param project The project ID.
	 * @param id 
	 */
	executePostponedRollback: MutationOperation<{ pathParams: { project: string, id: string } }, void>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand: MutationOperation<{ body: ExecuteSchedulerCommandBody }, void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	exportAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * export code search result
	 *
	 * Downloads code search matches result list as CSV The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportCodeMatchResult: MutationOperation<{ pathParams: { project: string }, queryParams: ExportCodeMatchResultQueryParams }, void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard
	 */
	exportDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
	 * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, TeamscaleVersionContainer>

	/**
	 * Export external findings
	 *
	 * Downloads external findings groups and descriptions as a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	exportExternalFindings: MutationOperation<{ queryParams: ExportExternalFindingsQueryParams }, File>

	/**
	 * Downloads external metrics
	 *
	 * Downloads external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	exportExternalMetrics: MutationOperation<void, void>

	/**
	 * Export impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of tests is a subset of all tests known to Teamscale in CSV format. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportImpactedTestsAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: ExportImpactedTestsAsCsvQueryParams }, void>

	/**
	 * Export metric assessment
	 *
	 * Exports the metric assessment based on a threshold configuration as CSV The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportMetricAssessmentAsCSV: MutationOperation<{ pathParams: { project: string }, queryParams: ExportMetricAssessmentAsCSVQueryParams }, void>

	/**
	 * Export metric threshold configuration
	 *
	 * Export metric threshold configuration. The API requires the user to have View permissions on the Metric Threshold Configuration target.
	 * @param target Metric threshold configuration name.
	 */
	exportMetricThresholdsConfiguration: MutationOperation<{ pathParams: { target: string } }, void>

	/**
	 * Export project configuration
	 *
	 * Downloads the project configuration which is only the configuration and does not include the project data, analysis profiles or external accounts. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportProjectConfiguration: MutationOperation<{ pathParams: { project: string } }, void>

	/**
	 * Export a Teamscale IDE Configuration File
	 *
	 * Exports a Teamscale IDE Configuration File for the given project and repository, to be used by the IDE plugins. This service is public API since Teamscale 9.9. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportTeamscaleToml: MutationOperation<{ pathParams: { project: string }, queryParams: ExportTeamscaleTomlQueryParams }, string>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
	 * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist: MutationOperation<{ pathParams: { externalCredentialsName: string } }, ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
	 * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists: MutationOperation<{ pathParams: { externalStorageBackendName: string } }, ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue: MutationOperation<void, Array<JobDescriptor>>

	/**
	 * Gets GitLab repositories for an account
	 *
	 * Get all GitLab repositories that the current user has maintainer access to. The API requires the user to have View permissions on the External Account credentialsName.
	 * @param credentialsName Name of the external accounts
	 */
	fetchGitlabProjects: MutationOperation<{ pathParams: { credentialsName: string } }, Array<GitLabProject>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit: MutationOperation<{ pathParams: { project: string, unresolvedCommit: UnresolvedCommitDescriptor }, queryParams: FindInitialCommitQueryParams }, CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	findLogEntriesInRange: MutationOperation<{ pathParams: { project: string }, queryParams: FindLogEntriesInRangeQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	flagFindings: MutationOperation<{ pathParams: { project: string }, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody }, void>

	/**
	 * Get Absolute Date
	 *
	 * Transforms a relative date string into a fixed date string The API requires no permissions
	 */
	getAbsoluteDate: MutationOperation<{ queryParams: GetAbsoluteDateQueryParams }, string>

	/**
	 * List Access Keys Metadata
	 *
	 * List the metadata of all access keys of the user The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	getAccessKeysMetadata: MutationOperation<{ pathParams: { user: string } }, Array<AccessKeyDTO>>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the given last number of days that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects: MutationOperation<{ queryParams: GetActiveUsersForProjectsQueryParams, body: Array<string> }, Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getAdditionallyCoveredMethods: MutationOperation<{ pathParams: { project: string, jobId: string } }, Array<Array<MethodLocation>>>

	/**
	 * Get affected files for the given commits as CSV
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAffectedFilesCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetAffectedFilesCsvQueryParams }, void>

	/**
	 * Get affected files by commits
	 *
	 * Returns a wrapper object with repository log file entries for commits, i.e. the association between files and repository log entries. The returned typeCounts mapping will always count the entries across all types (not just the passed one) The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAffectedFilesForCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetAffectedFilesForCommitsQueryParams }, AffectedFiles>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics: MutationOperation<void, void>

	/**
	 * Get an AI generated finding resolution suggestion
	 *
	 * Retrieves the AI generated resolution for a finding. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param engine The name of the configured AI engine to use.
	 * @param id The id of the finding to find a resolution for.
	 */
	getAiFindingResolution: MutationOperation<{ pathParams: { project: string, engine: string, id: string }, queryParams: GetAiFindingResolutionQueryParams }, FindingResolutionResult>

	/**
	 * Get an AI generated summary for an element
	 *
	 * Retrieves the AI summary for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param engine The interal name of the configured AI engine to use.
	 */
	getAiSummary: MutationOperation<{ pathParams: { project: string, engine: string }, queryParams: GetAiSummaryQueryParams }, string>

	/**
	 * Get all existing A3 Options.
	 *
	 * Returns all A3 option identifiers. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getAllA3OptionIdentifiers: MutationOperation<void, Array<string>>

	/**
	 * Get all architecture assessments
	 *
	 * Returns available architecture assessments. All architectures in analyzed and in pending commits are returned including tags on the state of their analysis (added, modified, deleted). Returns an empty list if no assessments available. This service is public API since Teamscale 5.7. The API requires the user to have View Project permissions on the project.
	 * @param project ID of the project where architectures are defined.
	 */
	getAllArchitectureAssessments: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllArchitectureAssessmentsQueryParams }, Array<ArchitectureOverviewInfo>>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the project. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllBaselines: MutationOperation<{ pathParams: { project: string } }, Array<BaselineInfo>>

	/**
	 * Get all test coverage partitions
	 *
	 * Returns the all available test coverage partitions of the project across all branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllCrossBranchPartitions: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates: MutationOperation<void, Array<UserResolvedDashboardTemplateDescriptor>>

	/**
	 * Get all dashboards
	 *
	 * Retrieves all the dashboards available in the system No permissions needed, as the service will only return the dashboards visible to current user.
	 */
	getAllDashboards: MutationOperation<{ queryParams: GetAllDashboardsQueryParams }, Array<DashboardEntryWithPermissions>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts: MutationOperation<void, Array<ExternalCredentialsData>>

	/**
	 * Get external clone classes
	 *
	 * Retrieves the found external clone classes The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
	 * @param project The project ID.
	 */
	getAllExternalCrossClones: MutationOperation<{ pathParams: { project: string } }, Array<ExternalXCloneClass>>

	/**
	 * Get external snapshots
	 *
	 * Returns the list of all available externally uploaded snapshots The API requires the user to have Access Administrative Services permissions.
	 */
	getAllExternalSnapshots: MutationOperation<{ queryParams: GetAllExternalSnapshotsQueryParams }, Array<ExternalInstanceComparisonSnapshotDto>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends: MutationOperation<void, Array<ExternalStorageBackendData>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getAllGitTags: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllGitTagsQueryParams }, Array<GitTag>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns a the available test coverage partitions. Each object contains a project and it's test partitions. Only partitions are returned for which the user has view permissions.
	 */
	getAllGlobalLineBasedPartitions: MutationOperation<void, Array<ProjectPartitionsInfo>>

	/**
	 * Get guidelines
	 *
	 * Gets all guidelines available in the current Teamscale version. No permission needed since every user can explore guidelines with the check explorer.
	 */
	getAllGuidelines: MutationOperation<void, Array<Guideline>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames: MutationOperation<{ queryParams: GetAllMetricThresholdConfigurationNamesQueryParams }, Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations: MutationOperation<void, Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles: MutationOperation<{ queryParams: GetAllMetricsForThresholdProfilesQueryParams }, Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries: MutationOperation<void, Array<ParseLogOverviewEntry>>

	/**
	 * Get postponed rollbacks
	 *
	 * Returns all postponed rollbacks that are currently pending for the project. The API requires the user to have Edit Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllPostponedRollbacks: MutationOperation<{ pathParams: { project: string } }, Array<PostponedRollback>>

	/**
	 * Get all profiler configurations
	 *
	 * Retrieves all the defined profiler configurations No permissions needed, as the service will only return the profiler configurations visible to current user.
	 */
	getAllProfilerConfigurations: MutationOperation<void, Array<ProfilerConfiguration>>

	/**
	 * Get all project file paths
	 *
	 * Retrieves all files paths of a project as a CSV file. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getAllProjectFilePaths: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllProjectFilePathsQueryParams }, void>

	/**
	 * Get all project files
	 *
	 * Retrieves all files of a project as a ZIP file. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getAllProjectFiles: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllProjectFilesQueryParams }, void>

	/**
	 * Get project IDs
	 *
	 * Returns all primary project IDs. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjectIds: MutationOperation<{ queryParams: GetAllProjectIdsQueryParams }, Array<string>>

	/**
	 * Get portfolio labels for all projects
	 *
	 * Returns portfolio labels for all projects. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjectPortfolioLabels: MutationOperation<void, Array<string>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects: MutationOperation<{ queryParams: GetAllProjectsQueryParams }, Array<ProjectInfo>>

	/**
	 * Get all existing SAP connection identifiers.
	 *
	 * Returns all SAP connection identifiers configured in the instance. This service is public API since Teamscale 6.4. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getAllSapSystemConnectionIdentifiers: MutationOperation<void, Array<string>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds: MutationOperation<{ queryParams: GetAllSnapshotIdsQueryParams }, Array<string>>

	/**
	 * Get all role assignments for all groups and users (and assigned groups).
	 *
	 * Returns a list of all subject role assignments. The API requires the user to have Assign Global Roles permissions.
	 */
	getAllSubjectRoleAssignments: MutationOperation<void, Array<Array<SubjectRoleAssignments>>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllTestExecutionPartitionDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllTestExecutionPartitionDetailsQueryParams }, Array<PartitionInfo>>

	/**
	 * Get all test execution partitions
	 *
	 * Returns all available test execution partitions of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllTestExecutionPartitions: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllTestExecutionPartitionsQueryParams }, Array<string>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml: MutationOperation<void, string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames: MutationOperation<void, Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups: MutationOperation<void, Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers: MutationOperation<void, Array<UserWithActivity>>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions of a project known by uploaded PDBs. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllVersions: MutationOperation<{ pathParams: { project: string } }, Array<DotNetVersionInfo>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff: MutationOperation<{ pathParams: { analysisProfileName: string }, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams }, AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis group
	 *
	 * Returns a mapping from code scopes from the provided project to the analysis group from the analysis profile of the code scope identified by the group name. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param groupName Name of the requested analysis group.
	 */
	getAnalysisGroupPerCodeScope: MutationOperation<{ pathParams: { project: string, groupName: string } }, Record<string, AnalysisGroup>>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<number>>

	/**
	 * Get analysis profile metadata
	 *
	 * Retrieves the analysis profile identified by given name and returns its description, languages and tools. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileMetadata: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileMetadata>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames: MutationOperation<void, Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo: MutationOperation<void, Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileWithProjects>

	/**
	 * Get ClangTidy analysis results
	 *
	 * Gets the ClangTidy analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath: MutationOperation<{ queryParams: GetAnalysisResultsByPathQueryParams }, Array<ClangTidyResultsTransport>>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath1: MutationOperation<{ queryParams: GetAnalysisResultsByPath1QueryParams }, Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Returns branch or project analysis state
	 *
	 * Provides the most up-to-date information about completed and currently-analyzed projects and branches. The analysis state is obtained from the branch, if it is given as target. Otherwise the analysis state is based on the project's default branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param branchName 
	 */
	getAnalysisState: MutationOperation<{ pathParams: { project: string, branchName: string } }, AnalysisState>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming: MutationOperation<void, void>

	/**
	 * Get the status of a ClangTidy Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus: MutationOperation<{ queryParams: GetAnalysisStatusQueryParams }, EClangTidyOutsourcedAnalysisStatusEntry>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus1: MutationOperation<{ queryParams: GetAnalysisStatus1QueryParams }, ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get analysis tools
	 *
	 * Retrieves the available tools based on the languages provided. Teamscale itself is not returned as a tool. No specific permissions required to retrieve the available tools
	 */
	getAnalysisTools: MutationOperation<{ queryParams: GetAnalysisToolsQueryParams }, Array<EAnalysisToolEntry>>

	/**
	 * Get analysis tools
	 *
	 * Returns the available analysis tools for a specific project. Teamscale itself is not returned as a tool. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getAnalysisToolsForProject: MutationOperation<{ pathParams: { project: string }, queryParams: GetAnalysisToolsForProjectQueryParams }, Array<EAnalysisToolEntry>>

	/**
	 * Get architecture assessment
	 *
	 * Returns the architecture assessment with the identifier in the path parameter. Returns an empty assessment if an unknown identifier is given. The API requires the user to have View Project permissions on the project.
	 * @param project ID of the project where architecture is defined.
	 * @param uniformPath Uniform path of requested architecture
	 */
	getArchitectureAssessment: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetArchitectureAssessmentQueryParams }, ArchitectureAssessmentInfo>

	/**
	 * Get architecture upload info
	 *
	 * Returns architecture upload info for the specified commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param commit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	getArchitectureCommitUploadInfo: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor } }, ArchitectureCommitUploadInfo | null>

	/**
	 * Get all architecture upload infos
	 *
	 * Returns architecture upload infos for the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param architecture 
	 */
	getArchitectureCommitUploadInfos: MutationOperation<{ pathParams: { project: string, architecture: string } }, Array<CommitWithUserName>>

	/**
	 * Get architecture components
	 *
	 * Returns the list of architecture component assignments for an element in all the available project architectures. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getArchitectureComponentAssignments: MutationOperation<{ pathParams: { project: string }, queryParams: GetArchitectureComponentAssignmentsQueryParams }, Array<ArchitectureComponentAssignment>>

	/**
	 * Profile architecture pattern performance
	 *
	 * Measures the performance of every include/exclude pattern of the provided architecture, by matching it against all available types. Reports the 10 slowest patterns and their runtime. Can be used to detect slow patterns in the architecture analysis.  The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 * @param architecture 
	 */
	getArchitecturePerformanceStatistics: MutationOperation<{ pathParams: { project: string, architecture: string }, queryParams: GetArchitecturePerformanceStatisticsQueryParams }, string>

	/**
	 * Get all architectures with commit count
	 *
	 * Returns architecture uniform paths and the number of commits per architecture for the project. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getArchitectures: MutationOperation<{ pathParams: { project: string } }, Array<ArchitectureWithCommitCount>>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds: MutationOperation<{ pathParams: { id: string } }, Array<string>>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Create Users permissions.
	 */
	getAuthenticatorInformation: MutationOperation<void, AuthenticatorMappingReply>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Edit permissions on the User username.
	 * @param username User to retrieve authentication info for
	 */
	getAuthenticatorInformationForUser: MutationOperation<{ pathParams: { username: string } }, AuthenticatorMappingReply>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers: MutationOperation<void, Record<string, Array<string>>>

	/**
	 * Get the backup export status
	 *
	 * Get the current backup export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupExportStatus: MutationOperation<{ pathParams: { backupId: string } }, BackupExportStatus>

	/**
	 * Get the complete backup export summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupExportSummary: MutationOperation<void, Array<BackupJobSummary>>

	/**
	 * Get the backup import status
	 *
	 * Get the current backup import status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupImportStatus: MutationOperation<{ pathParams: { backupId: string } }, BackupImportStatus>

	/**
	 * Get the complete backup import summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupImportSummary: MutationOperation<void, Array<BackupJobSummary>>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard: MutationOperation<void, Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	getBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, BaselineInfo>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getBaselines: MutationOperation<{ queryParams: GetBaselinesQueryParams }, Record<string, Array<BaselineInfo>>>

	/**
	 * Returns the basic preprocessor information for a file
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getBasicPreprocessorExpansionsInfo: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetBasicPreprocessorExpansionsInfoQueryParams }, PreprocessorExpansionsTransport>

	/**
	 * Get basic roles assigned to an instance
	 *
	 * Returns all basic roles with assignments. Requires the basic permission to \"Assign Roles\"
	 * @param instance The instance&#39;s id
	 */
	getBasicRoles: MutationOperation<{ pathParams: { instance: string }, queryParams: GetBasicRolesQueryParams }, RolesWithAssignmentsEBasicRole>

	/**
	 * Get benchmark projects
	 *
	 * Returns the projects with benchmark analysis profiles User must have audit support included in the license.
	 */
	getBenchmarkProjects: MutationOperation<void, Array<ProjectDescription>>

	/**
	 * Get a (partly anonymized) benchmark results for a specific metric
	 *
	 * Returns a CSV file with all benchmark results. Only projects visible to the user are returned.
	 */
	getBenchmarkResultsAsCsv: MutationOperation<{ queryParams: GetBenchmarkResultsAsCsvQueryParams }, void>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetBranchForSpecItemQueryParams }, string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesGetRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesPostRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesPostRequestQueryParams }, BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getChangeTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetChangeTreemapQueryParams }, TreeMapNode>

	/**
	 * Get children resource types
	 *
	 * Retrieves the types of children of the resource represented by a uniform path. For children that are containers, this will be the type of their deepest relative path with more than one child The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getChildrenResourceTypes: MutationOperation<{ pathParams: { project: string }, queryParams: GetChildrenResourceTypesQueryParams }, Record<string, EResourceTypeEntry>>

	/**
	 * Download source code
	 *
	 * Starts a download of the source code file at the given uniform path. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The uniform path of the source code file to be downloaded.
	 */
	getCode: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetCodeQueryParams }, string>

	/**
	 * Get code changes
	 *
	 * Retrieves information on code changes. Returns for all changes after the given baseline the timestamp of the last change in the corresponding region. Regions are line-based starting at 1 with both bounds being inclusive. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getCodeChanges: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetCodeChangesQueryParams }, Array<LineBasedChangeRegion>>

	/**
	 * Get code city
	 *
	 * Builds a code city for the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeCities: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeCitiesQueryParams }, CodeCityNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeComponentsTreemapWrapperQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get code outline
	 *
	 * Returns a code outline for a file, which summarizes the abstract syntax tree of the code The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getCodeOutline: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetCodeOutlineQueryParams }, Array<OutlineElement>>

	/**
	 * Get ownership treemap
	 *
	 * Builds a code ownership treemap using the given parameters The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeOwnershipTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeOwnershipTreemapQueryParams }, TreeMapNode>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams }, SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams }, CodeSearchResultsWrapper>

	/**
	 * Get code usage treemap
	 *
	 * Returns a usage treemap. For each method under the selected uniform path, the treemap shows how often the method was executed in the specified time frame. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeUsageTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeUsageTreemapQueryParams }, UsageTreeMapWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCommitAlerts: MutationOperation<{ pathParams: { project: string }, queryParams: GetCommitAlertsQueryParams }, Array<CommitAlertWithCommit>>

	/**
	 * Get teamscale commits
	 *
	 * Returns the teamscale commit representations for a given revision. As a revision can occur in multiple repositories, more than one commit may be returned. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param revision 
	 */
	getCommitsForRevision: MutationOperation<{ pathParams: { project: string, revision: string } }, Array<CommitDescriptor>>

	/**
	 * Get task commits
	 *
	 * Returns the timestamps of commits associated with a given task. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the requested task
	 */
	getCommitsForTask: MutationOperation<{ pathParams: { project: string, id: number } }, Array<CommitDescriptor>>

	/**
	 * Retrieves the analysis progress of the given merge request in terms of commits left to be analyzed
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The name of the project.
	 */
	getCommitsLeftToAnalyzeForMergeRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetCommitsLeftToAnalyzeForMergeRequestQueryParams }, number>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, InstanceComparisonComputationMetadata>

	/**
	 * Get specific project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param projectId 
	 * @param contributor 
	 */
	getComparisonResult: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, projectId: string, contributor: string } }, InstanceComparisonResultBase>

	/**
	 * Get comparison result metadata
	 *
	 * Returns the comparison metadata for the specified comparison. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 */
	getComparisonResultMetadata: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, Record<string, Record<string, InstanceComparisonResultMetadata>>>

	/**
	 * Debug dump compilation commands
	 *
	 * Create a debug dump for compilation commands for a given file from a given partition. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param commit The commit of the external upload
	 * @param partition The partition to read from
	 * @param path The path to read for
	 */
	getCompilationCommandsDebugDump: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor, partition: string, path: string } }, string>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param project The name of the project.
	 * @param contributor The contributor of the comparison.
	 * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string }, queryParams: GetCompleteExampleListQueryParams }, Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails: MutationOperation<{ queryParams: GetConcurrencyPerformanceDetailsQueryParams }, string>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis, sorted by their readable name. This is available to every authenticated user.
	 */
	getConfigLanguages: MutationOperation<void, Array<Language>>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis for a specific project, sorted by their readable name. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getConfigLanguagesForProject: MutationOperation<{ pathParams: { project: string } }, Array<Language>>

	/**
	 * Get configuration template
	 *
	 * Retrieves the configuration template for given languages. No specific permissions required to retrieve a configuration template.
	 */
	getConfigurationTemplate: MutationOperation<{ queryParams: GetConfigurationTemplateQueryParams }, ConfigurationTemplate>

	/**
	 * Get connector descriptors
	 *
	 * Retrieves connector descriptors available in the system. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getConnectorDescriptors: MutationOperation<void, Array<ConnectorDescriptorTransport>>

	/**
	 * Returns all connectors for a project
	 *
	 * Returns information about the connector configurations associated with the project. User must either have the global permission to create projects, or the permission to view any existing project.
	 * @param projectId The project id.
	 */
	getConnectors: MutationOperation<{ pathParams: { projectId: string } }, Array<ConnectorConfiguration>>

	/**
	 * Get copyright license treemap
	 *
	 * Builds a treemap of the copyright licenses in a system. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCopyrightLicensesTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCopyrightLicensesTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCoverageSources: MutationOperation<{ pathParams: { project: string }, queryParams: GetCoverageSourcesQueryParams }, Array<CoverageSourceInfo>>

	/**
	 * Get scheduler filtering status
	 *
	 * Returns the current filtering status of the scheduler. The API requires no permissions
	 */
	getCurrentSchedulingFilter: MutationOperation<void, ProjectSchedulingFilter>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, DashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
	 * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor: MutationOperation<{ pathParams: { templateId: string } }, UserResolvedDashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDebugContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDebugContentQueryParams }, string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility: MutationOperation<{ queryParams: GetDeclarationOfAccessibilityQueryParams }, string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext: MutationOperation<void, DeclarationOfAccessibilityContext>

	/**
	 * Returns the default value for 'Disable automatic webhook creation'
	 *
	 * Returns the default value for 'Disable automatic webhook creation' specified by JVM flags. The API requires no permissions
	 */
	getDefaultForDisableWebhookCreation: MutationOperation<void, boolean>

	/**
	 * Get hidden metrics
	 *
	 * Returns the names of the metrics that are hidden by default in the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPathType 
	 */
	getDefaultHiddenMetrics: MutationOperation<{ pathParams: { project: string, uniformPathType: ETypeEntry }, queryParams: GetDefaultHiddenMetricsQueryParams }, Array<string>>

	/**
	 * Get default patterns
	 *
	 * Returns the default include/exclude patterns for connectors as a map from analysis profile name to the corresponding default patterns based on the languages configured in the respective analysis profile The API requires no permissions
	 */
	getDefaultPatterns: MutationOperation<void, Record<string, IncludeExcludePatterns>>

	/**
	 * Get token element churn
	 *
	 * Provides a token element churn (i.e., the list of changed elements) for a given uniform path and time range. This service is public API since Teamscale 5.9.10. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDeltaAffectedFiles: MutationOperation<{ pathParams: { project: string }, queryParams: GetDeltaAffectedFilesQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get dependencies
	 *
	 * Returns a list of dependencies for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDependenciesWithOccurrenceLocation: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDependenciesWithOccurrenceLocationQueryParams }, Array<DependencyWithOccurrenceLocation>>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDependencyGraph: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDependencyGraphQueryParams }, string>

	/**
	 * Get names of deriving metric threshold configurations
	 *
	 * Retrieves names of deriving metric threshold configurations. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
	 * @param metricThresholdConfigurationName 
	 */
	getDerivingMetricThresholdConfigurationNames: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string } }, Array<string>>

	/**
	 * Get diffs for two files
	 *
	 * Compares two elements with each other. The elements are given via a full path including project and uniform path and (optionally) the commit separated by '#@#' Requires read permissions on the projects containing the files to be compared
	 */
	getDiffs: MutationOperation<{ queryParams: GetDiffsQueryParams }, Array<DiffDescription>>

	/**
	 * Get metric values
	 *
	 * Retrieves the metric values for a resource The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDirectMetricDirectoryEntry: MutationOperation<{ pathParams: { project: string }, queryParams: GetDirectMetricDirectoryEntryQueryParams }, MetricDirectoryEntry>

	/**
	 * Get .NET version info
	 *
	 * Returns a the version info for a specific PDB. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param versionName The name of the version to get infos for
	 */
	getDotNetVersionInfo: MutationOperation<{ pathParams: { project: string, versionName: string } }, DotNetVersionInfo>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions known by uploaded PDBs for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getDotNetVersions: MutationOperation<{ queryParams: GetDotNetVersionsQueryParams }, Record<string, Array<DotNetVersionInfo>>>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDumpCommitDescriptor: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitDescriptorQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDumpCommitTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitTreeNodes: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeNodesQueryParams }, Array<CommitTreeNodeData>>

	/**
	 * Returns the configuration of the service provider that is passed to this service.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires the user to have Access Administrative Services permissions.
	 */
	getEditedServiceProviderConfiguration: MutationOperation<{ queryParams: GetEditedServiceProviderConfigurationQueryParams, body: SamlAuthenticationOption }, SamlServiceProviderConfiguration>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements: MutationOperation<void, Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeQueryParams }, Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeSVGQueryParams }, Array<string>>

	/**
	 * Get execution unit
	 *
	 * Returns a execution unit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param executionUnit The execution unit to return.
	 * @param partition The partition of the execution unit
	 */
	getExecutionUnit: MutationOperation<{ pathParams: { project: string, executionUnit: string, partition: string }, queryParams: GetExecutionUnitQueryParams }, ExecutionUnit>

	/**
	 * Get extended resource types
	 *
	 * Returns the extended resource types for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getExtendedResourceTypes: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetExtendedResourceTypesQueryParams }, Array<EExtendedResourceTypeEntry>>

	/**
	 * Get all external analysis upload commits for the specified timeframe and partitions
	 *
	 * Returns status infos for all external analysis upload commits in the given partitions which occurred in the given timeframe. The relevant commits are collected by going back from the given end commit through all of its predecessors until the given baseline timestamp is reached. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExternalAnalysisCommitInfos: MutationOperation<{ pathParams: { project: string }, queryParams: GetExternalAnalysisCommitInfosQueryParams }, Record<string, Array<ExternalAnalysisCommitStatus>>>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExternalAnalysisStatusPartitions: MutationOperation<{ pathParams: { project: string } }, Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get uploads
	 *
	 * Returns the uploads for a given partition. The API requires the user to have Perform External Uploads, View Project permissions on the project.
	 * @param project The project ID.
	 * @param partition 
	 */
	getExternalAnalysisStatusUploads: MutationOperation<{ pathParams: { project: string, partition: string } }, Array<ExternalAnalysisCommitStatus>>

	/**
	 * Get external file content
	 *
	 * Retrieves the file content of a file from disk with local pre-processing. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
	 * @param path The path to retrieve files for
	 */
	getExternalFileWithPreprocessing: MutationOperation<{ pathParams: { path: string } }, FormattedTokenElementInfo>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getExternalFindingsDescriptions: MutationOperation<void, Array<ExternalFindingsDescription>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getExternalFindingsGroups: MutationOperation<void, Array<ExternalAnalysisGroup>>

	/**
	 * Get ABAP external link
	 *
	 * Allows obtaining an external link for a file, e.g. for opening it in an IDE. This only works for ABAP files imported from an SAP system. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param uniformPath 
	 */
	getExternalLink: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetExternalLinkQueryParams }, string | null>

	/**
	 * Get file summary
	 *
	 * Retrieves the file summary showing the different file types in a given folder on the local file system along with the corresponding number of lines of code. User must have audit support included in the license.
	 */
	getFileSummary: MutationOperation<{ queryParams: GetFileSummaryQueryParams }, PairListStringFileSummaryInfoRecord>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalContentQueryParams }, Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalNames: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalNamesQueryParams }, Array<FileGroup> | null>

	/**
	 * Get finding
	 *
	 * Retrieves a finding by its ID. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id 
	 */
	getFinding: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetFindingQueryParams }, TrackedFinding>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingChurnCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingChurnCountQueryParams }, Array<FindingChurnCount>>

	/**
	 * Get finding churn list
	 *
	 * Provides the findings churn (i.e. the list of added and deleted findings) for a given commit. This service is public API since Teamscale 5.6.4. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingChurnList: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingChurnListQueryParams }, FindingChurnList>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingDeltaQueryParams }, ExtendedFindingDelta>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingDeltaCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingDeltaCountQueryParams }, FindingDeltaCount>

	/**
	 * Get sibling findings
	 *
	 * Returns all sibling findings of the given finding. Only searches for siblings in the same findings partition. The finding with the given finding id is not included in the list. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id 
	 */
	getFindingSiblings: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetFindingSiblingsQueryParams }, Array<TrackedFinding>>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingTypeDescriptions: MutationOperation<{ pathParams: { project: string }, body: Array<string> }, Array<FindingTypeDescription>>

	/**
	 * Get finding with diff information
	 *
	 * Retrieves a finding by its ID with additional information on when and where the finding was introduced or removed. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id 
	 */
	getFindingWithDiffInfo: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetFindingWithDiffInfoQueryParams }, TrackedFindingWithDiffInfo>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsQueryParams }, Array<ExtendedTrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param format The export format used.
	 */
	getFindingsExport: MutationOperation<{ pathParams: { project: string, format: EFindingsExportFormatEntry }, queryParams: GetFindingsExportQueryParams }, void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding 
	 */
	getFindingsSiblings: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFindingsSiblingsQueryParams }, PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsSummaryQueryParams }, FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsTreemapQueryParams }, FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsWithCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsWithCountQueryParams }, PaginatedFindingsWithCount>

	/**
	 * Get findings with provided ids
	 *
	 * Gets a list of all findings with provided ids. This service is public API since Teamscale 6.0. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsWithIds: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsWithIdsQueryParams, body: Array<string> }, Array<TrackedFinding | null>>

	/**
	 * Get the first branch name to be displayed in the UI.
	 *
	 * Get the first branch name to be displayed in the UI. This is either the preselected UI branch or the default branch. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getFirstUIBranchNameForProject: MutationOperation<{ pathParams: { project: string } }, string>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingIds: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingIdsQueryParams }, Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFlaggedFindingInfoQueryParams }, UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsQueryParams }, Array<FindingBlacklistInfo>>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingsDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsDeltaQueryParams }, Array<ExtendedTrackedFinding>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingsInfos: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string> }, Array<FindingBlacklistInfo>>

	/**
	 * Get formatted content for an element
	 *
	 * Retrieves the formatted content for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFormattedTokenElementInfo: MutationOperation<{ pathParams: { project: string }, queryParams: GetFormattedTokenElementInfoQueryParams }, FormattedTokenElementInfo | null>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getGitConnectorIds: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories: MutationOperation<{ queryParams: GetGitHubAppInstallationRepositoriesQueryParams }, Array<string>>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls: MutationOperation<void, Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param tagName 
	 */
	getGitTagByName: MutationOperation<{ pathParams: { project: string, tagName: string }, queryParams: GetGitTagByNameQueryParams }, GitTag | null>

	/**
	 * Get global analysis progress
	 *
	 * Retrieves the global analysis progress (for all projects). User must either have the permission to view system status or the permission to access administrative services
	 */
	getGlobalAnalysisProgress: MutationOperation<void, Record<string, Array<AnalysisProgressDescriptor>>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get all project's directories
	 *
	 * Returns global container info by merging container info from all projects. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	getGlobalContainerInfo: MutationOperation<{ queryParams: GetGlobalContainerInfoQueryParams }, ContainerInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalCriticalEventLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogs: MutationOperation<{ queryParams: GetGlobalCriticalEventLogsQueryParams }, ShortLogResponseShortLogEntryBase>

	/**
	 * Get multi project first UI branch.
	 *
	 * Gets the branch to be displayed first in the UI for one of the specified projects. The API requires no permissions
	 */
	getGlobalFirstUIBranchGetRequest: MutationOperation<{ queryParams: GetGlobalFirstUIBranchGetRequestQueryParams }, string | null>

	/**
	 * Get quality indicator and analysis group names
	 *
	 * Retrieves the available quality indicator and analysis group names from all configured projects. Every authenticated user should see the available quality indicator and analysis group names.
	 */
	getGlobalIndicatorsAndGroups: MutationOperation<void, IndicatorsAndGroups>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalInteractionLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogs: MutationOperation<{ queryParams: GetGlobalInteractionLogsQueryParams }, ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalJavascriptErrorLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: GetGlobalJavascriptErrorLogsQueryParams }, ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getGlobalMetricDirectorySchemas: MutationOperation<{ queryParams: GetGlobalMetricDirectorySchemasQueryParams }, Array<MetricDirectorySchema>>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalRecentBranchesGetRequestQueryParams }, Array<string>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalServiceLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogs: MutationOperation<{ queryParams: GetGlobalServiceLogsQueryParams }, ShortLogResponseShortServiceLog>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalWorkerLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogs: MutationOperation<{ queryParams: GetGlobalWorkerLogsQueryParams }, ShortLogResponseShortWorkerLog>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetGroupAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Checks if unlinked changes exist
	 *
	 * Checks if methods have unlinked changes since a given baseline. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getHasUnlinkedChangesExist: MutationOperation<{ pathParams: { project: string }, queryParams: GetHasUnlinkedChangesExistQueryParams }, boolean>

	/**
	 * Get system health status
	 *
	 * Retrieves a log of system health status information. This service is public API since Teamscale 5.5. No login required to perform monitoring.
	 */
	getHealthStatus: MutationOperation<{ queryParams: GetHealthStatusQueryParams }, string>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage: MutationOperation<void, string>

	/**
	 * Get related changes to a test
	 *
	 * Returns the commit history of commits that affected code tested by the test. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testUniformPath 
	 */
	getHistoryForChangedMethods: MutationOperation<{ pathParams: { project: string, testUniformPath: string }, queryParams: GetHistoryForChangedMethodsQueryParams }, MethodHistoryEntriesWrapper>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned flat list of tests is a subset of all tests known to Teamscale. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. Please use the PUT endpoint instead to provide a list of available tests. In particular, this endpoint may return tests that no longer exist. Callers must handle this case. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImpactedTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetImpactedTestsQueryParams }, Array<PrioritizableTest>>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of test clusters is a subset of the tests given in the request body. Clusters of selected and prioritized tests are formed based on the clustering information given in the request body. The tests from the request body are furthermore used to determine if any tests have changed or new tests were added. These are always included in the returned list of impacted tests. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImpactedTestsFromAvailableTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetImpactedTestsFromAvailableTestsQueryParams, body: Array<TestWithClusterId> }, Array<PrioritizableTestCluster>>

	/**
	 * Get the names of the important branches
	 *
	 * Get the names of the important branches The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImportantBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get included source file paths
	 *
	 * Gets paths to all source files that are included in a project file which is included in a solution file. Requires the Project Connector to be configured so that only solution (.sln), project (.*proj) and source files (e.g. .cs, .cpp, .hpp, ...) are included. Also, solution (.sln) and project (.*proj) files need to be mapped to the language LINE. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIncludedDotNetSourcePaths: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get permissions for an instance
	 *
	 * Returns a set of basic permissions for a single instance identified by the instance ID. The API requires no permissions
	 * @param instanceId ID of the instance to get the permissions for
	 */
	getInstancePermissions: MutationOperation<{ pathParams: { instanceId: string }, queryParams: GetInstancePermissionsQueryParams }, Array<EBasicPermissionEntry>>

	/**
	 * Get issue
	 *
	 * Retrieves an issue by ID. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssue: MutationOperation<{ pathParams: { project: string, issueId: string } }, UserResolvedTeamscaleIssue>

	/**
	 * Get files affected by issue
	 *
	 * Retrieves the files affected by the commits to a given issue. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve affected files for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueAffectedFiles: MutationOperation<{ pathParams: { project: string, issueId: string } }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get issue commits
	 *
	 * Returns the list of commits associated with the issue specified after the slash. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve the commits for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssueCommits: MutationOperation<{ pathParams: { project: string, issueId: string } }, Array<CommitDescriptor>>

	/**
	 * Get issue count
	 *
	 * Retrieves the overall issue count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueCount: MutationOperation<{ pathParams: { project: string } }, number>

	/**
	 * Get issue finding churn
	 *
	 * Determines an aggregated finding churn across all commits of the issue. This service is public API since Teamscale 5.9. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to determine the finding churn for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;. For compatibility reasons, using only the issue ID (e.g. \&quot;TS-123\&quot;) is also supported but discouraged.
	 */
	getIssueFindingChurn: MutationOperation<{ pathParams: { project: string, issueId: string }, queryParams: GetIssueFindingChurnQueryParams }, FindingChurnList>

	/**
	 * Get issue hierarchy
	 *
	 * Retrieves the respective issue hierarchy for the given issue. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve the hierarchy for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueHierarchy: MutationOperation<{ pathParams: { project: string, issueId: string } }, IssueHierarchy>

	/**
	 * Get impacted tests by issue
	 *
	 * Retrieves the impacted tests for a given issue. This are all tests that are expected to execute the methods that have been changed in the context of the given ticket. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve impacted tests for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueImpactedTests: MutationOperation<{ pathParams: { project: string, issueId: string }, queryParams: GetIssueImpactedTestsQueryParams }, Array<PrioritizableTest>>

	/**
	 * Get issue queries
	 *
	 * Retrieves the list of available issue query descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueQueries: MutationOperation<{ pathParams: { project: string } }, Array<StoredQueryDescriptor>>

	/**
	 * Get Issue Query TGA Summary
	 *
	 * Retrieves the TGA summary for a set of issues matched by the given query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueQueryTgaSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssueQueryTgaSummaryQueryParams }, TgaSummary>

	/**
	 * Perform issue trend query
	 *
	 * Retrieves the issue trend for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueQueryTrend: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssueQueryTrendQueryParams }, QueryTrendResult>

	/**
	 * Get issue treemap
	 *
	 * Builds an issue treemap for the given parameters. If the color metric is set to -1, it calculates how many issues reference the given nodes, while any other valid color metric value calculates the corresponding metric for the nodes. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssueTreemapQueryParams }, RelatedIssuesMetricTreemapWrapper>

	/**
	 * Get issue trend
	 *
	 * Downloads issue trend data. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssueTrendAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssueTrendAsCsvQueryParams }, File>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesByRepositoryCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssuesByRepositoryCommitsQueryParams }, Array<Array<TeamscaleIssueStatus> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssuesDetailsQueryParams }, Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Export issues
	 *
	 * Export issues for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param format The export format used.
	 */
	getIssuesExport: MutationOperation<{ pathParams: { project: string, format: EIssuesExportFormatEntry }, queryParams: GetIssuesExportQueryParams }, void>

	/**
	 * Get contained package names
	 *
	 * All packages that should be profiled (subpackages will be profiled as well). The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getJavaProfilerJvmPackageNames: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize: MutationOperation<void, JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getJobs: MutationOperation<{ pathParams: { project: string } }, Array<TestMinimizationJobRun>>

	/**
	 * Get available column names
	 *
	 * Returns the names of all columns that are available for issue entities The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getKnownIssueQueryColumns: MutationOperation<{ pathParams: { project: string } }, Record<string, EKeyedObjectTypeEntry>>

	/**
	 * Get available column names
	 *
	 * Returns the names of all columns that are available for specification item entities The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getKnownSpecItemColumns: MutationOperation<{ pathParams: { project: string }, queryParams: GetKnownSpecItemColumnsQueryParams }, Record<string, EKeyedObjectTypeEntry>>

	/**
	 * Get full Kubernetes manifest
	 *
	 * Returns the content of the full Kubernetes manifest which is built from the given Helm chart or Kustomization. Returns null in case no full manifest is available for the given uniform path, e.g. a non-root Kustomization. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getKubernetesManifest: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetKubernetesManifestQueryParams }, FormattedTokenElementInfo>

	/**
	 * Retrieve the language processing information
	 *
	 * Returns whether the given language has a shallow parser and a preprocessor. The API requires no permissions
	 * @param language The name of the language that we want to check for parser support and preprocessor
	 */
	getLanguageProcessingInfo: MutationOperation<{ pathParams: { language: ELanguageEntry } }, LanguageProcessingInfo>

	/**
	 * Get rules
	 *
	 * Gets rules for a given language available in the current Teamscale version. No permission needed since every user can explore rules with the rule browser.
	 */
	getLanguageRules: MutationOperation<{ queryParams: GetLanguageRulesQueryParams }, RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages and tools for a specific analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName The analysis profile for which to get rules.
	 */
	getLanguageRulesForAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string }, queryParams: GetLanguageRulesForAnalysisProfileQueryParams }, RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages for a specific project. The API requires the user to have View Project permissions on the project.
	 * @param project The project for which to get rules.
	 */
	getLanguageRulesForProject: MutationOperation<{ pathParams: { project: string }, queryParams: GetLanguageRulesForProjectQueryParams }, RulesContainer>

	/**
	 * Get project languages
	 *
	 * Returns a list of languages a project has been configured for This service is public API since Teamscale 5.5. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getLanguages: MutationOperation<{ pathParams: { project: string } }, Array<ELanguageEntry>>

	/**
	 * Get GitLab repository languages
	 *
	 * Gets all languages associated with a GitLab repository, including their usage percentages in the project codebase. The API requires the user to have View permissions on the External Account credentialsName.
	 * @param credentialsName Name of the external account
	 */
	getLanguagesForGitlabProject: MutationOperation<{ pathParams: { credentialsName: string }, queryParams: GetLanguagesForGitlabProjectQueryParams }, Array<ProjectLanguage>>

	/**
	 * Return last change log entry
	 *
	 * Returns the log entry for the last change containing the given file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getLastChangeLogEntry: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetLastChangeLogEntryQueryParams }, RepositoryLogEntry | null>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getLastCommitOfType: MutationOperation<{ pathParams: { project: string }, queryParams: GetLastCommitOfTypeQueryParams }, RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo: MutationOperation<{ pathParams: { project: string } }, LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo: MutationOperation<{ queryParams: GetLicenseInfoQueryParams }, LicenseInfo | null>

	/**
	 * Get licenses
	 *
	 * Service to retrieve all licenses sorted by criticality The API requires no permissions
	 * @param project The project ID.
	 */
	getLicenses: MutationOperation<{ pathParams: { project: string }, queryParams: GetLicensesQueryParams }, Array<CopyrightLicenseInfo>>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Account accountName.
	 * @param accountName The ID of the external account the user needs access to.
	 */
	getLinkRoles: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last n days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters: MutationOperation<{ queryParams: GetListOfActiveCommittersQueryParams }, Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last n days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects: MutationOperation<{ queryParams: GetListOfActiveCommittersForProjectsQueryParams, body: Array<string> }, Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext: MutationOperation<{ queryParams: GetLoginContextQueryParams }, LoginContext>

	/**
	 * Get a single merge request
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequest: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, ExtendedMergeRequest | null>

	/**
	 * Get affected files for merge request
	 *
	 * Provides the list of changed files for a given merge request. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestAffectedFiles: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestAffectedFilesQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get merge request changed lines
	 *
	 * Calculates the lines that have been changed in the merge request for the given file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequestChangedLines: MutationOperation<{ pathParams: { project: string, idWithRepository: string }, queryParams: GetMergeRequestChangedLinesQueryParams }, Array<number>>

	/**
	 * Get a merge request analysis delta
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequestDelta: MutationOperation<{ pathParams: { project: string, idWithRepository: string }, queryParams: GetMergeRequestDeltaQueryParams }, MergeRequestDelta | null>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestFindingChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestFindingChurnQueryParams }, ExtendedFindingDelta>

	/**
	 * Get merge request parent info
	 *
	 * Retrieves information on the parent commits for a merge request (merge-base, source-only ancestors). The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestParentInfo: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestParentInfoQueryParams }, MergeRequestParentInfoTransport>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
	 * @param project ID of the project which analyzes the merge request.
	 */
	getMergeRequestRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestRepositoryChurnQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get merge request test coverage
	 *
	 * Calculates how much lines of the new and changed lines are covered by tests. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequestTestCoverage: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, TestCoverageDeltaInfo>

	/**
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP system.
	 *
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP System The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param filePath Path to the file in Teamscale.
	 */
	getMetadataForFile: MutationOperation<{ pathParams: { project: string, filePath: string }, queryParams: GetMetadataForFileQueryParams }, AbapFileMetadata>

	/**
	 * Get method-based treemap
	 *
	 * Builds a treemap based on methods. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMethodBasedTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetMethodBasedTreemapQueryParams }, MethodBasedTreemapWrapper>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetMethodHistoryQueryParams }, MethodHistoryEntriesWrapper>

	/**
	 * Get methods executed by the queried tests
	 *
	 * Returns all methods executed by the given test execution(s) matched by the given query, as a *.csv file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMethodsExecutedByTest: MutationOperation<{ pathParams: { project: string }, queryParams: GetMethodsExecutedByTestQueryParams }, File>

	/**
	 * Get methods
	 *
	 * Returns the list of methods for a given file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getMethodsForFile: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetMethodsForFileQueryParams }, Array<LineBasedMethodInfo>>

	/**
	 * Get metric assessment
	 *
	 * Provides a list of metrics and their assessment for a given path and threshold configuration The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricAssessmentWithUniformPath: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricAssessmentWithUniformPathQueryParams }, Array<GroupAssessment>>

	/**
	 * Get metric badges configuration for the given merge request.
	 *
	 * Get metric badges configuration for the given merge request. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param idWithRepository 
	 */
	getMetricBadgesConfigurationForMergeRequest: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, MetricBadgesConfiguration>

	/**
	 * Get benchmark results
	 *
	 * Returns (partly anonymized) benchmark results for a specific metric. User must have audit support included in the license.
	 */
	getMetricBenchmark: MutationOperation<{ queryParams: GetMetricBenchmarkQueryParams }, Array<BenchmarkResult>>

	/**
	 * Get metric delta
	 *
	 * Provides the metric delta (i.e., the changes in metric values) for a given uniform path and time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricDeltaQueryParams }, Array<MetricDeltaValue>>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath: MutationOperation<{ pathParams: { project: string, uniformPathType: ETypeEntry } }, MetricDirectorySchema>

	/**
	 * Get metric schemas
	 *
	 * Returns all metric schemas of a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricDirectorySchemas: MutationOperation<{ pathParams: { project: string } }, Record<string, MetricDirectorySchema>>

	/**
	 * Get metric distribution
	 *
	 * Retrieves metric distributions for a resource sub-tree. A metric distribution can basically be thought of as a table which describes the distribution of a set of specified metrics with respect to a selected metric (called \"principal metric\") according to a set of given ranges (which are consecutive intervals based on a set of given boundaries). The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricDistribution: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricDistributionQueryParams }, Array<MetricDistributionEntry>>

	/**
	 * Get metric distribution delta
	 *
	 * Retrieves metrics distribution with deltas for a resource sub-tree. A metric distribution delta can basically be thought of as a table which describes the distribution of the delta for a set of specified metrics with respect to a selected metric delta (called \"principal metric delta\") according to a set of given ranges (which are consecutive intervals based on a set of given boundaries). The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricDistributionDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricDistributionDeltaQueryParams }, MetricDistributionWithDelta>

	/**
	 * Get metric file distribution
	 *
	 * Retrieves the metric file distribution for the provided metric and regexps. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricFileDistribution: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricFileDistributionQueryParams }, Array<number>>

	/**
	 * Get metric history
	 *
	 * Retrieves the metric history for a resource. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricHistory: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricHistoryQueryParams }, Array<MetricTrendEntry>>

	/**
	 * Get metric history commits
	 *
	 * Returns the full commits (with branch name) for points in time of a metric history trend The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricHistoryCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricHistoryCommitsQueryParams }, Array<CommitDescriptor>>

	/**
	 * Get metric hotspots
	 *
	 * Retrieves the metric hotspots for the provided parameters. Hotspots are determined using the aggregation information of the metric. The result is determined by ranking all elements with regards to each individual metric and computing an overall ranking by equally weighting each metric rank. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricHotspots: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricHotspotsQueryParams }, Array<ScoredMetricDirectoryEntry>>

	/**
	 * Get metric notification rules
	 *
	 * Retrieves the currently set metric notification rules for the current user The API requires no permissions
	 */
	getMetricNotificationRules: MutationOperation<void, MetricNotificationRules>

	/**
	 * Get metrics
	 *
	 * Retrieves the available external metrics. The API requires no permissions
	 */
	getMetricSchemaEntries: MutationOperation<{ queryParams: GetMetricSchemaEntriesQueryParams }, Array<MetricSchemaChangeEntry>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
	 * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string }, queryParams: GetMetricThresholdConfigurationQueryParams }, MetricThresholdConfiguration>

	/**
	 * Provides threshold path for a metric.
	 *
	 * Provides the time course for a given metric threshold configuration and metric. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricThresholdPathForMetric: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricThresholdPathForMetricQueryParams }, EvaluatedMetricThresholdPath>

	/**
	 * Get treemap
	 *
	 * Builds a treemap for the given metric. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMetricTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetMetricTreemapQueryParams }, TreeMapNode>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMinimizedTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetMinimizedTestsQueryParams }, TestMinimizationResult>

	/**
	 * Returns a preview of the data we would send
	 *
	 * Returns a the preview of the data we would send to our monitoring back-end. The API requires the user to have Access Administrative Services permissions.
	 */
	getMonitoringPreview: MutationOperation<void, string>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for a given project as well as the corresponding test and application subprojects. User must have audit support included in the license.
	 */
	getMultiProjectLatexTable: MutationOperation<{ queryParams: GetMultiProjectLatexTableQueryParams }, string>

	/**
	 * Get unlinked commits
	 *
	 * Retrieves the commits for changes not linked to any issue. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getNonIssueTgaCommitsAndTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetNonIssueTgaCommitsAndTreemapQueryParams }, UnlinkedChangesWrapper>

	/**
	 * Get notification rules
	 *
	 * Retrieves the notification rules for the current user The API requires no permissions
	 */
	getNotificationRules: MutationOperation<void, FindingsNotificationRules>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers: MutationOperation<void, number>

	/**
	 * Get OpenAPI definition
	 *
	 * Returns an OpenAPI (formerly known as Swagger) definition for all available services. The API requires no permissions
	 * @param type 
	 */
	getOpenApi: MutationOperation<{ pathParams: { type: string }, queryParams: GetOpenApiQueryParams }, object>

	/**
	 * Retrieves OpenID endpoints
	 *
	 * Tries to retrieve all Teamscale-relevant endpoints from the issuer URL The API requires the user to have Access Administrative Services permissions.
	 */
	getOpenIdEndpoints: MutationOperation<{ queryParams: GetOpenIdEndpointsQueryParams }, OpenIdEndpointInfo>

	/**
	 * List parse log of single element
	 *
	 * Lists all parse log entries for the element with given uniform path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getParseLogElement: MutationOperation<{ pathParams: { project: string }, queryParams: GetParseLogElementQueryParams }, Array<ParseLogEntry>>

	/**
	 * Get architecture analysis progress.
	 *
	 * Returns pending architecture uploads. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPendingArchitectureUploads: MutationOperation<{ pathParams: { project: string }, queryParams: GetPendingArchitectureUploadsQueryParams }, Record<string, EArchitectureUploadTypeEntry>>

	/**
	 * Get pending finding exclusions
	 *
	 * Returns any pending finding exclusions for the given commits, or null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding Id of the finding the information is requested for.
	 */
	getPendingFindingExclusion: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetPendingFindingExclusionQueryParams }, UserResolvedFindingBlacklistInfo>

	/**
	 * Get system performance metrics
	 *
	 * Provides a trend of performance metrics of previous days. The API requires the user to have View System Status permissions.
	 */
	getPerformanceMetricsTrend: MutationOperation<{ queryParams: GetPerformanceMetricsTrendQueryParams }, Array<PerformanceMetricsEntry>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup: MutationOperation<{ queryParams: GetPermissionLookupQueryParams }, PermissionLookupEBasicPermission>

	/**
	 * Perspective context
	 *
	 * Provides basic information about the logged in user, permissions, available projects and the Teamscale instance. The API requires no permissions
	 */
	getPerspectiveContext: MutationOperation<void, PerspectiveContext>

	/**
	 * Get Polarion link roles
	 *
	 * Returns the link roles for a Polarion project. The API requires the user to have View permissions on the External Account accountName.
	 * @param accountName The ID of the external account the user needs access to.
	 */
	getPolarionWorkItemLinkRoles: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, PolarionWorkItemLinkRolesResult>

	/**
	 * Get Polarion work item types
	 *
	 * Returns the work item types for a Polarion project. The API requires the user to have View permissions on the External Account accountName.
	 * @param accountName The ID of the external account the user needs access to.
	 */
	getPolarionWorkItemTypes: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, PolarionWorkItemTypeResult>

	/**
	 * Returns portfolio manual assessments
	 *
	 * Returns portfolio quality goal manual assessments for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The id of the project to which manual assessments belongs
	 */
	getPortfolioManualAssessments: MutationOperation<{ pathParams: { project: string } }, Array<QualityGoalManualAssessment>>

	/**
	 * Get portfolio overview
	 *
	 * Retrieves the portfolio overview for projects filtered by labels withing a specified time range Only projects visible to the user are shown in the overview
	 */
	getPortfolioOverview: MutationOperation<{ queryParams: GetPortfolioOverviewQueryParams }, Array<ProjectPortfolioResult>>

	/**
	 * Gets postponed rollback counts of all Teamscale projects
	 *
	 * Provides information about project postponed rollbacks counts. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getPostponedRollbackCounts: MutationOperation<void, PostponedRollbackCounts>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPreCommitContent: MutationOperation<{ pathParams: { project: string }, queryParams: GetPreCommitContentQueryParams }, FormattedTokenElementInfo | null>

	/**
	 * Get probe coverage
	 *
	 * Returns the probe coverage data for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getProbeCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetProbeCoverageQueryParams }, ProbeCoverageInfo | null>

	/**
	 * Get profiler configuration
	 *
	 * Retrieves the profiler configuration identified by given ID. The API requires the user to have View permissions on the Profiler Configuration configurationId.
	 * @param configurationId ID of the profiler configuration to retrieve.
	 */
	getProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string } }, ProfilerConfiguration>

	/**
	 * Get project
	 *
	 * Returns details on a project. This service is public API since Teamscale 7.1. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getProject: MutationOperation<{ pathParams: { project: string } }, ProjectInfo>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectAnalysisProgress: MutationOperation<{ pathParams: { project: string } }, Array<AnalysisProgressDescriptor>>

	/**
	 * Get project configuration
	 *
	 * Returns the configuration used for creation of the project in the path parameter. This service is public API since Teamscale 9.9. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getProjectConfiguration: MutationOperation<{ pathParams: { project: string } }, ProjectConfiguration>

	/**
	 * Get connector status details
	 *
	 * Returns detailed status information for each connector in a project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectConnectorStatusDetails: MutationOperation<{ pathParams: { project: string } }, Array<ProjectConnectorStatus>>

	/**
	 * Get project credentials
	 *
	 * Returns the names of credentials that are assigned to the given Teamscale projects. User must have the permission to configure external accounts.
	 */
	getProjectCredentials: MutationOperation<{ queryParams: GetProjectCredentialsQueryParams }, ExternalCredentialsUsageInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectCriticalEventLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectCriticalEventLogsQueryParams }, ShortLogResponseShortLogEntryBase>

	/**
	 * Get project IDs
	 *
	 * Returns available internal and public project IDs, grouped by primary public ID. Only projects visible to the user are returned.
	 */
	getProjectIdMap: MutationOperation<void, Record<string, ProjectIdEntry>>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectInteractionLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectInteractionLogsQueryParams }, ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectJavascriptErrorLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectJavascriptErrorLogsQueryParams }, ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules: MutationOperation<void, ProjectNotificationRules | null>

	/**
	 * Get schema
	 *
	 * Retrieves project option schema. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectOptionSchema: MutationOperation<{ pathParams: { project: string } }, Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectOptions: MutationOperation<{ pathParams: { project: string } }, Record<string, object>>

	/**
	 * Get project roles with assignments
	 *
	 * Returns the roles assigned to users or groups on an object. Requires the permission to \"Assign Roles\" for the project
	 * @param instance The project ID to load roles for.
	 */
	getProjectRolesWithAssignments: MutationOperation<{ pathParams: { instance: string } }, RolesWithAssignmentsProjectRole>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectServiceLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectServiceLogsQueryParams }, ShortLogResponseShortServiceLog>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend: MutationOperation<{ queryParams: GetProjectUserActivityTrendQueryParams }, Array<Array<number>>>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectWorkerLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectWorkerLogsQueryParams }, ShortLogResponseShortWorkerLog>

	/**
	 * Gets connector states of all Teamscale projects
	 *
	 * Provides information about project connector states. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getProjectsConnectorStates: MutationOperation<void, ProjectsConnectorState>

	/**
	 * Returns all projects' connectors
	 *
	 * Returns information about the connector configurations associated with each project by its ID (not alias). The service returns the connector configurations only for projects visible to the user and thus no permissions are required. The API requires no permissions
	 */
	getProjectsConnectors: MutationOperation<void, Record<string, Array<ConnectorConfiguration>>>

	/**
	 * Gets analysis states of all Teamscale projects
	 *
	 * Provides the most up-to-date information about completed and currently-analyzed projects. This service returns project states only for projects that the user has at least view permissions for.
	 */
	getProjectsStates: MutationOperation<void, ProjectsState>

	/**
	 * Get projects that use timestamp-based thresholds
	 *
	 * null Only returns projects the user is allowed to view
	 */
	getProjectsWithTimestampBasedThresholds: MutationOperation<void, Record<string, boolean>>

	/**
	 * Get quality indicator names
	 *
	 * Returns the union of distinct quality indicator names configured in the analysis profiles of all code scopes of a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getQualityIndicators: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Select random files.
	 *
	 * Select files randomly for manual review in an audit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRandomFilesInRange: MutationOperation<{ pathParams: { project: string }, queryParams: GetRandomFilesInRangeQueryParams }, Array<CodeFileInfo>>

	/**
	 * Get simple read/write performance statistics
	 *
	 * Returns a textual summary of read/write performance statistics. The API requires the user to have Access Administrative Services permissions.
	 */
	getReadWriteStatistics: MutationOperation<void, string>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRecentBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralsQuery: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralsQueryQueryParams }, Array<RedundantLiteralInfo>>

	/**
	 * Get refactoring suggestions
	 *
	 * Returns extract method refactoring suggestions for a given finding as a sorted list (best-first) of refactoring suggestions for the method with the given finding. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param findingId The finding for which to suggest possible refactorings
	 */
	getRefactoringSuggestions: MutationOperation<{ pathParams: { project: string, findingId: string }, queryParams: GetRefactoringSuggestionsQueryParams }, RefactoringSuggestions | null>

	/**
	 * Get change data of testwise coverage methods in treemap
	 *
	 * Retrieves the changes of methods covered by a test with testwise coverage data. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testExecution 
	 */
	getRelatedChangesTreemap: MutationOperation<{ pathParams: { project: string, testExecution: string }, queryParams: GetRelatedChangesTreemapQueryParams }, MethodTreeMapNode>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
	 * @param id The report id
	 */
	getReport: MutationOperation<{ pathParams: { id: string } }, QualityReport>

	/**
	 * Get report slide
	 *
	 * Returns the data necessary to render the slide in the frontend. The API requires the user to have View permissions on the Quality Report reportId.
	 * @param reportId The ID of the report.
	 * @param slideId The ID of the slide.
	 */
	getReportSlide: MutationOperation<{ pathParams: { reportId: string, slideId: string } }, ReportSlideWithRenderData>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryActivitySummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryActivitySummaryQueryParams }, RepositoryActivitySummary>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryChurnQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLog: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the number of all repository log entries
	 *
	 * Returns the number of repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLogCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogCountQueryParams }, number>

	/**
	 * Get all repository log entries, with split aggregates
	 *
	 * Returns repository log entries for given commits. Aggregated entries are split into their constituents.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLogWithSplitAggregates: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogWithSplitAggregatesQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositorySummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositorySummaryQueryParams }, RepositorySummary>

	/**
	 * Get resolved tasks
	 *
	 * Returns all available tasks with resolved properties. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getResolvedTasks: MutationOperation<{ pathParams: { project: string }, queryParams: GetResolvedTasksQueryParams }, Array<ResolvedTask>>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getResourceHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetResourceHistoryQueryParams }, Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get resource type
	 *
	 * Retrieves the type of a resource represented by a uniform path The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getResourceType: MutationOperation<{ pathParams: { project: string }, queryParams: GetResourceTypeQueryParams }, EResourceTypeEntry>

	/**
	 * Get retrospective
	 *
	 * Returns the retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project of the retrospective.
	 * @param id The retrospective id
	 */
	getRetrospective: MutationOperation<{ pathParams: { id: string } }, UserResolvedRetrospective>

	/**
	 * Get retrospective notes
	 *
	 * Returns the retrospective notes for the given project. The user needs to have \"Edit Retrospectives\" permission on the given project ID.
	 * @param id The ID of the retrospective to get notes for.
	 * @param projectId 
	 */
	getRetrospectiveNote: MutationOperation<{ pathParams: { id: string, projectId: string } }, string>

	/**
	 * Get quality retrospectives
	 *
	 * Returns all retrospectives stored for the given project. No permissions needed, as the service will only return retrospectives visible to current user i.e., the user has \"Edit Retrospectives\" permission on at least one project of the retrospective.
	 */
	getRetrospectivesForProject: MutationOperation<{ queryParams: GetRetrospectivesForProjectQueryParams }, Array<UserResolvedRetrospective>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getReviewBaseline: MutationOperation<{ pathParams: { project: string } }, number>

	/**
	 * Get review status
	 *
	 * Allows to retrieve the review status for a file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The uniform path of the file for which the review status should be retrieved
	 */
	getReviewStatus: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetReviewStatusQueryParams }, AssessedReviewStatus | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRisks: MutationOperation<{ pathParams: { project: string }, queryParams: GetRisksQueryParams }, RiskMethods>

	/**
	 * Get role schema
	 *
	 * Returns the RoleSchema holding all existing global and project roles. The API requires no permissions
	 */
	getRoleSchema: MutationOperation<void, RoleSchemaData>

	/**
	 * Get running profiler
	 *
	 * Retrieves the running profiler The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId 
	 */
	getRunningProfiler: MutationOperation<{ pathParams: { profilerId: string } }, RunningProfilerInfo>

	/**
	 * Get running profilers
	 *
	 * Retrieves the running profilers available in the system No permissions needed, as the service will only return the profiler infos that use a profiler configuration that is visible to the current user.
	 */
	getRunningProfilers: MutationOperation<{ queryParams: GetRunningProfilersQueryParams }, RunningProfilersResult>

	/**
	 * Returns the SAML metadata from the given URL.
	 *
	 * This is meant to simplify SAML configuration in Teamscale. The API requires the user to have Access Administrative Services permissions.
	 */
	getSamlIdentityProviderMetadata: MutationOperation<{ queryParams: GetSamlIdentityProviderMetadataQueryParams }, string>

	/**
	 * Get loading state of SAP JCo library.
	 *
	 * Allows to determine whether the SAP JCo library has been loaded or not. No permissions needed, as this service will not provide any critical information
	 */
	getSapJcoLibraryIsLoaded: MutationOperation<void, boolean>

	/**
	 * Get metric scatter plot
	 *
	 * Retrieves a list of metrics for all files in the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getScatterPlotMetrics: MutationOperation<{ pathParams: { project: string }, queryParams: GetScatterPlotMetricsQueryParams }, Array<MetricDirectoryEntry>>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage: MutationOperation<void, string>

	/**
	 * Autocompletion for search field
	 *
	 * Service that provides possible completions for a query. The service will only search among projects visible to current user.
	 */
	getSearchAutocompletionResults: MutationOperation<{ queryParams: GetSearchAutocompletionResultsQueryParams }, Record<string, Array<SearchSuggestion>>>

	/**
	 * Search code, issues, commits
	 *
	 * Provides the possibility to search the indexed code base. The service will only search through projects visible to current user.
	 */
	getSearchResults: MutationOperation<{ queryParams: GetSearchResultsQueryParams }, SearchResultContainer>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getSelectedTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: GetSelectedTestsQueryParams }, Array<SelectedTest>>

	/**
	 * Get schema
	 *
	 * Retrieves server option schema. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptionSchema: MutationOperation<void, Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available server options. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptions: MutationOperation<void, Record<string, object>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad: MutationOperation<void, Array<LoadProfile>>

	/**
	 * Get Simulink file references
	 *
	 * Returns a list of Simulink file references for a source element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getSimulinkFileReferenceDependencies: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetSimulinkFileReferenceDependenciesQueryParams }, Array<SimulinkFileReferenceDependency>>

	/**
	 * Get simulink model
	 *
	 * Returns the content for the specified view in a simulink model (mdl and slx). The view can be the model itself, a subsystem, a Stateflow subchart, a Simulink Function node in Stateflow, ... . The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getSimulinkModel: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetSimulinkModelQueryParams }, SimulinkBlockData>

	/**
	 * Get comparison info
	 *
	 * Returns the comparison information for two Simulink subsystems. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSimulinkModelComparisonResult: MutationOperation<{ pathParams: { project: string }, queryParams: GetSimulinkModelComparisonResultQueryParams }, SimulinkModelComparisonResult>

	/**
	 * Get signals
	 *
	 * Returns the list of signals for a Simulink dependency. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSimulinkSignalsForDependency: MutationOperation<{ pathParams: { project: string }, queryParams: GetSimulinkSignalsForDependencyQueryParams }, Array<string>>

	/**
	 * Get test coverage for Simulink models
	 *
	 * Retrieves test coverage for a simulink model. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getSimulinkTestCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetSimulinkTestCoverageQueryParams }, DerivedTestCoverageInfo | null>

	/**
	 * Get single metric delta table
	 *
	 * Creates a table with the changes of a single metric in a merge request. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSingleMetricDeltaTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetSingleMetricDeltaTableQueryParams }, SingleMetricDeltaTableData>

	/**
	 * Get single metric delta treemap
	 *
	 * Creates a treemap with the changes of a single metric in a merge request. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSingleMetricDeltaTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetSingleMetricDeltaTreemapQueryParams }, SingleMetricDeltaTreemapData>

	/**
	 * Returns a specific token-expansion (i.e., the result of one macro or directive expansion/replacement). This service should be called with the commit returned by the preprocessor-expansions endpoint to achieve correct results (the file contents might have changed in the meantime).
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getSinglePreprocessorExpansion: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetSinglePreprocessorExpansionQueryParams }, Array<SinglePreprocessorExpansionTransport>>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for the project. User must have audit support included in the license.
	 * @param project The project ID.
	 */
	getSingleProjectLatexTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetSingleProjectLatexTableQueryParams }, string>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption: MutationOperation<{ pathParams: { optionId: string } }, object>

	/**
	 * Returns an image that has been stored previously using this service
	 *
	 * null The API requires the user to have View permissions on the Quality Report reportId.
	 * @param reportId The ID of the quality report the user needs access to.
	 * @param id The unique image id that was returned by the POST call to this service.
	 */
	getSlideImage: MutationOperation<{ pathParams: { reportId: string, id: string } }, void>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot: MutationOperation<{ pathParams: { id: string }, queryParams: GetSnapshotQueryParams }, InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus: MutationOperation<{ pathParams: { id: string } }, EInstanceComparisonStatusEntry>

	/**
	 * Get spec item
	 *
	 * Gets the spec item identified by given ID The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch
	 */
	getSpecItem: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetSpecItemQueryParams }, ImportedLinksAndTypeResolvedSpecItem>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemChurnQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get spec item code references
	 *
	 * Retrieves all spec item code references for the given spec item ID. If there is no spec item code reference, it returns an empty list. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch
	 */
	getSpecItemCodeReferences: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetSpecItemCodeReferencesQueryParams }, Array<SpecItemCodeReference>>

	/**
	 * Get spec item count
	 *
	 * Fetches the count of spec items for the given project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemCountQueryParams }, number>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemDetailsQueryParams }, Array<UserResolvedSpecItem | null>>

	/**
	 * Get spec item graph
	 *
	 * Retrieves a graph representation of the performed spec item query. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getSpecItemGraph: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemGraphQueryParams }, SpecItemGraph>

	/**
	 * Get spec item queries
	 *
	 * Retrieves the list of available spec item query descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemQueries: MutationOperation<{ pathParams: { project: string } }, Array<StoredQueryDescriptor>>

	/**
	 * Get spec item test executions
	 *
	 * Retrieves all test executions for tests that have a reference to the spec item. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch
	 */
	getSpecItemTestExecutions: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetSpecItemTestExecutionsQueryParams }, Array<TestExecutionWithPartition>>

	/**
	 * Get the spec items that the given one is verifying
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the verifying spec item
	 */
	getSpecItemVerifies: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetSpecItemVerifiesQueryParams }, Array<SpecItemReferenceElement>>

	/**
	 * Get spec items
	 *
	 * Retrieves the available spec items filtered by given parameters. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemsByIdAndSorted: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemsByIdAndSortedQueryParams }, Array<SpecItem>>

	/**
	 * Get the spec items that are verified by the queried items 
	 *
	 * Retrieves all 'verifies' references from the queried spec items to the verified ones. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemsVerifiedByQueried: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemsVerifiedByQueriedQueryParams }, Array<SpecItemRelationReference>>

	/**
	 * Get the spec items that verify the queried items 
	 *
	 * Retrieves all 'verified-by' references from the queried spec items to the verifying ones. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemsVerifyingQueried: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemsVerifyingQueriedQueryParams }, Array<SpecItemRelationReference>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace: MutationOperation<{ pathParams: { project: string, test: string }, queryParams: GetStacktraceQueryParams }, Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getStateChangeTimestamps: MutationOperation<{ pathParams: { project: string } }, Record<string, number>>

	/**
	 * Returns a static SVG badge for metrics
	 *
	 * null The API requires no login
	 */
	getStaticMetricBadge: MutationOperation<{ queryParams: GetStaticMetricBadgeQueryParams }, string>

	/**
	 * Get external clone detection status
	 *
	 * Retrieves the current status of running external clone detection. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
	 * @param project The project ID.
	 */
	getStatusOfCloneDetection: MutationOperation<{ pathParams: { project: string } }, ExternalXCloneStatus>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails: MutationOperation<void, void>

	/**
	 * Returns the configuration of the service provider that is stored at the named option.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires no login
	 */
	getStoredServiceProviderConfiguration: MutationOperation<{ queryParams: GetStoredServiceProviderConfigurationQueryParams }, File>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestLists: MutationOperation<{ pathParams: { project: string } }, Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestListsNames: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get stored test queries
	 *
	 * Retrieves the list of available test query descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestQueries: MutationOperation<{ pathParams: { project: string } }, Array<StoredQueryDescriptor>>

	/**
	 * Get subject role assignments for a specific subject.
	 *
	 * Returns a list of subject role assignments for a specific subject. We have to do the permission check by hand as the needed permissions to display the own subject role assignments differ from the ones needed to view someone elses subject role assignments.
	 * @param subjectId 
	 * @param subjectType 
	 */
	getSubjectRoleAssignments: MutationOperation<{ pathParams: { subjectId: string, subjectType: string } }, Array<SubjectRoleAssignments>>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation: MutationOperation<void, Array<SystemProcessInfo>>

	/**
	 * Get task
	 *
	 * Returns the respective task with detailed findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the requested task
	 */
	getTask: MutationOperation<{ pathParams: { project: string, id: number }, queryParams: GetTaskQueryParams }, TaskWithDetailedFindings>

	/**
	 * Get task siblings
	 *
	 * Retrieves the task siblings (previous and next tasks) for the task specified by ID, allowing user filters. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param task ID of the task to retrieve the sibling tasks for.
	 */
	getTaskPreviousNext: MutationOperation<{ pathParams: { project: string, task: number }, queryParams: GetTaskPreviousNextQueryParams }, PreviousNextSiblings>

	/**
	 * Get task summary
	 *
	 * Retrieves the counts of tasks in each possible task state. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetTaskSummaryQueryParams }, Record<string, number>>

	/**
	 * Get task tags
	 *
	 * Allows to retrieve all used task tags. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskTags: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get finding to task mapping
	 *
	 * Returns the IDs of all tasks containing the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskToFindingMapping: MutationOperation<{ pathParams: { project: string }, body: GetTaskToFindingMappingBody }, Array<GetTaskToFindingMappingDefaultResponseInner>>

	/**
	 * Get tasks with count
	 *
	 * Returns all available tasks with count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTasksWithCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetTasksWithCountQueryParams }, TasksWithCount>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage: MutationOperation<void, string>

	/**
	 * Get a valid teamscale-upload invocation.
	 *
	 * Allows the user to get a valid teamscale-upload invocation that the Teamscale Upload wizard UI created. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID to which the external coverage report should be uploaded
	 */
	getTeamscaleUploadInvocation: MutationOperation<{ pathParams: { project: string }, queryParams: GetTeamscaleUploadInvocationQueryParams }, string>

	/**
	 * Input for the Teamscale Upload wizard.
	 *
	 * Returns the input needed to render the Teamscale Upload wizard in the UI. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getTeamscaleUploadWizardInput: MutationOperation<{ pathParams: { project: string } }, TeamscaleUploadWizardInput>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyDependencies: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyDependenciesQueryParams }, Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyTreeMapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestCoverageQueryParams }, LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestCoverageOverlay: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestCoverageOverlayQueryParams }, TestCoverageOverlayData>

	/**
	 * Test Coverage Partitions
	 *
	 * Returns the line coverage partitions for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoveragePartitions: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestCoveragePartitionsQueryParams }, Array<TestCoveragePartitionInfo>>

	/**
	 * Get the execution unit
	 *
	 * Returns the path of the execution unit the given test belongs to. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testExecution The path of the test for which to return the execution unit.
	 * @param partition 
	 */
	getTestExecutionExecutionUnit: MutationOperation<{ pathParams: { project: string, testExecution: string, partition: string }, queryParams: GetTestExecutionExecutionUnitQueryParams }, string | null>

	/**
	 * Get test implementation path
	 *
	 * Returns a test executions corresponding implementation path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testExecution The path of the test for which to return the test implementation path.
	 */
	getTestExecutionImplementation: MutationOperation<{ pathParams: { project: string, testExecution: string }, queryParams: GetTestExecutionImplementationQueryParams }, string | null>

	/**
	 * Get test execution partitions
	 *
	 * Returns all partitions in which the test execution path exists. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testExecution The path of the test for which to return the partitions.
	 */
	getTestExecutionPartitions: MutationOperation<{ pathParams: { project: string, testExecution: string }, queryParams: GetTestExecutionPartitionsQueryParams }, Array<string>>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapDataAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapDataAsCsvQueryParams }, File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapTreeMapQueryParams }, TestGapTreeMapWrapper>

	/**
	 * Get test history
	 *
	 * Retrieves the history of executions of a specific test. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testExecution Uniform path to retrieve test history for
	 */
	getTestHistory: MutationOperation<{ pathParams: { project: string, testExecution: string }, queryParams: GetTestHistoryQueryParams }, TestHistoryWrapper>

	/**
	 * Get test implementation
	 *
	 * Returns a test implementation. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testImplementation The path of the test implementation to return.
	 */
	getTestImplementation: MutationOperation<{ pathParams: { project: string, testImplementation: string }, queryParams: GetTestImplementationQueryParams }, TestImplementation>

	/**
	 * Get test executions
	 *
	 * Returns the test executions for a given test implementation path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param testImplementation The path of the test implementation for which to return the test executions.
	 */
	getTestImplementationTestExecutions: MutationOperation<{ pathParams: { project: string, testImplementation: string }, queryParams: GetTestImplementationTestExecutionsQueryParams }, Array<string>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getTestMinimizationJobStatus: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationJobRun>

	/**
	 * Get test information for a file.
	 *
	 * Returns a list of test locations and their execution results for a file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The path to a file for which we collect test information from.
	 */
	getTestPathsAndExecResults: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestPathsAndExecResultsQueryParams }, Array<TestPathExecutionWrapper> | null>

	/**
	 * Get tests executing a method
	 *
	 * Returns the tests executing a given method. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 * @param startOffset The character-based offset in file on which the method starts (at the specified time).
	 * @param endOffset The character-based offset in file on which the method ends (at the specified time).
	 */
	getTestsExecutingMethod: MutationOperation<{ pathParams: { project: string, uniformPath: string, startOffset: number, endOffset: number }, queryParams: GetTestsExecutingMethodQueryParams }, PartitionedTestSet | null>

	/**
	 * Get testwise coverage partitions
	 *
	 * Returns a list of partitions that have testwise coverage in the given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestwiseCoveragePartitions: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestwiseCoveragePartitionsQueryParams }, Array<string>>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaPercentage: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaPercentageQueryParams }, number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTableQueryParams }, Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTestCoveragePartitions: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTestCoveragePartitionsQueryParams }, Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTrend: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTrendQueryParams }, Array<MetricTrendEntry>>

	/**
	 * Get threshold configuration documentation
	 *
	 * Retrieves a text describing the specified threshold configuration The API requires the user to have View permissions on the Metric Threshold Configuration thresholdConfigurationName.
	 * @param thresholdConfigurationName 
	 */
	getThresholdConfigurationDocumentation: MutationOperation<{ pathParams: { thresholdConfigurationName: string } }, void>

	/**
	 * Provides threshold path for a configuration.
	 *
	 * Provides the time course for a given metric threshold configuration and metric. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getThresholdPath: MutationOperation<{ pathParams: { project: string }, queryParams: GetThresholdPathQueryParams }, EvaluatedMetricThresholdPath>

	/**
	 * Get details for a token element
	 *
	 * Retrieves the details for a token element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTokenElementInfoDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetTokenElementInfoDetailsQueryParams }, Array<TokenElementDetailBase> | null>

	/**
	 * Get custom fields definitions
	 *
	 * Returns the list of all available custom fields from the given connector User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getTrackerCustomFields: MutationOperation<{ body: ConnectorConfiguration }, ExternalToolIssueCustomFieldResult>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTriggerGraphDot: MutationOperation<{ pathParams: { project: string }, queryParams: GetTriggerGraphDotQueryParams }, Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails: MutationOperation<void, void>

	/**
	 * Get a lookup mapping from types to files.
	 *
	 * Returns the lookup mapping from types to files. In the context of architecture assessments the lookup can be used to map from unmapped types of type based architectures to their respective code file location. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTypeToFileLookup: MutationOperation<{ pathParams: { project: string }, queryParams: GetTypeToFileLookupQueryParams }, Record<string, string>>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId: MutationOperation<void, string>

	/**
	 * Get upload commit details
	 *
	 * Returns the details for a given upload commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param commit 
	 */
	getUploadCommitDetails: MutationOperation<{ pathParams: { project: string, commit: UnresolvedCommitDescriptor } }, ExternalAnalysisStatusInfo>

	/**
	 * Get code usage data as csv
	 *
	 * Returns code usage information in a CSV file with the format 'Uniform Path;Method Name;Method Region;Execution Count'. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getUsageDataAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetUsageDataAsCsvQueryParams }, void>

	/**
	 * Preview of usage data
	 *
	 * Returns a preview of the data we collect if you opt-in to provide that data. The API requires the user to have Access Administrative Services permissions.
	 */
	getUsageDataPreview: MutationOperation<{ body: UsageDataReportingOption }, UsageDataPreview>

	/**
	 * Get user
	 *
	 * Returns information about one user. This service is public API since Teamscale 5.2. The API requires the user to have View permissions on the user.
	 * @param user 
	 */
	getUser: MutationOperation<{ pathParams: { user: string } }, UserWithActivity>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetUserAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
	 * @param user 
	 */
	getUserAvatar: MutationOperation<{ pathParams: { user: string }, queryParams: GetUserAvatarQueryParams }, void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
	 * @param userGroupName Name of the requested user group.
	 */
	getUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, UserGroup>

	/**
	 * Get schema
	 *
	 * Retrieves the user option schema. The API requires no permissions
	 */
	getUserOptionSchema: MutationOperation<void, Array<OptionDescriptor>>

	/**
	 * Get options
	 *
	 * Retrieves all available user options. The API requires the user to have Edit permissions on the user.
	 * @param user Name of the user to retrieve options for
	 */
	getUserOptions: MutationOperation<{ pathParams: { user: string } }, Record<string, object>>

	/**
	 * Collect commits done by specific users
	 *
	 * Retrieves a map from committing users to their commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getUserToCommitMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetUserToCommitMapQueryParams }, CommitData>

	/**
	 * Get the spec items that are verifying the given one (the given spec item is verified by...)
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch
	 */
	getVerifiedBySpecItems: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetVerifiedBySpecItemsQueryParams }, Array<SpecItemReferenceElement>>

	/**
	 * Returns all accessible projects along with their metric schema
	 *
	 * null The permissions will be checked at runtime (this service works cross-project)
	 */
	getWidgetContext: MutationOperation<void, WidgetContext>

	/**
	 * Get worker status
	 *
	 * Retrieves the worker status info. The API requires the user to have View System Status permissions.
	 */
	getWorkerStatus: MutationOperation<void, Array<WorkerGroupStatus>>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam: MutationOperation<void, SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement: MutationOperation<{ body: string }, void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile: MutationOperation<{ body: ImportAbapLintConfigurationFileBody }, Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile: MutationOperation<{ body: ImportAnalysisProfileBody }, void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. Every logged in user is allowed to create dashboards. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards: MutationOperation<{ body: ImportAndReplaceDashboardsBody }, Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup: MutationOperation<{ body: ImportBackupBody }, string>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. Every logged in user is allowed to create dashboards
	 */
	importDashboard: MutationOperation<{ body: ImportDashboardBody }, Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate: MutationOperation<{ body: ImportDashboardTemplateBody }, Array<string>>

	/**
	 * Import external findings
	 *
	 * Uploads external findings groups and/or descriptions put from a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	importExternalFindings: MutationOperation<{ body: ImportExternalFindingsBody }, void>

	/**
	 * Imports external metrics
	 *
	 * Imports external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	importExternalMetrics: MutationOperation<{ body: ImportExternalMetricsBody }, void>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
	 * @param groupName The name of the group that should be imported.
	 */
	importGroup: MutationOperation<{ pathParams: { groupName: string }, queryParams: ImportGroupQueryParams }, Array<string>>

	/**
	 * Import metric threshold configuration
	 *
	 * Import metric threshold configuration. The service will only allow to import metric threshold configurations for projects that are visible to current user.
	 */
	importMetricThresholdsConfiguration: MutationOperation<{ body: ImportMetricThresholdsConfigurationBody }, void>

	/**
	 * Import project(s)
	 *
	 * Imports a project(s) into the instance. The given file can either be a project configuration (.tsproject) or a project backup (.zip). The API requires the user to have Create Projects permissions.
	 */
	importProjectConfiguration: MutationOperation<{ body: ImportProjectConfigurationBody }, void>

	/**
	 * Upload Sonar profile
	 *
	 * Uploads Sonar Quality Profiles which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSonarProfile: MutationOperation<{ body: ImportSonarProfileBody }, SonarQualityProfileImportSummary>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSwiftLintConfiguration: MutationOperation<{ body: ImportSwiftLintConfigurationBody }, SwiftLintConfigurationImportSummary>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
	 * @param userName The name of the user that should be imported.
	 */
	importUser: MutationOperation<{ pathParams: { userName: string }, queryParams: ImportUserQueryParams }, string>

	/**
	 * Validate issue query
	 *
	 * Decided whether an issue query is valid The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isIssueQueryValid: MutationOperation<{ pathParams: { project: string }, queryParams: IsIssueQueryValidQueryParams }, boolean>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isMergeRequestsEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Availability of review findings
	 *
	 * Returns true if review findings are enabled in the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isReviewFindingsEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported: MutationOperation<void, boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Validate spec item query
	 *
	 * Decided whether an spec item query is valid The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isSpecItemQueryValid: MutationOperation<{ pathParams: { project: string }, queryParams: IsSpecItemQueryValidQueryParams }, boolean>

	/**
	 * Validate test query
	 *
	 * Decides whether an test query is valid. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isTestQueryValid: MutationOperation<{ pathParams: { project: string }, queryParams: IsTestQueryValidQueryParams }, boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
	 * @param project The project ID.
	 */
	listAllParseLogEntries: MutationOperation<{ pathParams: { project: string }, queryParams: ListAllParseLogEntriesQueryParams }, Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	listMergeRequests: MutationOperation<{ pathParams: { project: string }, queryParams: ListMergeRequestsQueryParams }, ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports: MutationOperation<{ queryParams: ListQualityReportsQueryParams }, Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	loadFromNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: LoadFromNamedTestListQueryParams }, number>

	/**
	 * Logout
	 *
	 * Performs a logout of the currently logged in user by invalidating the current session. The API requires no login
	 */
	logout: MutationOperation<void, void>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
	 * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite: MutationOperation<{ pathParams: { dashboardId: string }, queryParams: MarkDashboardAsFavoriteQueryParams }, void>

	/**
	 * Perform issue query
	 *
	 * Retrieves the size and list of issues for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performIssueQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformIssueQueryQueryParams }, IssueQueryResultUserResolvedTeamscaleIssue>

	/**
	 * Perform issue query with TGA-related data
	 *
	 * Retrieves the size and list of issues for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performIssueTgaQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformIssueTgaQueryQueryParams }, IssueQueryResultUserResolvedTeamscaleIssue>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin: MutationOperation<{ body: Credentials }, void>

	/**
	 * Perform spec item query
	 *
	 * Retrieves the size and list of spec items for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performSpecItemQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformSpecItemQueryQueryParams }, IssueQueryResultReferenceResolvedSpecItem>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performTestQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformTestQueryQueryParams }, TestQueryResult>

	/**
	 * Poll pre-commit 3.0 results
	 *
	 * Returns exactly the same response as the initial finding request. It may, however, omit the list of findings unless the analysis is ready. If the analysis is ready, the list of findings is returned (may be empty) and the token is empty. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param token 
	 */
	pollPreCommitResults: MutationOperation<{ pathParams: { project: string, token: string } }, PreCommit3Result>

	/**
	 * Assess architecture definition
	 *
	 * Returns the architecture assessment of the given architecture definition. The API requires the user to have View Project permissions on the project.
	 * @param project ID of the project where architecture should be defined.
	 */
	postArchitectureAssessment: MutationOperation<{ pathParams: { project: string }, queryParams: PostArchitectureAssessmentQueryParams, body: ArchitectureInfo }, ArchitectureAssessmentInfo>

	/**
	 * Apply basic role change for instance
	 *
	 * Applies the instance's role changes sent to the sever. Requires the basic permission to \"Assign Roles\"
	 * @param instance The instance&#39;s id
	 */
	postBasicRoleForInstance: MutationOperation<{ pathParams: { instance: string }, queryParams: PostBasicRoleForInstanceQueryParams, body: RoleChange }, void>

	/**
	 * Apply basic role change globally
	 *
	 * Applies the role changes sent to the sever. The API requires the user to have Assign Global Roles permissions.
	 */
	postBasicRoleGlobally: MutationOperation<{ queryParams: PostBasicRoleGloballyQueryParams, body: RoleChange }, void>

	/**
	 * Create global role
	 *
	 * Creates a new global role. The API requires the user to have Edit Roles permissions.
	 */
	postGlobalRole: MutationOperation<{ body: GlobalRole }, void>

	/**
	 * Apply role change for global role assignments
	 *
	 * Updates the role assignments with the given changes. The API requires the user to have Assign Global Roles permissions.
	 */
	postGlobalRoleWithAssignments: MutationOperation<{ body: RoleChange }, void>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	postJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, body: Array<string> }, number>

	/**
	 * Create project role
	 *
	 * Creates a new project role. The API requires the user to have Edit Roles permissions.
	 */
	postProjectRole: MutationOperation<{ body: ProjectRole }, void>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases: MutationOperation<{ body: PostResolveUserAliasesBody }, Array<string>>

	/**
	 * Apply role change for the project's role assignments
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} for the given project. Requires the permission to \"Assign Roles\" for the project
	 * @param instance The project ID to load roles for.
	 */
	postRoleChangeForInstance: MutationOperation<{ pathParams: { instance: string }, body: Record<string, RoleChange> }, void>

	/**
	 * Apply role change for project role assignments globally
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} globally. The API requires the user to have Assign Global Roles permissions.
	 */
	postRoleChangeGlobally: MutationOperation<{ body: RoleChange }, void>

	/**
	 * Retrieves branches from VCSs
	 *
	 * Returns set of all branches for a given VCS connector The API requires no permissions
	 */
	previewRepositoryBranches: MutationOperation<{ queryParams: PreviewRepositoryBranchesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Retrieves commit messages from VCSs
	 *
	 * Returns latest commit messages for a given branch for a given VCS connector The API requires no permissions
	 */
	previewRepositoryCommitMessages: MutationOperation<{ queryParams: PreviewRepositoryCommitMessagesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Retrieves files from VCSs
	 *
	 * Returns list of all files for a given VCS connector existing on the default branch at HEAD The API requires no permissions
	 */
	previewRepositoryFiles: MutationOperation<{ queryParams: PreviewRepositoryFilesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Handles the incoming requests from code collaboration platforms signaling an update in the status of build jobs.
	 *
	 * Handles the incoming requests from code collaboration platforms signaling the start or completion of a build job.The service identifies the merge request corresponding to the provided revision and triggers the merge request update process. The API requires no login
	 * @param project The project ID.
	 */
	processBuildWebhookEvent: MutationOperation<{ pathParams: { project: string }, queryParams: ProcessBuildWebhookEventQueryParams }, void>

	/**
	 * Handles incoming requests from Gitea.
	 *
	 * Handles the incoming requests from Gitea and processes the request body depending on the event. The events which are handled by the service are 'push' and 'pull_request'. The API requires no login
	 */
	processWebhook: MutationOperation<{ body: string }, void>

	/**
	 * Update global role
	 *
	 * Updates/renames an existing global role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to update/rename.
	 */
	putGlobalRole: MutationOperation<{ pathParams: { name: string }, body: GlobalRole }, void>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromImpacted: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromImpactedQueryParams }, number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromQuery: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromQueryQueryParams }, number>

	/**
	 * Update project role
	 *
	 * Updates/renames an existing project role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to update/rename.
	 */
	putProjectRole: MutationOperation<{ pathParams: { name: string }, body: ProjectRole }, void>

	/**
	 * Update user
	 *
	 * Creates a new user or overwrites an existing one. This service is public API since Teamscale 5.2. The user needs either CREATE_USER or EDIT user permissions to create or edit the user and EDIT permissions on the groups the user is added to or removed from.
	 * @param user 
	 */
	putUser: MutationOperation<{ pathParams: { user: string }, body: UserData }, void>

	/**
	 * Receive an \"analysis completed\" notification from the clang-tidy execution server
	 *
	 * Receiver for the \"analysis completed\" notification from execution server to Teamscale server. After the notification, this Teamscale server will query the analysis results from the execution server.  The API requires the user to have Access Administrative Services permissions.
	 */
	receiveAnalysisCompletedConfirmation: MutationOperation<{ queryParams: ReceiveAnalysisCompletedConfirmationQueryParams }, void>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires no login
	 */
	receiveAnalysisCompletedConfirmation1: MutationOperation<{ queryParams: ReceiveAnalysisCompletedConfirmation1QueryParams }, void>

	/**
	 * Profiler heartbeat
	 *
	 * Updates the profiler infos and sets the profiler to still alive. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId The ID of the profiler to update.
	 */
	receiveHeartbeat: MutationOperation<{ pathParams: { profilerId: string }, body: ProfilerInfo }, void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost: MutationOperation<{ queryParams: RedirectToCorrectHostQueryParams }, void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate: MutationOperation<{ queryParams: RegenerateStoredServiceProviderCertificateQueryParams }, boolean>

	/**
	 * Registers a profiler instance
	 *
	 * Registers a profiler to Teamscale and returns the profiler configuration it should be started with. This service is public API since Teamscale 9.4. The API requires the user to have View permissions on the Profiler Configuration .
	 */
	registerProfiler: MutationOperation<{ queryParams: RegisterProfilerQueryParams, body: ProcessInformation }, ProfilerRegistration>

	/**
	 * Register project visit
	 *
	 * Notifies the server that the user visited a project. This is used to display information about the most recently visited projects in the project selector. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	registerProjectViewedByUser: MutationOperation<{ pathParams: { project: string } }, void>

	/**
	 * Mark branch as visited
	 *
	 * Adds the given branch to the list of recently viewed branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param branchName 
	 */
	registerVisitedBranch: MutationOperation<{ pathParams: { project: string, branchName: string } }, void>

	/**
	 * Revoke Access Keys
	 *
	 * Revokes the given access key of the user. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	removeAccessKeys: MutationOperation<{ pathParams: { user: string }, queryParams: RemoveAccessKeysQueryParams }, void>

	/**
	 * Removes a manual assessment
	 *
	 * Removes a manual assessment from the list of assessments that belong to a project. The API requires the user to have View Project permissions on the project.
	 * @param project The id of the project
	 */
	removePortfolioManualAssessment: MutationOperation<{ pathParams: { project: string }, body: QualityGoalManualAssessment }, void>

	/**
	 * Delete report slides
	 *
	 * Deletes the given slides from an existing report. The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The id of the report that is edited.
	 */
	removeQualityReportSlides: MutationOperation<{ pathParams: { reportId: string }, body: Array<string> }, void>

	/**
	 * Report JavaScript error
	 *
	 * Writes the given JavaScript error that occurred in the Web UI into the Teamscale's JavaScript error log. The API requires no permissions
	 */
	reportJavascriptError: MutationOperation<{ body: JavaScriptError }, void>

	/**
	 * Request a ClangTidy analysis
	 *
	 * Opens and schedules a new ClangTidy analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession: MutationOperation<{ queryParams: RequestAnalysisAndOpenSessionQueryParams, body: ClangTidyOutsourcedAnalysisRequestParameters }, string>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession1: MutationOperation<{ queryParams: RequestAnalysisAndOpenSession1QueryParams, body: SonarLintIndependentAnalysisRequestParameters }, string>

	/**
	 * Request pre-commit 3.0 analysis
	 *
	 * Uploads new change data and analyzes them on a user-specific pre-commit branch. The new content is uploaded as a map from path to new/changed content. To mark deleted files (also after a rename), put null values into the map. The service returns immediately with a list of already calculated findings and an optional pre-commit token that can be used to poll for an updated list of findings given the new file contents. The initial finding response contains already adjusted finding locations with regards to the passed content, this means no location adjustment is needed on the client side. If all file contents match the content on the server, no pre-commit polling token is returned. If pre-commit limits are exceeded, an optional error message is returned, but the current findings are returned as well. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	requestPreCommitAnalysis: MutationOperation<{ pathParams: { project: string }, queryParams: RequestPreCommitAnalysisQueryParams, body: Record<string, string> }, PreCommit3Result>

	/**
	 * Returns the a resolved commit Descriptor for the given commit descriptor string. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	resolveCommit: MutationOperation<{ pathParams: { project: string, unresolvedCommit: UnresolvedCommitDescriptor } }, CommitDescriptor>

	/**
	 * Resolve code review finding
	 *
	 * Mark the provided code review finding as resolved. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param findingId ID of the resolved finding
	 */
	resolveReviewFinding: MutationOperation<{ pathParams: { project: string, findingId: string }, queryParams: ResolveReviewFindingQueryParams }, void>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	resolveTag: MutationOperation<{ pathParams: { project: string }, body: GitTag }, CommitDescriptor | null>

	/**
	 * Re-vote on all open merge requests
	 *
	 * Re-votes on all open merge requests in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	revoteOnAllOpenMergeRequests: MutationOperation<{ pathParams: { project: string } }, void>

	/**
	 * Re-vote on a specific open merge request
	 *
	 * Re-votes on a specific open merge request in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	revoteOnMergeRequest: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, void>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction: MutationOperation<void, void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	saveAsNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, body: string }, number>

	/**
	 * Save issue query
	 *
	 * Creates an issue query descriptor in the system. This service is public API since Teamscale 7.4. The API requires the user to have Edit Issue Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveIssueQueryDescriptor: MutationOperation<{ pathParams: { project: string }, body: StoredQueryDescriptor }, void>

	/**
	 * Save spec item query
	 *
	 * Creates an spec item query descriptor in the system. The API requires the user to have Edit Specification Item Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveSpecItemQueryDescriptor: MutationOperation<{ pathParams: { project: string }, body: StoredQueryDescriptor }, void>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveTestListDescriptor: MutationOperation<{ pathParams: { project: string }, body: TestListDescriptor }, void>

	/**
	 * Save test query
	 *
	 * Creates a test query descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveTestQueryDescriptor: MutationOperation<{ pathParams: { project: string }, body: StoredQueryDescriptor }, void>

	/**
	 * Schedule external clone detection
	 *
	 * Schedules the trigger for external clone detection The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
	 * @param project The project ID.
	 */
	scheduleCloneDetectionTrigger: MutationOperation<{ pathParams: { project: string }, body: ExternalXClonesParameter }, void>

	/**
	 * Send test e-mail
	 *
	 * Allows to test sending of e-mails. This will attempt to send an e-mail to the e-mail address of the current user. The API requires the user to have Edit Server Options permissions.
	 */
	sendTestEmail: MutationOperation<void, void>

	/**
	 * Set metric notification rules
	 *
	 * Edits the currently set metric notification rules for the current user The API requires no permissions
	 */
	setMetricNotificationRules: MutationOperation<{ body: MetricNotificationRules }, void>

	/**
	 * Set notification rules
	 *
	 * Sets the notification rules for the current user The API requires no permissions
	 */
	setNotificationRules: MutationOperation<{ body: FindingsNotificationRules }, void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules: MutationOperation<{ body: ProjectNotificationRules }, void>

	/**
	 * Set project scheduling
	 *
	 * Pauses or resumes the scheduling for a specific project. The API requires the user to have Edit Project permissions on the project.
	 * @param project 
	 */
	setProjectSchedulePausing: MutationOperation<{ pathParams: { project: string }, body: string }, void>

	/**
	 * Set review status
	 *
	 * Allows to retrieve the review status for a file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath The uniform path of the file for which the review status should be changed
	 */
	setReviewStatus: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: SetReviewStatusQueryParams, body: ReviewUploadInfo }, void>

	/**
	 * Set scheduler filtering status
	 *
	 * Sets the filtering status of the scheduler The API requires the user to have Access Administrative Services permissions.
	 */
	setSchedulingMode: MutationOperation<{ body: string }, void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	setupJob: MutationOperation<{ pathParams: { project: string }, queryParams: SetupJobQueryParams }, string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning: MutationOperation<void, boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning: MutationOperation<void, boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning: MutationOperation<void, boolean>

	/**
	 * Returns if the legacy default for 'Disable automatic webhook creation' is enabled, if the user has admin permissions The API requires no permissions
	 */
	showLegacyWebHookWarning: MutationOperation<void, boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning: MutationOperation<void, boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown: MutationOperation<{ body: ShutdownBody }, void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	startJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	stopAndDeleteMinimizationJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	storeEventAnnouncement: MutationOperation<{ pathParams: { eventId: string }, body: EventAnnouncement }, void>

	/**
	 * Synchronize groups
	 *
	 * Synchronizes all groups with a remote server. Will also automatically update all users in the groups. In case groups are deleted on the server, the process will remove all users (and additional remote server information) from them, but will not delete them, in order to preserve access rights. Returns a list containing the names of the synchronized groups. The API requires the user to have Create Users, Create Groups permissions.
	 */
	synchronizeAllGroups: MutationOperation<{ queryParams: SynchronizeAllGroupsQueryParams }, Array<string>>

	/**
	 * Synchronize users
	 *
	 * Synchronizes all users with a remote server. Returns the names of updated users. The API requires the user to have Create Users permissions.
	 */
	synchronizeAllUsers: MutationOperation<{ queryParams: SynchronizeAllUsersQueryParams }, Array<string>>

	/**
	 * Synchronize group
	 *
	 * Synchronizes the given group with a remote server. Will also automatically update all users in the group. In case the group is deleted on the server, the process will remove all users (and additional remote server information) from it, but will not delete it, in order to preserve access rights. The API requires the user to have Create Users, Create Groups permissions.
	 * @param groupName The name of the group that should be synchronized.
	 */
	synchronizeSingleGroup: MutationOperation<{ pathParams: { groupName: string }, queryParams: SynchronizeSingleGroupQueryParams }, void>

	/**
	 * Trigger multi-project re-analysis.
	 *
	 * Triggers re-analysis of all projects specified in the request body (a list of project ids). The API requires the user to have Edit Project permissions on all provided projects.
	 */
	triggerMultiReanalysis: MutationOperation<{ queryParams: TriggerMultiReanalysisQueryParams, body: Array<string> }, TriggerMultiReanalysisResult>

	/**
	 * Trigger project reanalysis
	 *
	 * Triggers reanalysis of the project specified in the path parameter. This service is public API since Teamscale 5.9. The API requires the user to have Edit Project permissions on the project.
	 * @param project 
	 */
	triggerReanalysis: MutationOperation<{ pathParams: { project: string }, queryParams: TriggerReanalysisQueryParams }, void>

	/**
	 * Trigger S3 commit hook
	 *
	 * Allows to trigger updates of S3 repos via a commit hook. Returns a map from (potentially) affected project connectors to their associated keys. Only projects that are visible to the user and for which the user has the \"Trigger Commit Hook\" permission will be affected by the commit hook
	 */
	triggerS3CommitHook: MutationOperation<{ body: Array<string> }, Record<string, Array<string>>>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup: MutationOperation<{ body: TriggerSnapshotBackupBody }, void>

	/**
	 * Try get user
	 *
	 * Returns information about one user. The API requires the user to have View permissions on the user.
	 * @param user 
	 */
	tryGetUser: MutationOperation<{ pathParams: { user: string } }, UserWithActivity | null>

	/**
	 * Resolves the element as a link
	 *
	 * Tries to resolve the content of the token element as a link to another element. This currently only works for certain non-code metrics that have a json content attached with file and line properties. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	tryResolveNonCodeLink: MutationOperation<{ pathParams: { project: string }, queryParams: TryResolveNonCodeLinkQueryParams }, ResourceLink | null>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Mark Red Findings, Mark Yellow Findings permissions on the project.
	 * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: MutationOperation<{ pathParams: { project: string }, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams }, Array<string>>

	/**
	 * Unregister profiler
	 *
	 * Removes the profiler identified by given ID. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId The ID of the profiler to unregister.
	 */
	unregisterProfiler: MutationOperation<{ pathParams: { profilerId: string } }, void>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile: MutationOperation<{ queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile }, void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Template templateId.
	 * @param templateId ID of the template to update
	 */
	updateDashboardTemplate: MutationOperation<{ pathParams: { templateId: string }, body: DashboardTemplateDescriptor }, void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Account externalCredentialsName.
	 * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData }, void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string }, body: ExternalAnalysisGroup }, void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionTypeId: string }, body: ExternalFindingsDescription }, void>

	/**
	 * Update project metric schema
	 *
	 * Updates the project metric schema with the entries in the analysis profile. User must either have the permission to create analysis profiles, or the permission to view any existing analysis profile.
	 * @param project The project ID.
	 */
	updateExternalMetricSchema: MutationOperation<{ pathParams: { project: string }, queryParams: UpdateExternalMetricSchemaQueryParams }, void>

	/**
	 * Update metrics
	 *
	 * Updates a set of external metrics The API requires the user to have Edit External Metrics Schema permissions.
	 */
	updateExternalMetrics: MutationOperation<{ queryParams: UpdateExternalMetricsQueryParams, body: Array<MetricSchemaChangeEntry> }, void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backend externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, body: ExternalStorageBackendData }, void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	updateJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: UpdateJobOptionsQueryParams }, void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Update password
	 *
	 * Updates the user password The API requires the user to have Edit permissions on the User username.
	 * @param username The ID of the user the user needs access to.
	 */
	updatePassword: MutationOperation<{ pathParams: { username: string }, body: PasswordChangeRequest }, void>

	/**
	 * Updates the selected manual assessment
	 *
	 * Updates the currently selected manual assessment for a given project in the portfolio overview. The API requires the user to have View Project permissions on the project.
	 * @param project The id of the project
	 */
	updatePortfolioSelectedManualAssessment: MutationOperation<{ pathParams: { project: string }, body: QualityGoalManualAssessment }, void>

	/**
	 * Update profiler configuration
	 *
	 * Updates an existing profiler configuration. The API requires the user to have Edit permissions on the Profiler Configuration configurationId.
	 * @param configurationId The ID of the dashboard to update.
	 */
	updateProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string }, body: ProfilerConfiguration }, void>

	/**
	 * Updates quality report
	 *
	 * Updates a quality report's metadata such as title and global settings like default project and threshold profile. The API requires the user to have Edit permissions on the Quality Report id.
	 * @param id The ID of the report that gets an update.
	 */
	updateQualityReport: MutationOperation<{ pathParams: { id: string }, body: QualityArtifactParameters }, void>

	/**
	 * Update report slide
	 *
	 * Updates an existing slide in a quality report. The API requires the user to have Edit permissions on the Quality Report id.
	 * @param id The ID of the report that is updated.
	 */
	updateQualityReportSlides: MutationOperation<{ pathParams: { id: string }, body: Array<ReportSlideBase> }, void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
	 * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospective: MutationOperation<{ pathParams: { id: string }, body: QualityArtifactParameters }, void>

	/**
	 * Update retrospective notes
	 *
	 * Updates an existing note for a project in a retrospective. The user needs to have \"Edit Retrospectives\" permission on at least one project referenced in the retrospective or the global \"Access Administrative Services\" permission.
	 * @param id The ID of the retrospective that gets an update.
	 * @param projectId 
	 */
	updateRetrospectiveNote: MutationOperation<{ pathParams: { id: string, projectId: string }, body: string }, void>

	/**
	 * Update task
	 *
	 * Updates the task with the given ID. The API requires the user to have Edit Tasks permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTask: MutationOperation<{ pathParams: { project: string, id: number }, queryParams: UpdateTaskQueryParams, body: Task }, Task>

	/**
	 * Update task resolution
	 *
	 * Updates the resolution of the task with the given ID. The API requires the user to have Update Task Resolution permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTaskResolution: MutationOperation<{ pathParams: { project: string, id: number }, body: string }, Task>

	/**
	 * Update task status
	 *
	 * Updates the status of the task with the given ID. The API requires the user to have Update Task Status permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTaskStatus: MutationOperation<{ pathParams: { project: string, id: number }, body: string }, Task>

	/**
	 * Update user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
	 * @param user 
	 */
	updateUserAvatar: MutationOperation<{ pathParams: { user: string }, body: UpdateUserAvatarBody }, void>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
	 * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup: MutationOperation<{ pathParams: { oldUserGroupName: string }, body: UserGroup }, void>

	/**
	 * Upload architecture
	 *
	 * Imports an architecture file. This service is public API since Teamscale 5.9. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 */
	uploadArchitecture: MutationOperation<{ pathParams: { project: string }, queryParams: UploadArchitectureQueryParams, body: Record<string, File> }, void>

	/**
	 * Upload architecture
	 *
	 * Saves an architecture definition. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 */
	uploadArchitectureAsJson: MutationOperation<{ pathParams: { project: string }, queryParams: UploadArchitectureAsJsonQueryParams, body: ArchitectureInfoWithUniformPath }, void>

	/**
	 * Upload snapshot
	 *
	 * Uploads an external snapshot for comparison The API requires the user to have Access Administrative Services permissions.
	 */
	uploadExternalSnapshot: MutationOperation<{ body: UploadExternalSnapshotBody }, void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport: MutationOperation<{ pathParams: { project: string, sessionId: string }, queryParams: UploadReportQueryParams, body: UploadReportBody }, void>

	/**
	 * Stores the passed image and returns a unique key to reference it in future scenarios
	 *
	 * null The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The ID of the quality report the user needs access to.
	 */
	uploadSlideImage: MutationOperation<{ pathParams: { reportId: string }, body: UploadSlideImageBody }, string>

	/**
	 * Validate analysis profile
	 *
	 * Validates the analysis profile provided in the request body. Requires the edit permission on the respective analysis profile.
	 */
	validateAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, AnalysisProfileValidationResult | null>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration: MutationOperation<{ body: ProjectBranchingConfiguration }, string | null>

	/**
	 * Validates a connector configuration
	 *
	 * Validates a given connector configuration User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateConnector: MutationOperation<{ queryParams: ValidateConnectorQueryParams, body: ConnectorConfiguration }, string | null>

	/**
	 * Validate RegEx
	 *
	 * Validates the given regex. The API requires no permissions
	 */
	validateRegex: MutationOperation<{ queryParams: ValidateRegexQueryParams }, string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup: MutationOperation<void, string>

	/**
	 * Whether the legacy API was used since the last instance restart.
	 *
	 * The legacy API will be disabled in Teamscale v10.0 and completely removed in a future version. This endpoint provides information whether the legacy API was used since the last instance restart. The API requires no permissions
	 */
	wasLegacyApiUsed: MutationOperation<void, boolean>

}

/** Maps operation IDs to the actual path, method and expected Content-Type. */
export const urlMapping = { 
	addCommentToTask: {
		method: 'POST',
		path: '/api/projects/{project}/tasks/{task}/comments',
		contentType: 'application/json'
	},
	addExternalMetrics: {
		method: 'POST',
		path: '/api/external-metrics',
		contentType: 'application/json'
	},
	addPortfolioManualAssessment: {
		method: 'PUT',
		path: '/api/quality-reports/portfolio-overview/manuals/{project}',
		contentType: 'application/json'
	},
	addQualityReportSlides: {
		method: 'POST',
		path: '/api/quality-reports/{reportId}/add-slides',
		contentType: 'application/json'
	},
	autocompleteIssueId: {
		method: 'GET',
		path: '/api/projects/{project}/issues/find'
	},
	autocompleteUserGroup: {
		method: 'GET',
		path: '/api/user-groups/find'
	},
	autocompleteUserName: {
		method: 'GET',
		path: '/api/users/find'
	},
	calculateActiveUsersStatistics: {
		method: 'GET',
		path: '/api/user-activity-history'
	},
	calculateCommitterStatistics: {
		method: 'GET',
		path: '/api/committer-activity-history'
	},
	cancelTrigger: {
		method: 'PUT',
		path: '/api/execution-status/workers/{workerId}/cancel',
		contentType: 'application/json'
	},
	changeReviewFindingMessage: {
		method: 'POST',
		path: '/api/projects/{project}/review-findings/{findingId}/message'
	},
	clearHistoryIdLock: {
		method: 'DELETE',
		path: '/api/sap-history-id-lock/{sapConfigurationId}'
	},
	clearPreCommitBranch: {
		method: 'DELETE',
		path: '/api/projects/{project}/pre-commit3'
	},
	clearResultsCache: {
		method: 'DELETE',
		path: '/api/analysis/clangtidy-execution/clearResultsCache'
	},
	commitAnalysisResults: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	computeMetricTable: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/table'
	},
	createAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	createAnalysisProfileForLanguages: {
		method: 'POST',
		path: '/api/analysis-profiles/languages'
	},
	createBackup: {
		method: 'POST',
		path: '/api/backups/export',
		contentType: 'application/x-www-form-urlencoded'
	},
	createComparison: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons',
		contentType: 'application/x-www-form-urlencoded'
	},
	createDashboard: {
		method: 'POST',
		path: '/api/dashboards',
		contentType: 'application/json'
	},
	createDashboardFromTemplate: {
		method: 'POST',
		path: '/api/dashboards/from-template'
	},
	createDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates',
		contentType: 'application/json'
	},
	createExternalAccounts: {
		method: 'POST',
		path: '/api/external-accounts',
		contentType: 'application/json'
	},
	createExternalAnalysisGroup: {
		method: 'POST',
		path: '/api/external-findings/groups',
		contentType: 'application/json'
	},
	createExternalFindingDescription: {
		method: 'POST',
		path: '/api/external-findings/descriptions',
		contentType: 'application/json'
	},
	createExternalStorageBackend: {
		method: 'POST',
		path: '/api/external-storage-backends',
		contentType: 'application/json'
	},
	createMetricBadgeMarkdown: {
		method: 'POST',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/metrics/markdown-badge',
		contentType: 'application/json'
	},
	createMetricThresholdConfiguration: {
		method: 'POST',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	createNewRandomAccessKey: {
		method: 'POST',
		path: '/api/users/{user}/access-key'
	},
	createOrUpdateBaseline: {
		method: 'PUT',
		path: '/api/projects/{project}/baselines/{baseline}',
		contentType: 'application/json'
	},
	createProfilerConfiguration: {
		method: 'POST',
		path: '/api/profiler-configurations',
		contentType: 'application/json'
	},
	createProject: {
		method: 'POST',
		path: '/api/projects',
		contentType: 'application/json'
	},
	createQualityReport: {
		method: 'POST',
		path: '/api/quality-reports',
		contentType: 'application/json'
	},
	createRetrospective: {
		method: 'POST',
		path: '/api/retrospectives',
		contentType: 'application/json'
	},
	createReviewFinding: {
		method: 'POST',
		path: '/api/projects/{project}/findings/review-findings',
		contentType: 'application/json'
	},
	createSession: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session'
	},
	createSnapshot: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots',
		contentType: 'application/x-www-form-urlencoded'
	},
	createSupportRequest: {
		method: 'POST',
		path: '/api/support-request',
		contentType: 'application/json'
	},
	createTask: {
		method: 'POST',
		path: '/api/projects/{project}/tasks',
		contentType: 'application/json'
	},
	createUserGroup: {
		method: 'POST',
		path: '/api/user-groups',
		contentType: 'application/json'
	},
	deleteAllArchitectureCommits: {
		method: 'DELETE',
		path: '/api/projects/{project}/architectures/{uniformPath}/all-commits'
	},
	deleteAllUnprocessedArchitectureCommits: {
		method: 'DELETE',
		path: '/api/projects/{project}/architectures/all-unprocessed-uploads'
	},
	deleteAnalysisProfile: {
		method: 'DELETE',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	deleteAnalysisResults: {
		method: 'DELETE',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	deleteArchitecture: {
		method: 'DELETE',
		path: '/api/projects/{project}/architectures/{uniformPath}'
	},
	deleteBaseline: {
		method: 'DELETE',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	deleteComparison: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	deleteDashboard: {
		method: 'DELETE',
		path: '/api/dashboards/{dashboardId}'
	},
	deleteDashboardTemplate: {
		method: 'DELETE',
		path: '/api/dashboard-templates/{templateId}'
	},
	deleteEventAnnouncement: {
		method: 'DELETE',
		path: '/api/event-announcement/delete/{eventId}'
	},
	deleteExternalAccounts: {
		method: 'DELETE',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	deleteExternalAnalysisGroup: {
		method: 'DELETE',
		path: '/api/external-findings/groups/{groupName}'
	},
	deleteExternalFindingDescription: {
		method: 'DELETE',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionName}'
	},
	deleteExternalMetric: {
		method: 'DELETE',
		path: '/api/external-metrics/{metricName}'
	},
	deleteExternalSnapshot: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/external/{name}'
	},
	deleteExternalStorageBackend: {
		method: 'DELETE',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	deleteGlobalCriticalEventLogs: {
		method: 'DELETE',
		path: '/api/logs/critical-events'
	},
	deleteGlobalInteractionLogs: {
		method: 'DELETE',
		path: '/api/logs/interaction'
	},
	deleteGlobalJavascriptErrorLogs: {
		method: 'DELETE',
		path: '/api/logs/javascript-errors'
	},
	deleteGlobalRole: {
		method: 'DELETE',
		path: '/api/roles/global-roles/{name}'
	},
	deleteGlobalServiceLogs: {
		method: 'DELETE',
		path: '/api/logs/service'
	},
	deleteGlobalWorkerLogs: {
		method: 'DELETE',
		path: '/api/logs/worker'
	},
	deleteIssueQueryDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/issues/queries/{issueQueryName}'
	},
	deleteJobTests: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	deleteMetricThresholdConfiguration: {
		method: 'DELETE',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	deleteProfilerConfiguration: {
		method: 'DELETE',
		path: '/api/profiler-configurations/{configurationId}'
	},
	deleteProject: {
		method: 'DELETE',
		path: '/api/projects/{project}'
	},
	deleteProjectCriticalEventLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/critical-events'
	},
	deleteProjectInteractionLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/interaction'
	},
	deleteProjectJavascriptErrorLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/javascript-errors'
	},
	deleteProjectOption: {
		method: 'DELETE',
		path: '/api/projects/{project}/options/{optionId}'
	},
	deleteProjectRole: {
		method: 'DELETE',
		path: '/api/roles/project-roles/{name}'
	},
	deleteProjectServiceLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/service'
	},
	deleteProjectWorkerLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/worker'
	},
	deleteQualityReport: {
		method: 'DELETE',
		path: '/api/quality-reports/{id}'
	},
	deleteRetrospective: {
		method: 'DELETE',
		path: '/api/retrospectives/{id}'
	},
	deleteServerOption: {
		method: 'DELETE',
		path: '/api/options/server/{optionId}'
	},
	deleteSingleUpload: {
		method: 'DELETE',
		path: '/api/projects/{project}/external-analysis/commits/{commit}'
	},
	deleteSnapshot: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	deleteSpecItemQueryDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/spec-items/queries/{specItemQueryName}'
	},
	deleteTestListDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/test-lists/{testListName}'
	},
	deleteTestQueryDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/tests/queries/{testQueryName}'
	},
	deleteUploadPartition: {
		method: 'DELETE',
		path: '/api/projects/{project}/external-analysis/partitions/{partition}'
	},
	deleteUser: {
		method: 'DELETE',
		path: '/api/users/{users}'
	},
	deleteUserGroup: {
		method: 'DELETE',
		path: '/api/user-groups/{userGroupName}'
	},
	deleteUserOption: {
		method: 'DELETE',
		path: '/api/users/{user}/options/{optionId}'
	},
	deleteUsers: {
		method: 'POST',
		path: '/api/users',
		contentType: 'application/json'
	},
	downloadAllExternalReportsAsZip: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/reports/{commit}/download',
		acceptType: 'application/zip'
	},
	downloadAnalysisProfileDocumentation: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/documentation'
	},
	downloadArchitectureAsXml: {
		method: 'POST',
		path: '/api/projects/{project}/architectures/download',
		contentType: 'application/x-www-form-urlencoded',
		acceptType: 'application/xml'
	},
	downloadAuditData: {
		method: 'GET',
		path: '/api/projects/{project}/audit/data-export/{uniformPath}',
		acceptType: 'text/csv'
	},
	downloadBackup: {
		method: 'GET',
		path: '/api/backups/export/{backupId}/download',
		acceptType: 'application/zip'
	},
	downloadBenchmark: {
		method: 'GET',
		path: '/api/projects/{project}/audit/benchmark'
	},
	downloadGlobalCriticalEventLogs: {
		method: 'GET',
		path: '/api/logs/critical-events/download',
		acceptType: 'text/plain'
	},
	downloadGlobalInteractionLogs: {
		method: 'GET',
		path: '/api/logs/interaction/download',
		acceptType: 'text/plain'
	},
	downloadGlobalJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/logs/javascript-errors/download',
		acceptType: 'text/plain'
	},
	downloadGlobalServiceLogs: {
		method: 'GET',
		path: '/api/logs/service/download',
		acceptType: 'text/plain'
	},
	downloadGlobalWorkerLogs: {
		method: 'GET',
		path: '/api/logs/worker/download',
		acceptType: 'text/plain'
	},
	downloadHeapDump: {
		method: 'POST',
		path: '/api/debug/heap-dump',
		contentType: 'application/x-www-form-urlencoded'
	},
	downloadMetricHistory: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/history/csv'
	},
	downloadProjectCriticalEventLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/download',
		acceptType: 'text/plain'
	},
	downloadProjectInteractionLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/download',
		acceptType: 'text/plain'
	},
	downloadProjectJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/download',
		acceptType: 'text/plain'
	},
	downloadProjectServiceLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/download',
		acceptType: 'text/plain'
	},
	downloadProjectWorkerLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/download',
		acceptType: 'text/plain'
	},
	downloadReport: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/reports/{commit}/{reportIndex}'
	},
	downloadSearchResultCsv: {
		method: 'GET',
		path: '/api/search/csv',
		acceptType: 'text/csv'
	},
	downloadSupportRequest: {
		method: 'GET',
		path: '/api/support-request',
		acceptType: 'application/zip'
	},
	downloadTestSelectionCsv: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/csv',
		acceptType: 'text/csv'
	},
	editDashboard: {
		method: 'PUT',
		path: '/api/dashboards/{dashboardId}',
		contentType: 'application/json'
	},
	editProject: {
		method: 'PUT',
		path: '/api/projects/{project}',
		contentType: 'application/json'
	},
	editProjectOption: {
		method: 'PUT',
		path: '/api/projects/{project}/options/{optionId}',
		contentType: 'application/json'
	},
	editProjectWithConfiguration: {
		method: 'PUT',
		path: '/api/projects/{project}/configuration',
		contentType: 'application/json'
	},
	editServerOption: {
		method: 'PUT',
		path: '/api/options/server/{optionId}',
		contentType: 'application/json'
	},
	editUserOption: {
		method: 'PUT',
		path: '/api/users/{user}/options/{optionId}',
		contentType: 'application/json'
	},
	enableVotingOption: {
		method: 'PUT',
		path: '/api/projects/{project}/connectors/voting-options/{votingOption}'
	},
	executePostponedRollback: {
		method: 'POST',
		path: '/api/projects/{project}/postponed-rollbacks/execute/{id}'
	},
	executeSchedulerCommand: {
		method: 'POST',
		path: '/api/scheduler',
		contentType: 'application/x-www-form-urlencoded'
	},
	exportAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/export'
	},
	exportCodeMatchResult: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-search/export'
	},
	exportDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}/export'
	},
	exportDashboardTemplate: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}/export'
	},
	exportExternalFindings: {
		method: 'GET',
		path: '/api/external-findings/export',
		acceptType: 'application/zip'
	},
	exportExternalMetrics: {
		method: 'GET',
		path: '/api/external-metrics/export'
	},
	exportImpactedTestsAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/impacted-tests/csv'
	},
	exportMetricAssessmentAsCSV: {
		method: 'GET',
		path: '/api/projects/{project}/export-metric-assessment'
	},
	exportMetricThresholdsConfiguration: {
		method: 'GET',
		path: '/api/metric-thresholds/{target}/export'
	},
	exportProjectConfiguration: {
		method: 'GET',
		path: '/api/projects/{project}/configuration/export'
	},
	exportTeamscaleToml: {
		method: 'GET',
		path: '/api/projects/{project}/teamscale-toml',
		acceptType: 'text/plain'
	},
	externalAccountsExist: {
		method: 'GET',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	externalStorageBackendExists: {
		method: 'GET',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	extractJobQueue: {
		method: 'GET',
		path: '/api/execution-status/queue'
	},
	fetchGitlabProjects: {
		method: 'GET',
		path: '/api/projects/gitlab/{credentialsName}'
	},
	findInitialCommit: {
		method: 'GET',
		path: '/api/projects/{project}/initial-commit-finder/{unresolvedCommit}'
	},
	findLogEntriesInRange: {
		method: 'GET',
		path: '/api/projects/{project}/repository-log-range'
	},
	flagFindings: {
		method: 'PUT',
		path: '/api/projects/{project}/findings/flagged',
		contentType: 'application/json'
	},
	getAbsoluteDate: {
		method: 'GET',
		path: '/api/relative-date-resolution'
	},
	getAccessKeysMetadata: {
		method: 'GET',
		path: '/api/users/{user}/access-key'
	},
	getActiveUsersForProjects: {
		method: 'POST',
		path: '/api/user-activity-history',
		contentType: 'application/json'
	},
	getAdditionallyCoveredMethods: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/additional-coverage'
	},
	getAffectedFilesCsv: {
		method: 'GET',
		path: '/api/projects/{project}/commits/affected-files/csv'
	},
	getAffectedFilesForCommits: {
		method: 'GET',
		path: '/api/projects/{project}/commits/affected-files'
	},
	getAggregatedTriggerPerformanceMetrics: {
		method: 'GET',
		path: '/api/debug/performance/trigger/aggregate.csv'
	},
	getAiFindingResolution: {
		method: 'GET',
		path: '/api/projects/{project}/ai/{engine}/findings/{id}/resolution'
	},
	getAiSummary: {
		method: 'GET',
		path: '/api/projects/{project}/ai/{engine}/content/summary'
	},
	getAllA3OptionIdentifiers: {
		method: 'GET',
		path: '/api/a3-option-identifiers'
	},
	getAllArchitectureAssessments: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/assessments'
	},
	getAllBaselines: {
		method: 'GET',
		path: '/api/projects/{project}/baselines'
	},
	getAllCrossBranchPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/line-based/partitions/all'
	},
	getAllDashboardTemplates: {
		method: 'GET',
		path: '/api/dashboard-templates'
	},
	getAllDashboards: {
		method: 'GET',
		path: '/api/dashboards'
	},
	getAllExternalAccounts: {
		method: 'GET',
		path: '/api/external-accounts'
	},
	getAllExternalCrossClones: {
		method: 'GET',
		path: '/api/projects/{project}/audit/external-x-clones/classes'
	},
	getAllExternalSnapshots: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/external'
	},
	getAllExternalStorageBackends: {
		method: 'GET',
		path: '/api/external-storage-backends'
	},
	getAllGitTags: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags'
	},
	getAllGlobalLineBasedPartitions: {
		method: 'GET',
		path: '/api/test-coverage/line-based/partitions'
	},
	getAllGuidelines: {
		method: 'GET',
		path: '/api/guidelines'
	},
	getAllMetricThresholdConfigurationNames: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/names'
	},
	getAllMetricThresholdConfigurations: {
		method: 'GET',
		path: '/api/metric-threshold-configurations'
	},
	getAllMetricsForThresholdProfiles: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/metrics'
	},
	getAllParseLogOverviewEntries: {
		method: 'GET',
		path: '/api/system/parse-log/overview'
	},
	getAllPostponedRollbacks: {
		method: 'GET',
		path: '/api/projects/{project}/postponed-rollbacks'
	},
	getAllProfilerConfigurations: {
		method: 'GET',
		path: '/api/profiler-configurations'
	},
	getAllProjectFilePaths: {
		method: 'GET',
		path: '/api/projects/{project}/project-files/file-paths.csv'
	},
	getAllProjectFiles: {
		method: 'GET',
		path: '/api/projects/{project}/project-files/snapshot.zip'
	},
	getAllProjectIds: {
		method: 'GET',
		path: '/api/projects/ids'
	},
	getAllProjectPortfolioLabels: {
		method: 'GET',
		path: '/api/quality-reports/portfolio-overview/labels'
	},
	getAllProjects: {
		method: 'GET',
		path: '/api/projects'
	},
	getAllSapSystemConnectionIdentifiers: {
		method: 'GET',
		path: '/api/sap-connection-identifiers'
	},
	getAllSnapshotIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/ids'
	},
	getAllSubjectRoleAssignments: {
		method: 'GET',
		path: '/api/subject-role-assignments'
	},
	getAllTestExecutionPartitionDetails: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/partitions/details'
	},
	getAllTestExecutionPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/partitions/all'
	},
	getAllThreadsDumpAsHtml: {
		method: 'GET',
		path: '/api/debug/thread-dump',
		acceptType: 'text/html'
	},
	getAllUserGroupNames: {
		method: 'GET',
		path: '/api/user-groups/names'
	},
	getAllUserGroups: {
		method: 'GET',
		path: '/api/user-groups'
	},
	getAllUsers: {
		method: 'GET',
		path: '/api/users'
	},
	getAllVersions: {
		method: 'GET',
		path: '/api/projects/{project}/dot-net-versions'
	},
	getAnalysisEditHistoryVersionDiff: {
		method: 'GET',
		path: '/api/analysis-profiles/compare-versions/{analysisProfileName}'
	},
	getAnalysisGroupPerCodeScope: {
		method: 'GET',
		path: '/api/projects/{project}/configuration/analysis-groups/{groupName}'
	},
	getAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistory: {
		method: 'GET',
		path: '/api/analysis-profiles/history/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistoryTimestamps: {
		method: 'GET',
		path: '/api/analysis-profiles/history-timestamps/{analysisProfileName}'
	},
	getAnalysisProfileMetadata: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/metadata'
	},
	getAnalysisProfileNames: {
		method: 'GET',
		path: '/api/analysis-profiles'
	},
	getAnalysisProfileUsageInfo: {
		method: 'GET',
		path: '/api/analysis-profiles/with-referenced-projects'
	},
	getAnalysisProfileWithProjects: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/with-referenced-projects'
	},
	getAnalysisResultsByPath: {
		method: 'GET',
		path: '/api/analysis/clangtidy-execution'
	},
	getAnalysisResultsByPath1: {
		method: 'GET',
		path: '/api/analysis/sonarlint'
	},
	getAnalysisState: {
		method: 'GET',
		path: '/api/projects/{project}/branch-analysis-state/{branchName}'
	},
	getAnalysisStateChangeTiming: {
		method: 'GET',
		path: '/api/debug/performance/state-changes.csv'
	},
	getAnalysisStatus: {
		method: 'GET',
		path: '/api/analysis/clangtidy-execution/status'
	},
	getAnalysisStatus1: {
		method: 'GET',
		path: '/api/analysis/sonarlint/status'
	},
	getAnalysisTools: {
		method: 'GET',
		path: '/api/configuration-tools'
	},
	getAnalysisToolsForProject: {
		method: 'GET',
		path: '/api/configuration-tools/{project}'
	},
	getArchitectureAssessment: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/assessments/{uniformPath}'
	},
	getArchitectureCommitUploadInfo: {
		method: 'GET',
		path: '/api/projects/{project}/external-uploads/architectures/{commit}'
	},
	getArchitectureCommitUploadInfos: {
		method: 'GET',
		path: '/api/projects/{project}/external-uploads/architectures/{architecture}/commits'
	},
	getArchitectureComponentAssignments: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/components'
	},
	getArchitecturePerformanceStatistics: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/{architecture}/performance',
		acceptType: 'text/plain'
	},
	getArchitectures: {
		method: 'GET',
		path: '/api/projects/{project}/external-uploads/architectures'
	},
	getAssociatedComparisonIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/comparisons/ids'
	},
	getAuthenticatorInformation: {
		method: 'GET',
		path: '/api/authentication'
	},
	getAuthenticatorInformationForUser: {
		method: 'GET',
		path: '/api/authentication/{username}'
	},
	getAvailableServers: {
		method: 'GET',
		path: '/api/auth/servers'
	},
	getBackupExportStatus: {
		method: 'GET',
		path: '/api/backups/export/{backupId}/status'
	},
	getBackupExportSummary: {
		method: 'GET',
		path: '/api/backups/export/summary'
	},
	getBackupImportStatus: {
		method: 'GET',
		path: '/api/backups/import/{backupId}/status'
	},
	getBackupImportSummary: {
		method: 'GET',
		path: '/api/backups/import/summary'
	},
	getBackupsForImportWizard: {
		method: 'GET',
		path: '/api/import-wizard'
	},
	getBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	getBaselines: {
		method: 'GET',
		path: '/api/baselines'
	},
	getBasicPreprocessorExpansionsInfo: {
		method: 'GET',
		path: '/api/projects/{project}/preprocessor-expansions/{uniformPath}'
	},
	getBasicRoles: {
		method: 'GET',
		path: '/api/roles/basic-role-assignments/{instance}'
	},
	getBenchmarkProjects: {
		method: 'GET',
		path: '/api/benchmark-projects'
	},
	getBenchmarkResultsAsCsv: {
		method: 'GET',
		path: '/api/audit/metric-benchmark.csv'
	},
	getBranchForSpecItem: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/branch'
	},
	getBranchesGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/branches'
	},
	getBranchesPostRequest: {
		method: 'POST',
		path: '/api/projects/{project}/branches'
	},
	getChangeTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/change-treemap'
	},
	getChildrenResourceTypes: {
		method: 'GET',
		path: '/api/projects/{project}/resource-type/children'
	},
	getCode: {
		method: 'GET',
		path: '/api/projects/{project}/source-code-download/{uniformPath}'
	},
	getCodeChanges: {
		method: 'GET',
		path: '/api/projects/{project}/code-changes/{uniformPath}'
	},
	getCodeCities: {
		method: 'GET',
		path: '/api/projects/{project}/code-city'
	},
	getCodeComponentsTreemapWrapper: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-components-treemap'
	},
	getCodeOutline: {
		method: 'GET',
		path: '/api/projects/{project}/code-outline/{uniformPath}'
	},
	getCodeOwnershipTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/code-ownership/treemap'
	},
	getCodeReferencesAndTestExecutionMapping: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/references'
	},
	getCodeSearchMatchResultAndTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-search/match-list-and-treemap'
	},
	getCodeUsageTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/code-usage/treemap'
	},
	getCommitAlerts: {
		method: 'GET',
		path: '/api/projects/{project}/commit-alerts'
	},
	getCommitsForRevision: {
		method: 'GET',
		path: '/api/projects/{project}/revision/{revision}/commits'
	},
	getCommitsForTask: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{id}/commits'
	},
	getCommitsLeftToAnalyzeForMergeRequest: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/analysis-progress-info'
	},
	getComparison: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	getComparisonResult: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{projectId}/{contributor}'
	},
	getComparisonResultMetadata: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/result'
	},
	getCompilationCommandsDebugDump: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/debug-dump-compilation-commands/{commit}/{partition}/{path}'
	},
	getCompleteExampleList: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{project}/{contributor}/{diffEntryName}'
	},
	getConcurrencyPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/concurrency'
	},
	getConfigLanguages: {
		method: 'GET',
		path: '/api/config-languages'
	},
	getConfigLanguagesForProject: {
		method: 'GET',
		path: '/api/config-languages/{project}'
	},
	getConfigurationTemplate: {
		method: 'GET',
		path: '/api/configuration-template'
	},
	getConnectorDescriptors: {
		method: 'GET',
		path: '/api/connector-descriptors'
	},
	getConnectors: {
		method: 'GET',
		path: '/api/project-connectors/{projectId}'
	},
	getCopyrightLicensesTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/copyright-licenses/treemap'
	},
	getCoverageSources: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/overview'
	},
	getCurrentSchedulingFilter: {
		method: 'GET',
		path: '/api/scheduler/mode'
	},
	getDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}'
	},
	getDashboardTemplateDescriptor: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}'
	},
	getDebugContent: {
		method: 'GET',
		path: '/api/projects/{project}/debug/content/{uniformPath}'
	},
	getDeclarationOfAccessibility: {
		method: 'GET',
		path: '/api/accessibility/declaration',
		acceptType: 'text/markdown'
	},
	getDeclarationOfAccessibilityContext: {
		method: 'GET',
		path: '/api/context/accessibility'
	},
	getDefaultForDisableWebhookCreation: {
		method: 'GET',
		path: '/api/disable-webhook-creation-default'
	},
	getDefaultHiddenMetrics: {
		method: 'GET',
		path: '/api/projects/{project}/default-hidden-metrics/{uniformPathType}'
	},
	getDefaultPatterns: {
		method: 'GET',
		path: '/api/connectors/default-patterns'
	},
	getDeltaAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-files'
	},
	getDependenciesWithOccurrenceLocation: {
		method: 'GET',
		path: '/api/projects/{project}/dependencies/{uniformPath}'
	},
	getDependencyGraph: {
		method: 'GET',
		path: '/api/projects/{project}/audit/dependency-graph-extractor/{uniformPath}'
	},
	getDerivingMetricThresholdConfigurationNames: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}/deriving-configuration-names'
	},
	getDiffs: {
		method: 'GET',
		path: '/api/compare-elements'
	},
	getDirectMetricDirectoryEntry: {
		method: 'GET',
		path: '/api/projects/{project}/metrics'
	},
	getDotNetVersionInfo: {
		method: 'GET',
		path: '/api/projects/{project}/dot-net-versions/{versionName}'
	},
	getDotNetVersions: {
		method: 'GET',
		path: '/api/dot-net-versions'
	},
	getDumpCommitDescriptor: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-descriptors',
		acceptType: 'text/plain'
	},
	getDumpCommitTree: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree',
		acceptType: 'text/plain'
	},
	getDumpCommitTreeNodes: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree-nodes'
	},
	getEditedServiceProviderConfiguration: {
		method: 'POST',
		path: '/api/auth/saml/sp-configuration',
		contentType: 'application/json'
	},
	getEventAnnouncements: {
		method: 'GET',
		path: '/api/event-announcement/events'
	},
	getExceptionsHierarchyTree: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/object'
	},
	getExceptionsHierarchyTreeSVG: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/graph'
	},
	getExecutionUnit: {
		method: 'GET',
		path: '/api/projects/{project}/execution-units/{executionUnit}/partitions/{partition}'
	},
	getExtendedResourceTypes: {
		method: 'GET',
		path: '/api/projects/{project}/ext-resource-type/{uniformPath}'
	},
	getExternalAnalysisCommitInfos: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/commit-infos'
	},
	getExternalAnalysisStatusPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/partitions'
	},
	getExternalAnalysisStatusUploads: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/partitions/{partition}'
	},
	getExternalFileWithPreprocessing: {
		method: 'GET',
		path: '/api/audit/external-x-clones/file/{path}'
	},
	getExternalFindingsDescriptions: {
		method: 'GET',
		path: '/api/external-findings/descriptions'
	},
	getExternalFindingsGroups: {
		method: 'GET',
		path: '/api/external-findings/groups'
	},
	getExternalLink: {
		method: 'GET',
		path: '/api/projects/{project}/external-link/{uniformPath}'
	},
	getFileSummary: {
		method: 'GET',
		path: '/api/file-summary'
	},
	getFilesWithIdenticalContent: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-content/{uniformPath}'
	},
	getFilesWithIdenticalNames: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-name/{uniformPath}'
	},
	getFinding: {
		method: 'GET',
		path: '/api/projects/{project}/findings/{id}'
	},
	getFindingChurnCount: {
		method: 'GET',
		path: '/api/projects/{project}/finding-churn/count'
	},
	getFindingChurnList: {
		method: 'GET',
		path: '/api/projects/{project}/finding-churn/list'
	},
	getFindingDelta: {
		method: 'GET',
		path: '/api/projects/{project}/findings/delta'
	},
	getFindingDeltaCount: {
		method: 'GET',
		path: '/api/projects/{project}/findings/delta/count'
	},
	getFindingSiblings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/{id}/siblings'
	},
	getFindingTypeDescriptions: {
		method: 'POST',
		path: '/api/projects/{project}/finding-type-descriptors',
		contentType: 'application/json'
	},
	getFindingWithDiffInfo: {
		method: 'GET',
		path: '/api/projects/{project}/findings/{id}/with-diff-info'
	},
	getFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list'
	},
	getFindingsExport: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/export/{format}'
	},
	getFindingsSiblings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/previousNextSiblings/{finding}'
	},
	getFindingsSummary: {
		method: 'GET',
		path: '/api/projects/{project}/findings/summary'
	},
	getFindingsTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/findings/treemap'
	},
	getFindingsWithCount: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/with-count'
	},
	getFindingsWithIds: {
		method: 'POST',
		path: '/api/projects/{project}/findings/list/with-ids',
		contentType: 'application/json'
	},
	getFirstUIBranchNameForProject: {
		method: 'GET',
		path: '/api/projects/{project}/default-branch'
	},
	getFlaggedFindingIds: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/ids'
	},
	getFlaggedFindingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/{finding}'
	},
	getFlaggedFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged'
	},
	getFlaggedFindingsDelta: {
		method: 'GET',
		path: '/api/projects/{project}/findings/delta/flagged'
	},
	getFlaggedFindingsInfos: {
		method: 'POST',
		path: '/api/projects/{project}/findings/flagged/with-ids',
		contentType: 'application/json'
	},
	getFormattedTokenElementInfo: {
		method: 'GET',
		path: '/api/projects/{project}/content/formatted'
	},
	getGitConnectorIds: {
		method: 'GET',
		path: '/api/projects/{project}/connectors/git/connector-ids'
	},
	getGitHubAppInstallationRepositories: {
		method: 'GET',
		path: '/api/github/repositories'
	},
	getGitHubAppUrls: {
		method: 'GET',
		path: '/api/github/urls'
	},
	getGitTagByName: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags/{tagName}'
	},
	getGlobalAnalysisProgress: {
		method: 'GET',
		path: '/api/analysis-progress'
	},
	getGlobalBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches'
	},
	getGlobalContainerInfo: {
		method: 'GET',
		path: '/api/directories'
	},
	getGlobalCriticalEventLog: {
		method: 'GET',
		path: '/api/logs/critical-events/details/{timestamp}'
	},
	getGlobalCriticalEventLogFrequencies: {
		method: 'GET',
		path: '/api/logs/critical-events/frequencies'
	},
	getGlobalCriticalEventLogs: {
		method: 'GET',
		path: '/api/logs/critical-events'
	},
	getGlobalFirstUIBranchGetRequest: {
		method: 'GET',
		path: '/api/branches/default-branch'
	},
	getGlobalIndicatorsAndGroups: {
		method: 'GET',
		path: '/api/global-indicators-and-groups'
	},
	getGlobalInteractionLog: {
		method: 'GET',
		path: '/api/logs/interaction/details/{timestamp}'
	},
	getGlobalInteractionLogFrequencies: {
		method: 'GET',
		path: '/api/logs/interaction/frequencies'
	},
	getGlobalInteractionLogs: {
		method: 'GET',
		path: '/api/logs/interaction'
	},
	getGlobalJavascriptErrorLog: {
		method: 'GET',
		path: '/api/logs/javascript-errors/details/{timestamp}'
	},
	getGlobalJavascriptErrorLogFrequencies: {
		method: 'GET',
		path: '/api/logs/javascript-errors/frequencies'
	},
	getGlobalJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/logs/javascript-errors'
	},
	getGlobalMetricDirectorySchemas: {
		method: 'GET',
		path: '/api/metric-schema'
	},
	getGlobalRecentBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches/recent-branches'
	},
	getGlobalServiceLog: {
		method: 'GET',
		path: '/api/logs/service/details/{timestamp}'
	},
	getGlobalServiceLogFrequencies: {
		method: 'GET',
		path: '/api/logs/service/frequencies'
	},
	getGlobalServiceLogs: {
		method: 'GET',
		path: '/api/logs/service'
	},
	getGlobalWorkerLog: {
		method: 'GET',
		path: '/api/logs/worker/details/{timestamp}'
	},
	getGlobalWorkerLogFrequencies: {
		method: 'GET',
		path: '/api/logs/worker/frequencies'
	},
	getGlobalWorkerLogs: {
		method: 'GET',
		path: '/api/logs/worker'
	},
	getGroupAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/groups/auto-completion-suggestions/{input}'
	},
	getHasUnlinkedChangesExist: {
		method: 'GET',
		path: '/api/projects/{project}/unlinked-changes'
	},
	getHealthStatus: {
		method: 'GET',
		path: '/api/health-check'
	},
	getHeapDumpFormPage: {
		method: 'GET',
		path: '/api/debug/heap-dump',
		acceptType: 'text/html'
	},
	getHistoryForChangedMethods: {
		method: 'GET',
		path: '/api/projects/{project}/tests/{testUniformPath}/executed-methods-changelog'
	},
	getImpactedTests: {
		method: 'GET',
		path: '/api/projects/{project}/impacted-tests'
	},
	getImpactedTestsFromAvailableTests: {
		method: 'PUT',
		path: '/api/projects/{project}/impacted-tests',
		contentType: 'application/json'
	},
	getImportantBranches: {
		method: 'GET',
		path: '/api/projects/{project}/important-branches'
	},
	getIncludedDotNetSourcePaths: {
		method: 'GET',
		path: '/api/projects/{project}/audit/dotnet-source-paths-extractor'
	},
	getInstancePermissions: {
		method: 'GET',
		path: '/api/basic-permissions/{instanceId}'
	},
	getIssue: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}'
	},
	getIssueAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/affected-files'
	},
	getIssueCommits: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/commits'
	},
	getIssueCount: {
		method: 'GET',
		path: '/api/projects/{project}/issues/count'
	},
	getIssueFindingChurn: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/finding-churn'
	},
	getIssueHierarchy: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/hierarchy'
	},
	getIssueImpactedTests: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/impacted-tests'
	},
	getIssueQueries: {
		method: 'GET',
		path: '/api/projects/{project}/issues/queries'
	},
	getIssueQueryTgaSummary: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/tga-summary'
	},
	getIssueQueryTrend: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/trend'
	},
	getIssueTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/issues/treemap'
	},
	getIssueTrendAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/trend/download',
		acceptType: 'text/csv'
	},
	getIssuesByRepositoryCommits: {
		method: 'GET',
		path: '/api/projects/{project}/commit-issues'
	},
	getIssuesDetails: {
		method: 'GET',
		path: '/api/projects/{project}/issues/details'
	},
	getIssuesExport: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/with-tga/export/{format}'
	},
	getJavaProfilerJvmPackageNames: {
		method: 'GET',
		path: '/api/projects/{project}/jacoco-wizard/packages'
	},
	getJobOptions: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/options'
	},
	getJobQueueSize: {
		method: 'GET',
		path: '/api/execution-status/queue-size'
	},
	getJobs: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	getKnownIssueQueryColumns: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/columns'
	},
	getKnownSpecItemColumns: {
		method: 'GET',
		path: '/api/projects/{project}/spec-item-query/columns'
	},
	getKubernetesManifest: {
		method: 'GET',
		path: '/api/projects/{project}/kubernetes-manifest/{uniformPath}'
	},
	getLanguageProcessingInfo: {
		method: 'GET',
		path: '/api/language-info/{language}'
	},
	getLanguageRules: {
		method: 'GET',
		path: '/api/language-rules'
	},
	getLanguageRulesForAnalysisProfile: {
		method: 'GET',
		path: '/api/language-rules/analysis-profile/{analysisProfileName}'
	},
	getLanguageRulesForProject: {
		method: 'GET',
		path: '/api/language-rules/{project}'
	},
	getLanguages: {
		method: 'GET',
		path: '/api/projects/{project}/languages'
	},
	getLanguagesForGitlabProject: {
		method: 'GET',
		path: '/api/projects/gitlab/{credentialsName}/languages'
	},
	getLastChangeLogEntry: {
		method: 'GET',
		path: '/api/projects/{project}/repository/last-change/{uniformPath}'
	},
	getLastCommitOfType: {
		method: 'GET',
		path: '/api/projects/{project}/repository/last-commit'
	},
	getLateExternalUploadProcessingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/late-external-upload'
	},
	getLicenseInfo: {
		method: 'GET',
		path: '/api/license-info'
	},
	getLicenses: {
		method: 'GET',
		path: '/api/projects/{project}/licenses'
	},
	getLinkRoles: {
		method: 'PUT',
		path: '/api/spec-items/default/{accountName}/schema/link-roles',
		contentType: 'application/json'
	},
	getListOfActiveCommitters: {
		method: 'GET',
		path: '/api/license/active-committer-names'
	},
	getListOfActiveCommittersForProjects: {
		method: 'POST',
		path: '/api/license/active-committer-names',
		contentType: 'application/json'
	},
	getLoginContext: {
		method: 'GET',
		path: '/api/context/login'
	},
	getMergeRequest: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}'
	},
	getMergeRequestAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/affected-files'
	},
	getMergeRequestChangedLines: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/test-coverage/changed-lines'
	},
	getMergeRequestDelta: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/delta'
	},
	getMergeRequestFindingChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/finding-churn'
	},
	getMergeRequestParentInfo: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/parent-info'
	},
	getMergeRequestRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/repository-churn'
	},
	getMergeRequestTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/test-coverage'
	},
	getMetadataForFile: {
		method: 'GET',
		path: '/api/projects/{project}/abap-file-meta-data/{filePath}'
	},
	getMethodBasedTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/methods/treemap'
	},
	getMethodHistory: {
		method: 'GET',
		path: '/api/projects/{project}/methods/history/{uniformPath}'
	},
	getMethodsExecutedByTest: {
		method: 'GET',
		path: '/api/projects/{project}/test-query/executed-methods.csv',
		acceptType: 'text/csv'
	},
	getMethodsForFile: {
		method: 'GET',
		path: '/api/projects/{project}/code/{uniformPath}/methods'
	},
	getMetricAssessmentWithUniformPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-assessments'
	},
	getMetricBadgesConfigurationForMergeRequest: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/metrics/threshold-configurations'
	},
	getMetricBenchmark: {
		method: 'GET',
		path: '/api/audit/metric-benchmark'
	},
	getMetricDelta: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/delta'
	},
	getMetricDirectorySchemaForPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-schema/{uniformPathType}'
	},
	getMetricDirectorySchemas: {
		method: 'GET',
		path: '/api/projects/{project}/metric-schemas'
	},
	getMetricDistribution: {
		method: 'GET',
		path: '/api/projects/{project}/metric-distribution'
	},
	getMetricDistributionDelta: {
		method: 'GET',
		path: '/api/projects/{project}/metric-distribution/delta'
	},
	getMetricFileDistribution: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/file-distribution'
	},
	getMetricHistory: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/history'
	},
	getMetricHistoryCommits: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/history/commits'
	},
	getMetricHotspots: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/hotspots'
	},
	getMetricNotificationRules: {
		method: 'GET',
		path: '/api/notification-rules/metric'
	},
	getMetricSchemaEntries: {
		method: 'GET',
		path: '/api/external-metrics'
	},
	getMetricThresholdConfiguration: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	getMetricThresholdPathForMetric: {
		method: 'GET',
		path: '/api/projects/{project}/metric-threshold-path-for-metric'
	},
	getMetricTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/treemap'
	},
	getMinimizedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests'
	},
	getMonitoringPreview: {
		method: 'GET',
		path: '/api/monitoring-info'
	},
	getMultiProjectLatexTable: {
		method: 'GET',
		path: '/api/audit/latex-table'
	},
	getNonIssueTgaCommitsAndTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/unlinked-changes/treemap'
	},
	getNotificationRules: {
		method: 'GET',
		path: '/api/notification-rules/findings'
	},
	getNumberOfActiveUsers: {
		method: 'GET',
		path: '/api/global-warnings/activeUsers'
	},
	getOpenApi: {
		method: 'GET',
		path: '/openapi.{type}'
	},
	getOpenIdEndpoints: {
		method: 'GET',
		path: '/api/auth/openid/issuer-endpoints'
	},
	getParseLogElement: {
		method: 'GET',
		path: '/api/projects/{project}/parse-log/element'
	},
	getPendingArchitectureUploads: {
		method: 'GET',
		path: '/api/projects/{project}/architecture-analysis-progress'
	},
	getPendingFindingExclusion: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/{finding}/pending'
	},
	getPerformanceMetricsTrend: {
		method: 'GET',
		path: '/api/performance-metrics-trend'
	},
	getPermissionLookup: {
		method: 'GET',
		path: '/api/basic-permissions'
	},
	getPerspectiveContext: {
		method: 'GET',
		path: '/api/context/perspective'
	},
	getPolarionWorkItemLinkRoles: {
		method: 'PUT',
		path: '/api/spec-items/polarion/{accountName}/work-items/link-roles',
		contentType: 'application/json'
	},
	getPolarionWorkItemTypes: {
		method: 'PUT',
		path: '/api/spec-items/polarion/{accountName}/work-items/types',
		contentType: 'application/json'
	},
	getPortfolioManualAssessments: {
		method: 'GET',
		path: '/api/quality-reports/portfolio-overview/manuals/{project}'
	},
	getPortfolioOverview: {
		method: 'GET',
		path: '/api/quality-reports/portfolio-overview'
	},
	getPostponedRollbackCounts: {
		method: 'GET',
		path: '/api/project-analysis-states/postponed-rollbacks'
	},
	getPreCommitContent: {
		method: 'GET',
		path: '/api/projects/{project}/content/formatted/pre-commit'
	},
	getProbeCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/probe-coverage/{uniformPath}'
	},
	getProfilerConfiguration: {
		method: 'GET',
		path: '/api/profiler-configurations/{configurationId}'
	},
	getProject: {
		method: 'GET',
		path: '/api/projects/{project}'
	},
	getProjectAnalysisProgress: {
		method: 'GET',
		path: '/api/projects/{project}/analysis-progress'
	},
	getProjectConfiguration: {
		method: 'GET',
		path: '/api/projects/{project}/configuration'
	},
	getProjectConnectorStatusDetails: {
		method: 'GET',
		path: '/api/projects/{project}/connectors/statuses'
	},
	getProjectCredentials: {
		method: 'GET',
		path: '/api/projects/credentials'
	},
	getProjectCriticalEventLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/details/{timestamp}'
	},
	getProjectCriticalEventLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/frequencies'
	},
	getProjectCriticalEventLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events'
	},
	getProjectIdMap: {
		method: 'GET',
		path: '/api/project-ids'
	},
	getProjectInteractionLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/details/{timestamp}'
	},
	getProjectInteractionLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/frequencies'
	},
	getProjectInteractionLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction'
	},
	getProjectJavascriptErrorLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/details/{timestamp}'
	},
	getProjectJavascriptErrorLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/frequencies'
	},
	getProjectJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors'
	},
	getProjectNotificationRules: {
		method: 'GET',
		path: '/api/notification-rules/project'
	},
	getProjectOptionSchema: {
		method: 'GET',
		path: '/api/projects/{project}/options/schema'
	},
	getProjectOptions: {
		method: 'GET',
		path: '/api/projects/{project}/options'
	},
	getProjectRolesWithAssignments: {
		method: 'GET',
		path: '/api/roles/project-role-assignments/{instance}'
	},
	getProjectServiceLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/details/{timestamp}'
	},
	getProjectServiceLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/frequencies'
	},
	getProjectServiceLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service'
	},
	getProjectUserActivityTrend: {
		method: 'GET',
		path: '/api/unique-project-users/trend'
	},
	getProjectWorkerLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/details/{timestamp}'
	},
	getProjectWorkerLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/frequencies'
	},
	getProjectWorkerLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker'
	},
	getProjectsConnectorStates: {
		method: 'GET',
		path: '/api/project-analysis-states/connectors'
	},
	getProjectsConnectors: {
		method: 'GET',
		path: '/api/project-connectors'
	},
	getProjectsStates: {
		method: 'GET',
		path: '/api/project-analysis-states'
	},
	getProjectsWithTimestampBasedThresholds: {
		method: 'GET',
		path: '/api/projects-with-timestamp-based-thresholds'
	},
	getQualityIndicators: {
		method: 'GET',
		path: '/api/projects/{project}/configuration/quality-indicators'
	},
	getRandomFilesInRange: {
		method: 'GET',
		path: '/api/projects/{project}/random-files'
	},
	getReadWriteStatistics: {
		method: 'GET',
		path: '/api/io-performance',
		acceptType: 'text/plain'
	},
	getRecentBranches: {
		method: 'GET',
		path: '/api/projects/{project}/recent-branches'
	},
	getRedundantLiteralTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals/treemap'
	},
	getRedundantLiteralsQuery: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals'
	},
	getRefactoringSuggestions: {
		method: 'GET',
		path: '/api/projects/{project}/findings/{findingId}/extract-method-suggestions'
	},
	getRelatedChangesTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/{testExecution}/related-changes/treemap'
	},
	getReport: {
		method: 'GET',
		path: '/api/quality-reports/{id}'
	},
	getReportSlide: {
		method: 'GET',
		path: '/api/quality-reports/{reportId}/slides/{slideId}'
	},
	getRepositoryActivitySummary: {
		method: 'GET',
		path: '/api/projects/{project}/repository-summary/details'
	},
	getRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/repository-churn'
	},
	getRepositoryLog: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs'
	},
	getRepositoryLogCount: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs/count'
	},
	getRepositoryLogWithSplitAggregates: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs/split'
	},
	getRepositorySummary: {
		method: 'GET',
		path: '/api/projects/{project}/repository-summary'
	},
	getResolvedTasks: {
		method: 'GET',
		path: '/api/projects/{project}/tasks'
	},
	getResourceHistory: {
		method: 'GET',
		path: '/api/projects/{project}/{uniformPath}/resource-history'
	},
	getResourceType: {
		method: 'GET',
		path: '/api/projects/{project}/resource-type'
	},
	getRetrospective: {
		method: 'GET',
		path: '/api/retrospectives/{id}'
	},
	getRetrospectiveNote: {
		method: 'GET',
		path: '/api/retrospectives/{id}/notes/{projectId}'
	},
	getRetrospectivesForProject: {
		method: 'GET',
		path: '/api/retrospectives'
	},
	getReviewBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baseline'
	},
	getReviewStatus: {
		method: 'GET',
		path: '/api/projects/{project}/{uniformPath}/review-status'
	},
	getRisks: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/risks'
	},
	getRoleSchema: {
		method: 'GET',
		path: '/api/roles'
	},
	getRunningProfiler: {
		method: 'GET',
		path: '/api/running-profilers/{profilerId}'
	},
	getRunningProfilers: {
		method: 'GET',
		path: '/api/running-profilers'
	},
	getSamlIdentityProviderMetadata: {
		method: 'GET',
		path: '/api/auth/saml/idp-metadata'
	},
	getSapJcoLibraryIsLoaded: {
		method: 'GET',
		path: '/api/sap-jco-loaded'
	},
	getScatterPlotMetrics: {
		method: 'GET',
		path: '/api/projects/{project}/metrics-scatter-plot'
	},
	getSchedulerControlPage: {
		method: 'GET',
		path: '/api/scheduler',
		acceptType: 'text/html'
	},
	getSearchAutocompletionResults: {
		method: 'GET',
		path: '/api/search/autocomplete'
	},
	getSearchResults: {
		method: 'GET',
		path: '/api/search'
	},
	getSelectedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	getServerOptionSchema: {
		method: 'GET',
		path: '/api/options/server/schema'
	},
	getServerOptions: {
		method: 'GET',
		path: '/api/options/server'
	},
	getServiceLoad: {
		method: 'GET',
		path: '/api/service-load'
	},
	getSimulinkFileReferenceDependencies: {
		method: 'GET',
		path: '/api/projects/{project}/simulink/file-references/{uniformPath}'
	},
	getSimulinkModel: {
		method: 'GET',
		path: '/api/projects/{project}/simulink/model/{uniformPath}'
	},
	getSimulinkModelComparisonResult: {
		method: 'GET',
		path: '/api/projects/{project}/simulink/comparison'
	},
	getSimulinkSignalsForDependency: {
		method: 'GET',
		path: '/api/projects/{project}/simulink/dependencies/signals'
	},
	getSimulinkTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/simulink/test-coverage/{uniformPath}'
	},
	getSingleMetricDeltaTable: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/single-metric/delta-table'
	},
	getSingleMetricDeltaTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/single-metric/delta-treemap'
	},
	getSinglePreprocessorExpansion: {
		method: 'GET',
		path: '/api/projects/{project}/preprocessor-expansions/{uniformPath}/expansions'
	},
	getSingleProjectLatexTable: {
		method: 'GET',
		path: '/api/projects/{project}/audit/latex-table'
	},
	getSingleProjectOption: {
		method: 'GET',
		path: '/api/projects/{project}/options/{optionId}'
	},
	getSingleServerOption: {
		method: 'GET',
		path: '/api/options/server/{optionId}'
	},
	getSlideImage: {
		method: 'GET',
		path: '/api/reports/{reportId}/images/{id}',
		acceptType: 'image/*'
	},
	getSnapshot: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	getSnapshotStatus: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/status'
	},
	getSpecItem: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}'
	},
	getSpecItemChurn: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-spec-items'
	},
	getSpecItemCodeReferences: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/code-references'
	},
	getSpecItemCount: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/count'
	},
	getSpecItemDetails: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/details'
	},
	getSpecItemGraph: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/graph'
	},
	getSpecItemQueries: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/queries'
	},
	getSpecItemTestExecutions: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/test-executions'
	},
	getSpecItemVerifies: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/verifies'
	},
	getSpecItemsByIdAndSorted: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items'
	},
	getSpecItemsVerifiedByQueried: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/verified-by-queried-items'
	},
	getSpecItemsVerifyingQueried: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/verify-queried-items'
	},
	getStacktrace: {
		method: 'GET',
		path: '/api/projects/{project}/tests/{test}/stacktrace'
	},
	getStateChangeTimestamps: {
		method: 'GET',
		path: '/api/debug/performance/state/timestamps/{project}'
	},
	getStaticMetricBadge: {
		method: 'GET',
		path: '/api/badges/metric/static',
		acceptType: 'image/svg+xml'
	},
	getStatusOfCloneDetection: {
		method: 'GET',
		path: '/api/projects/{project}/audit/external-x-clones/status'
	},
	getStorePerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/store-details.csv'
	},
	getStoredServiceProviderConfiguration: {
		method: 'GET',
		path: '/api/auth/saml/sp-configuration',
		acceptType: 'application/xml'
	},
	getStoredTestLists: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists'
	},
	getStoredTestListsNames: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists/names'
	},
	getStoredTestQueries: {
		method: 'GET',
		path: '/api/projects/{project}/tests/queries'
	},
	getSubjectRoleAssignments: {
		method: 'GET',
		path: '/api/subject-role-assignments/{subjectType}/{subjectId}'
	},
	getSystemInformation: {
		method: 'GET',
		path: '/api/system-info'
	},
	getTask: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{id}'
	},
	getTaskPreviousNext: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{task}/siblings'
	},
	getTaskSummary: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/summary'
	},
	getTaskTags: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/tags'
	},
	getTaskToFindingMapping: {
		method: 'POST',
		path: '/api/projects/{project}/findings/tasks',
		contentType: 'application/x-www-form-urlencoded'
	},
	getTasksWithCount: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/with-count'
	},
	getTeamscaleShutdownPage: {
		method: 'GET',
		path: '/api/shutdown',
		acceptType: 'text/html'
	},
	getTeamscaleUploadInvocation: {
		method: 'GET',
		path: '/api/projects/{project}/ts-upload-wizard/ts-upload-invocation'
	},
	getTeamscaleUploadWizardInput: {
		method: 'GET',
		path: '/api/projects/{project}/ts-upload-wizard'
	},
	getTechnologyDependencies: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/dependencies'
	},
	getTechnologyTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/treemap'
	},
	getTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/{uniformPath}'
	},
	getTestCoverageOverlay: {
		method: 'GET',
		path: '/api/projects/{project}/overlay-tests'
	},
	getTestCoveragePartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage-partitions/{uniformPath}'
	},
	getTestExecutionExecutionUnit: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/{testExecution}/partitions/{partition}/execution-unit'
	},
	getTestExecutionImplementation: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/{testExecution}/implementation'
	},
	getTestExecutionPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/{testExecution}/partitions'
	},
	getTestGapDataAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps.csv',
		acceptType: 'text/csv'
	},
	getTestGapTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/treemap'
	},
	getTestHistory: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/{testExecution}/history'
	},
	getTestImplementation: {
		method: 'GET',
		path: '/api/projects/{project}/test-implementations/{testImplementation}'
	},
	getTestImplementationTestExecutions: {
		method: 'GET',
		path: '/api/projects/{project}/test-implementations/{testImplementation}/executions'
	},
	getTestMinimizationJobStatus: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	getTestPathsAndExecResults: {
		method: 'GET',
		path: '/api/projects/{project}/code/{uniformPath}/tests'
	},
	getTestsExecutingMethod: {
		method: 'GET',
		path: '/api/projects/{project}/code/{uniformPath}/methods/{startOffset}-{endOffset}/tests'
	},
	getTestwiseCoveragePartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/testwise/partitions'
	},
	getTgaPercentage: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/percentage'
	},
	getTgaTable: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/metrics'
	},
	getTgaTestCoveragePartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/line-based/partitions'
	},
	getTgaTrend: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/trend'
	},
	getThresholdConfigurationDocumentation: {
		method: 'GET',
		path: '/api/threshold-configurations/{thresholdConfigurationName}/documentation'
	},
	getThresholdPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-threshold-path-for-threshold'
	},
	getTokenElementInfoDetails: {
		method: 'GET',
		path: '/api/projects/{project}/content/details'
	},
	getTrackerCustomFields: {
		method: 'PUT',
		path: '/api/connectors/custom-fields',
		contentType: 'application/json'
	},
	getTriggerGraphDot: {
		method: 'GET',
		path: '/api/projects/{project}/debug/triggers/graph-json'
	},
	getTriggerPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/details.csv'
	},
	getTypeToFileLookup: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/assessments/type-to-file-lookup'
	},
	getUnusedEventId: {
		method: 'GET',
		path: '/api/event-announcement/unused-id'
	},
	getUploadCommitDetails: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/commits/{commit}'
	},
	getUsageDataAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/code-usage/csv',
		acceptType: 'text/csv'
	},
	getUsageDataPreview: {
		method: 'POST',
		path: '/api/usage-data/preview',
		contentType: 'application/json'
	},
	getUser: {
		method: 'GET',
		path: '/api/users/{user}'
	},
	getUserAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/users/auto-completion-suggestions/{input}'
	},
	getUserAvatar: {
		method: 'GET',
		path: '/api/users/{user}/avatar',
		acceptType: 'image/*'
	},
	getUserGroup: {
		method: 'GET',
		path: '/api/user-groups/{userGroupName}'
	},
	getUserOptionSchema: {
		method: 'GET',
		path: '/api/users/options/schema'
	},
	getUserOptions: {
		method: 'GET',
		path: '/api/users/{user}/options'
	},
	getUserToCommitMap: {
		method: 'GET',
		path: '/api/projects/{project}/commit-chart'
	},
	getVerifiedBySpecItems: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/verified-by'
	},
	getWidgetContext: {
		method: 'GET',
		path: '/api/dashboards/widget-context'
	},
	getWorkerStatus: {
		method: 'GET',
		path: '/api/execution-status/workers'
	},
	hasSufficientRam: {
		method: 'GET',
		path: '/api/global-warnings/sufficientRam'
	},
	hideEventAnnouncement: {
		method: 'POST',
		path: '/api/event-announcement/hide',
		contentType: 'application/json'
	},
	importAbapLintConfigurationFile: {
		method: 'POST',
		path: '/api/import-abaplint-configuration',
		contentType: 'multipart/form-data'
	},
	importAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles/import',
		contentType: 'multipart/form-data'
	},
	importAndReplaceDashboards: {
		method: 'PUT',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importBackup: {
		method: 'POST',
		path: '/api/backups/import',
		contentType: 'multipart/form-data'
	},
	importDashboard: {
		method: 'POST',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates/import',
		contentType: 'multipart/form-data'
	},
	importExternalFindings: {
		method: 'POST',
		path: '/api/external-findings/import',
		contentType: 'multipart/form-data'
	},
	importExternalMetrics: {
		method: 'POST',
		path: '/api/external-metrics/import',
		contentType: 'multipart/form-data'
	},
	importGroup: {
		method: 'POST',
		path: '/api/auth/import/groups/{groupName}'
	},
	importMetricThresholdsConfiguration: {
		method: 'POST',
		path: '/api/metric-thresholds/import',
		contentType: 'multipart/form-data'
	},
	importProjectConfiguration: {
		method: 'POST',
		path: '/api/projects/import',
		contentType: 'multipart/form-data'
	},
	importSonarProfile: {
		method: 'POST',
		path: '/api/import-sonar-profile',
		contentType: 'multipart/form-data'
	},
	importSwiftLintConfiguration: {
		method: 'POST',
		path: '/api/import-swiftlint-configuration',
		contentType: 'multipart/form-data'
	},
	importUser: {
		method: 'POST',
		path: '/api/auth/import/users/{userName}'
	},
	isIssueQueryValid: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/validation'
	},
	isMergeRequestsEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/merge-request'
	},
	isReviewFindingsEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/review-finding'
	},
	isSnapshotBackupSupported: {
		method: 'GET',
		path: '/api/storage-snapshot'
	},
	isSpecItemAnalysisEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/spec-item'
	},
	isSpecItemQueryValid: {
		method: 'GET',
		path: '/api/projects/{project}/spec-item-query/validation'
	},
	isTestQueryValid: {
		method: 'GET',
		path: '/api/projects/{project}/test-query/validation'
	},
	listAllParseLogEntries: {
		method: 'GET',
		path: '/api/projects/{project}/parse-log/all'
	},
	listMergeRequests: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests'
	},
	listQualityReports: {
		method: 'GET',
		path: '/api/quality-reports'
	},
	loadFromNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/load-from-named-list'
	},
	logout: {
		method: 'GET',
		path: '/logout'
	},
	markDashboardAsFavorite: {
		method: 'POST',
		path: '/api/dashboards/{dashboardId}/favorite'
	},
	performIssueQuery: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query'
	},
	performIssueTgaQuery: {
		method: 'GET',
		path: '/api/projects/{project}/issue-query/with-tga'
	},
	performLogin: {
		method: 'POST',
		path: '/api/auth/login',
		contentType: 'application/json'
	},
	performSpecItemQuery: {
		method: 'GET',
		path: '/api/projects/{project}/spec-item-query'
	},
	performTestQuery: {
		method: 'GET',
		path: '/api/projects/{project}/test-query'
	},
	pollPreCommitResults: {
		method: 'GET',
		path: '/api/projects/{project}/pre-commit3/{token}'
	},
	postArchitectureAssessment: {
		method: 'POST',
		path: '/api/projects/{project}/architectures/assessments',
		contentType: 'application/json'
	},
	postBasicRoleForInstance: {
		method: 'POST',
		path: '/api/roles/basic-role-assignments/{instance}',
		contentType: 'application/json'
	},
	postBasicRoleGlobally: {
		method: 'POST',
		path: '/api/roles/basic-role-assignments',
		contentType: 'application/json'
	},
	postGlobalRole: {
		method: 'POST',
		path: '/api/roles/global-roles',
		contentType: 'application/json'
	},
	postGlobalRoleWithAssignments: {
		method: 'POST',
		path: '/api/roles/global-role-assignments',
		contentType: 'application/json'
	},
	postJobTests: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	postProjectRole: {
		method: 'POST',
		path: '/api/roles/project-roles',
		contentType: 'application/json'
	},
	postResolveUserAliases: {
		method: 'POST',
		path: '/api/users/names/resolution',
		contentType: 'application/x-www-form-urlencoded'
	},
	postRoleChangeForInstance: {
		method: 'POST',
		path: '/api/roles/project-role-assignments/{instance}',
		contentType: 'application/json'
	},
	postRoleChangeGlobally: {
		method: 'POST',
		path: '/api/roles/project-role-assignments',
		contentType: 'application/json'
	},
	previewRepositoryBranches: {
		method: 'POST',
		path: '/api/branches-retriever',
		contentType: 'application/json'
	},
	previewRepositoryCommitMessages: {
		method: 'POST',
		path: '/api/commit-messages-retriever',
		contentType: 'application/json'
	},
	previewRepositoryFiles: {
		method: 'POST',
		path: '/api/files-retriever',
		contentType: 'application/json'
	},
	processBuildWebhookEvent: {
		method: 'POST',
		path: '/api/projects/{project}/build-web-hook'
	},
	processWebhook: {
		method: 'POST',
		path: '/api/gitea/web-hook',
		contentType: 'application/json'
	},
	putGlobalRole: {
		method: 'PUT',
		path: '/api/roles/global-roles/{name}',
		contentType: 'application/json'
	},
	putJobTestsFromImpacted: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests-from-impacted'
	},
	putJobTestsFromQuery: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	putProjectRole: {
		method: 'PUT',
		path: '/api/roles/project-roles/{name}',
		contentType: 'application/json'
	},
	putUser: {
		method: 'PUT',
		path: '/api/users/{user}',
		contentType: 'application/json'
	},
	receiveAnalysisCompletedConfirmation: {
		method: 'POST',
		path: '/api/analysis/clangtidy/completion-hook'
	},
	receiveAnalysisCompletedConfirmation1: {
		method: 'POST',
		path: '/api/analysis/sonarlint/completion-hook'
	},
	receiveHeartbeat: {
		method: 'PUT',
		path: '/api/running-profilers/{profilerId}',
		contentType: 'application/json'
	},
	redirectToCorrectHost: {
		method: 'GET',
		path: '/api/instance-comparison/redirect'
	},
	regenerateStoredServiceProviderCertificate: {
		method: 'POST',
		path: '/api/auth/saml/sp-configuration/certificate/generate'
	},
	registerProfiler: {
		method: 'POST',
		path: '/api/running-profilers',
		contentType: 'application/json'
	},
	registerProjectViewedByUser: {
		method: 'POST',
		path: '/api/projects/{project}/visited'
	},
	registerVisitedBranch: {
		method: 'POST',
		path: '/api/projects/{project}/recent-branches/{branchName}'
	},
	removeAccessKeys: {
		method: 'DELETE',
		path: '/api/users/{user}/access-key'
	},
	removePortfolioManualAssessment: {
		method: 'DELETE',
		path: '/api/quality-reports/portfolio-overview/manuals/{project}',
		contentType: 'application/json'
	},
	removeQualityReportSlides: {
		method: 'POST',
		path: '/api/quality-reports/{reportId}/remove-slides',
		contentType: 'application/json'
	},
	reportJavascriptError: {
		method: 'POST',
		path: '/api/logs/javascript-errors/report',
		contentType: 'application/json'
	},
	requestAnalysisAndOpenSession: {
		method: 'POST',
		path: '/api/analysis/clangtidy-execution',
		contentType: 'application/json'
	},
	requestAnalysisAndOpenSession1: {
		method: 'POST',
		path: '/api/analysis/sonarlint',
		contentType: 'application/json'
	},
	requestPreCommitAnalysis: {
		method: 'PUT',
		path: '/api/projects/{project}/pre-commit3',
		contentType: 'application/json'
	},
	resolveCommit: {
		method: 'GET',
		path: '/api/projects/{project}/commit-resolver/{unresolvedCommit}'
	},
	resolveReviewFinding: {
		method: 'POST',
		path: '/api/projects/{project}/review-findings/{findingId}/resolution'
	},
	resolveTag: {
		method: 'POST',
		path: '/api/projects/{project}/git-tags/resolve',
		contentType: 'application/json'
	},
	revoteOnAllOpenMergeRequests: {
		method: 'POST',
		path: '/api/projects/{project}/merge-requests/re-vote'
	},
	revoteOnMergeRequest: {
		method: 'POST',
		path: '/api/projects/{project}/merge-requests/re-vote/{idWithRepository}'
	},
	runDatabaseCompaction: {
		method: 'POST',
		path: '/api/database-compaction'
	},
	saveAsNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/save-as-named-list',
		contentType: 'application/json'
	},
	saveIssueQueryDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/issues/queries',
		contentType: 'application/json'
	},
	saveSpecItemQueryDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/spec-items/queries',
		contentType: 'application/json'
	},
	saveTestListDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/test-lists',
		contentType: 'application/json'
	},
	saveTestQueryDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/tests/queries',
		contentType: 'application/json'
	},
	scheduleCloneDetectionTrigger: {
		method: 'POST',
		path: '/api/projects/{project}/audit/external-x-clones',
		contentType: 'application/json'
	},
	sendTestEmail: {
		method: 'POST',
		path: '/api/mail-settings/test'
	},
	setMetricNotificationRules: {
		method: 'PUT',
		path: '/api/notification-rules/metric',
		contentType: 'application/json'
	},
	setNotificationRules: {
		method: 'PUT',
		path: '/api/notification-rules/findings',
		contentType: 'application/json'
	},
	setProjectNotificationRules: {
		method: 'PUT',
		path: '/api/notification-rules/project',
		contentType: 'application/json'
	},
	setProjectSchedulePausing: {
		method: 'PUT',
		path: '/api/scheduler/projects/{project}',
		contentType: 'application/json'
	},
	setReviewStatus: {
		method: 'POST',
		path: '/api/projects/{project}/{uniformPath}/review-status',
		contentType: 'application/json'
	},
	setSchedulingMode: {
		method: 'PUT',
		path: '/api/scheduler/mode',
		contentType: 'application/json'
	},
	setupJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	showDefaultAdminWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultAdmin'
	},
	showDefaultBackupSettingsWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultBackupSettings'
	},
	showInMemoryWarning: {
		method: 'GET',
		path: '/api/global-warnings/inMemory'
	},
	showLegacyWebHookWarning: {
		method: 'GET',
		path: '/api/disable-webhook-creation-default/legacy'
	},
	showShadowModeWarning: {
		method: 'GET',
		path: '/api/global-warnings/shadowMode'
	},
	shutdown: {
		method: 'POST',
		path: '/api/shutdown',
		contentType: 'application/x-www-form-urlencoded'
	},
	startJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/start'
	},
	stopAndDeleteMinimizationJob: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	storeEventAnnouncement: {
		method: 'PUT',
		path: '/api/event-announcement/events/{eventId}',
		contentType: 'application/json'
	},
	synchronizeAllGroups: {
		method: 'POST',
		path: '/api/auth/synchronization/groups'
	},
	synchronizeAllUsers: {
		method: 'POST',
		path: '/api/auth/synchronization/users'
	},
	synchronizeSingleGroup: {
		method: 'POST',
		path: '/api/auth/synchronization/groups/{groupName}'
	},
	triggerMultiReanalysis: {
		method: 'POST',
		path: '/api/projects/reanalyze',
		contentType: 'application/json'
	},
	triggerReanalysis: {
		method: 'POST',
		path: '/api/projects/{project}/reanalysis'
	},
	triggerS3CommitHook: {
		method: 'POST',
		path: '/api/s3/web-hook',
		contentType: 'application/json'
	},
	triggerSnapshotBackup: {
		method: 'POST',
		path: '/api/storage-snapshot',
		contentType: 'multipart/form-data'
	},
	tryGetUser: {
		method: 'GET',
		path: '/api/users/{user}/try'
	},
	tryResolveNonCodeLink: {
		method: 'GET',
		path: '/api/projects/{project}/content/resolved'
	},
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: {
		method: 'DELETE',
		path: '/api/projects/{project}/findings/unmark-before-commit'
	},
	unregisterProfiler: {
		method: 'DELETE',
		path: '/api/running-profilers/{profilerId}'
	},
	updateAnalysisProfile: {
		method: 'PUT',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	updateDashboardTemplate: {
		method: 'PUT',
		path: '/api/dashboard-templates/{templateId}',
		contentType: 'application/json'
	},
	updateExternalAccounts: {
		method: 'PUT',
		path: '/api/external-accounts/{externalCredentialsName}',
		contentType: 'application/json'
	},
	updateExternalAnalysisGroup: {
		method: 'PUT',
		path: '/api/external-findings/groups/{groupName}',
		contentType: 'application/json'
	},
	updateExternalFindingDescription: {
		method: 'PUT',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionTypeId}',
		contentType: 'application/json'
	},
	updateExternalMetricSchema: {
		method: 'POST',
		path: '/api/projects/{project}/metric-update'
	},
	updateExternalMetrics: {
		method: 'PUT',
		path: '/api/external-metrics',
		contentType: 'application/json'
	},
	updateExternalStorageBackend: {
		method: 'PUT',
		path: '/api/external-storage-backends/{externalStorageBackendName}',
		contentType: 'application/json'
	},
	updateJobOptions: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	updateMetricThresholdConfiguration: {
		method: 'PUT',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	updatePassword: {
		method: 'PUT',
		path: '/api/authentication/{username}',
		contentType: 'application/json'
	},
	updatePortfolioSelectedManualAssessment: {
		method: 'POST',
		path: '/api/quality-reports/portfolio-overview/manuals/{project}/selected',
		contentType: 'application/json'
	},
	updateProfilerConfiguration: {
		method: 'PUT',
		path: '/api/profiler-configurations/{configurationId}',
		contentType: 'application/json'
	},
	updateQualityReport: {
		method: 'PUT',
		path: '/api/quality-reports/{id}',
		contentType: 'application/json'
	},
	updateQualityReportSlides: {
		method: 'PUT',
		path: '/api/quality-reports/{id}/update-slides',
		contentType: 'application/json'
	},
	updateRetrospective: {
		method: 'PUT',
		path: '/api/retrospectives/{id}',
		contentType: 'application/json'
	},
	updateRetrospectiveNote: {
		method: 'PUT',
		path: '/api/retrospectives/{id}/notes/{projectId}',
		contentType: 'application/json'
	},
	updateTask: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}',
		contentType: 'application/json'
	},
	updateTaskResolution: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}/resolution',
		contentType: 'application/json'
	},
	updateTaskStatus: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}/status',
		contentType: 'application/json'
	},
	updateUserAvatar: {
		method: 'PUT',
		path: '/api/users/{user}/avatar',
		contentType: 'multipart/form-data'
	},
	updateUserGroup: {
		method: 'PUT',
		path: '/api/user-groups/{oldUserGroupName}',
		contentType: 'application/json'
	},
	uploadArchitecture: {
		method: 'POST',
		path: '/api/projects/{project}/architectures',
		contentType: 'multipart/form-data'
	},
	uploadArchitectureAsJson: {
		method: 'POST',
		path: '/api/projects/{project}/architectures/as-json',
		contentType: 'application/json'
	},
	uploadExternalSnapshot: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots/external',
		contentType: 'multipart/form-data'
	},
	uploadReport: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}/report',
		contentType: 'multipart/form-data'
	},
	uploadSlideImage: {
		method: 'POST',
		path: '/api/reports/{reportId}/images',
		contentType: 'multipart/form-data'
	},
	validateAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles/validate',
		contentType: 'application/json'
	},
	validateBranchingConfiguration: {
		method: 'PUT',
		path: '/api/branching-configuration/validation',
		contentType: 'application/json'
	},
	validateConnector: {
		method: 'PUT',
		path: '/api/validate-connector',
		contentType: 'application/json'
	},
	validateRegex: {
		method: 'GET',
		path: '/api/validate-regex'
	},
	verifyPowershellScriptAnalyzerSetup: {
		method: 'GET',
		path: '/api/verify-powershell-script-analyzer-setup'
	},
	wasLegacyApiUsed: {
		method: 'GET',
		path: '/api/global-warnings/legacy-api-usage'
	}
} satisfies Record<string, OperationInfo>;
